import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/styles';
import { generateKey } from 'shared/functions/generateKey';
import React, { ReactElement, FC, useState } from 'react';
import { BaseProps } from 'shared/types';
import { theme } from 'theme';
import { useTranslation } from 'react-i18next';
import { MoreIcon, StarIcon, DownloadIcon, CopyIcon, CancelIcon, ClaimIcon } from 'components';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { useCommonStyles } from 'shared/styles/common';

type IThreeDotsMenuItem = {
  icon: string;
  value: string;
  translateCode: string;
  actionType: string;
  action: string | (() => void);
  hidden?: boolean;
};

type ThreeDotsMenuProps = {
  items?: IThreeDotsMenuItem[];
} & BaseProps;

const useStyles = makeStyles({
  // TODO: move to common when will be possible to avoid double-code
  root: {
    color: theme.palette.custom.veryDarkViolet,
    whiteSpace: 'nowrap',
  },
  iconWrapper: {
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    '&.active': {
      boxShadow: theme.shadows[1],
      zIndex: 10,
    },
    '&.active, &:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.custom.darkCyan,
      borderRadius: '50%',
      '& svg path': {
        fill: 'white',
      },
    },
  },
});

const renderIcon = (icon: string) => {
  switch (icon) {
    case 'Star': {
      return <StarIcon />;
    }
    case 'Download': {
      return <DownloadIcon />;
    }
    case 'Copy': {
      return <CopyIcon />;
    }
    case 'Cancel': {
      return <CancelIcon />;
    }
    case 'Claim': {
      return <ClaimIcon />;
    }
    default: {
      return null;
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleClick = (type: string, link: any) => {
  if (type === 'link') {
    window.location.href = link;
  } else {
    link();
  }
};

const ThreeDotsMenu: FC<ThreeDotsMenuProps> = ({ items }): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [openMenu, setOpenMenu] = useState(false);

  const handleClickAway = () => {
    if (openMenu) {
      setOpenMenu(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box position="relative">
        <div
          onClick={() => setOpenMenu(!openMenu)}
          onKeyDown={() => setOpenMenu(!openMenu)}
          role="button"
          tabIndex={0}
          className={classes.iconWrapper + (openMenu ? ' active' : '')}
        >
          <MoreIcon />
        </div>
        {openMenu && items && items.length && (
          <Box
            position="absolute"
            bgcolor={theme.palette.custom.white}
            borderRadius={12}
            paddingY={3}
            paddingRight={0}
            paddingLeft={0}
            paddingTop={3}
            paddingBottom={3}
            boxShadow={1}
            right={16}
            top={16}
            zIndex={5}
            minWidth={200}
          >
            {items.map((item, i) => (
              <MenuItem
                className={clsx(
                  classes.root,
                  commonClasses.pr12,
                  item?.hidden && commonClasses.hidden,
                )}
                key={generateKey(i, 'three-dots')}
                onClick={() => handleClick(item.actionType, item.action)}
              >
                <span className={clsx(commonClasses.pr3, commonClasses.leading3)}>
                  {renderIcon(item.icon)}
                </span>{' '}
                {t(item.translateCode)}
              </MenuItem>
            ))}
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default ThreeDotsMenu;
