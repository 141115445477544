import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const SenderIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 12,
  height = 11,
  fill = '#858585',
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00391 10H2.49609C2.35547 10 2.23828 9.95312 2.14453 9.85938C2.05078 9.75781 2.00391 9.63672 2.00391 9.49609V1.50391C2.00391 1.36328 2.05078 1.24609 2.14453 1.15234C2.23828 1.05078 2.35547 1 2.49609 1H5.00391C5.14453 1 5.26172 0.953125 5.35547 0.859375C5.44922 0.757812 5.49609 0.636719 5.49609 0.496094C5.49609 0.355469 5.44922 0.238281 5.35547 0.144531C5.26172 0.0507812 5.14453 0.00390625 5.00391 0.00390625H2.49609C2.07422 0.0117188 1.72266 0.15625 1.44141 0.4375C1.16016 0.71875 1.01172 1.07422 0.996094 1.50391V9.49609C1.01172 9.92578 1.16016 10.2812 1.44141 10.5625C1.72266 10.8438 2.07422 10.9883 2.49609 10.9961H5.00391C5.14453 10.9961 5.26172 10.9492 5.35547 10.8555C5.44922 10.7617 5.49609 10.6445 5.49609 10.5039C5.49609 10.3633 5.44922 10.2422 5.35547 10.1406C5.26172 10.0469 5.14453 10 5.00391 10ZM8.14453 7.15234C8.05078 7.25391 8.00391 7.37109 8.00391 7.50391C8.00391 7.62891 8.05469 7.74219 8.15625 7.84375C8.25781 7.94531 8.37109 7.99609 8.49609 7.99609C8.62891 7.99609 8.74609 7.94922 8.84766 7.85547L10.8516 5.85156C10.9531 5.75 11.0039 5.63281 11.0039 5.5C11.0039 5.36719 10.9531 5.25 10.8516 5.14844L8.84766 3.14453C8.74609 3.05078 8.62891 3.00391 8.49609 3.00391C8.37109 3.00391 8.25781 3.05469 8.15625 3.15625C8.05469 3.25781 8.00391 3.375 8.00391 3.50781C8.00391 3.63281 8.05078 3.74609 8.14453 3.84766L9.79688 5.5L8.14453 7.15234ZM10.5 4.99609H4.5C4.35938 5.00391 4.24219 5.05469 4.14844 5.14844C4.05469 5.24219 4.00781 5.35938 4.00781 5.5C4.00781 5.64062 4.05469 5.75781 4.14844 5.85156C4.24219 5.94531 4.35938 5.99609 4.5 6.00391H10.5C10.6406 5.99609 10.7578 5.94531 10.8516 5.85156C10.9453 5.75781 10.9922 5.64062 10.9922 5.5C10.9922 5.35938 10.9453 5.24219 10.8516 5.14844C10.7578 5.05469 10.6406 5.00391 10.5 4.99609Z"
        fill={fill}
      />
    </svg>
  );
};

export default SenderIcon;
