import qs from 'qs';
import { useFetchApi } from 'shared/functions/fetchApi';
import { ApiResponse } from 'shared/types/api';
import {
  PriceRequestApiRequest,
  PriceRequestDetailsPayload,
  PriceRequestsApiRequest,
  PriceRequestsPayload,
  RejectOfferApiRequest,
  SavePriceRequestApiRequest,
  PriceRequestStatus,
  ApproveOfferApiRequest,
  PriceOfferRejectReason,
  UnSavedPriceRequestApiRequest,
} from 'shared/types/priceRequests/priceRequests';
import {
  ShipmentCommentsApiRequest,
  ShipmentCommentsPayload,
} from 'shared/types/shipments/shipments';

type UsePriceRequestsReturnType = {
  fetchPriceRequests: (
    request: PriceRequestsApiRequest,
  ) => Promise<ApiResponse<PriceRequestsPayload>>;
  fetchPriceRequest: (
    request: PriceRequestApiRequest,
  ) => Promise<ApiResponse<PriceRequestDetailsPayload>>;
  deleteDraft: (request: PriceRequestApiRequest) => Promise<ApiResponse<boolean>>;
  cancelPriceRequest: (request: PriceRequestApiRequest) => Promise<ApiResponse<boolean>>;
  savePriceRequest: (request: SavePriceRequestApiRequest) => Promise<ApiResponse<number>>;
  rejectPriceOffer: (request: RejectOfferApiRequest) => Promise<ApiResponse<boolean>>;
  approvePriceOffer: (request: ApproveOfferApiRequest) => Promise<ApiResponse<boolean>>;
  fetchPriceRequestComments: (
    request: ShipmentCommentsApiRequest,
  ) => Promise<ApiResponse<ShipmentCommentsPayload>>;
  saveUnSavedPriceRequest: (request: UnSavedPriceRequestApiRequest) => Promise<ApiResponse<string>>;
};

export const usePriceRequests = (): UsePriceRequestsReturnType => {
  const { fetchApi } = useFetchApi();

  const fetchPriceRequests = async ({
    page = 1,
    sizePerPage = 25,
    createdByMe: CreatedByMe = true,
    sortName,
    sortOrder,
    filters = {},
  }: PriceRequestsApiRequest): Promise<ApiResponse<PriceRequestsPayload>> => {
    const params = {
      page,
      sizePerPage,
      CreatedByMe,
      sortName,
      sortOrder,
      ...filters,
    };

    const response = await fetchApi(
      `/api/price-request?${qs.stringify(params, { arrayFormat: 'comma' })}`,
    );
    return response.json();
  };

  const fetchPriceRequest = async ({
    id,
  }: PriceRequestApiRequest): Promise<ApiResponse<PriceRequestDetailsPayload>> => {
    const responsePromise: Promise<ApiResponse<PriceRequestDetailsPayload>> = (
      await fetchApi(`/api/price-request/${id}`, undefined, undefined, true)
    ).json();

    return responsePromise.then((response) => ({
      ...response,
      payload: response.payload,
    }));
  };

  const fetchPriceRequestComments = async ({
    id,
  }: ShipmentCommentsApiRequest): Promise<ApiResponse<ShipmentCommentsPayload>> => {
    const response = await fetchApi(`/api/object_comment/${id}/get-comments/PriceRequest`, {
      method: 'GET',
    });
    return response.json();
  };

  const deleteDraft = async ({ id }: PriceRequestApiRequest): Promise<ApiResponse<boolean>> => {
    const response = await fetchApi(`/api/price-request/${id}`, { method: 'DELETE' });
    return response.json();
  };

  const cancelPriceRequest = async ({
    id,
  }: PriceRequestApiRequest): Promise<ApiResponse<boolean>> => {
    const body = JSON.stringify({ status: PriceRequestStatus.Canceled });

    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body,
    };
    const response = await fetchApi(`/api/price-request/update-status/${id}`, params);
    return response.json();
  };

  const savePriceRequest = async ({ id, payload, draft = false }: SavePriceRequestApiRequest) => {
    const savingPayload: PriceRequestDetailsPayload = {
      ...payload,
      regularShipmentsNumber: payload.regularShipmentsNumber
        ? payload.regularShipmentsNumber
        : null,
      regularShipmentsPeriod: payload.regularShipmentsPeriod
        ? payload.regularShipmentsPeriod
        : null,
    };
    const body = JSON.stringify(savingPayload);

    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: id ? 'PUT' : 'POST',
      body,
    };

    const idParam = id ? `${id}/` : '';

    const response = await fetchApi(
      `/api/price-request/${idParam}${draft ? '' : 'confirm'}`,
      params,
    );
    return response.json();
  };

  const rejectPriceOffer = async (payload: RejectOfferApiRequest) => {
    const { requestId } = payload;
    const body = JSON.stringify({ status: PriceRequestStatus.Rejected, ...payload });

    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body,
    };

    const response = await fetchApi(`/api/price-request/update-status/${requestId}`, params);
    return response.json();
  };

  const approvePriceOffer = async (payload: ApproveOfferApiRequest) => {
    const { requestId, selectedOffer } = payload;
    const body = JSON.stringify({
      status: PriceRequestStatus.Accepted,
      selectedOffer,
      rejectReason: PriceOfferRejectReason.NotSelected,
      notes: '',
    });

    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body,
    };

    const response = await fetchApi(`/api/price-request/update-status/${requestId}`, params);
    return response.json();
  };

  const saveUnSavedPriceRequest = async ({
    payload,
    id,
  }: UnSavedPriceRequestApiRequest): Promise<ApiResponse<string>> => {
    const body = JSON.stringify(payload);

    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body,
    };
    const response = await fetchApi(`/api/unfinished-objects/price-request/${id}`, params);
    return response.json();
  };
  return {
    fetchPriceRequests,
    fetchPriceRequest,
    savePriceRequest,
    deleteDraft,
    cancelPriceRequest,
    rejectPriceOffer,
    approvePriceOffer,
    fetchPriceRequestComments,
    saveUnSavedPriceRequest,
  };
};
