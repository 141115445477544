import React, { ReactElement, FC } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import { theme } from 'theme';

import { BaseProps } from 'shared/types';
import { useTranslation } from 'react-i18next';
import { useCommonStyles } from 'shared/styles/common';
import { Typography } from 'components';

type ShipmentItemsCommentsProps = {
  comments: string;
  bigHeading: boolean;
  translationKey?: string;
} & BaseProps;

const useStyles = makeStyles({
  title: {
    color: theme.palette.custom.veryDarkGray,
    fontWeight: 700,
  },
});

const ShipmentItemsComments: FC<ShipmentItemsCommentsProps> = ({
  comments,
  className,
  bigHeading,
  translationKey,
}): ReactElement => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const translation = translationKey ?? 'COMMENTS_REGARDING_THE_ITEMS';
  return (
    <div className={className}>
      <Typography
        variant={bigHeading ? 'subtitle2' : 'body2'}
        marginBottom={bigHeading ? 4 : 1}
        className={classes.title}
      >
        {t(translation)}
      </Typography>
      <Typography variant="body2" className={commonClasses.wordBreak}>
        {comments}
      </Typography>
    </div>
  );
};

export default ShipmentItemsComments;
