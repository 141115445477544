import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const TimesCircleIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 16,
  height = 16,
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15.3281C5.92708 15.276 4.19792 14.5625 2.8125 13.1875C1.4375 11.8021 0.723958 10.0729 0.671875 8C0.723958 5.92708 1.4375 4.20312 2.8125 2.82812C4.19792 1.44271 5.92708 0.723958 8 0.671875C10.0729 0.723958 11.7969 1.44271 13.1719 2.82812C14.5573 4.20312 15.276 5.92708 15.3281 8C15.276 10.0729 14.5573 11.8021 13.1719 13.1875C11.7969 14.5625 10.0729 15.276 8 15.3281ZM8 14C9.69792 13.9583 11.1094 13.375 12.2344 12.25C13.3698 11.1146 13.9583 9.69792 14 8C13.9583 6.30208 13.3698 4.89062 12.2344 3.76562C11.1094 2.63021 9.69792 2.04167 8 2C6.30208 2.04167 4.88542 2.63021 3.75 3.76562C2.625 4.89062 2.04167 6.30208 2 8C2.04167 9.69792 2.625 11.1146 3.75 12.25C4.88542 13.375 6.30208 13.9583 8 14ZM9.53125 5.53125L5.53125 9.53125C5.40625 9.66667 5.34375 9.82292 5.34375 10C5.34375 10.1771 5.40625 10.3333 5.53125 10.4688C5.66667 10.5938 5.82292 10.6562 6 10.6562C6.17708 10.6562 6.33333 10.5938 6.46875 10.4688L10.4688 6.46875C10.5625 6.38542 10.625 6.28646 10.6562 6.17188C10.6875 6.05729 10.6875 5.94271 10.6562 5.82812C10.625 5.70312 10.5677 5.59896 10.4844 5.51562C10.401 5.43229 10.2969 5.375 10.1719 5.34375C10.0573 5.3125 9.94271 5.3125 9.82812 5.34375C9.71354 5.375 9.61458 5.4375 9.53125 5.53125ZM5.53125 6.46875L9.53125 10.4688C9.66667 10.5938 9.82292 10.6562 10 10.6562C10.1771 10.6562 10.3281 10.5938 10.4531 10.4688C10.5885 10.3333 10.6562 10.1771 10.6562 10C10.6562 9.82292 10.5938 9.66667 10.4688 9.53125L6.46875 5.53125C6.33333 5.40625 6.17708 5.34375 6 5.34375C5.82292 5.34375 5.66667 5.41146 5.53125 5.54688C5.40625 5.67188 5.34375 5.82292 5.34375 6C5.34375 6.17708 5.40625 6.33333 5.53125 6.46875Z"
        fill="white"
      />
    </svg>
  );
};

export default TimesCircleIcon;
