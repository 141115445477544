import qs from 'qs';
import { useFetchApi } from 'shared/functions/fetchApi';
import {
  Address,
  AddressBasic,
  AddressBookImportPayload,
  AddressBooksApiRequest,
  AddressBooksPayload,
  SaveAddressApiRequest,
} from 'shared/types/addressBook/addressBook';
import { ApiBasePropsWithId, ApiResponse } from 'shared/types/api';
import { FilesUploadRequest } from 'shared/types/files';

type UseAddressBookReturnType = {
  fetchAddressBook: (request: AddressBooksApiRequest) => Promise<ApiResponse<AddressBooksPayload>>;
  deleteAddress: (request: ApiBasePropsWithId) => Promise<ApiResponse<boolean>>;
  saveAddress: (request: SaveAddressApiRequest) => Promise<ApiResponse<boolean>>;
  fetchAddress: (request: ApiBasePropsWithId) => Promise<ApiResponse<Address>>;
  fetchCompanyAddressForShipment: () => Promise<ApiResponse<AddressBasic>>;
  importAddresses: (request: FilesUploadRequest) => Promise<ApiResponse<AddressBookImportPayload>>;
  importFromWithHeldExcelFile: (
    request: ApiBasePropsWithId,
  ) => Promise<ApiResponse<AddressBookImportPayload>>;
};

export const useAddressBook = (): UseAddressBookReturnType => {
  const { fetchApi } = useFetchApi();

  const fetchAddressBook = async ({
    keyword,
    page = 1,
    sizePerPage = 25,
    sortName,
    sortOrder,
  }: AddressBooksApiRequest): Promise<ApiResponse<AddressBooksPayload>> => {
    const params = {
      keyword,
      page,
      sizePerPage,
      sortName,
      sortOrder,
    };

    const response = await fetchApi(
      `/api/addressbook?${qs.stringify(params, { arrayFormat: 'comma' })}`,
    );
    return response.json();
  };

  const fetchAddress = async ({ id }: ApiBasePropsWithId): Promise<ApiResponse<Address>> => {
    const response = await fetchApi(`/api/addressbook/${id}`);
    return response.json();
  };

  const importFromWithHeldExcelFile = async ({
    id,
  }: ApiBasePropsWithId): Promise<ApiResponse<AddressBookImportPayload>> => {
    const response = await fetchApi(`/api/addressbook/import-from-excel/${id}`);
    return response.json();
  };
  const fetchCompanyAddressForShipment = async (): Promise<ApiResponse<AddressBasic>> => {
    const response = await fetchApi(`/api/addressbook/0/debtor-contact`);
    return response.json();
  };

  const deleteAddress = async ({ id }: ApiBasePropsWithId): Promise<ApiResponse<boolean>> => {
    const response = await fetchApi(`/api/addressbook/${id}`, {
      method: 'DELETE',
    });
    return response.json();
  };

  const saveAddress = async ({
    id,
    address,
    isNew,
  }: SaveAddressApiRequest): Promise<ApiResponse<boolean>> => {
    const body = JSON.stringify(address);
    const params = {
      method: isNew ? 'POST' : 'PUT',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = isNew
      ? await fetchApi(`/api/addressbook`, params)
      : await fetchApi(`/api/addressbook/${id}`, params);
    return response.json();
  };

  const importAddresses = async ({
    files,
  }: FilesUploadRequest): Promise<ApiResponse<AddressBookImportPayload>> => {
    const formData = new FormData();

    for (let i = 0; i < files.length; i += 1) {
      formData.append(`files[${i}]`, files[i]);
    }

    const response = await fetchApi(
      `/api/addressbook/import-from-excel/`,
      {
        method: 'POST',
        body: formData,
      },
      ['Content-Type'],
    );

    return response.json();
  };

  return {
    fetchAddressBook,
    deleteAddress,
    fetchAddress,
    saveAddress,
    fetchCompanyAddressForShipment,
    importAddresses,
    importFromWithHeldExcelFile,
  };
};
