import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const LogOutIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 12,
  height = 14,
  fill = theme.palette.custom.veryDarkViolet,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M4.83789 12H1.91211C1.74805 12 1.61133 11.9453 1.50195 11.8359C1.39258 11.7174 1.33789 11.5762 1.33789 11.4121V2.08789C1.33789 1.92383 1.39258 1.78711 1.50195 1.67773C1.61133 1.55924 1.74805 1.5 1.91211 1.5H4.83789C5.00195 1.5 5.13867 1.44531 5.24805 1.33594C5.35742 1.21745 5.41211 1.07617 5.41211 0.912109C5.41211 0.748047 5.35742 0.611328 5.24805 0.501953C5.13867 0.392578 5.00195 0.337891 4.83789 0.337891H1.91211C1.41992 0.347005 1.00977 0.515625 0.681641 0.84375C0.353516 1.17188 0.180339 1.58659 0.162109 2.08789V11.4121C0.180339 11.9134 0.353516 12.3281 0.681641 12.6562C1.00977 12.9844 1.41992 13.153 1.91211 13.1621H4.83789C5.00195 13.1621 5.13867 13.1074 5.24805 12.998C5.35742 12.8887 5.41211 12.752 5.41211 12.5879C5.41211 12.4238 5.35742 12.2826 5.24805 12.1641C5.13867 12.0547 5.00195 12 4.83789 12ZM8.50195 8.67773C8.39258 8.79622 8.33789 8.93294 8.33789 9.08789C8.33789 9.23372 8.39714 9.36589 8.51562 9.48438C8.63411 9.60286 8.76628 9.66211 8.91211 9.66211C9.06706 9.66211 9.20378 9.60742 9.32227 9.49805L11.6602 7.16016C11.7786 7.04167 11.8379 6.90495 11.8379 6.75C11.8379 6.59505 11.7786 6.45833 11.6602 6.33984L9.32227 4.00195C9.20378 3.89258 9.06706 3.83789 8.91211 3.83789C8.76628 3.83789 8.63411 3.89714 8.51562 4.01562C8.39714 4.13411 8.33789 4.27083 8.33789 4.42578C8.33789 4.57161 8.39258 4.70378 8.50195 4.82227L10.4297 6.75L8.50195 8.67773ZM11.25 6.16211H4.25C4.08594 6.17122 3.94922 6.23047 3.83984 6.33984C3.73047 6.44922 3.67578 6.58594 3.67578 6.75C3.67578 6.91406 3.73047 7.05078 3.83984 7.16016C3.94922 7.26953 4.08594 7.32878 4.25 7.33789H11.25C11.4141 7.32878 11.5508 7.26953 11.6602 7.16016C11.7695 7.05078 11.8242 6.91406 11.8242 6.75C11.8242 6.58594 11.7695 6.44922 11.6602 6.33984C11.5508 6.23047 11.4141 6.17122 11.25 6.16211Z"
        fill={fill}
      />
    </svg>
  );
};

export default LogOutIcon;
