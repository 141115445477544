import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const InvoicesIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 20,
  height = 19,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 14"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path d="M1.33789 7.57031V9.08789H2.8418L8.67969 3.25L7.16211 1.74609L1.33789 7.57031ZM7.57227 0.501953L9.91016 2.83984C10.0286 2.95833 10.0879 3.09505 10.0879 3.25C10.0879 3.40495 10.0286 3.54167 9.91016 3.66016L3.49805 10.0723C3.37956 10.1908 3.24284 10.25 3.08789 10.25H0.75C0.585938 10.25 0.449219 10.1953 0.339844 10.0859C0.230469 9.96745 0.171224 9.82617 0.162109 9.66211V7.33789C0.171224 7.17383 0.230469 7.03711 0.339844 6.92773L6.75195 0.501953C6.87044 0.392578 7.00716 0.337891 7.16211 0.337891C7.31706 0.337891 7.45378 0.392578 7.57227 0.501953ZM0.75 13.1621H11.25C11.4141 13.1621 11.5508 13.1074 11.6602 12.998C11.7695 12.8887 11.8242 12.752 11.8242 12.5879C11.8242 12.4238 11.7695 12.2826 11.6602 12.1641C11.5508 12.0547 11.4141 12 11.25 12H0.75C0.585938 12 0.449219 12.0547 0.339844 12.1641C0.230469 12.2826 0.175781 12.4238 0.175781 12.5879C0.175781 12.752 0.230469 12.8887 0.339844 12.998C0.449219 13.1074 0.585938 13.1621 0.75 13.1621Z" />
    </svg>
  );
};

export default InvoicesIcon;
