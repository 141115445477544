import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const BookmarkIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 11,
  height = 14,
  fill = theme.palette.custom.white,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.5 11.7031V2.32812C9.5 2.14062 9.43229 1.98438 9.29688 1.85938C9.17188 1.73438 9.01562 1.67188 8.82812 1.67188H2.17188C1.98438 1.67188 1.82292 1.73438 1.6875 1.85938C1.5625 1.98438 1.5 2.14062 1.5 2.32812V11.7031L5.10938 9.125C5.23438 9.04167 5.36458 9 5.5 9C5.63542 9 5.76562 9.04167 5.89062 9.125L9.5 11.7031ZM1.21875 13.5469C1.11458 13.6198 1 13.6562 0.875 13.6562C0.760417 13.6667 0.645833 13.6406 0.53125 13.5781C0.416667 13.526 0.328125 13.4479 0.265625 13.3438C0.203125 13.2396 0.171875 13.125 0.171875 13V2.32812C0.182292 1.76562 0.375 1.29688 0.75 0.921875C1.125 0.546875 1.59896 0.348958 2.17188 0.328125H8.82812C9.40104 0.348958 9.875 0.546875 10.25 0.921875C10.625 1.29688 10.8177 1.76562 10.8281 2.32812V13C10.8281 13.125 10.7969 13.2396 10.7344 13.3438C10.6719 13.4479 10.5833 13.526 10.4688 13.5781C10.3542 13.6406 10.2344 13.6667 10.1094 13.6562C9.99479 13.6562 9.88542 13.6198 9.78125 13.5469L5.5 10.4844L1.21875 13.5469Z"
        fill={fill}
      />
    </svg>
  );
};

export default BookmarkIcon;
