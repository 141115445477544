import React, { ReactElement, FC } from 'react';

import Box, { BoxProps } from '@material-ui/core/Box';

import { BaseProps } from 'shared/types';

import { ShipmentDate, Typography } from 'components';
import { useTranslation } from 'react-i18next';

import { ShipmentPayload } from 'shared/types/shipments/shipments';
import { combineDateWithTime, parseISOWithoutTimeZone, toLocal } from 'shared/functions/date';
import { PriceRequestDetailsPayload } from 'shared/types/priceRequests/priceRequests';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { theme } from '../../theme';

type ShipmentDatesProps = {
  payload: ShipmentPayload | PriceRequestDetailsPayload;
  boxProps?: BoxProps;
  smallHeading?: boolean;
  marginTop?: number;
} & BaseProps;

const ShipmentDates: FC<ShipmentDatesProps> = ({
  payload,
  smallHeading,
  boxProps = {},
  marginTop,
}): ReactElement => {
  const { t } = useTranslation();

  const {
    earliestPickupDate,
    earliestPickupTime,
    latestPickupDate,
    latestPickupTime,
    earliestArrivalDate,
    earliestArrivalTime,
    latestArrivalDate,
    latestArrivalTime,
    confirmedEarliestPickupDate,
    confirmedEarliestArrivalDate,
    confirmedLatestPickupDate,
    confirmedLatestArrivalDate,
  } = payload;

  // TODO: replace nulls with *time params once we split date & time globally
  const earliestPickup = combineDateWithTime(earliestPickupDate, earliestPickupTime);
  const confirmedEarliestPickup = confirmedEarliestPickupDate
    ? toLocal(parseISOWithoutTimeZone(confirmedEarliestPickupDate))
    : null;
  const latestPickup = combineDateWithTime(latestPickupDate, latestPickupTime);
  const confirmedLatestPickup = confirmedLatestPickupDate
    ? toLocal(parseISOWithoutTimeZone(confirmedLatestPickupDate))
    : null;

  const earliestArrival = combineDateWithTime(earliestArrivalDate, earliestArrivalTime);
  const confirmedEarliestArrival = confirmedEarliestArrivalDate
    ? toLocal(parseISOWithoutTimeZone(confirmedEarliestArrivalDate))
    : null;
  const latestArrival = combineDateWithTime(latestArrivalDate, latestArrivalTime);
  const confirmedLatestArrival = confirmedLatestArrivalDate
    ? toLocal(parseISOWithoutTimeZone(confirmedLatestArrivalDate))
    : null;

  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box marginTop={marginTop ?? 10} {...boxProps}>
      <Typography
        variant={smallHeading ? 'subtitle2' : 'h5'}
        fontWeight={smallHeading ? 'semibold' : undefined}
        marginBottom={4}
      >
        {t('PICKUP_AND_DELIVERY_DATES')}
      </Typography>
      <Box display={!isDownMd ? 'flex' : 'block'}>
        <ShipmentDate
          companyName={
            payload.useSenderAlternative
              ? payload.senderAlternative?.companyName
              : payload.sender?.companyName
          }
          address={
            payload.useSenderAlternative
              ? payload.senderAlternative?.addressLine1
              : payload.sender?.addressLine1
          }
          type="pickup"
          fixedDate={payload.isFixedPickupDate}
          earliestDate={earliestPickup}
          confirmedEarliestDate={confirmedEarliestPickup}
          latestDate={latestPickup}
          confirmedLatestDate={confirmedLatestPickup}
          boxProps={{ marginRight: !isDownMd ? 6 : 0, width: !isDownMd ? 444 : 360 }}
        />
        <ShipmentDate
          companyName={
            payload.useReceiverAlternative
              ? payload.receiverAlternative?.companyName
              : payload.receiver?.companyName
          }
          address={
            payload.useReceiverAlternative
              ? payload.receiverAlternative?.addressLine1
              : payload.receiver?.addressLine1
          }
          type="delivery"
          fixedDate={payload.isFixedArrivalDate}
          earliestDate={earliestArrival}
          confirmedEarliestDate={confirmedEarliestArrival}
          latestDate={latestArrival}
          confirmedLatestDate={confirmedLatestArrival}
          boxProps={{ width: !isDownMd ? 444 : 360, marginTop: !isDownMd ? 0 : 4 }}
        />
      </Box>
    </Box>
  );
};

export default ShipmentDates;
