import React, { ReactElement, FC, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import Box, { BoxProps } from '@material-ui/core/Box';

import clsx from 'clsx';

import { theme } from 'theme';
import { BaseProps } from 'shared/types';
import { generateKey } from 'shared/functions/generateKey';
import { Separator, Typography } from 'components';
import { SeparatorProps } from 'components/Separator/Separator';
import { UserAction } from 'shared/constants/user/userRights';

export type ItemType = {
  label: string | ReactElement;
  code: string;
  badge?: number;
  icon?: ReactElement;
  inactive?: boolean;
  isLink?: boolean;
  object?: string;
  action?: UserAction;
};

type ExpandableSettingsProps = {
  selectedItem?: ItemType;
  items: ItemType[];
  onItemSelected: (itemCode: string, object?: string) => void;
  selectedItemClassName?: string;
  itemClassName?: string;
  separatorProps?: SeparatorProps;
  shadowCollapsed?: number;
  shadowExpanded?: number;
} & BoxProps &
  BaseProps;

const useStyles = makeStyles(() => ({
  container: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    transform: `translateY(calc(-100% + ${48}px))`,
    transition: 'transform 0.3s',
    cursor: 'pointer',
    zIndex: 100,
  },
  expanded: {
    transform: `translateY(0px)`,
  },
  item: {
    color: theme.palette.primary.main,
    paddingTop: 3,
  },
  text: {
    color: theme.palette.custom.veryDarkGray,
    cursor: 'default',
    '&&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const ExpandableSettings: FC<ExpandableSettingsProps> = ({
  selectedItem,
  items,
  onItemSelected,
  selectedItemClassName,
  itemClassName,
  className,
  separatorProps = {},
  shadowCollapsed,
  shadowExpanded = 1,
  ...restProps
}): ReactElement | null => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  useEffect(() => {
    const clickListener = () => {
      if (expanded) {
        toggleExpanded();
      }
    };

    if (expanded) {
      document.addEventListener('click', clickListener);
    } else {
      document.removeEventListener('click', clickListener);
    }
    return () => {
      document.removeEventListener('click', clickListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  return (
    <Box
      className={clsx(classes.container, expanded && classes.expanded, className)}
      boxShadow={expanded ? shadowExpanded : shadowCollapsed}
      bgcolor={theme.palette.custom.white}
      position="absolute"
      top={0}
      pt={2}
      pb={3.5}
      onClick={toggleExpanded}
      {...restProps}
    >
      {items
        .filter(({ code }) => code !== selectedItem?.code)
        .map(({ label, code, icon, isLink, object }, index) => (
          <Box
            key={generateKey(index, `item_${code}`)}
            display="flex"
            alignItems="center"
            px={4}
            py={2}
            className={clsx(itemClassName, isLink === false && classes.text)}
            onClick={() => onItemSelected(code, object)}
          >
            {icon && (
              <Box mr={2} mt={0.5} display="flex">
                {icon}
              </Box>
            )}
            <Typography
              className={clsx(classes.item, isLink === false && classes.text)}
              variant="body2"
            >
              {label}
            </Typography>
          </Box>
        ))}
      <Separator
        height={2}
        boxShadow="0px -4px 10px rgba(0, 0, 0, 0.25)"
        mb={3.5}
        mt={2}
        {...separatorProps}
      />
      {selectedItem && (
        <Box px={4} display="flex" justifyContent="center" alignItems="center">
          {selectedItem.icon && (
            <Box mr={2} mt={0.5}>
              {selectedItem.icon}
            </Box>
          )}
          <Typography className={selectedItemClassName} variant="body2" badge={selectedItem.badge}>
            {selectedItem.label}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ExpandableSettings;
