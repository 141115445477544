import React, { ReactElement, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Typography } from 'components';

import { BaseProps } from 'shared/types';
import { useFormikContext } from 'formik';
import {
  NewShipmentPayload,
  ShipmentAddress,
  ShipmentType,
} from 'shared/types/shipments/shipments';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';
import { theme } from 'theme';
import { RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import RadioOption from '../../../CustomMaterial/Radio/Radio';
import { emptyLoadingContact } from '../../../../shared/constants/shipments/shipmentAddress';

type ShipmentReferenceNumberStepProps = BaseProps;

export type ShipmentReferenceData = {
  notifyAboutShipment?: boolean;
  saveShipmentAsTemplate?: boolean;
};

const useStyles = makeStyles({
  root: {
    '& .MuiRadio-root ': {
      color: theme.palette.custom.gray,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '0.875rem',
      lineHeight: '20px',
      fontWeight: 500,
    },
    '& .Mui-checked': {
      color: theme.palette.custom.veryDarkDesaturatedViolet,
    },
  },
  dateWrapper: {
    display: 'flex',
  },
  selected: {
    '& .MuiFormControlLabel-label': {
      fontWeight: 700,
    },
  },
});

const ShipmentTypeStep: FC<ShipmentReferenceNumberStepProps> = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<NewShipmentPayload>();
  const [shipmentTypeSelected, setShipmentTypeSelected] = useState(formik.values.type);

  const swapAddresses = () => {
    const receiverAddressData: ShipmentAddress = {
      address: formik.values.receiver ?? emptyLoadingContact,
      alternativeAddress: formik.values.receiverAlternative,
    };

    const senderAddressData: ShipmentAddress = {
      address: formik.values.sender ?? emptyLoadingContact,
      alternativeAddress: formik.values.senderAlternative,
    };

    formik.setFieldValue('sender', { ...receiverAddressData.address });
    formik.setFieldValue(
      'senderAlternative',
      receiverAddressData.alternativeAddress
        ? { ...receiverAddressData.alternativeAddress }
        : undefined,
    );

    formik.setFieldValue('receiver', { ...senderAddressData.address });
    formik.setFieldValue(
      'receiverAlternative',
      senderAddressData.alternativeAddress
        ? { ...senderAddressData.alternativeAddress }
        : undefined,
    );
  };

  const toggleType = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setShipmentTypeSelected(parseInt(value, 10));
    formik.setFieldValue('type', parseInt(value, 10));
    swapAddresses();
  };
  return (
    <Box>
      <Typography variant="h5" marginBottom={4.25}>
        {t('SHIPMENT.SHIPMENT_TYPE')}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={0}
        marginTop={1}
      >
        <RadioGroup
          aria-label=""
          name="type"
          value={shipmentTypeSelected}
          onChange={(event) => toggleType(event, event.target.value)}
          className={classes.root}
        >
          <FormControlLabel
            className={clsx(shipmentTypeSelected === ShipmentType.Sender && classes.selected)}
            value={ShipmentType.Sender}
            control={<RadioOption />}
            label={t('SHIPMENT.TYPE.SENDER')}
            labelPlacement="end"
          />
          <FormControlLabel
            className={clsx(shipmentTypeSelected === ShipmentType.Receiver && classes.selected)}
            value={ShipmentType.Receiver}
            control={<RadioOption />}
            label={t('SHIPMENT.TYPE.RECEIVER')}
            labelPlacement="end"
          />
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default ShipmentTypeStep;
