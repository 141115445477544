import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const MachineIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width,
  fill = theme.palette.custom.veryDarkGray,
}): ReactElement => {
  return (
    <svg
      width={width}
      height={30}
      viewBox="0 0 5944 3438"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fill={fill}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 1,
      }}
    >
      <g transform="matrix(1,0,0,1,1478.41,-310.894)">
        <g transform="matrix(1,0,0,1,1162.38,-255.175)">
          <g transform="matrix(45.9971,0,0,49.9273,-2640.79,-212.865)">
            <path
              d="M32,50.45L6.62,50.45C3.535,50.455 1,52.995 1,56.08L1,63.74C1,63.743 1,63.747 1,63.75C1,64.683 1.767,65.45 2.7,65.45C2.703,65.45 2.707,65.45 2.71,65.45L55.91,65.45C57.054,65.445 57.995,64.504 58,63.36L58,62.45C58,62.45 46,62.45 32,50.45Z"
              style={{ fill: 'rgb(255,255,255)', fillRule: 'nonzero' }}
            />
          </g>
          <g transform="matrix(45.9971,0,0,49.9273,-2640.79,-212.865)">
            <path
              d="M51.5,41L41.84,41C41.105,41 40.5,41.605 40.5,42.34L40.5,52C40.503,52.478 40.748,52.923 41.15,53.18C45.186,55.672 49.566,57.558 54.15,58.78C54.246,58.794 54.344,58.794 54.44,58.78C54.664,58.782 54.883,58.708 55.06,58.57C55.331,58.377 55.488,58.062 55.48,57.73L55.48,52.42C55.381,48.655 54.416,44.962 52.66,41.63C52.412,41.23 51.97,40.99 51.5,41ZM54.5,57.78L54.44,57.83C49.945,56.632 45.649,54.783 41.69,52.34C41.571,52.269 41.498,52.139 41.5,52L41.5,42.29C41.5,42.103 41.653,41.95 41.84,41.95L51.5,41.95C51.625,41.95 51.742,42.015 51.81,42.12C53.486,45.312 54.406,48.846 54.5,52.45L54.5,57.78Z"
              style={{ fillOpacity: 0.98, fillRule: 'nonzero' }}
            />
          </g>
          <g transform="matrix(45.9971,0,0,49.9273,-2640.79,-212.865)">
            <path
              d="M5,76.45C5,79.193 7.257,81.45 10,81.45L60,81.45C62.743,81.45 65,79.193 65,76.45C65,73.707 62.743,71.45 60,71.45L10,71.45C7.257,71.45 5,73.707 5,76.45ZM60,72.45C62.194,72.45 64,74.256 64,76.45C64,78.644 62.194,80.45 60,80.45C57.806,80.45 56,78.644 56,76.45C56,74.256 57.806,72.45 60,72.45ZM57,72.45C55.742,73.394 55,74.877 55,76.45C55,78.023 55.742,79.506 57,80.45L13,80.45C14.258,79.506 15,78.023 15,76.45C15,74.877 14.258,73.394 13,72.45L57,72.45ZM14,76.45C14,78.644 12.194,80.45 10,80.45C7.806,80.45 6,78.644 6,76.45C6,74.256 7.806,72.45 10,72.45C12.194,72.45 14,74.256 14,76.45Z"
              style={{ fillOpacity: 0.98, fillRule: 'nonzero' }}
            />
          </g>
          <g transform="matrix(45.9971,0,0,49.9273,-2640.79,-212.865)">
            <path
              d="M59,56.88L58.65,40.14L54.733,37.45L34.61,37.45C33.178,37.451 31.974,38.572 31.87,40L31.08,49.47L6.62,49.47C2.994,49.475 0.011,52.454 0,56.08L0,63.74C0,65.227 1.223,66.45 2.71,66.45L20,66.45L18.5,68.45L10.27,68.45C6.005,68.387 2.37,71.67 2,75.92C1.988,76.096 1.982,76.273 1.982,76.45C1.982,80.839 5.594,84.45 9.982,84.45C9.988,84.45 9.994,84.45 10,84.45L59.73,84.45C63.988,84.514 67.62,81.242 68,77C68.012,76.824 68.018,76.647 68.018,76.47C68.018,72.081 64.406,68.47 60.018,68.47C60.012,68.47 60.006,68.47 60,68.47L47.5,68.47L46,66.47L55.91,66.47C57.605,66.47 59,65.075 59,63.38C59,63.373 59,63.367 59,63.36L59,56.88ZM60,70.45C60.017,70.45 60.035,70.45 60.052,70.45C63.344,70.45 66.052,73.158 66.052,76.45C66.052,76.714 66.035,76.978 66,77.24C65.534,80.255 62.891,82.491 59.84,82.45L10.21,82.45C7.159,82.491 4.516,80.255 4.05,77.24C4.015,76.978 3.998,76.714 3.998,76.45C3.998,73.158 6.706,70.45 9.998,70.45C10.015,70.45 10.033,70.45 10.05,70.45L60,70.45ZM21,68.45L22.5,66.45L43.5,66.45L45,68.45L21,68.45ZM55.91,64.45L2.71,64.45C2.321,64.45 2,64.129 2,63.74L2,56.08C2,56.077 2,56.073 2,56.07C2,53.536 4.086,51.45 6.62,51.45L31.62,51.45C43.48,61.49 53.8,63.13 57,63.4C56.979,63.983 56.493,64.45 55.91,64.45ZM57,61.38C53.93,61.07 44.12,59.38 33,50.02L33.82,40.14C33.851,39.752 34.18,39.449 34.57,39.45L53.355,39.45C54.141,39.044 57.278,41.678 57,47.92C56.93,49.487 57.017,51.014 57,52.45L57,61.38ZM59,55.11L59,52.45C59.002,50.933 58.885,49.418 58.65,47.92L59,55.11Z"
              style={{ fillRule: 'nonzero' }}
            />
          </g>
          <g transform="matrix(65.2702,0,0,53.8916,-3434.6,-1150.24)">
            <path
              d="M94.75,36.82L94.66,36.92L92.03,38.92L91.14,40.6L63.22,47.92C60.833,48.553 58.722,49.96 57.22,51.92L53.15,57.18L52.171,54.302C52.027,53.316 50.676,52.709 49.68,52.72L51,49.27C53.056,44.236 57.732,40.728 63.14,40.16L94.75,36.82Z"
              style={{
                fill: 'rgb(253,255,255)',
                fillRule: 'nonzero',
                stroke: '#858585',
                strokeWidth: '1.25px',
              }}
            />
          </g>
          <g transform="matrix(65.2702,0,0,53.8916,-3434.6,-1150.24)">
            <path
              d="M102.09,37.56L99.3,41.26L81.91,64.26L79,63.5L91.14,40.61L92,38.93L94.63,36.93L100.08,32.78C100.282,32.626 100.529,32.543 100.782,32.543C101.266,32.543 101.702,32.846 101.87,33.3L102.46,34.9C102.795,35.791 102.656,36.794 102.09,37.56Z"
              style={{
                fill: 'rgb(253,255,255)',
                fillRule: 'nonzero',
                stroke: '#858585',
                strokeWidth: '1.25px',
              }}
            />
          </g>
          <g transform="matrix(65.2702,0,0,53.8916,-3434.6,-1150.24)">
            <path
              d="M81,73.85L69.85,73.85L69.85,70.59L71.85,70.16L78.76,63.42L79.04,63.5L81.94,64.3L82.53,64.47L83.86,66.85L84.46,68C84.758,68.571 84.914,69.206 84.914,69.851C84.914,72.013 83.161,73.804 81,73.85Z"
              style={{
                fill: 'none',
                fillRule: 'nonzero',
                stroke: '#858585',
                strokeWidth: '1.25px',
              }}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MachineIcon;
