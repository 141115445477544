import get from 'lodash/get';
import { useFormikContext } from 'formik';

type UseFormikErrorReturnType = (fieldName: string) => boolean;

export const useFormikError = (): UseFormikErrorReturnType => {
  const formik = useFormikContext();

  const isFormikError = (fieldName: string): boolean => !!get(formik.errors, fieldName);

  return isFormikError;
};
