import React, { FC, ReactElement } from 'react';

import MaterialChip, { ChipProps as MaterialChipProps } from '@material-ui/core/Chip';
import { Theme, makeStyles } from '@material-ui/core/styles';

const useNativeStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.custom.lightGrayishVioletAlt2,
    padding: theme.spacing(0.5, 3),
    marginTo: theme.spacing(-1),
    '&:hover': {
      backgroundColor: theme.palette.custom.verySoftViolet,
    },
  },
  label: {
    padding: theme.spacing(0),
    color: theme.palette.custom.veryDarkGray,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
  },
}));

type ChipProps = MaterialChipProps;

const Chip: FC<ChipProps> = (props): ReactElement => {
  const nativeClasses = useNativeStyles();

  return <MaterialChip classes={nativeClasses} {...props} />;
};

export default Chip;
