import React, { FC, ReactElement, SVGProps } from 'react';
import { theme } from 'theme';
import { IconBaseProps } from '../../../shared/types';

type StarIconProps = {
  borderOnly?: boolean;
  width?: number;
  height?: number;
  fill?: string;
};

const StarIcon: FC<IconBaseProps & StarIconProps & SVGProps<SVGSVGElement>> = ({
  borderOnly,
  width = 14,
  height = 16,
  fill = theme.palette.custom.gray,
  ...restProps
}): ReactElement => {
  let icon = null;
  if (!borderOnly) {
    icon = (
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
      >
        <path
          d="M6.48047 0.652344C6.52604 0.552083 6.5944 0.474609 6.68555 0.419922C6.78581 0.365234 6.89062 0.337891 7 0.337891C7.10938 0.337891 7.20964 0.365234 7.30078 0.419922C7.40104 0.474609 7.47396 0.552083 7.51953 0.652344L9.1875 4.0293L12.9199 4.57617C13.0293 4.5944 13.125 4.63997 13.207 4.71289C13.2891 4.77669 13.3483 4.86328 13.3848 4.97266C13.4212 5.08203 13.4258 5.19141 13.3984 5.30078C13.3711 5.40104 13.3164 5.49219 13.2344 5.57422L10.541 8.19922L11.1836 11.9043C11.2018 12.0137 11.1882 12.1185 11.1426 12.2188C11.1061 12.319 11.0423 12.401 10.9512 12.4648C10.86 12.5378 10.7598 12.5742 10.6504 12.5742C10.541 12.5833 10.4362 12.5651 10.3359 12.5195L7 10.7695L3.66406 12.5195C3.5638 12.5651 3.45898 12.5833 3.34961 12.5742C3.24023 12.5742 3.13997 12.5378 3.04883 12.4648C2.95768 12.401 2.88932 12.319 2.84375 12.2188C2.80729 12.1185 2.79818 12.0137 2.81641 11.9043L3.45898 8.19922L0.765625 5.57422C0.683594 5.49219 0.628906 5.40104 0.601562 5.30078C0.574219 5.19141 0.578776 5.08203 0.615234 4.97266C0.651693 4.86328 0.710938 4.77669 0.792969 4.71289C0.875 4.63997 0.970703 4.5944 1.08008 4.57617L4.8125 4.0293L6.48047 0.652344Z"
          fill={fill}
        />
      </svg>
    );
  } else {
    icon = (
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
      >
        <path
          d="M4.89844 3.84766C4.82031 4.00391 4.69531 4.09375 4.52344 4.11719L2.07422 4.48047L3.84375 6.20312C3.96875 6.32812 4.01953 6.47656 3.99609 6.64844L3.57422 9.08594L5.76562 7.9375C5.92188 7.85938 6.07812 7.85938 6.23438 7.9375L8.42578 9.08594L8.00391 6.64844C7.98047 6.47656 8.03125 6.32812 8.15625 6.20312L9.92578 4.48047L7.47656 4.11719C7.30469 4.09375 7.17969 4.00391 7.10156 3.84766L6 1.63281L4.89844 3.84766ZM5.55469 0.273438C5.59375 0.1875 5.65234 0.121094 5.73047 0.0742188C5.81641 0.0273438 5.90625 0.00390625 6 0.00390625C6.09375 0.00390625 6.17969 0.0273438 6.25781 0.0742188C6.34375 0.121094 6.40625 0.1875 6.44531 0.273438L7.875 3.16797L11.0742 3.63672C11.168 3.65234 11.25 3.69141 11.3203 3.75391C11.3906 3.80859 11.4414 3.88281 11.4727 3.97656C11.5039 4.07031 11.5078 4.16406 11.4844 4.25781C11.4609 4.34375 11.4141 4.42188 11.3438 4.49219L9.03516 6.74219L9.58594 9.91797C9.60156 10.0117 9.58984 10.1016 9.55078 10.1875C9.51953 10.2734 9.46484 10.3438 9.38672 10.3984C9.30859 10.4609 9.22266 10.4922 9.12891 10.4922C9.03516 10.5 8.94531 10.4844 8.85938 10.4453L6 8.94531L3.14062 10.4453C3.05469 10.4844 2.96484 10.5 2.87109 10.4922C2.77734 10.4922 2.69141 10.4609 2.61328 10.3984C2.53516 10.3438 2.47656 10.2734 2.4375 10.1875C2.40625 10.1016 2.39844 10.0117 2.41406 9.91797L2.96484 6.74219L0.65625 4.49219C0.585938 4.42188 0.539062 4.34375 0.515625 4.25781C0.492188 4.16406 0.496094 4.07031 0.527344 3.97656C0.558594 3.88281 0.609375 3.80859 0.679688 3.75391C0.75 3.69141 0.832031 3.65234 0.925781 3.63672L4.125 3.16797L5.55469 0.273438Z"
          fill={fill}
        />
      </svg>
    );
  }

  return icon;
};

export default StarIcon;
