import React, { ReactElement, FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { theme } from 'theme';

export type SeparatorProps = BoxProps;

const Separator: FC<SeparatorProps> = ({
  height = '1px',
  width = 1,
  bgcolor = theme.palette.custom.lightGray,
  ...restProps
}): ReactElement => {
  return (
    <Box
      height={height}
      width={width}
      bgcolor={bgcolor}
      {...restProps}
      boxShadow="0px -4px 10px rgba(0, 0, 0, 0.25)"
    />
  );
};

export default Separator;
