import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import { theme } from 'theme';

const useStyles = makeStyles(() => ({
  panel: {
    height: '48px',
    position: 'relative',
    zIndex: 200,
    backgroundColor: theme.palette.custom.strongViolet,
  },
  text: {
    color: theme.palette.custom.white,
    lineHeight: '48px',
    textAlign: 'center',
  },
}));

const EnvironmentPanel: FC = (): ReactElement => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Box className={classes.panel}>
      <Typography variant="body2" className={classes.text}>
        {t('THIS_IS_DEMO_ENVIRONMENT')}
      </Typography>
    </Box>
  );
};

export default EnvironmentPanel;
