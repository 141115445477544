import React, { ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { TextFieldFormik, Typography } from 'components';

import { BaseProps } from 'shared/types';
import { useFormikContext } from 'formik';
import { PriceRequestDetailsPayload } from 'shared/types/priceRequests/priceRequests';

type PriceRequestCommentsStepProps = BaseProps;

const PriceRequestCommentsStep: FC<PriceRequestCommentsStepProps> = (): ReactElement => {
  const { t } = useTranslation();

  const formik = useFormikContext<PriceRequestDetailsPayload>();

  return (
    <Box>
      <Typography variant="h5" marginBottom={4.25}>
        {t('PRICE_REQUEST.ADD_COMMENTS_REGARDING_THE_PRICE_REQUEST')}
      </Typography>

      <Typography variant="body2" marginBottom={2}>
        {t('PRICE_REQUEST.ADD_A_COMMENT')}
      </Typography>
      <TextFieldFormik
        name="otherInstructions"
        variant="variant6"
        size="small"
        fullWidth
        multiline
        inputProps={{ maxLength: 2000 }}
        rows={4}
        onChange={formik.handleChange}
        value={formik.values.otherInstructions}
        debounced
      />
    </Box>
  );
};

export default PriceRequestCommentsStep;
