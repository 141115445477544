import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const LabelIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 15,
  height = 15,
  fill = theme.palette.custom.veryDarkGray,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M13.1562 7.46875L7.67188 1.98438H1.98438V7.67188L7.46875 13.1406C7.60417 13.276 7.76042 13.3438 7.9375 13.3438C8.125 13.3438 8.28125 13.276 8.40625 13.1406L13.1562 8.40625C13.2812 8.27083 13.3438 8.11458 13.3438 7.9375C13.3438 7.76042 13.2812 7.60417 13.1562 7.46875ZM14.0938 9.34375L9.34375 14.0781C9.15625 14.2656 8.94271 14.4062 8.70312 14.5C8.46354 14.6042 8.20833 14.6562 7.9375 14.6562C7.67708 14.6562 7.42708 14.6042 7.1875 14.5C6.94792 14.4062 6.73438 14.2656 6.54688 14.0781L0.859375 8.40625C0.734375 8.28125 0.671875 8.125 0.671875 7.9375V1.32812C0.671875 1.14062 0.734375 0.984375 0.859375 0.859375C0.984375 0.734375 1.14062 0.671875 1.32812 0.671875H7.9375C8.125 0.671875 8.28125 0.734375 8.40625 0.859375L14.0938 6.54688C14.4792 6.95312 14.6719 7.42188 14.6719 7.95312C14.6719 8.47396 14.4792 8.9375 14.0938 9.34375ZM4 4.67188C4 4.78646 4.02604 4.89583 4.07812 5C4.14062 5.10417 4.22396 5.1875 4.32812 5.25C4.43229 5.30208 4.54167 5.32812 4.65625 5.32812C4.78125 5.32812 4.89583 5.30208 5 5.25C5.10417 5.1875 5.18229 5.10417 5.23438 5C5.29688 4.89583 5.32812 4.78646 5.32812 4.67188C5.32812 4.54688 5.29688 4.4375 5.23438 4.34375C5.18229 4.23958 5.10417 4.15625 5 4.09375C4.89583 4.03125 4.78125 4 4.65625 4C4.54167 4 4.43229 4.03125 4.32812 4.09375C4.22396 4.15625 4.14062 4.23958 4.07812 4.34375C4.02604 4.4375 4 4.54688 4 4.67188Z"
        fill={fill}
      />
    </svg>
  );
};

export default LabelIcon;
