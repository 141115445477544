import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const DataIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 14,
  height = 16,
  fill = theme.palette.custom.veryDarkGray,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M1.67188 5.01562V8C1.70312 8.29167 2.21875 8.58333 3.21875 8.875C4.21875 9.15625 5.47917 9.30729 7 9.32812C8.52083 9.30729 9.78125 9.15625 10.7812 8.875C11.7812 8.58333 12.2969 8.29167 12.3281 8V5.01562C11.7135 5.33854 10.9479 5.58333 10.0312 5.75C9.11458 5.91667 8.10417 6 7 6C5.89583 6 4.88542 5.91667 3.96875 5.75C3.05208 5.58333 2.28646 5.33854 1.67188 5.01562ZM0.328125 3.32812C0.380208 2.48438 1.03125 1.83333 2.28125 1.375C3.54167 0.90625 5.11458 0.671875 7 0.671875C8.88542 0.671875 10.4531 0.90625 11.7031 1.375C12.9635 1.83333 13.6198 2.48438 13.6719 3.32812V12.6719C13.6198 13.5156 12.9688 14.1667 11.7188 14.625C10.4688 15.0938 8.89583 15.3281 7 15.3281C5.10417 15.3281 3.53125 15.0938 2.28125 14.625C1.03125 14.1667 0.380208 13.5156 0.328125 12.6719V3.32812ZM12.3281 9.6875C11.7135 10.0104 10.9479 10.2552 10.0312 10.4219C9.11458 10.5885 8.10417 10.6719 7 10.6719C5.89583 10.6719 4.88542 10.5885 3.96875 10.4219C3.05208 10.2448 2.28646 10 1.67188 9.6875V12.6719C1.70312 12.9635 2.21875 13.2552 3.21875 13.5469C4.21875 13.8281 5.47917 13.9792 7 14C8.52083 13.9792 9.78125 13.8281 10.7812 13.5469C11.7812 13.2552 12.2969 12.9635 12.3281 12.6719V9.6875ZM7 4.67188C8.51042 4.65104 9.76562 4.49479 10.7656 4.20312C11.776 3.91146 12.2969 3.61979 12.3281 3.32812C12.2969 3.03646 11.776 2.75 10.7656 2.46875C9.76562 2.17708 8.51042 2.02083 7 2C5.48958 2.02083 4.22917 2.17708 3.21875 2.46875C2.21875 2.75 1.70312 3.03646 1.67188 3.32812C1.70312 3.61979 2.21875 3.91146 3.21875 4.20312C4.22917 4.49479 5.48958 4.65104 7 4.67188Z"
        fill={fill}
      />
    </svg>
  );
};

export default DataIcon;
