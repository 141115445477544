import { ShipmentStatus } from 'shared/types/shipments/shipments';
import { PriceRequestStatus } from '../../types/priceRequests/priceRequests';

export const simplifiedStatusMapping = (
  status: ShipmentStatus,
): Partial<Record<ShipmentStatus, boolean>> => ({
  [ShipmentStatus.Draft]: status !== ShipmentStatus.Canceled,

  [ShipmentStatus.Received]: [
    ShipmentStatus.Received,
    ShipmentStatus.Processing,
    ShipmentStatus.InTransit,
    ShipmentStatus.Delivered,
  ].includes(status),

  [ShipmentStatus.Processing]: [
    ShipmentStatus.Processing,
    ShipmentStatus.InTransit,
    ShipmentStatus.Delivered,
  ].includes(status),
  [ShipmentStatus.InTransit]: [ShipmentStatus.PickedUp, ShipmentStatus.Delivered].includes(status),
  [ShipmentStatus.Delivered]: ShipmentStatus.Delivered === status,
});

export const simplifiedStatusTrackingMapping = (
  status: ShipmentStatus,
): Partial<Record<ShipmentStatus, boolean>> => ({
  [ShipmentStatus.Received]: [
    ShipmentStatus.Received,
    ShipmentStatus.Processing,
    ShipmentStatus.InTransit,
    ShipmentStatus.Delivered,
  ].includes(status),

  [ShipmentStatus.Processing]: [
    ShipmentStatus.Processing,
    ShipmentStatus.InTransit,
    ShipmentStatus.Delivered,
  ].includes(status),
  [ShipmentStatus.InTransit]: [ShipmentStatus.PickedUp, ShipmentStatus.Delivered].includes(status),
  [ShipmentStatus.Delivered]: ShipmentStatus.Delivered === status,
});

export const simplifiedPriceRequestStatusMapping = (
  status: PriceRequestStatus,
): Partial<Record<PriceRequestStatus, boolean>> => ({
  [PriceRequestStatus.Draft]: PriceRequestStatus.Draft === status,
  [PriceRequestStatus.Received]: PriceRequestStatus.Received === status,
  [PriceRequestStatus.WaitingConfirmed]: PriceRequestStatus.WaitingConfirmed === status,
  [PriceRequestStatus.Accepted]: PriceRequestStatus.Accepted === status,
});
