import React, { ReactElement, FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { Typography } from 'components';
import { theme } from 'theme';

import { BaseProps } from 'shared/types';

type PlaceholderProps = {
  text: string;
  error?: string;
} & BaseProps &
  BoxProps;

const Placeholder: FC<PlaceholderProps> = ({
  error,
  text,
  borderRadius = 16,
  ...restProps
}): ReactElement => {
  return (
    <>
      {error && (
        <Typography
          customColor={theme.palette.custom.alertError}
          variant="caption"
          marginBottom={2}
        >
          {error}
        </Typography>
      )}
      <Box
        width={1}
        padding={6.5}
        textAlign="center"
        bgcolor={theme.palette.custom.veryLightGrayAlt2}
        borderRadius={borderRadius}
        border={error ? `1px solid ${theme.palette.custom.alertError}` : undefined}
        {...restProps}
      >
        <Typography variant="body2" fontWeight="bold" customColor={theme.palette.custom.black}>
          {text}
        </Typography>
      </Box>
    </>
  );
};

export default Placeholder;
