import qs from 'qs';
import { useFetchApi } from 'shared/functions/fetchApi';
import { ApiResponse } from 'shared/types/api';
import {
  TemplatesApiRequest,
  TemplatesPayload,
  TemplateDetailsPayload,
  TemplateApiRequest,
  TemplatePayload,
} from 'shared/types/templates/templates';

type UseTemplatesReturnType = {
  fetchTemplates: (request: TemplatesApiRequest) => Promise<ApiResponse<TemplatesPayload>>;
  fetchTemplate: (request: TemplateApiRequest) => Promise<ApiResponse<TemplateDetailsPayload>>;
  deleteTemplate: (request: TemplateApiRequest) => Promise<ApiResponse<boolean>>;
  saveTemplate: (request: TemplateDetailsPayload) => Promise<ApiResponse<number>>;
};

export const useTemplates = (): UseTemplatesReturnType => {
  const { fetchApi } = useFetchApi();

  const fetchTemplates = async ({
    page = 1,
    sizePerPage = 25,
    createdByMe: CreatedByMe = true,
    sortName,
    sortOrder,
    filters = {},
  }: TemplatesApiRequest): Promise<ApiResponse<TemplatesPayload>> => {
    const params = {
      page,
      sizePerPage,
      CreatedByMe,
      sortName,
      sortOrder,
      ...filters,
    };

    const response = await fetchApi(
      `/api/template?${qs.stringify(params, { arrayFormat: 'comma' })}`,
    );
    return response.json();
  };

  const fetchTemplate = async ({
    id,
  }: TemplatePayload): Promise<ApiResponse<TemplateDetailsPayload>> => {
    const responsePromise: Promise<ApiResponse<TemplateDetailsPayload>> = (
      await fetchApi(`/api/template/${id}`, undefined, undefined, true)
    ).json();

    return responsePromise.then((response) => ({
      ...response,
      payload: response.payload,
    }));
  };

  const deleteTemplate = async ({ id }: TemplateApiRequest): Promise<ApiResponse<boolean>> => {
    const response = await fetchApi(`/api/template/${id}`, { method: 'DELETE' });
    return response.json();
  };
  const saveTemplate = async (payload: TemplateDetailsPayload): Promise<ApiResponse<number>> => {
    const body = JSON.stringify(payload);

    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: payload.id ? 'PUT' : 'POST',
      body,
    };

    const idParam = payload.id ? `${payload.id}/` : '';

    const response = await fetchApi(`/api/template/${idParam}`, params);
    return response.json();
  };

  return {
    fetchTemplates,
    fetchTemplate,
    deleteTemplate,
    saveTemplate,
  };
};
