import React, { FC, lazy, ReactElement } from 'react';
import {
  match as RouteMatch,
  RouteComponentProps,
  RouteProps as NativeRouteProps,
} from 'react-router-dom';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { useTranslation } from 'react-i18next';
import { UserAction, UserActionType } from './shared/constants/user/userRights';

const Shipments = lazy(() => import('./scenes/Shipments/Shipments'));
const PriceRequests = lazy(() => import('./scenes/PriceRequests/PriceRequests'));
const Shipment = lazy(() => import('./scenes/Shipment/Shipment'));
const NewClaim = lazy(() => import('./scenes/NewClaim/NewClaim'));
const Claim = lazy(() => import('./scenes/Claim/Claim'));
const Claims = lazy(() => import('./scenes/Claims/Claims'));
const NewShipment = lazy(() => import('./scenes/NewShipment/NewShipment'));
const AddressBook = lazy(() => import('./scenes/AddressBook/AddressBook'));
const Dashboard = lazy(() => import('./scenes/Dashboard/Dashboard'));
const NotFound = lazy(() => import('./scenes/NotFound/NotFound'));
const NewPriceRequest = lazy(() => import('./scenes/NewPriceRequest/NewPriceRequest'));
const PriceRequest = lazy(() => import('./scenes/PriceRequest/PriceRequest'));
const Company = lazy(() => import('./scenes/Company/Company'));
const Templates = lazy(() => import('./scenes/Templates/Templates'));
const NewTemplate = lazy(() => import('./scenes/NewTemplate/NewTemplate'));
const Invoices = lazy(() => import('./scenes/Invoices/Invoices'));
const Warehouse = lazy(() => import('./scenes/Warehouse/Warehouse'));
const Users = lazy(() => import('./scenes/Users/Users'));

export type RouteLocationProps = {
  pathname: string;
  search: string;
};

export type RouteProps = NativeRouteProps &
  BreadcrumbsRoute & {
    name: string;
    path: string;
    action?: UserAction;
    actionType?: UserActionType;
    component: React.ComponentType<RouteComponentProps> | React.ComponentType;
    exact: boolean;
    environment?: string;
    featureName?: string;
  };

type AvailableRouteParams = {
  shipmentId: string;
  priceRequestId: string;
};

type BreadcrumbProps = {
  match: RouteMatch<AvailableRouteParams>;
  location: RouteLocationProps;
};
export const routeLogin = '/';
export const routeDashboard = '/dashboard';
export const routeShipments = '/shipments';
export const routeAddressBook = '/address-book';
export const routePriceRequests = '/price-requests';
export const routeCompanyProfile = '/company';
export const routeTemplates = '/templates';
export const notFound = '/404';
export const routeInvoices = '/invoices';
export const routeWarehouse = '/warehouse';
export const routeClaims = '/claims';
export const routeUsers = '/users';
export const forgotPassword = '/forgot-password';

export const featureWarehouseView = 'warehouseView';

const locationMapping: Record<string, string> = {
  '/': 'BREADCRUMB.SHIPMENTS',
  '/shipments': 'BREADCRUMB.SHIPMENTS',
  '/shipments/new-shipment': 'BREADCRUMB.SHIPMENTS_ADD',
  '/price-requests': 'BREADCRUMB.PRICE_REQUESTS',
  '/price-requests/new-price-request': 'BREADCRUMB.PRICE_REQUESTS_ADD',
  '/company': 'BREADCRUMB.COMPANY_PROFILE',
  '/templates': 'BREADCRUMB.TEMPLATES',
  '/templates/new-template': 'BREADCRUMB.TEMPLATES_ADD',
  '/claims': 'CLAIMS',
  '/claims/new-claim': 'BREADCRUMB.CLAIM_ADD',
  '/users': 'SIDEBAR_USERS',
};

export const LocalizedBreadcrumb: FC<BreadcrumbProps> = ({ match }): ReactElement => {
  const label = locationMapping[match.path];
  const { t } = useTranslation();
  return <span>{label && t(label)}</span>;
};

export const EditShipmentBreadcrumb: FC<BreadcrumbProps> = ({ match }): ReactElement => {
  const { t } = useTranslation();
  return (
    <span>
      {t('BREADCRUMB.EDIT_SHIPMENT_DRAFT_NO').replace('{0}', `S-${match.params.shipmentId}`)}
    </span>
  );
};

export const EditTemplateBreadcrumb: FC<BreadcrumbProps> = (): ReactElement => {
  const { t } = useTranslation();
  return <span>{t('BREADCRUMB.EDIT_TEMPLATE')}</span>;
};

export const EditPriceRequestBreadcrumb: FC<BreadcrumbProps> = ({ match }): ReactElement => {
  const { t } = useTranslation();

  return (
    <span>
      {t('BREADCRUMB.EDIT_PRICE_REQUEST_DRAFT_NO').replace(
        '{0}',
        `PR-${match.params.priceRequestId}`,
      )}
    </span>
  );
};

export const DynamicBreadcrumb: FC<BreadcrumbProps> = ({ match }): ReactElement => {
  const { t } = useTranslation();
  if (match.path.startsWith(routeShipments)) {
    return (
      <span>
        {t('SHIPMENT.SHIPMENT_NR')} S-{match.params.shipmentId}
      </span>
    );
  }
  if (match.path.startsWith(routePriceRequests)) {
    return (
      <span>
        {t('BREADCRUMB.PRICE_REQUEST')} PR-{match.params.priceRequestId}
      </span>
    );
  }
  return <span />;
};

const routes: RouteProps[] = [
  {
    name: 'dashboard',
    path: `${routeDashboard}`,
    exact: true,
    component: Dashboard,
  },
  {
    name: 'invoices',
    path: `${routeInvoices}`,
    exact: true,
    component: Invoices,
    action: UserAction.invoices,
    actionType: UserActionType.ViewOnly,
  },
  {
    name: 'addressBook',
    path: `${routeAddressBook}`,
    exact: true,
    component: AddressBook,
  },
  {
    name: 'priceRequests',
    path: `${routePriceRequests}`,
    exact: true,
    component: PriceRequests,
    breadcrumb: LocalizedBreadcrumb,
    action: UserAction.priceRequests,
    actionType: UserActionType.ViewOnly,
  },
  {
    name: 'editPriceRequest',
    path: `${routePriceRequests}/new-price-request/:priceRequestId`,
    exact: true,
    component: NewPriceRequest,
    breadcrumb: EditPriceRequestBreadcrumb,
    action: UserAction.priceRequests,
    actionType: UserActionType.Edit,
  },
  {
    name: 'newPriceRequest',
    path: `${routePriceRequests}/new-price-request`,
    exact: true,
    component: NewPriceRequest,
    breadcrumb: LocalizedBreadcrumb,
    action: UserAction.priceRequests,
    actionType: UserActionType.Edit,
  },
  {
    name: 'priceRequest',
    path: `${routePriceRequests}/:priceRequestId`,
    exact: true,
    component: PriceRequest,
    breadcrumb: DynamicBreadcrumb,
    action: UserAction.priceRequests,
    actionType: UserActionType.ViewOnly,
  },
  {
    name: 'shipments',
    path: `${routeShipments}`,
    exact: true,
    component: Shipments,
    breadcrumb: LocalizedBreadcrumb,
    action: UserAction.shipments,
    actionType: UserActionType.ViewOnly,
  },
  {
    name: 'editShipment',
    path: `${routeShipments}/new-shipment/:shipmentId`,
    exact: true,
    component: NewShipment,
    breadcrumb: EditShipmentBreadcrumb,
    action: UserAction.shipments,
    actionType: UserActionType.ViewOnly,
  },
  {
    name: 'newShipment',
    path: `${routeShipments}/new-shipment`,
    exact: true,
    component: NewShipment,
    breadcrumb: LocalizedBreadcrumb,
    action: UserAction.shipments,
    actionType: UserActionType.Edit,
  },
  {
    name: 'claims',
    path: `${routeClaims}`,
    exact: true,
    component: Claims,
    breadcrumb: LocalizedBreadcrumb,
    action: UserAction.claims,
    actionType: UserActionType.ViewOnly,
  },
  {
    name: 'editClaim',
    path: `${routeClaims}/new-claim/:claimId`,
    exact: true,
    component: NewClaim,
    breadcrumb: LocalizedBreadcrumb,
    action: UserAction.claims,
    actionType: UserActionType.Edit,
  },
  {
    name: 'newClaim',
    path: `${routeClaims}/new-claim`,
    exact: true,
    component: NewClaim,
    breadcrumb: LocalizedBreadcrumb,
    action: UserAction.claims,
    actionType: UserActionType.Edit,
  },
  {
    name: 'claim',
    path: `${routeClaims}/:claimId`,
    exact: true,
    component: Claim,
    breadcrumb: DynamicBreadcrumb,
    action: UserAction.claims,
    actionType: UserActionType.ViewOnly,
  },
  {
    name: 'shipment',
    path: `${routeShipments}/:shipmentId`,
    exact: true,
    component: Shipment,
    breadcrumb: DynamicBreadcrumb,
    action: UserAction.shipments,
    actionType: UserActionType.ViewOnly,
  },
  {
    name: 'companyProfile',
    path: '/company',
    exact: true,
    component: Company,
    breadcrumb: DynamicBreadcrumb,
  },
  {
    name: 'companyUsers',
    path: `${routeUsers}`,
    exact: true,
    component: Users,
    breadcrumb: DynamicBreadcrumb,
  },
  {
    name: 'templates',
    path: '/templates',
    exact: true,
    component: Templates,
    breadcrumb: LocalizedBreadcrumb,
    action: UserAction.shipments,
    actionType: UserActionType.ViewOnly,
  },
  {
    name: 'editTemplate',
    path: `${routeTemplates}/new-template/:templateId`,
    exact: true,
    component: NewTemplate,
    breadcrumb: EditTemplateBreadcrumb,
    action: UserAction.shipments,
    actionType: UserActionType.ViewOnly,
  },
  {
    name: 'newTemplate',
    path: `${routeTemplates}/new-template`,
    exact: true,
    component: NewTemplate,
    breadcrumb: LocalizedBreadcrumb,
    action: UserAction.shipments,
    actionType: UserActionType.ViewOnly,
  },
  {
    name: 'notFound',
    path: '/404',
    exact: true,
    component: NotFound,
  },
  {
    name: 'warehouse',
    path: routeWarehouse,
    exact: true,
    component: Warehouse,
    featureName: featureWarehouseView,
    action: UserAction.shipments,
    actionType: UserActionType.ViewOnly,
  },
];

export { routes };
