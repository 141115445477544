import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { phoneRegExp } from 'shared/constants/regExp';
import { ShipmentItemSubType } from 'shared/types/shipments/shipmentItem';
import { validateTimes } from 'shared/functions/date';

type ValidationReturnType = {
  confirmValidationSchema: Yup.AnySchema;
  draftValidationSchema: Yup.AnySchema;
  loadingContactValidationSchema: Yup.AnySchema;
  shipmentDetailsRowValidationSchema: Yup.AnySchema;
  dangerousGoodsInfoValidationSchema: Yup.AnySchema;
  newTemplateValidationSchema: Yup.AnySchema;
};

export const sizeIsRequired = (typeId: string): boolean => {
  return (
    typeId.toString() !== ShipmentItemSubType.FullTruckLoad.toString() &&
    typeId.toString() !== ShipmentItemSubType.LDM.toString()
  );
};

export const ldmIsRequired = (typeId: string): boolean => {
  return typeId.toString() === ShipmentItemSubType.LDM.toString();
};

export const useNewShipmentValidation = (): ValidationReturnType => {
  const { t } = useTranslation();

  const newTemplateValidationSchema = Yup.object({
    templateName: Yup.string()
      .min(1, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.TEMPLATE_NAME')),
  });

  const loadingContactValidationSchema = Yup.object({
    addressLine1: Yup.string()
      .trim()
      .min(2, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    city: Yup.string()
      .trim()
      .min(2, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    companyName: Yup.string()
      .trim()
      .min(2, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    contactPersonName: Yup.string()
      .trim()
      .min(2, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    country: Yup.string()
      .trim()
      .min(2, t('VALIDATION.INVALID_VALUE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    email: Yup.string()
      .email(t('VALIDATION.INVALID_EMAIL'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    notes: Yup.string().nullable(),
    phone: Yup.string()
      .matches(phoneRegExp, t('VALIDATION.INVALID_PHONE'))
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
    postCode: Yup.string().trim().required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
  })
    .notRequired()
    .default(undefined);

  const draftLoadingContactValidationSchema = Yup.object({
    addressLine1: Yup.string().min(2, t('VALIDATION.INVALID_VALUE')).nullable(),
    city: Yup.string().min(2, t('VALIDATION.INVALID_VALUE')).nullable(),
    companyName: Yup.string().min(2, t('VALIDATION.INVALID_VALUE')).nullable(),
    contactPersonName: Yup.string().min(2, t('VALIDATION.INVALID_VALUE')).nullable(),
    country: Yup.string().min(2, t('VALIDATION.INVALID_VALUE')).nullable(),
    email: Yup.string().email(t('VALIDATION.INVALID_EMAIL')).nullable(),
    notes: Yup.string().nullable(),
    phone: Yup.string().matches(phoneRegExp, t('VALIDATION.INVALID_PHONE')).nullable(),
    postCode: Yup.string().nullable(),
  })
    .notRequired()
    .default(undefined);

  const dangerousGoodsInfoValidationSchema = Yup.array().of(
    Yup.object().shape({
      classVal: Yup.string().required(),
      pg: Yup.string().nullable(),
      properShippingName: Yup.string().nullable(),
      un: Yup.string().required(),
    }),
  );

  const draftDangerousGoodsInfoValidationSchema = Yup.array().of(
    Yup.object().shape({
      classVal: Yup.string().nullable(),
      pg: Yup.string().nullable(),
      properShippingName: Yup.string().nullable(),
      un: Yup.string().nullable(),
    }),
  );

  const shipmentDetailsRowValidationSchema = Yup.object().shape({
    dangerousGoods: Yup.boolean().nullable(),
    dangerousGoodsInfo: dangerousGoodsInfoValidationSchema.when('dangerousGoods', {
      is: true,
      then: dangerousGoodsInfoValidationSchema,
      otherwise: dangerousGoodsInfoValidationSchema.nullable(),
    }),
    height: Yup.string()
      .nullable()
      .when('typeId', {
        is: () => true,
        then: Yup.string().required(),
      }),
    ldm: Yup.string()
      .nullable()
      .when('typeId', {
        is: (typeId: string) => ldmIsRequired(typeId),
        then: Yup.string().required(),
      }),
    length: Yup.string()
      .nullable()
      .when('typeId', {
        is: (typeId: string) => sizeIsRequired(typeId),
        then: Yup.string().required(),
      }),
    productDescription: Yup.string().nullable(),
    quantityId: Yup.string().required(),
    temperature: Yup.string()
      .nullable()
      .when('coldFrozen', {
        is: (value: string) => !!value,
        then: Yup.string().required(),
      }),
    temperatureMax: Yup.string()
      .nullable()
      .when('coldFrozen', {
        is: (value: string) => !!value,
        then: Yup.string().required(),
      }),
    typeId: Yup.string().required(),
    weight: Yup.string().required(),
    width: Yup.string()
      .nullable()
      .when('typeId', {
        is: (typeId: string) => sizeIsRequired(typeId),
        then: Yup.string().required(),
      }),
  });

  const draftShipmentDetailsRowValidationSchema = Yup.object().shape({
    dangerousGoods: Yup.boolean().nullable(),
    dangerousGoodsInfo: draftDangerousGoodsInfoValidationSchema.nullable(),
    height: Yup.string().nullable(),
    ldm: Yup.string().nullable(),
    length: Yup.string().nullable(),
    productDescription: Yup.string().nullable(),
    quantityId: Yup.string().nullable(),
    temperature: Yup.string().nullable(),
    temperatureMax: Yup.string().nullable(),
    typeId: Yup.string().nullable(),
    weight: Yup.string().nullable(),
    width: Yup.string().nullable(),
  });

  const confirmValidationSchema = Yup.object().shape({
    earliestArrivalDate: Yup.string()
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED'))
      .test(
        'is-earliest-arrival-earlier-than-latest-arrival',
        t('VALIDATION.SELECTED_EARLIEST_DATE_IS_WRONG'),
        (_, context) =>
          !context.parent.latestArrivalDate ||
          validateTimes(
            context.parent.earliestArrivalDate,
            context.parent.earliestArrivalTime,
            context.parent.latestArrivalDate,
            context.parent.latestArrivalTime,
          ),
      ),
    earliestPickupDate: Yup.string()
      .required(t('VALIDATION.THIS_FIELD_IS_REQUIRED'))
      .test(
        'is-earliest-pickup-earlier-than-latest-pickup',
        t('VALIDATION.SELECTED_EARLIEST_DATE_IS_WRONG'),
        (_, context) =>
          !context.parent.latestPickupDate ||
          validateTimes(
            context.parent.earliestPickupDate,
            context.parent.earliestPickupTime,
            context.parent.latestPickupDate,
            context.parent.latestPickupTime,
          ),
      )
      .test(
        'is-earliest-pickup-earlier-than-earliest-arrival',
        t('VALIDATION.SELECTED_ARRIVAL_DATE_IS_WRONG'),
        (_, context) =>
          !context.parent.earliestArrivalDate ||
          validateTimes(
            context.parent.earliestPickupDate,
            context.parent.earliestPickupTime,
            context.parent.earliestArrivalDate,
            context.parent.earliestArrivalTime,
          ),
      )
      .test(
        'is-earliest-pickup-earlier-than-latest-arrival',
        t('VALIDATION.SELECTED_DATE_IS_WRONG'),
        (_, context) =>
          !context.parent.latestArrivalDate ||
          validateTimes(
            context.parent.earliestPickupDate,
            context.parent.earliestPickupTime,
            context.parent.latestArrivalDate,
            context.parent.latestArrivalTime,
          ),
      ),
    earliestArrivalTime: Yup.string().nullable(),
    earliestPickupTime: Yup.string().nullable(),
    latestArrivalDate: Yup.string().when('latestArrivalTime', {
      is: (value: string) => !!value,
      then: Yup.string().required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
      otherwise: Yup.string().nullable(),
    }),
    latestPickupDate: Yup.string().when('latestPickupTime', {
      is: (value: string) => !!value,
      then: Yup.string().required(t('VALIDATION.THIS_FIELD_IS_REQUIRED')),
      otherwise: Yup.string().nullable(),
    }),
    latestPickupTime: Yup.string().nullable(),
    latestArrivalTime: Yup.string().nullable(),
    reference: Yup.string().nullable(),
    receiver: loadingContactValidationSchema,
    receiverAlternative: draftLoadingContactValidationSchema, // real validation of the alt address should take place in the alt address modal
    sender: loadingContactValidationSchema,
    senderAlternative: draftLoadingContactValidationSchema, // real validation of the alt address should take place in the alt address modal
    shipmentDetailsRows: Yup.array()
      .of(shipmentDetailsRowValidationSchema)
      .min(1, t('VALIDATION.AT_LEAST_ONE_ITEM_NEEDS_TO_BE_ADDED_IN_THE_SHIPMENT')),
    templateName: Yup.string()
      .nullable()
      .test(
        'is-defined',
        t('VALIDATION.THIS_FIELD_IS_REQUIRED'),
        (value) => value === null || !!value,
      ),
  });

  const draftValidationSchema = Yup.object().shape({
    earliestArrivalDate: Yup.string()
      .nullable()
      .test(
        'is-earliest-arrival-earlier-than-latest-arrival',
        t('VALIDATION.SELECTED_DATE_IS_WRONG'),
        (_, context) =>
          !context.parent.latestArrivalDate ||
          !context.parent.earliestArrivalDate ||
          validateTimes(
            context.parent.earliestArrivalDate,
            context.parent.earliestArrivalTime,
            context.parent.latestArrivalDate,
            context.parent.latestArrivalTime,
          ),
      ),
    earliestPickupDate: Yup.string()
      .nullable()
      .test(
        'is-earliest-pickup-earlier-than-latest-pickup',
        t('VALIDATION.SELECTED_DATE_IS_WRONG'),
        (_, context) =>
          !context.parent.latestPickupDate ||
          !context.parent.earliestPickupDate ||
          validateTimes(
            context.parent.earliestPickupDate,
            context.parent.earliestPickupTime,
            context.parent.latestPickupDate,
            context.parent.latestPickupTime,
          ),
      )
      .test(
        'is-earliest-pickup-earlier-than-earliest-arrival',
        t('VALIDATION.SELECTED_DATE_IS_WRONG'),
        (_, context) =>
          !context.parent.earliestPickupDate ||
          !context.parent.earliestArrivalDate ||
          validateTimes(
            context.parent.earliestPickupDate,
            context.parent.earliestPickupTime,
            context.parent.earliestArrivalDate,
            context.parent.earliestArrivalTime,
          ),
      )
      .test(
        'is-earliest-pickup-earlier-than-latest-arrival',
        t('VALIDATION.SELECTED_DATE_IS_WRONG'),
        (_, context) =>
          !context.parent.latestArrivalDate ||
          !context.parent.earliestPickupDate ||
          validateTimes(
            context.parent.earliestPickupDate,
            context.parent.earliestPickupTime,
            context.parent.latestArrivalDate,
            context.parent.latestArrivalTime,
          ),
      ),
    earliestArrivalTime: Yup.string().nullable(),
    earliestPickupTime: Yup.string().nullable(),
    latestArrivalDate: Yup.string().nullable(),
    latestPickupDate: Yup.string().nullable(),
    latestPickupTime: Yup.string().nullable(),
    latestArrivalTime: Yup.string().nullable(),
    reference: Yup.string().nullable(),
    receiver: draftLoadingContactValidationSchema,
    receiverAlternative: draftLoadingContactValidationSchema,
    sender: draftLoadingContactValidationSchema,
    senderAlternative: draftLoadingContactValidationSchema,
    shipmentDetailsRows: Yup.array().of(draftShipmentDetailsRowValidationSchema).nullable(),
    templateName: Yup.string().nullable(),
  });

  return {
    newTemplateValidationSchema,
    confirmValidationSchema,
    draftValidationSchema,
    loadingContactValidationSchema,
    shipmentDetailsRowValidationSchema,
    dangerousGoodsInfoValidationSchema,
  };
};
