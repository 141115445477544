import React, {
  FC,
  FocusEvent,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { OutlinedInput, OutlinedInputProps } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { useDebouncedCallback } from 'use-debounce';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import { makeStyles } from '@material-ui/styles';
import { theme } from 'theme';
import { inputDelay } from 'shared/constants/inputDelay';
import { StyleProps, Variant } from '../CustomMaterial/TextField/TextField';

export type TimeInputProps = {
  name: string;
  variant?: Variant;
  value?: unknown;
  width?: number | string;
  minWidth?: number | string;
  size?: 'small' | 'normal';
} & OutlinedInputProps;

const useStyles = makeStyles({
  root: ({
    color,
    backgroundColor,
    textColor,
    shadow,
    width,
    minWidth,
    size,
    multiline,
  }: StyleProps) => ({
    ...(size === 'small' && { fontSize: '0.75rem', lineHeight: '1.06rem' }),
    ...(size === 'small' && !multiline && { height: 32 }),
    width,
    minWidth,
    backgroundColor: backgroundColor || theme.palette.custom.white,
    color: textColor,
    '& fieldset': {
      borderColor: color,
    },
    '&:hover fieldset': {
      borderColor: color,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: color,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: color,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: color,
      borderWidth: 1,
      boxShadow: shadow,
    },
    '& label.Mui-focused': {
      color,
    },
    '& .MuiInput-underline:after': {
      borderColor: color,
    },
    '& .Mui-error.MuiOutlinedInput-input': {
      color: textColor,
      '&:hover fieldset': {
        borderColor: theme.palette.custom.brightRed,
      },
    },
    '&:-internal-autofill-selected': {
      backgroundColor: 'unset !important',
    },
    '&:-webkit-autofill': {
      '-webkit-text-fill-color': `${textColor} !important`,
    },
  }),
});

export type MaskedTimeInputProps = InputBaseComponentProps;

const MaskedTimeInput: FC<MaskedTimeInputProps> = forwardRef(({ ...restProps }, ref) => {
  const { inputRef, ...other } = restProps;

  const checkHours = (value: string) => (value[0] === '2' ? /[0-3]/ : /[0-9]/);

  return (
    <MaskedInput
      {...other}
      ref={(refInput) => {
        inputRef(ref ? refInput?.inputElement : null);
      }}
      placeholderChar=" "
      mask={[/[0-2]/, checkHours('3'), ':', /[0-5]/, /\d/]}
      placeholder="00 : 00"
    />
  );
});

const TimeInput: FC<TimeInputProps> = ({
  name,
  onChange,
  onBlur,
  value,
  width = 'auto',
  minWidth = '',
  variant = 'variant1',
  size = 'small',
  ...props
}): ReactElement => {
  const {
    mostlyDesaturatedDarkViolet,
    lightGrayishVioletAlt,
    grayishViolet,
    lightGray,
    white,
  } = theme.palette.custom;

  const defaultValues: Record<Variant, string> = {
    variant1: '',
    variant2: '',
    variant3: '',
    variant4: '',
    variant5: '',
    variant6: '',
  };

  const colors: Record<Variant, string> = {
    variant1: mostlyDesaturatedDarkViolet,
    variant2: lightGrayishVioletAlt,
    variant3: grayishViolet,
    variant4: white,
    variant5: white,
    variant6: lightGray,
  };

  const textColors: Record<Variant, string> = {
    ...defaultValues,
    variant1: theme.palette.text.secondary,
  };

  const backgroundColors: Record<Variant, string> = {
    ...defaultValues,
    variant1: 'transparent',
  };

  const shadows: Record<Variant, string> = {
    ...defaultValues,
    variant5: theme.shadows[1],
  };

  const color = colors[variant];
  const textColor = textColors[variant] || theme.palette.text.primary;
  const backgroundColor = backgroundColors[variant];
  const shadow = shadows[variant];

  const classes = useStyles({
    color,
    textColor,
    backgroundColor,
    shadow,
    width,
    minWidth,
    size,
  });

  // TODO: This logic is taken directly from our TextField implementation. When there's time to do that,
  // it's good to have this fields unified somehow, not to duplicate the logic. I believe that we could
  // make TimeInput component to use TextField internally.

  const [innerValue, setInnerValue] = useState('');

  useEffect(() => {
    const valueExists = value !== null && value !== undefined;
    setInnerValue(valueExists ? (value as string) : '');
  }, [value]);

  const handleOnChangeCallback = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  }, []);

  const debouncedHandleOnChange = useDebouncedCallback(handleOnChangeCallback, inputDelay);

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setInnerValue(event.currentTarget.value);
    debouncedHandleOnChange.callback(event);
  }, []);

  const handleOnBlur = useCallback(
    (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      debouncedHandleOnChange.flush();
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur],
  );

  // End of TODO

  return (
    <OutlinedInput
      classes={classes}
      name={name}
      value={innerValue}
      {...props}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      inputComponent={MaskedTimeInput}
    />
  );
};

export default TimeInput;
