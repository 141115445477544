import React from 'react';
import { DatatableTemplate, Template } from 'shared/types/templates/templates';

const addressStr = (entries: string[]) => entries.filter((entry) => entry).join(', ');

type UseTemplatesParserReturnType = {
  templatesParser: (templates: Template[]) => DatatableTemplate[];
};

export const useTemplatesParser = (): UseTemplatesParserReturnType => {
  return {
    templatesParser: (templates: Template[]): DatatableTemplate[] => {
      return templates.map(
        ({ id, totalWeight, totalVolume, receiver, sender, templateName, createdOn }) => ({
          id,
          amount: (
            <span>
              {totalWeight}kg / {totalVolume}m<sup>3</sup>
            </span>
          ),
          deliveryAddress: receiver
            ? addressStr([
                receiver.companyName || '',
                receiver.country || '',
                receiver.postCode || '',
                receiver.addressLine1 || '',
              ])
            : '',
          pickupAddress: sender
            ? addressStr([
                sender.companyName || '',
                sender.country || '',
                sender.postCode || '',
                sender.addressLine1 || '',
              ])
            : '',
          templateName,
          createdOn,
        }),
      );
    },
  };
};
