import * as Sentry from '@sentry/react';
import { API_URL } from 'shared/constants/apiUrl';
import { useUser } from 'shared/hooks/useUser';
import { useNotFound } from 'shared/hooks/useNotFound';

const reportPotentialFailures = async (response: Response): Promise<void> => {
  let body = null;

  try {
    body = await response.clone().json();
  } catch {
    body = await response.clone().text();
  }

  const isJson = typeof body !== 'string';

  if (response.status > 400 || (isJson && body.isSuccessful === false)) {
    const bodyStr = isJson ? JSON.stringify(body) : body;
    Sentry.captureMessage(
      `API call failure\nURL: ${response.url}\nStatus: ${response.status}\nBody: ${bodyStr}`,
    );
  }
};

export const callFetchApi = async (
  endpoint: string,
  token?: string | null,
  params: RequestInit = { headers: {} },
  excludedHeaders: string[] = [],
  on401handler: () => void = () => {},
  on400handler: () => void = () => {},
): Promise<Response> => {
  const headers: Record<string, string> = {
    authorization: token ? `Bearer ${token}` : '',
    LogtechHoldingNewBooking: 'true',
    ...(params.headers as Record<string, string>),
  };
  excludedHeaders.forEach((header) => {
    delete headers[header];
  });

  return fetch(`${API_URL}${endpoint}`, {
    ...params,
    headers,
  })
    .then((response) => {
      reportPotentialFailures(response.clone());

      if ([401].indexOf(response.status) >= 0) {
        on401handler();
        return Promise.reject(response);
      }
      // FIXME redirect does not work
      if ([400].indexOf(response.status) >= 0) {
        on400handler();
        return Promise.reject(response);
      }
      return response;
    })
    .catch((error) => {
      Sentry.captureMessage(`API call failure\nError: ${error}`);
      return error;
    });
};

type UseFetchApiReturnType = {
  fetchApi: (
    endpoint: string,
    params?: RequestInit,
    excludedHeaders?: string[],
    redirect404?: boolean,
  ) => Promise<Response>;
};

export const useFetchApi = (): UseFetchApiReturnType => {
  const user = useUser();
  const notFound = useNotFound();

  const fetchApi = async (
    endpoint: string,
    params: RequestInit = { headers: {} },
    excludedHeaders: string[] = [],
    show404?: boolean,
  ): Promise<Response> => {
    const token = await user.getValidToken();
    return callFetchApi(
      endpoint,
      token,
      params,
      excludedHeaders,
      user.logOut,
      show404 ? notFound : () => {},
    );
  };

  return {
    fetchApi,
  };
};
