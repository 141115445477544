import React, { FC, ReactElement } from 'react';
import { ShipmentsExcelImportErrorPayload } from 'shared/types/shipments/shipments';
import { SystemAlert, Typography } from 'components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import Box from '@material-ui/core/Box';
import { useCommonStyles } from '../../../../shared/styles/common';

type ShipmentImportResultTableProps = {
  shipments: ShipmentsExcelImportErrorPayload[];
  errorMessage?: string;
  cell2Header: string;
};

const useStyles = makeStyles({
  tableContainer: {
    overflow: 'hidden',
    boxShadow: 'none',
    marginTop: theme.spacing(9.8),
    '& .MuiTableCell-head': {
      fontWeight: 700,
      fontSize: '0.75rem',
      lineHeight: '17px',
      color: theme.palette.custom.veryDarkGrayAlt2,
      borderBottomColor: theme.palette.custom.gray,
      paddingBottom: theme.spacing(3),
      whiteSpace: 'nowrap',
    },
    '& .MuiTableCell-body': {
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: '17px',
      color: theme.palette.custom.veryDarkGray,
      textOverflow: 'ellipsis',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      borderBottomColor: theme.palette.custom.lightGray,
    },
  },
  table: { maxWidth: 910 },
});

const ShipmentImportResultTable: FC<ShipmentImportResultTableProps> = ({
  shipments,
  errorMessage,
  cell2Header,
}): ReactElement | null => {
  const [showError, setShowError] = React.useState(true);
  const classes = useStyles();
  const { t } = useTranslation();
  const commonStyles = useCommonStyles();

  const showFieldValue = (fieldValue: string | undefined, showSlash: boolean): ReactElement => {
    return (
      <>
        <Typography
          className={commonStyles.italic}
          customColor={theme.palette.custom.alertError}
          variant="caption"
          component="span"
        >
          {fieldValue}
        </Typography>
        {showSlash && ' / '}
      </>
    );
  };

  return (
    <>
      {errorMessage && showError && (
        <SystemAlert
          color="error"
          hideCloseButton
          borderRadius
          lessPadding
          onClose={() => setShowError(false)}
        >
          <Typography variant="inherit">{errorMessage}</Typography>
        </SystemAlert>
      )}
      <TableContainer component={Box} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell width={150}>{t('SHIPMENT.EXCEL_ROW_NUMBER')}</TableCell>
              <TableCell>{cell2Header}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shipments.map((row, index) => {
              const firstError = row.errors[0];
              const errors = row.errors.map((rowError) => {
                const key = `SHIPMENT.EXCEL_IMPORT_ERRORS.${rowError.message}`;
                return row.doNotTranslate ? rowError.message : t(key);
              });
              return (
                <TableRow key={index.toString()}>
                  <TableCell>{firstError.row}</TableCell>
                  <TableCell>
                    {errors.map((error, indexRow) =>
                      showFieldValue(error, indexRow + 1 !== errors.length),
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ShipmentImportResultTable;
