import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const CalendarIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 42,
  height = 47,
  fill = theme.palette.custom.gray,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M14.75 4.5H27.25V2.40039C27.25 1.81445 27.4453 1.32617 27.8359 0.935547C28.2591 0.544922 28.7637 0.349609 29.3496 0.349609C29.9355 0.349609 30.4238 0.544922 30.8145 0.935547C31.2051 1.32617 31.4004 1.81445 31.4004 2.40039V4.5H35.5996C37.3574 4.53255 38.8223 5.13477 39.9941 6.30664C41.166 7.47852 41.7845 8.95964 41.8496 10.75V39.9492C41.7845 41.707 41.166 43.1719 39.9941 44.3438C38.8223 45.5156 37.3574 46.1178 35.5996 46.1504H6.40039C4.64258 46.1178 3.17773 45.5156 2.00586 44.3438C0.833984 43.1719 0.215495 41.707 0.150391 39.9492V10.75C0.215495 8.95964 0.833984 7.47852 2.00586 6.30664C3.17773 5.13477 4.64258 4.53255 6.40039 4.5H10.5996V2.40039C10.5996 2.04232 10.681 1.70052 10.8438 1.375C11.0391 1.04948 11.2995 0.805339 11.625 0.642578C11.9505 0.447266 12.2923 0.349609 12.6504 0.349609C13.041 0.349609 13.3991 0.447266 13.7246 0.642578C14.0501 0.805339 14.2943 1.04948 14.457 1.375C14.6523 1.70052 14.75 2.04232 14.75 2.40039V4.5ZM37.6504 21.1504H4.34961V39.9492C4.34961 40.5352 4.54492 41.0234 4.93555 41.4141C5.32617 41.8047 5.81445 42 6.40039 42H35.5996C36.1855 42 36.6738 41.8047 37.0645 41.4141C37.4551 41.0234 37.6504 40.5352 37.6504 39.9492V21.1504ZM14.75 8.65039V10.75C14.75 11.1406 14.6523 11.4987 14.457 11.8242C14.2943 12.1172 14.0501 12.3613 13.7246 12.5566C13.3991 12.752 13.041 12.8496 12.6504 12.8496C12.2923 12.8496 11.9505 12.752 11.625 12.5566C11.2995 12.3613 11.0391 12.1172 10.8438 11.8242C10.681 11.4987 10.5996 11.1406 10.5996 10.75V8.65039H6.40039C5.84701 8.68294 5.35872 8.89453 4.93555 9.28516C4.54492 9.67578 4.34961 10.1641 4.34961 10.75V17H37.6504V10.75C37.6504 10.1641 37.4388 9.67578 37.0156 9.28516C36.625 8.89453 36.153 8.68294 35.5996 8.65039H31.4004V10.75C31.4004 11.3359 31.2051 11.8242 30.8145 12.2148C30.4238 12.6055 29.9355 12.8008 29.3496 12.8008C28.7637 12.8008 28.2591 12.6055 27.8359 12.2148C27.4453 11.8242 27.25 11.3359 27.25 10.75V8.65039H14.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default CalendarIcon;
