import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const ArrowRight: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 6,
  height = 9,
  fill = theme.palette.custom.white,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M0.839844 7.83984C0.730469 7.95833 0.675781 8.09505 0.675781 8.25C0.675781 8.40495 0.730469 8.54167 0.839844 8.66016C0.958333 8.76953 1.09505 8.82422 1.25 8.82422C1.40495 8.82422 1.54167 8.76953 1.66016 8.66016L5.16016 5.16016C5.27865 5.04167 5.33789 4.90495 5.33789 4.75C5.33789 4.59505 5.27865 4.45833 5.16016 4.33984L1.66016 0.839844C1.54167 0.730469 1.40495 0.675781 1.25 0.675781C1.09505 0.675781 0.958333 0.735026 0.839844 0.853516C0.730469 0.962891 0.675781 1.09505 0.675781 1.25C0.675781 1.40495 0.730469 1.54167 0.839844 1.66016L3.92969 4.75L0.839844 7.83984Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowRight;
