import React, { ReactElement, FC } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { theme } from 'theme';
import { QuestionMarkIcon } from 'components';
import { BaseProps } from 'shared/types';
import Box, { BoxProps } from '@material-ui/core/Box';
import clsx from 'clsx';
import pickBy from 'lodash/pickBy';

type Variant = 'variant1' | 'variant2' | 'variant3';

type LabeledProps = {
  text: string;
  tooltip?: string;
  error?: boolean;
  required?: boolean;
  variant?: Variant;
  marginRight?: number;
  marginLeft?: number;
  marginX?: number;
  width?: number | string;
} & BaseProps &
  Omit<InputLabelProps, 'variant'>;

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  variant1: {
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
  },
  variant2: {
    color: theme.palette.text.primary,
    fontSize: '0.75rem',
    marginBottom: theme.spacing(1),
  },
  variant3: {
    color: theme.palette.custom.veryDarkGrayAlt2,
    fontSize: '0.75rem',
    marginBottom: theme.spacing(0.5),
  },
  tooltipIcon: {
    marginLeft: theme.spacing(1),
  },
  asterisk: {
    color: theme.palette.custom.darkCyan,
    marginLeft: theme.spacing(1),
  },
});

const Labeled: FC<LabeledProps> = ({
  text,
  tooltip,
  error,
  required,
  variant = 'variant1',
  children,
  className,
  marginRight,
  marginLeft,
  marginX,
  width,
  ...restProps
}): ReactElement => {
  const classes = useStyles();

  const variantClasses: Record<Variant, string> = {
    variant1: classes.variant1,
    variant2: classes.variant2,
    variant3: classes.variant3,
  };

  const variantClass = variantClasses[variant];

  const boxProps: BoxProps = {
    marginLeft,
    marginRight,
    marginX,
    width,
  };

  const cleanBoxProps = pickBy(boxProps, (prop) => prop !== undefined);

  return (
    <Box {...cleanBoxProps}>
      <InputLabel
        error={error}
        className={clsx(classes.root, variantClass, className)}
        {...restProps}
      >
        {text}
        {required && <span className={classes.asterisk}>*</span>}
        {tooltip ? (
          <Tooltip title={tooltip} arrow>
            <div>
              <QuestionMarkIcon className={classes.tooltipIcon} />
            </div>
          </Tooltip>
        ) : null}
      </InputLabel>
      {children}
    </Box>
  );
};

export default Labeled;
