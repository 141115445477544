import React, { FC, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { theme } from 'theme';
import { routePriceRequests, routes, routeShipments } from 'routes';
import ArrowRight from '../Icons/Arrows/ArrowRight';

const useStyles = makeStyles({
  separator: {
    padding: '0 7px',
  },
});

type ExcludedBreadcrumbs = {
  path: string;
  excluded: string[];
};

const excludeBreadcrumbs: ExcludedBreadcrumbs[] = [
  {
    path: `${routeShipments}/new-shipment/:shipmentId`,
    excluded: [`${routeShipments}/new-shipment`],
  },
  {
    path: `${routePriceRequests}/new-price-request/:priceRequestId`,
    excluded: [`${routePriceRequests}/new-price-request`],
  },
];

const Breadcrumb: FC = (): ReactElement => {
  const classes = useStyles();

  const location = useLocation();

  const excludedBreadcrumbsForPath =
    excludeBreadcrumbs.find(({ path }) =>
      matchPath(location.pathname, {
        path,
        exact: true,
      }),
    )?.excluded ?? [];

  const breadcrumbs = useBreadcrumbs(routes, {
    disableDefaults: true,
    excludePaths: excludedBreadcrumbsForPath,
  });

  const breadcrumbsLen = breadcrumbs.length;

  return (
    <>
      {breadcrumbsLen > 1 && (
        <Box position="absolute" left={0} top={24} color={theme.palette.custom.veryDarkGray}>
          {breadcrumbs.map(({ match, breadcrumb }, index: number) => (
            <span key={match.url}>
              {breadcrumbsLen !== index + 1 ? (
                <>
                  <NavLink to={match.url}>{breadcrumb}</NavLink>
                  <Typography variant="inherit" className={classes.separator}>
                    <ArrowRight fill={theme.palette.custom.veryDarkGray} />
                  </Typography>
                </>
              ) : (
                <>{breadcrumb}</>
              )}
            </span>
          ))}
        </Box>
      )}
    </>
  );
};

export default Breadcrumb;
