import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDeepCompareEffectNoCheck as useDeepCompareEffect } from 'use-deep-compare-effect';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import { useUser } from 'shared/hooks/useUser';
import { useQuery } from 'shared/hooks/useQuery';

import { theme } from 'theme';

import { BaseProps } from 'shared/types';
import { Pagination as PaginationType, SortValue } from 'shared/types/table';
import { generateKey } from 'shared/functions/generateKey';
import { useCommonStyles } from 'shared/styles/common';
import { IShipmentsFilters } from 'shared/types/shipments/shipments';
import {
  Select,
  Loader,
  Pagination,
  Typography,
  ShipmentsNoResults,
  ShipmentsOnboarding,
} from 'components';
import { truncate } from 'shared/functions/truncate';

export type DatatableColumnValue = string | number | boolean | ReactElement;

export type DataTableColumn<T> = {
  name: string;
  label?: string;
  width?: number | string;
  margin?: number;
  limit?: number;
  backgroundColor?: string;
  tooltip?: (value?: DatatableColumnValue, label?: string, rowData?: T) => ReactElement | string;
  customRenderer?: (
    value?: DatatableColumnValue,
    label?: string,
    rowData?: T,
  ) => ReactElement | null;
  removeFirstCellClass?: boolean;
  hidden?: boolean;
};

export type DataTableOptions<T> = {
  initialSort?: SortValue;
  onSortChange?: (sort: SortValue) => void;
  onPageSizeChange?: (sizePerPage: number) => void;
  onPageChange?: (page: number) => void;
  onFilterChange?: (filters: IShipmentsFilters) => void;
  customRowClass?: (rowData: T) => string;
};

export type DataTableProps<T> = {
  data: T[];
  total: number;
  pagination: PaginationType;
  options?: DataTableOptions<T>;
  columns: DataTableColumn<T>[];
  toolbarExtraContent?: ReactElement | null;
  filterContent: ReactElement | null;
  sortValues: SortValue[];
  pageSizeValues: number[];
  isPending?: boolean;
  showFilters: boolean;
  setFilters: (visible: boolean) => void | undefined;
  filterIsUsed?: boolean;
  type?: 'shipments' | 'price-requests' | 'templates' | 'users';
} & BaseProps;

export type DataTableQueryParams = {
  sort?: SortValue;
  sizePerPage?: number;
  page?: number;
  filters?: IShipmentsFilters;
  createdByMe?: boolean;
};

const useStyles = makeStyles({
  text: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  label: {
    color: theme.palette.custom.veryDarkGrayAlt2,
  },
  value: {
    marginTop: theme.spacing(0.5),
  },
  showText: {
    marginRight: theme.spacing(2),
  },
  showSelect: {
    width: 70,
  },
  openFilter: {
    marginRight: theme.spacing(8),
    whiteSpace: 'nowrap',
  },
  sortSelect: {
    minWidth: 140,
  },
  sortBy: {
    marginRight: theme.spacing(2),
    width: 45,
    whiteSpace: 'nowrap',
    flexGrow: 1,
    textAlign: 'end',
  },
  toolTipped: {
    cursor: 'pointer',
  },
  firstCell: {
    paddingLeft: theme.spacing(10),
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,

    [theme.breakpoints.down(1440)]: {
      paddingLeft: theme.spacing(8),
    },
  },
});

const DataTable: <T extends Record<string, DatatableColumnValue>>(
  props: DataTableProps<T>,
) => ReactElement<DataTableProps<T>> = ({
  data,
  total,
  pagination,
  columns,
  options,
  toolbarExtraContent = null,
  sortValues = [],
  pageSizeValues = [],
  isPending = false,
  filterContent,
  showFilters,
  setFilters,
  filterIsUsed,
  type,
}): ReactElement => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const user = useUser();
  const { queryParams, updateQueryParams, queryParamsLoading } = useQuery<DataTableQueryParams>();
  const { filters, page, sizePerPage, sort } = queryParams;

  const { customRowClass } = options || {};

  const showNoPriceRequests = type === 'price-requests' && (!data || (!data.length && !isPending));

  const showNoShipments =
    type === 'shipments' &&
    ((!user.userOnboarding.shipments && !data) ||
      (!user.userOnboarding.shipments && !data.length && !isPending));

  const showShipmentsOnboarding =
    type === 'shipments' &&
    !isPending &&
    ((user.userOnboarding.shipments && !data) || (user.userOnboarding.shipments && !data.length));

  const onSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const index = event.target.value as number;
    const sortValue = sortValues[index];
    updateQueryParams({ sort: sortValue });
  };

  const onPageSizeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newPageSize = event.target.value as number;
    updateQueryParams({ sizePerPage: newPageSize });
  };

  const onPageChange = (newPageNumber: number) => {
    updateQueryParams({ page: newPageNumber });
  };

  useDeepCompareEffect(() => {
    if (options?.onFilterChange && !queryParamsLoading) {
      options.onFilterChange(filters ?? {});
    }
  }, [filters, queryParamsLoading]);

  useEffect(() => {
    if (page && options?.onPageChange && !queryParamsLoading) {
      options.onPageChange(page ?? 1);
    }
  }, [page, queryParamsLoading]);

  useEffect(() => {
    if (sizePerPage && options?.onPageSizeChange && !queryParamsLoading) {
      options.onPageSizeChange(sizePerPage ?? 50);
    }
  }, [sizePerPage, queryParamsLoading]);

  useDeepCompareEffect(() => {
    if (options?.onSortChange && sort && !queryParamsLoading) {
      options.onSortChange(sort);
    }
  }, [sort, queryParamsLoading]);

  const selectedSortValue = sortValues.findIndex(
    ({ value, sortOrder }) =>
      value === pagination.sort?.value && sortOrder === pagination.sort?.sortOrder,
  );

  const anyFilterSectionElements: boolean = filterContent !== null || sortValues.length > 0;

  const filterTopMargin = anyFilterSectionElements ? 7.5 : 3;

  return (
    <Box position="relative">
      {isPending && <Loader cover />}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={showFilters ? 6 : filterTopMargin}
        mt={showFilters ? 1 : 0}
      >
        {toolbarExtraContent && (
          <Box display="flex" alignItems="center" zIndex={5}>
            {toolbarExtraContent}
          </Box>
        )}
        {!showFilters && anyFilterSectionElements && (
          <Box display="flex" alignItems="center">
            {filterContent && (
              <Typography
                onClick={() => setFilters(true)}
                variant="body2"
                link
                className={classes.openFilter}
              >
                {t('OPEN_FILTER')}
              </Typography>
            )}
            {sortValues.length > 0 && (
              <>
                <Typography variant="caption" className={classes.sortBy}>
                  {t('SORT_BY')}
                </Typography>
                <Box width={142}>
                  <Select
                    displayEmpty
                    fullWidth
                    className={classes.sortSelect}
                    onChange={onSortChange}
                    value={selectedSortValue}
                    defaultValue={-1}
                  >
                    <MenuItem value={-1} className={commonClasses.defaultMenuItem}>
                      {t('SELECT')}
                    </MenuItem>
                    {sortValues.map(({ name }, index) => (
                      <MenuItem key={generateKey(index, 'sort_menu_item')} value={index}>
                        {t(name)}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
      {showFilters && (
        <>
          {filterContent}
          {sortValues.length > 0 && (
            <Box display="flex" alignItems="center" mb={5}>
              {filterIsUsed && (
                <Typography variant="subtitle2" fontWeight="bold">
                  {t('FILTER_RESULT')}
                </Typography>
              )}
              <Typography variant="caption" className={classes.sortBy}>
                {t('SORT_BY')}
              </Typography>
              <Box width={142}>
                <Select
                  displayEmpty
                  fullWidth
                  className={classes.sortSelect}
                  onChange={onSortChange}
                  value={selectedSortValue}
                  defaultValue={-1}
                >
                  <MenuItem value={-1} className={commonClasses.defaultMenuItem}>
                    {t('SELECT')}
                  </MenuItem>
                  {sortValues.map(({ name }, index) => (
                    <MenuItem key={generateKey(index, 'sort_menu_item')} value={index}>
                      {t(name)}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          )}
        </>
      )}
      {showNoPriceRequests && <ShipmentsNoResults />}
      {showNoShipments && <ShipmentsNoResults />}
      {showShipmentsOnboarding && <ShipmentsOnboarding />}
      {data.map((record, index) => (
        <Box
          key={generateKey(index, 'table_row')}
          border={1}
          borderColor={theme.palette.custom.lightGray}
          borderRadius={16}
          position="relative"
          display="flex"
          mb={4}
          pr={4}
          bgcolor={theme.palette.custom.white}
          className={customRowClass ? customRowClass(record) : undefined}
        >
          {columns.map(
            (
              {
                removeFirstCellClass,
                name,
                label,
                width,
                margin,
                tooltip,
                customRenderer,
                limit,
                backgroundColor,
                hidden,
              },
              columnIndex,
            ) => {
              const value = name in record ? record[name] : undefined;
              let tooltipData = tooltip;
              if (hidden) return <></>;
              const valueCustomTooltipNeeded =
                typeof value === 'string' &&
                !customRenderer &&
                !tooltip &&
                limit &&
                value.toString().length > limit;

              if (valueCustomTooltipNeeded) {
                tooltipData = (val) => {
                  return val ? <Typography variant="caption">{val}</Typography> : '–';
                };
              }

              const tooltipTitle =
                tooltipData && tooltipData(value, label, record) !== '–'
                  ? tooltipData(value, label, record)
                  : '';

              return (
                <Box
                  className={clsx(!removeFirstCellClass && !columnIndex && classes.firstCell)}
                  key={generateKey(columnIndex, `${name}_${index}`)}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  flexGrow={width ? 0 : 1}
                  width={width || 'unset'}
                  mr={margin || 'unset'}
                  py={4}
                  bgcolor={backgroundColor || 'transparent'}
                >
                  {customRenderer ? (
                    <Tooltip title={tooltipTitle} arrow enterDelay={200} enterNextDelay={200}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        className={clsx(tooltipTitle && classes.toolTipped)}
                      >
                        {customRenderer(value, label, record)}
                      </Box>
                    </Tooltip>
                  ) : (
                    <>
                      {label && label.length > 0 && (
                        <Typography className={clsx(classes.text, classes.label)} variant="caption">
                          {label}
                        </Typography>
                      )}
                      <Tooltip title={tooltipTitle} arrow enterDelay={200} enterNextDelay={200}>
                        <div>
                          <Typography
                            className={clsx(
                              classes.text,
                              classes.value,
                              tooltipTitle && classes.toolTipped,
                            )}
                            variant="body2"
                          >
                            {!value || !limit || !valueCustomTooltipNeeded
                              ? value || '–'
                              : truncate(value.toString(), limit)}
                          </Typography>
                        </div>
                      </Tooltip>
                    </>
                  )}
                </Box>
              );
            },
          )}
        </Box>
      ))}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {pagination && !pagination?.hideSizePerPage && (
          <Box display="flex" alignItems="center">
            <Typography variant="caption" className={classes.showText}>
              {t('SHOW')}
            </Typography>
            <Select
              displayEmpty
              className={classes.showSelect}
              value={pagination.sizePerPage}
              onChange={onPageSizeChange}
            >
              {pageSizeValues.map((option, index) => (
                <MenuItem key={generateKey(index, 'items_per_page')} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        <Pagination
          total={total}
          page={pagination.page || 0}
          sizePerPage={pagination.sizePerPage || 0}
          onPageChange={onPageChange}
          maxPages={pagination.maxPages || 0}
          hideSizePerPage={pagination.hideSizePerPage}
        />
      </Box>
    </Box>
  );
};

export default DataTable;
