import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const DownloadIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 14,
  height = 16,
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.162109 9.66211V11.4121C0.180339 11.9134 0.353516 12.3281 0.681641 12.6562C1.00977 12.9844 1.41992 13.153 1.91211 13.1621H10.0879C10.5801 13.153 10.9902 12.9844 11.3184 12.6562C11.6465 12.3281 11.8197 11.9134 11.8379 11.4121V9.66211C11.8288 9.49805 11.7695 9.36133 11.6602 9.25195C11.5508 9.14258 11.4141 9.08789 11.25 9.08789C11.0859 9.08789 10.9492 9.14258 10.8398 9.25195C10.7305 9.36133 10.6712 9.49805 10.6621 9.66211V11.4121C10.6621 11.5762 10.6074 11.7174 10.498 11.8359C10.3887 11.9453 10.252 12 10.0879 12H1.91211C1.74805 12 1.61133 11.9453 1.50195 11.8359C1.39258 11.7174 1.33789 11.5762 1.33789 11.4121V9.66211C1.32878 9.49805 1.26953 9.36133 1.16016 9.25195C1.05078 9.14258 0.914062 9.08789 0.75 9.08789C0.585938 9.08789 0.449219 9.14258 0.339844 9.25195C0.230469 9.36133 0.171224 9.49805 0.162109 9.66211ZM6 8.25391L4.07227 6.33984C3.99935 6.25781 3.91276 6.20312 3.8125 6.17578C3.71224 6.14844 3.61198 6.14844 3.51172 6.17578C3.41146 6.20312 3.32487 6.25326 3.25195 6.32617C3.17904 6.39909 3.12891 6.49023 3.10156 6.59961C3.07422 6.69987 3.07422 6.80013 3.10156 6.90039C3.12891 7.00065 3.17904 7.08724 3.25195 7.16016L5.58984 9.49805C5.70833 9.60742 5.84505 9.66211 6 9.66211C6.15495 9.66211 6.29167 9.60742 6.41016 9.49805L8.74805 7.16016C8.85742 7.04167 8.91211 6.90495 8.91211 6.75C8.91211 6.59505 8.85286 6.46289 8.73438 6.35352C8.61589 6.23503 8.47917 6.17578 8.32422 6.17578C8.17839 6.17578 8.04622 6.23047 7.92773 6.33984L6 8.25391ZM5.41211 0.912109V9.08789C5.42122 9.25195 5.48047 9.38867 5.58984 9.49805C5.69922 9.60742 5.83594 9.66211 6 9.66211C6.16406 9.66211 6.30078 9.60742 6.41016 9.49805C6.51953 9.38867 6.57878 9.25195 6.58789 9.08789V0.912109C6.57878 0.748047 6.51953 0.611328 6.41016 0.501953C6.30078 0.392578 6.16406 0.337891 6 0.337891C5.83594 0.337891 5.69922 0.392578 5.58984 0.501953C5.48047 0.611328 5.42122 0.748047 5.41211 0.912109Z"
        fill="#26084D"
      />
    </svg>
  );
};

export default DownloadIcon;
