import React, { ReactElement, FC } from 'react';
import { StarIcon } from 'components';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import { useShipments } from 'shared/services/shipments';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  editIcon: {
    marginRight: theme.spacing(2),
  },
  iconWrapper: {
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.custom.darkCyan,
      borderRadius: '50%',
      '& svg path': {
        fill: 'white',
      },
    },
  },
});

type ShipmentStarComponentProps = {
  shipmentId: number;
  isFavoriteShipment: boolean;
  onSuccess: () => void;
};

const ShipmentStarComponent: FC<ShipmentStarComponentProps> = ({
  shipmentId,
  isFavoriteShipment,
  onSuccess,
}): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { toggleAsFavorite } = useShipments();
  const starTooltip: string = t('SHIPMENT.STAR_TOOLTIP');

  const toggleIsFavorite = async () => {
    if (shipmentId) {
      const response = await toggleAsFavorite({ id: shipmentId });
      if (response.isSuccessful && response.payload) {
        await onSuccess();
      }
    }
  };

  return (
    <div
      role="presentation"
      onKeyDown={() => {}}
      className={clsx(classes.iconWrapper, classes.editIcon)}
      onClick={() => toggleIsFavorite()}
    >
      <Tooltip title={starTooltip}>
        <span>
          <StarIcon
            fill={
              !isFavoriteShipment ? theme.palette.custom.gray : theme.palette.custom.vividYellow
            }
            borderOnly={!isFavoriteShipment}
          />
        </span>
      </Tooltip>
    </div>
  );
};

export default ShipmentStarComponent;
