import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const ShipmentsIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 20,
  height = 19,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 19"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path d="M9.62891 0.417969C9.8763 0.300781 10.1237 0.300781 10.3711 0.417969L18.7109 4.59766C18.8542 4.66276 18.9648 4.76042 19.043 4.89062C19.1211 5.02083 19.1602 5.17057 19.1602 5.33984C19.1602 5.49609 19.1211 5.63932 19.043 5.76953C18.9648 5.89974 18.8542 6.00391 18.7109 6.08203L10.3711 10.2422C10.1237 10.3594 9.8763 10.3594 9.62891 10.2422L1.28906 6.08203C1.14583 6.00391 1.03516 5.89974 0.957031 5.76953C0.878906 5.63932 0.839844 5.49609 0.839844 5.33984C0.839844 5.17057 0.878906 5.02083 0.957031 4.89062C1.03516 4.76042 1.14583 4.66276 1.28906 4.59766L9.62891 0.417969ZM3.53516 5.33984L10 8.5625L16.4648 5.33984L10 2.09766L3.53516 5.33984ZM2.03125 12.918C1.82292 12.8268 1.61458 12.8203 1.40625 12.8984C1.21094 12.9635 1.0612 13.1003 0.957031 13.3086C0.852865 13.5169 0.833333 13.7253 0.898438 13.9336C0.963542 14.1419 1.09375 14.2982 1.28906 14.4023L9.62891 18.582C9.8763 18.6992 10.1237 18.6992 10.3711 18.582L18.7109 14.4023C18.9062 14.2982 19.0365 14.1419 19.1016 13.9336C19.1667 13.7253 19.1471 13.5169 19.043 13.3086C18.9388 13.1003 18.7826 12.9635 18.5742 12.8984C18.3789 12.8203 18.1771 12.8268 17.9688 12.918L10 16.9023L2.03125 12.918ZM2.03125 8.75781C1.82292 8.66667 1.61458 8.66016 1.40625 8.73828C1.21094 8.80339 1.0612 8.9401 0.957031 9.14844C0.852865 9.35677 0.833333 9.5651 0.898438 9.77344C0.963542 9.96875 1.09375 10.125 1.28906 10.2422L9.62891 14.4023C9.8763 14.5195 10.1237 14.5195 10.3711 14.4023L18.7109 10.2422C18.9062 10.125 19.0365 9.96875 19.1016 9.77344C19.1667 9.5651 19.1471 9.35677 19.043 9.14844C18.9388 8.9401 18.7826 8.80339 18.5742 8.73828C18.3789 8.66016 18.1771 8.66667 17.9688 8.75781L10 12.7422L2.03125 8.75781Z" />
    </svg>
  );
};

export default ShipmentsIcon;
