export const classLabels = [
  'SHIPMENT.ITEM.CLASS.EXPLOSIVES',
  'SHIPMENT.ITEM.CLASS.GASES',
  'SHIPMENT.ITEM.CLASS.FLAMMABLE_LIQUIDS',
  'SHIPMENT.ITEM.CLASS.FLAMMABLE_SOLIDS',
  'SHIPMENT.ITEM.CLASS.OXIDIZERS',
  'SHIPMENT.ITEM.CLASS.TOXIC_SUBSTANCES',
  'SHIPMENT.ITEM.CLASS.RADIOACTIVE',
  'SHIPMENT.ITEM.CLASS.CORROSIVES',
  'SHIPMENT.ITEM.CLASS.MISCELLANOUS',
];
