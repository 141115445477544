import React, { ReactElement, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps, useFormikContext } from 'formik';
import Box, { BoxProps } from '@material-ui/core/Box';
import { DatePicker, Labeled, Typography } from 'components';
import { theme } from 'theme';
import RadioGroup from '@material-ui/core/RadioGroup';
import { BaseProps } from 'shared/types';
import { NewShipmentPayload, ShipmentDateType } from 'shared/types/shipments/shipments';
import { useCommonStyles } from 'shared/styles/common';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import TimeInput from '../TimeInput/TimeInput';
import RadioOption from '../CustomMaterial/Radio/Radio';

type ShipmentDatesEditableProps = {
  type: ShipmentDateType;
} & BoxProps &
  BaseProps;

const useStyles = makeStyles({
  root: {
    '& .MuiRadio-root ': {
      color: theme.palette.custom.gray,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '0.875rem',
      lineHeight: '20px',
      fontWeight: 500,
    },
    '& .Mui-checked': {
      color: theme.palette.custom.veryDarkDesaturatedViolet,
    },
  },
  dateWrapper: {
    display: 'flex',
  },
  selected: {
    '& .MuiFormControlLabel-label': {
      fontWeight: 700,
    },
  },
});

const ShipmentDatesEditable: FC<ShipmentDatesEditableProps> = ({
  type,
  ...restProps
}): ReactElement => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const formik = useFormikContext<NewShipmentPayload>();
  const { t } = useTranslation();

  const [fixedDateSelected, setFixedDateSelected] = useState(
    type === 'Pickup' ? formik.values.isFixedPickupDate : formik.values.isFixedArrivalDate,
  );

  const nameRadioButton = type === 'Pickup' ? 'isFixedPickupDate' : 'isFixedArrivalDate';
  const earliestInputName = `earliest${type}Date`;
  const latestInputName = `latest${type}Date`;
  const earliestTimeInputName = `earliest${type}Time`;
  const latestTimeInputName = `latest${type}Time`;

  const toggleUseFixedDates = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const shouldSelectFixedDate = value !== 'false';
    setFixedDateSelected(shouldSelectFixedDate);

    formik.setFieldValue(nameRadioButton, shouldSelectFixedDate);

    // Reset all field values
    formik.setFieldValue(earliestInputName, null);
    formik.setFieldValue(earliestTimeInputName, null);
    formik.setFieldValue(latestInputName, null);
    formik.setFieldValue(latestTimeInputName, null);

    // Reset all error states
    formik.setFieldError(earliestInputName, undefined);
    formik.setFieldError(earliestTimeInputName, undefined);
    formik.setFieldError(latestInputName, undefined);
    formik.setFieldError(latestTimeInputName, undefined);
  };

  const onTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      formik.setFieldValue(event.target.name, event.target.value);
    } catch {
      formik.setFieldError(event.target.name, t('SHIPMENTS_SCENARIO.FILTER.INVALID_DATE'));
    }
  };

  const datePickerField = (
    inputName: string,
    timeInputName: string,
    dateLabelText: string,
    timeLabelText: string,
    required: boolean,
  ) => {
    return (
      <>
        <Box display="inline-block">
          <Labeled error={false} variant="variant2" text={dateLabelText} required={required}>
            <DatePicker
              size="small"
              variant="variant6"
              width={217}
              startDateName={inputName}
              disablePast
            />
          </Labeled>
        </Box>
        <Field name={timeInputName}>
          {({ field }: FieldProps) => {
            return (
              <Box display="inline-block" marginLeft={6}>
                <Labeled text={timeLabelText} variant="variant2">
                  <TimeInput
                    {...field}
                    variant="variant6"
                    size="small"
                    width={85}
                    name={timeInputName}
                    onChange={onTimeChange}
                  />
                </Labeled>
              </Box>
            );
          }}
        </Field>
      </>
    );
  };

  return (
    <Box
      width="100%"
      borderRadius={16}
      paddingTop={10}
      paddingX={10}
      paddingBottom={8}
      bgcolor={theme.palette.custom.veryLightGrayAlt2}
      {...restProps}
    >
      <Typography variant="subtitle2" fontWeight="extraBold">
        {t(type === 'Pickup' ? 'SHIPMENT.DATES.PICKUP_DATES' : 'SHIPMENT.DATES.DELIVERY_DATES')}
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={3}
        marginTop={1}
      >
        <RadioGroup
          aria-label={nameRadioButton}
          name={nameRadioButton}
          value={fixedDateSelected}
          onChange={(event) => toggleUseFixedDates(event, event.target.value)}
          className={classes.root}
        >
          <FormControlLabel
            className={clsx(!fixedDateSelected && classes.selected)}
            value={false}
            control={<RadioOption />}
            label={t(
              type === 'Pickup'
                ? 'SHIPMENT.DATES.PICK_PICKUP_DATES'
                : 'SHIPMENT.DATES.PICK_DELIVERY_DATES',
            )}
            labelPlacement="end"
          />
          {!fixedDateSelected && (
            <>
              <Typography
                variant="inherit"
                className={clsx(commonClasses.ml9, classes.dateWrapper)}
              >
                {datePickerField(
                  earliestInputName,
                  earliestTimeInputName,
                  t(
                    type === 'Pickup'
                      ? 'SHIPMENT.EARLIEST_PICKUP_DATE'
                      : 'SHIPMENT.EARLIEST_DELIVERY_DATE',
                  ),
                  t(
                    type === 'Pickup'
                      ? 'SHIPMENT.EARLIEST_PICKUP_TIME'
                      : 'SHIPMENT.EARLIEST_DELIVERY_TIME',
                  ),
                  true,
                )}
              </Typography>
              <Typography
                variant="inherit"
                className={clsx(commonClasses.ml9, classes.dateWrapper, commonClasses.mt3)}
              >
                {datePickerField(
                  latestInputName,
                  latestTimeInputName,
                  t(
                    type === 'Pickup'
                      ? 'SHIPMENT.LATEST_PICKUP_DATE'
                      : 'SHIPMENT.LATEST_DELIVERY_DATE',
                  ),
                  t(
                    type === 'Pickup'
                      ? 'SHIPMENT.LATEST_PICKUP_TIME'
                      : 'SHIPMENT.LATEST_DELIVERY_TIME',
                  ),
                  false,
                )}
              </Typography>
            </>
          )}
          <FormControlLabel
            className={clsx(
              fixedDateSelected && classes.selected,
              fixedDateSelected && commonClasses.mt1,
              !fixedDateSelected && commonClasses.mt4,
            )}
            value
            control={<RadioOption />}
            label={t(
              type === 'Pickup'
                ? 'SHIPMENT.DATES.PICK_PICKUP_FIXED_DATE'
                : 'SHIPMENT.DATES.PICK_DELIVERY_FIXED_DATE',
            )}
          />
          {fixedDateSelected && (
            <Typography variant="inherit" className={clsx(commonClasses.ml9, classes.dateWrapper)}>
              {datePickerField(
                earliestInputName,
                earliestTimeInputName,
                t(
                  type === 'Pickup' ? 'SHIPMENT.FIXED_PICKUP_DATE' : 'SHIPMENT.FIXED_DELIVERY_DATE',
                ),
                t(
                  type === 'Pickup' ? 'SHIPMENT.FIXED_PICKUP_TIME' : 'SHIPMENT.FIXED_DELIVERY_TIME',
                ),
                true,
              )}
            </Typography>
          )}
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default ShipmentDatesEditable;
