import MaterialTypography, {
  TypographyProps as MaterialTypographyProps,
} from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { FC, ReactElement } from 'react';
import { theme } from 'theme';
import pickBy from 'lodash/pickBy';
import clsx from 'clsx';

type StyleProps = {
  marginRight?: number;
  marginLeft?: number;
  marginTop?: number;
  marginBottom?: number;
  margin?: number;
  width?: number;
  fontWeight?: 'regular' | 'medium' | 'semibold' | 'bold' | 'extraBold' | 'black';
  fontStyle?: 'inherit' | 'initial' | 'revert' | 'unset' | 'italic' | 'normal' | 'oblique';
  textAlign?: 'left' | 'center' | 'right';
  customColor?: string;
  underline?: boolean;
  link?: boolean;
};

type AllowedComponent = 'p' | 'a' | 'span' | 'div';

export type TypographyProps = {
  dashWhenEmpty?: boolean;
  badge?: number;
} & StyleProps &
  MaterialTypographyProps<AllowedComponent, { component?: AllowedComponent }>;

const fontWeightMapping = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};

const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  badge: {
    position: 'relative',
    right: theme.spacing(1),
    bottom: theme.spacing(2),
    display: 'inline-block',
    borderRadius: '50%',
    fontSize: '0.75rem',
    fontWeight: theme.spacing(4),
    textAlign: 'center',
    lineHeight: '15px',
    color: theme.palette.custom.white,
    background: theme.palette.custom.strongViolet,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  badgeSmallText: {
    fontSize: '0.5rem',
    lineHeight: '16px',
  },
});

const Typography: FC<TypographyProps> = ({
  className,
  marginLeft,
  marginRight,
  marginBottom,
  marginTop,
  margin,
  width,
  textAlign,
  fontWeight,
  fontStyle,
  customColor,
  underline,
  link,
  dashWhenEmpty,
  children,
  badge,
  ...typographyProps
}): ReactElement => {
  const classes = useStyles();

  const styles: React.CSSProperties = {
    margin: margin ? theme.spacing(margin) : undefined,
    marginLeft: marginLeft ? theme.spacing(marginLeft) : undefined,
    marginRight: marginRight ? theme.spacing(marginRight) : undefined,
    marginBottom: marginBottom ? theme.spacing(marginBottom) : undefined,
    marginTop: marginTop ? theme.spacing(marginTop) : undefined,
    width,
    fontWeight: fontWeight ? fontWeightMapping[fontWeight] : undefined,
    textAlign,
    fontStyle,
    color: customColor,
    textDecoration: underline ? 'underline' : undefined,
  };

  const cleanStyles = pickBy(styles, (value) => value !== undefined);

  // badge threshold for changing styles, two digit numbers won't fit with the current design...
  const largestDigit = 9;

  return (
    <MaterialTypography
      {...typographyProps}
      className={clsx(className, link && classes.link)}
      style={cleanStyles}
    >
      {!dashWhenEmpty ? children : children || '–'}
      {badge && badge > 0 ? (
        <span className={clsx(classes.badge, badge > largestDigit && classes.badgeSmallText)}>
          {badge}
        </span>
      ) : (
        ''
      )}
    </MaterialTypography>
  );
};

export default Typography;
