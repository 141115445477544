import React, { FC, ReactElement } from 'react';
import createPersistedState from 'use-persisted-state';

import { dummyUserContextData, UserContext, UserContextData } from 'shared/hooks/useUser';
import { LogInState } from 'shared/types/session';
import { UserProfileState } from 'shared/types/userProfile';
import { UserOnboardingState } from 'shared/types/userOnboarding';
import { UserUnreadCommentsState } from 'shared/types/comments';

const useLoginState = createPersistedState('laasone-customer-login');
const useUserProfileState = createPersistedState('laasone-customer-user');
const useUserOnboardingState = createPersistedState('laasone-customer-onboarding');
const useUnreadComments = createPersistedState('laasone-customer-unread-comments');

export const UserContextProvider: FC = ({ children }): ReactElement => {
  const [authDataState, setAuthDataState] = useLoginState<LogInState>(null);
  const [userProfileState, setUserProfileState] = useUserProfileState<UserProfileState>(null);
  const [userOnboardingState, setUserOnboardingState] = useUserOnboardingState<UserOnboardingState>(
    { shipments: true, priceRequests: true },
  );
  const [userUnreadCommentsState, setUserUnreadCommentsState] = useUnreadComments<
    UserUnreadCommentsState | undefined
  >(undefined);

  const initialUserContextData: UserContextData = {
    ...dummyUserContextData,
    authData: authDataState,
    setAuthData: setAuthDataState,
    userProfile: userProfileState,
    setUserProfile: setUserProfileState,
    userOnboarding: userOnboardingState,
    setUserOnboarding: setUserOnboardingState,
    userUnreadComments: userUnreadCommentsState,
    setUserUnreadComments: setUserUnreadCommentsState,
  };

  return <UserContext.Provider value={initialUserContextData}>{children}</UserContext.Provider>;
};
