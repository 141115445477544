import React, { FC, ReactElement } from 'react';
import MaterialAccordion, {
  AccordionProps as MaterialAccordionProps,
} from '@material-ui/core/Accordion';
import makeStyles from '@material-ui/styles/makeStyles';
import { BaseProps } from 'shared/types';
import { theme } from 'theme';

type StyleProps = {
  borderRadius?: number;
  backgroundColor?: string;
  backgroundColorExpanded?: string;
  borderWidth?: number;
  borderColor?: string;
};

type AccordionProps = StyleProps & BaseProps & MaterialAccordionProps;

const useNativeStyles = makeStyles({
  root: ({
    backgroundColor,
    borderRadius,
    backgroundColorExpanded,
    borderColor,
    borderWidth,
  }: StyleProps) => ({
    backgroundColor,
    borderRadius,
    borderColor,
    borderWidth,
    borderStyle: 'solid',
    boxShadow: 'none',
    padding: 0,
    '&:before': {
      height: 0,
      backgroundColor: 'unset',
    },
    '&.Mui-expanded': {
      backgroundColor: backgroundColorExpanded,
      margin: 0,
    },
    '& .MuiCollapse-container': {
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    },
    '& .MuiCollapse-wrapper': {
      padding: theme.spacing(0),
    },
    '& .MuiAccordionDetails-root': {
      padding: theme.spacing(0),
    },
  }),
  rounded: ({ borderRadius }: StyleProps) => ({
    '&:first-child': {
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
    },
    '&:last-child': {
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    },
  }),
});

const Accordion: FC<AccordionProps> = ({
  children,
  borderRadius = 16,
  backgroundColor = 'unset',
  backgroundColorExpanded,
  borderWidth = 1,
  borderColor = theme.palette.custom.lightGray,
  ...restProps
}): ReactElement => {
  const nativeClasses = useNativeStyles({
    borderRadius,
    backgroundColor,
    backgroundColorExpanded,
    borderWidth,
    borderColor,
  });

  return (
    <MaterialAccordion classes={nativeClasses} {...restProps}>
      {children}
    </MaterialAccordion>
  );
};

export default Accordion;
