import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const CopyLinkIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 14,
  height = 16,
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2H2V14H6V7C6 5.89543 6.89543 5 8 5H9V2ZM11 5V2C11 0.895431 10.1046 0 9 0H2C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H6V19C6 20.1046 6.89543 21 8 21H15C16.1046 21 17 20.1046 17 19V7C17 5.89543 16.1046 5 15 5H11ZM11 7H9H8V14V16V19H15V7H11Z"
        fill="#26084D"
      />
    </svg>
  );
};

export default CopyLinkIcon;
