import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const CircleCheckedIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 12,
  height = 11,
  fill = theme.palette.custom.white,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 487.736 487.736"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <g>
        <g>
          <path
            d="M26.2,140.611c-6.4,11.7-11.8,23.9-15.9,36.6c-2.5,6.2-3.5,12.8-5.2,19.2c-1.8,6.3-2.4,13.3-3.4,20.1l-0.7,5.1l-0.3,2.5
			l-0.1,2.3l-0.3,9.3l-0.2,4.6l-0.1,2.3v1.2v0.3v0.8v0.1v0.2v0.6l0.6,9.9l0.7,9.9c0.4,3.3,1,6.5,1.6,9.8l1.6,9.8
			c0.5,3.3,1.6,6.4,2.4,9.6c4.3,23.3,12.6,46.3,25.4,66.9c12.7,20.6,29.2,39.1,48.5,54.2s41.4,26.9,65,34.5l18,4.8
			c6.1,1.4,12.7,2.2,19.1,3.2l4.8,0.7l4.2,0.3l8.4,0.5l4.2,0.2l2.1,0.1l1.1,0.1h1.3h0.3h0.6l9.3-0.4c49.6-2.1,98.2-22.6,134.6-56.2
			c18.3-16.7,33.6-36.6,45.2-58.5c5.9-11,10.6-22.2,14.6-34.3l2.8-9.3l1.4-4.7c0.4-1.5,0.6-2.8,0.9-4.2l1.8-8.2l0.9-4.1l0.2-1l0.4-2
			l0.4-2.3c1.5-8.8-1.3-22.8-3.8-27c-2.4-4.1-4.7-3.4-6.7,0.1c-2.1,3.6-4.1,10-6.4,17.5c-8.2,24.6-19,49.9-35.4,72.6
			c-16.3,22.7-37,43.4-61.6,58.5c-29.2,18.8-63.8,27.8-98.1,27.5h-0.8h-0.3l-1.5-0.1l-3.5-0.2l-7-0.5l-7-0.5
			c-2.2-0.3-4.1-0.7-6.1-1.1c-4-0.8-8-1.4-12-2.2l-12.3-3.4c-16.1-5.3-31.9-11.9-45.9-21.6c-14-9.5-27.2-20.3-38-33.4
			c-10.8-12.9-20.6-26.9-27.3-42.5c-6.8-15.6-12.2-31.8-14.4-48.6l-1.1-6.3c-0.3-2.1-0.3-4.2-0.5-6.3l-0.4-6.4l-0.2-3.2l-0.1-1.6
			l-0.1-0.8v-0.4v-0.2v-0.1l0.4-13.3c0.1-2.3,0-4.9,0.3-6.9l0.8-6c0.8-4,1.1-7.8,2.3-11.9l3.4-12.1c1-4.1,3-7.9,4.3-11.8l2.3-5.9
			c0.8-2,1.9-3.8,2.7-5.7c11.5-23.9,28.3-45.2,48.7-62c20.7-16.5,44.8-28.4,70.4-34.7c51.3-12.9,107.8-1.9,151.1,29.7
			c15.6,11.1,28.3,23.1,37.2,28.6c-19.1,20.1-38.1,40.5-57.2,60.6c-30.1,22.5-57.8,48-84.8,74.5c-22-21.9-44.2-43.7-65.4-66.4
			c-2.5-2.7-9-4.3-12-3.8c-6,0.9-5.6,7.1-3,14.2c8.8,23.5,25.1,46.4,44.2,67.2l25.2,27.7c5.5,6.1,14.9,6.5,20.9,1l1-1
			c41.2-37.3,82.3-74.7,120-116l18.8-17.1c39.2-36.6,77.6-74.1,112.6-114.7c9.9-11.5,15.6-23.7,8.4-29.3c-6.6-5.1-18.5-1.9-30.2,8.4
			c-21.1,18.5-42.4,37.1-62.2,57c-8.3,8.4-16.5,16.9-24.7,25.4c-0.7-2.2-1.7-4.5-3-7c-4.9-10-14.3-22.6-28.5-34.2
			c-25.4-20.7-56.1-35.6-88.8-42.3l-12.2-1.8l-12.9-1.4l-11.6-0.6h-0.7h-0.4h-0.9l-1.6,0.1l-3.1,0.1l-6.3,0.2
			c-4.2,0.3-8.4,0.1-12.5,0.8l-12.4,1.8l-6.2,0.9l-6.1,1.5l-12.1,3.1C96.5,57.411,51.9,93.811,26.2,140.611z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};

export default CircleCheckedIcon;
