import React, { ReactElement, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box, { BoxProps } from '@material-ui/core/Box';
import { Typography, ShipmentItemIcon } from 'components';
import { theme } from 'theme';

import { BaseProps } from 'shared/types';
import { ShipmentItemType } from 'shared/types/shipments/shipmentItem';
import { shipmentItemTypeLabels } from 'shared/constants/shipments/shipmentItemTypeLabels';
import { useCommonStyles } from 'shared/styles/common';
import { useHover } from 'shared/hooks/useHover';

type ShipmentItemButtonProps = {
  itemType: ShipmentItemType;
  active?: boolean;
} & BaseProps &
  Omit<BoxProps, 'itemType'>;

const ShipmentItemButton: FC<ShipmentItemButtonProps> = ({
  itemType,
  active,
  ...restProps
}): ReactElement => {
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();

  const [hover, setHover] = useState(false);

  const hoverMouseHandlers = useHover({ hoverStateChanged: setHover });

  return (
    <Box
      className={commonClasses.cursorPointer}
      bgcolor={active || hover ? theme.palette.custom.lightGray : 'transparent'}
      width={80}
      height={80}
      border={`1px solid ${theme.palette.custom.gray}`}
      borderRadius={12}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...hoverMouseHandlers}
      {...restProps}
    >
      <ShipmentItemIcon
        width={itemType === ShipmentItemType.Machine ? 120 : 27.5}
        itemType={itemType}
        active={active || hover}
      />
      <Typography variant="caption" fontWeight="bold">
        {t(shipmentItemTypeLabels[itemType])}
      </Typography>
    </Box>
  );
};

export default ShipmentItemButton;
