export const pagingRange = (current: number, pageCount: number, maxPages: number): number[] => {
  let end = maxPages;
  if (end > pageCount) {
    end = pageCount;
  }

  let start = current - Math.floor(end / 2);
  start = Math.max(start, 1);
  start = Math.min(start, 1 + pageCount - end);

  return Array.from({ length: end }, (_, i) => start + i);
};
