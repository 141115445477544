import qs from 'qs';
import { callFetchApi, useFetchApi } from 'shared/functions/fetchApi';
import { ApiBaseProps, ApiResponse } from 'shared/types/api';
import { UserAccountApiResponse } from 'shared/types/userProfile';
import { ClaimsApiRequest } from '../types/claim/claims';
import { NewUserPayload, UsersPayload } from '../types/users/users';
import { ShipmentApiRequest } from '../types/shipments/shipments';

type UseUserAccountApiReturnType = {
  fetchUsers: (request: ClaimsApiRequest) => Promise<ApiResponse<UsersPayload>>;
  saveUser: (request: NewUserPayload) => Promise<ApiResponse<boolean>>;
  deleteUser: (request: ShipmentApiRequest) => Promise<ApiResponse<boolean>>;
  fetchUserAccountInfo: (apiBaseProps: ApiBaseProps) => Promise<UserAccountApiResponse>;
  resendInvitationUser: (request: ShipmentApiRequest) => Promise<ApiResponse<boolean>>;
  getUser: (request: ShipmentApiRequest) => Promise<ApiResponse<NewUserPayload>>;
};

export const useUserAccountApi = (): UseUserAccountApiReturnType => {
  const { fetchApi } = useFetchApi();

  const saveUser = async (payload: NewUserPayload): Promise<ApiResponse<boolean>> => {
    const body = JSON.stringify(payload);

    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: payload.id ? 'PUT' : 'POST',
      body,
    };

    const idParam = payload.id ? `/${payload.id}/` : '';

    const response = await fetchApi(`/api/employee${idParam}`, params);
    return response.json();
  };

  const fetchUserAccountInfo = ({ token }: ApiBaseProps): Promise<UserAccountApiResponse> => {
    return callFetchApi(`/api/account/get-current/bookingPortal`, token).then((response) =>
      response.json(),
    );
  };

  const fetchUsers = async ({
    page = 1,
    sizePerPage = 25,
    sortName,
    sortOrder,
    filters = {},
  }: ClaimsApiRequest): Promise<ApiResponse<UsersPayload>> => {
    const params = {
      page,
      sizePerPage,
      sortName,
      sortOrder,
      ...filters,
    };

    const response = await fetchApi(
      `/api/employee?${qs.stringify(params, { arrayFormat: 'comma' })}`,
    );
    return response.json();
  };

  const deleteUser = async ({ id }: ShipmentApiRequest): Promise<ApiResponse<boolean>> => {
    const response = await fetchApi(`/api/employee/${id}`, { method: 'DELETE' });
    return response.json();
  };
  const getUser = async ({ id }: ShipmentApiRequest): Promise<ApiResponse<NewUserPayload>> => {
    const response = await fetchApi(`/api/employee/${id}`, { method: 'GET' });
    return response.json();
  };
  const resendInvitationUser = async ({
    id,
  }: ShipmentApiRequest): Promise<ApiResponse<boolean>> => {
    const response = await fetchApi(`/api/employee/resend/${id}`, { method: 'POST' });
    return response.json();
  };
  return {
    fetchUserAccountInfo,
    fetchUsers,
    saveUser,
    deleteUser,
    resendInvitationUser,
    getUser,
  };
};
