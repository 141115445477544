import React, { FC, ReactElement, SVGProps } from 'react';
import { makeStyles } from '@material-ui/styles';
import { theme } from 'theme';
import clsx from 'clsx';

type StyleProps = {
  width?: string | number;
};

type DropdownIconProps = {
  variant?: 'light' | 'dark' | 'gray';
} & Omit<SVGProps<SVGSVGElement>, 'variant'>;

const useStyles = makeStyles({
  root: {
    minWidth: ({ width }: StyleProps) => width,
  },
});

const DropdownIcon: FC<DropdownIconProps> = ({
  variant = 'light',
  width = 11,
  height = 6,
  className,
  ...restProps
}): ReactElement => {
  const classes = useStyles({ width });

  let strokeColor = theme.palette.custom.white;
  if (variant === 'dark') {
    strokeColor = theme.palette.custom.darkCyan;
  } else if (variant === 'gray') {
    strokeColor = theme.palette.custom.grayishViolet;
  }

  return (
    <svg
      className={clsx(className, classes.root)}
      width={width}
      height={height}
      viewBox="0 0 11 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M1.96875 0.53125C1.83333 0.40625 1.67708 0.34375 1.5 0.34375C1.32292 0.34375 1.16667 0.411458 1.03125 0.546875C0.90625 0.671875 0.84375 0.822917 0.84375 1C0.84375 1.17708 0.90625 1.33333 1.03125 1.46875L5.03125 5.46875C5.16667 5.60417 5.32292 5.67188 5.5 5.67188C5.67708 5.67188 5.83333 5.60417 5.96875 5.46875L9.96875 1.46875C10.0625 1.38542 10.125 1.28646 10.1562 1.17188C10.1875 1.05729 10.1875 0.942708 10.1562 0.828125C10.125 0.703125 10.0677 0.598958 9.98438 0.515625C9.90104 0.432292 9.79688 0.375 9.67188 0.34375C9.55729 0.3125 9.44271 0.3125 9.32812 0.34375C9.21354 0.375 9.11458 0.4375 9.03125 0.53125L5.5 4.0625L1.96875 0.53125Z"
        fill={strokeColor}
      />
    </svg>
  );
};

export default DropdownIcon;
