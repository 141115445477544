import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const PriceRequestsIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 20,
  height = 19,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 17"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path d="M4.25391 12.0742C4.41016 11.918 4.60547 11.8398 4.83984 11.8398H14.8398C15.0742 11.8268 15.2695 11.7422 15.4258 11.5859C15.582 11.4297 15.6602 11.2344 15.6602 11V2.66016C15.6602 2.42578 15.582 2.23047 15.4258 2.07422C15.2695 1.91797 15.0742 1.83984 14.8398 1.83984H3.16016C2.92578 1.83984 2.73047 1.91797 2.57422 2.07422C2.41797 2.23047 2.33984 2.42578 2.33984 2.66016V13.9883L4.25391 12.0742ZM2.08594 16.5859C1.79948 16.8333 1.49349 16.8919 1.16797 16.7617C0.855469 16.6315 0.686198 16.3776 0.660156 16V2.66016C0.686198 1.95703 0.933594 1.37109 1.40234 0.902344C1.87109 0.433594 2.45703 0.186198 3.16016 0.160156H14.8398C15.543 0.186198 16.1289 0.433594 16.5977 0.902344C17.0664 1.37109 17.3138 1.95703 17.3398 2.66016V11C17.3138 11.7031 17.0664 12.2956 16.5977 12.7773C16.1289 13.2461 15.543 13.487 14.8398 13.5H5.17188L2.08594 16.5859Z" />
    </svg>
  );
};

export default PriceRequestsIcon;
