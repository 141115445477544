import React, { ReactElement, FC, useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Select, Checkbox, TextField } from 'components';
import { generateKey } from 'shared/functions/generateKey';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { theme } from 'theme';
import { Typography } from '@material-ui/core';
import { SelectProps } from '../Select/Select';

type Value = string | number;

export type SelectItem = {
  label: string;
  value: Value;
};

type MultiSelectProps = {
  resultsFilter?: boolean;
  data: SelectItem[];
  placeholder: string;
  width?: number | string;
  onChange?: (values: Value[]) => void;
} & Omit<SelectProps, 'onChange'>;

type StyleProps = {
  width: number | string;
  expanded: boolean;
};

const useStyles = makeStyles({
  root: {
    width: ({ width }: StyleProps) => width,
    boxShadow: ({ expanded }: StyleProps) =>
      expanded ? '4px 4px 10px rgba(0, 0, 0, 0.25)' : 'unset',
  },
  search: {
    '&.MuiTextField-root': {
      padding: theme.spacing(0.5, 2, 2.5, 3),
    },
  },
  checkbox: {
    marginRight: theme.spacing(1.5),
  },
  selectedText: {
    fontWeight: 700,
  },
  placeholder: {
    color: theme.palette.custom.gray,
  },
  listItem: {
    whiteSpace: 'break-spaces',
    marginTop: 0,
  },
});

const useMenuItemStyles = makeStyles({
  root: {
    paddingBottom: 'unset',
    paddingTop: 'unset',
    marginTop: 0,
    alignItems: 'flex-start',
    '&.Mui-selected': {
      backgroundColor: 'transparent',
    },
  },
  gutters: {
    padding: theme.spacing(0.5, 3),
  },
});

const MultiSelect: FC<MultiSelectProps> = ({
  data,
  placeholder,
  resultsFilter = false,
  width = 'auto',
  onChange,
  value: externalValue,
  ...props
}): ReactElement => {
  const { t } = useTranslation();
  const menuItemClasses = useMenuItemStyles();

  const [selected, setSelected] = useState<Value[]>(
    !isEmpty(externalValue) || !Array.isArray(externalValue)
      ? (externalValue as Value[])
      : [placeholder],
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [expanded, setExpanded] = useState<boolean>(false);
  const classes = useStyles({ width, expanded });
  const toggleExpanded = useCallback(() => setExpanded(!expanded), [setExpanded, expanded]);

  useEffect(() => {
    setSelected(
      !isEmpty(externalValue) || !Array.isArray(externalValue)
        ? (externalValue as Value[])
        : [placeholder],
    );
  }, [externalValue]);

  const onSelectChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      /* Filter out undefined values, because the search text field is still considered
       * by Select component as a real selectable menu item */
      const selectedValues = (event.target.value as Value[]).filter(
        (value) => value && value !== placeholder,
      );
      setSelected(selectedValues);
      if (onChange) {
        onChange(selectedValues);
      }
    },
    [onChange, setSelected],
  );

  const onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = searchTerm
    ? data.filter(({ label }) => label.toLowerCase().startsWith(searchTerm.toLowerCase()))
    : data;

  const stopPropagation = (e: React.KeyboardEvent<HTMLDivElement>) => e.stopPropagation();

  const renderValue = useCallback(
    (selectedValues: unknown) => {
      const selectedCount = (selectedValues as Array<Value>).filter(
        (value) => value !== placeholder,
      ).length;
      const selectedLabels = `${selectedCount} selected`;
      return (
        <Typography variant="caption" className={!selectedCount ? classes.placeholder : undefined}>
          {selectedCount ? selectedLabels : placeholder}
        </Typography>
      );
    },
    [placeholder],
  );

  const [menuProps] = useState({
    disableAutoFocusItem: true,
  });

  return (
    <Select
      {...props}
      className={classes.root}
      multiple
      value={selected}
      renderValue={renderValue}
      MenuProps={menuProps}
      onChange={onSelectChange}
      onOpen={toggleExpanded}
      onClose={toggleExpanded}
    >
      {resultsFilter && (
        <div role="presentation" onKeyDown={stopPropagation}>
          <TextField
            variant="variant2"
            size="small"
            value={searchTerm}
            placeholder={t('SEARCH')}
            onChange={onSearchTermChange}
            className={classes.search}
          />
        </div>
      )}
      {filteredData.map(({ label, value }, index) => {
        const checked = selected.indexOf(value) > -1;
        return (
          <MenuItem key={generateKey(index, `${label}`)} value={value} classes={menuItemClasses}>
            <Checkbox
              variant="lightAlt"
              size="tiny"
              checked={checked}
              className={classes.checkbox}
            />
            <ListItemText
              className={classes.listItem}
              primary={label}
              primaryTypographyProps={{
                variant: 'caption',
                className: checked ? classes.selectedText : undefined,
              }}
            />
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default MultiSelect;
