import React, { ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import Box, { BoxProps } from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography, SwapIcon } from 'components';
import { theme } from 'theme';

import { BaseProps } from 'shared/types';
import clsx from 'clsx';
import { useCommonStyles } from 'shared/styles/common';

type SwapButtonProps = BoxProps & BaseProps;

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.custom.slightlyDesaturatedCyanAlt,
    },
  },
});

const SwapButton: FC<SwapButtonProps> = ({ className, ...restProps }): ReactElement => {
  const { t } = useTranslation();
  const customClasses = useCommonStyles();
  const classes = useStyles();

  return (
    <Box
      width={48}
      height={48}
      borderRadius={12}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgcolor={theme.palette.custom.slightlyDesaturatedCyan}
      boxShadow={2}
      className={clsx(customClasses.cursorPointer, classes.root, className)}
      {...restProps}
    >
      <SwapIcon />
      <Typography variant="caption">{t('SWAP')}</Typography>
    </Box>
  );
};

export default SwapButton;
