import { useFetchApi } from '../functions/fetchApi';
import {
  DownloadFileRequest,
  FileDeleteRequest,
  FileResponse,
  FilesRequest,
  FilesUploadRequest,
} from '../types/files';
import { ApiResponse } from '../types/api';

export const downloadFile = async (
  response: Response,
  fileName: string,
): Promise<string | undefined> => {
  return response
    ? response.blob().then((blob) => {
        const address = window.URL.createObjectURL(new Blob([blob]));
        const file: HTMLAnchorElement = document.createElement('a');
        file.href = address;
        file.setAttribute('download', fileName);
        document.body.appendChild(file);
        file.click();
        file.parentNode?.removeChild(file);
        return 'Success';
      })
    : 'Failed';
};

type UseFilesReturnType = {
  uploadFiles: (request: FilesUploadRequest) => Promise<ApiResponse<boolean>>;
  fetchFiles: (request: FilesRequest) => Promise<ApiResponse<FileResponse[]>>;
  deleteFile: (request: FileDeleteRequest) => Promise<ApiResponse<FileResponse[]>>;
  toggleFileLocking: (request: FileDeleteRequest) => Promise<ApiResponse<FileResponse[]>>;
  downloadFiles: (request: DownloadFileRequest) => Promise<string | undefined>;
};

export const useFiles = (): UseFilesReturnType => {
  const { fetchApi } = useFetchApi();

  const uploadFiles = async ({
    objectId,
    object,
    files,
  }: FilesUploadRequest): Promise<ApiResponse<boolean>> => {
    const formData = new FormData();

    for (let i = 0; i < files.length; i += 1) {
      formData.append(`files[${i}]`, files[i]);
    }

    const response = await fetchApi(
      `/api/file-upload/${objectId}/upload-s3-files/${object}`,
      {
        method: 'POST',
        body: formData,
      },
      ['Content-Type'],
    );

    return response.json();
  };

  const fetchFiles = async ({
    objectId,
    object,
  }: FilesRequest): Promise<ApiResponse<FileResponse[]>> => {
    const response = await fetchApi(`/api/file-upload/${objectId}/files/${object}`);
    return response.json();
  };

  const downloadFiles = async ({
    id,
    objectId,
    object,
    fileName,
  }: DownloadFileRequest): Promise<string | undefined> => {
    const response = await fetchApi(
      `/api/file-upload/${id}/download-s3-file/${objectId}/false/${object}`,
    );
    return downloadFile(response, fileName);
  };

  const deleteFile = async ({
    objectId,
    object,
    fileId,
  }: FileDeleteRequest): Promise<ApiResponse<FileResponse[]>> => {
    const response = await fetchApi(`/api/file-upload/${objectId}/s3-file/${fileId}/${object}`, {
      method: 'DELETE',
    });
    return response.json();
  };

  const toggleFileLocking = async ({
    objectId,
    object,
    fileId,
  }: FileDeleteRequest): Promise<ApiResponse<FileResponse[]>> => {
    const response = await fetchApi(
      `/api/file-upload/${objectId}/toggle-locking/${fileId}/${object}`,
      {
        method: 'POST',
      },
    );
    return response.json();
  };
  return {
    uploadFiles,
    fetchFiles,
    deleteFile,
    downloadFiles,
    toggleFileLocking,
  };
};
