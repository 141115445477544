import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const NewPriceRequestIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 12,
  height = 14,
  fill = theme.palette.custom.veryDarkViolet,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M5.58789 3.08789H7.91211C8.07617 3.08789 8.21289 3.14258 8.32227 3.25195C8.44076 3.36133 8.5 3.49805 8.5 3.66211C8.5 3.82617 8.44076 3.96745 8.32227 4.08594C8.21289 4.19531 8.07617 4.25 7.91211 4.25H5.58789V7.16211H6.46289C7.20117 7.18034 7.81641 7.4401 8.30859 7.94141C8.8099 8.43359 9.06966 9.04883 9.08789 9.78711C9.06966 10.5345 8.8099 11.1543 8.30859 11.6465C7.81641 12.1387 7.20117 12.3939 6.46289 12.4121H5.58789V14.1621C5.57878 14.3262 5.51953 14.4629 5.41016 14.5723C5.30078 14.6908 5.16406 14.75 5 14.75C4.83594 14.75 4.69922 14.6908 4.58984 14.5723C4.48047 14.4629 4.42122 14.3262 4.41211 14.1621V12.4121H1.5C1.33594 12.4121 1.19922 12.3574 1.08984 12.248C0.980469 12.1387 0.925781 12.002 0.925781 11.8379C0.925781 11.6738 0.980469 11.5371 1.08984 11.4277C1.19922 11.3092 1.33594 11.25 1.5 11.25H4.41211V8.33789H3.53711C3.06315 8.32878 2.62565 8.21029 2.22461 7.98242C1.83268 7.75456 1.51367 7.43555 1.26758 7.02539C1.0306 6.60612 0.912109 6.16862 0.912109 5.71289C0.912109 5.24805 1.0306 4.81055 1.26758 4.40039C1.51367 3.99023 1.83268 3.67122 2.22461 3.44336C2.62565 3.20638 3.06315 3.08789 3.53711 3.08789H4.41211V1.33789C4.42122 1.17383 4.48047 1.03711 4.58984 0.927734C4.69922 0.809245 4.83594 0.75 5 0.75C5.16406 0.75 5.30078 0.809245 5.41016 0.927734C5.51953 1.03711 5.57878 1.17383 5.58789 1.33789V3.08789ZM5.58789 8.33789V11.25H6.46289C6.87305 11.2409 7.21484 11.0996 7.48828 10.8262C7.76172 10.5527 7.90299 10.2064 7.91211 9.78711C7.90299 9.37695 7.76172 9.03516 7.48828 8.76172C7.21484 8.48828 6.87305 8.34701 6.46289 8.33789H5.58789ZM4.41211 7.16211V4.25H3.53711C3.27279 4.25 3.03125 4.3138 2.8125 4.44141C2.59375 4.56901 2.41602 4.7513 2.2793 4.98828C2.15169 5.21615 2.08789 5.45768 2.08789 5.71289C2.08789 5.9681 2.15169 6.20964 2.2793 6.4375C2.41602 6.66536 2.59375 6.8431 2.8125 6.9707C3.03125 7.09831 3.27279 7.16211 3.53711 7.16211H4.41211Z"
        fill={fill}
      />
    </svg>
  );
};

export default NewPriceRequestIcon;
