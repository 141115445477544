import { LoadingContact } from 'shared/types/loadingContact';
import { ShipmentAddress } from 'shared/types/shipments/shipments';

export const emptyLoadingContact: LoadingContact = {
  addressLine1: '',
  city: '',
  companyName: '',
  contactPersonName: '',
  country: '',
  email: '',
  notes: '',
  postCode: '',
  phone: '',
  addressName: '',
  saveAddress: false,
};

export const emptyAddressData: ShipmentAddress = {
  address: { ...emptyLoadingContact },
  alternativeAddress: undefined,
};
