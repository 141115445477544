import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { theme } from 'theme';
import {
  LanguageSwitcher,
  Loader,
  ShipmentDates,
  ShipmentItems,
  ShipmentStatusOverview,
} from 'components';
import { languages } from 'shared/constants/languages';
import { getLanguageByCode } from 'shared/functions/getLanguageByCode';
import { useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { useShipments } from 'shared/services/shipments';
import { ShipmentEvent, ShipmentPayload, ShipmentStatus } from 'shared/types/shipments/shipments';
import { NotFound } from '../scenes';

const useStyles = makeStyles(() => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0, 18),
    },
  },
  topContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    zIndex: 1,
  },
  subtitle: {
    fontWeight: 100,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  container: {
    maxWidth: 1294,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 6),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3),
    },
    [theme.breakpoints.down(1440)]: {
      maxWidth: 1180,
    },
  },
  accent1: {
    position: 'absolute',
    top: 53,
    left: -252,
  },
  accent2: {
    position: 'absolute',
    top: 445,
    left: 691,
  },
  accent3: {
    position: 'absolute',
    top: -44,
    left: 36,
  },
  accent4: {
    position: 'absolute',
    bottom: -41,
    right: 26,
  },
  heroTextWrapper: {
    paddingTop: theme.spacing(14),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(0),
    },
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    zIndex: 1,
  },
  loginImage: {
    position: 'absolute',
    top: 0,
    maxWidth: '100%',
    objectFit: 'cover',
  },
  logo: {
    marginLeft: theme.spacing(10),
  },
  paper: {
    width: 400,
    padding: theme.spacing(16, 8, 19),
    marginLeft: theme.spacing(2.5),
    position: 'relative',
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  description: {
    marginBottom: theme.spacing(6),
  },
  label: {
    marginTop: theme.spacing(4),
  },
  passwordInput: {
    marginBottom: theme.spacing(6),
  },
  languageSwitcher: {
    right: 40,
  },
  shipmentColumn: {
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down(480)]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  sidebar: {
    [theme.breakpoints.down(1440)]: {
      width: 300,
      marginLeft: theme.spacing(4),
    },
  },
  shipmentStatusView: {
    marginTop: theme.spacing(12),
  },
}));

const TrackingApp: FC = (): ReactElement => {
  const { fetchShipmentByTrackingCode } = useShipments();

  const [trackingCode, setTrackingCode] = useState('');
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const [isPending, setIsPending] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [shipment, setShipment] = useState<ShipmentPayload>();
  const [shipmentEvents, setShipmentEvents] = useState<ShipmentEvent[]>([]);
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const getShipment = async () => {
    setIsPending(true);
    try {
      if (!trackingCode && pathParts && pathParts.length >= 3) setTrackingCode(pathParts[2]);

      if (trackingCode) {
        setNotFound(false);
        const response = await fetchShipmentByTrackingCode({ trackingCode });
        if (response.payload) {
          setShipment(response.payload);
          setShipmentEvents(response.payload.events ?? []);
        } else {
          // TODO: this endpoint returns empty payload instead of 404, therefore we have to do error handling here as well
          setNotFound(true);
          setIsPending(false);
        }
      }
    } catch (error) {
      setNotFound(true);
      setIsPending(false);
    } finally {
      setIsPending(false);
    }
  };

  useEffect(() => {
    setIsPending(true);
    if (!shipment && !notFound) getShipment();
    setIsPending(false);
  }, [pathParts]);

  const title = (
    <Box display={isLgUp ? 'flex' : 'block'} alignItems="center">
      {`${t('SHIPMENT.SHIPMENT_NR')} ${shipment?.number}`}
      <span className={classes.subtitle}>{`// ${t('SHIPMENT.REF_NR')} ${
        shipment?.reference ? shipment?.reference : '-'
      }`}</span>
    </Box>
  );

  const createdTimeEvent: ShipmentEvent[] | null = shipmentEvents
    ? shipmentEvents.filter(
        (item) =>
          item.status?.toLowerCase() === ShipmentStatus[ShipmentStatus.Received].toLowerCase(),
      )
    : null;

  const collectedTimeEvent: ShipmentEvent[] | null = shipmentEvents
    ? shipmentEvents.filter(
        (item) =>
          item.status?.toLowerCase() === ShipmentStatus[ShipmentStatus.PickedUp].toLowerCase(),
      )
    : null;

  const processingTimeEvent: ShipmentEvent[] | null = shipmentEvents
    ? shipmentEvents.filter(
        (item) =>
          item.status?.toLowerCase() === ShipmentStatus[ShipmentStatus.Processing].toLowerCase(),
      )
    : null;

  const deliveredTimeEvent: ShipmentEvent[] | null = shipmentEvents
    ? shipmentEvents.filter(
        (item) =>
          item.status?.toLowerCase() === ShipmentStatus[ShipmentStatus.Delivered].toLowerCase(),
      )
    : null;

  const shipmentStatus = shipment?.status ?? ShipmentStatus.Draft;

  const statusBlock: JSX.Element = (
    <Box marginLeft={9.25} width={240} className={classes.sidebar}>
      <ShipmentStatusOverview
        status={shipmentStatus}
        publicTracking
        shipmentEvents={shipmentEvents}
        className={classes.shipmentStatusView}
        createdTime={
          createdTimeEvent && createdTimeEvent[0] ? createdTimeEvent[0].dateString : undefined
        }
        collectedTime={
          collectedTimeEvent && collectedTimeEvent[0] ? collectedTimeEvent[0].dateString : undefined
        }
        processingTime={
          processingTimeEvent && processingTimeEvent[0]
            ? processingTimeEvent[0].dateString
            : undefined
        }
        deliveredTime={
          deliveredTimeEvent && deliveredTimeEvent[0] ? deliveredTimeEvent[0].dateString : undefined
        }
      />
    </Box>
  );

  return (
    <Box bgcolor={theme.palette.custom.white} width={1} height={1} overflow="hidden">
      <Container maxWidth="xl" className={classes.topContainer}>
        <a href="https://laasone.com">
          <img
            src="/images/sidebar-logo-sm.png"
            alt="LaasOne"
            style={{ marginLeft: 50 }}
            height={80}
          />
        </a>
        <LanguageSwitcher
          onLanguageChange={onLanguageChange}
          selectedLanguage={getLanguageByCode(i18n.language)}
          languages={languages}
          className={classes.languageSwitcher}
        />
      </Container>
      <Box display="flex" bgcolor={theme.palette.custom.white}>
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={0} className={classes.root}>
            {isPending && <Loader cover />}
            {notFound && <NotFound hideHomeButton />}
            <Box className={classes.shipmentColumn}>
              {shipment && (
                <Typography variant="h4" className={classes.title}>
                  {title}
                </Typography>
              )}
              {shipment && <ShipmentDates payload={shipment} marginTop={!isLgUp ? 2 : 10} />}
              {shipment && !isLgUp && statusBlock}
              {shipment && (
                <ShipmentItems
                  tailLiftTruckNeeded={shipment.tailLiftTruck ?? false}
                  tailLiftTruckDeliveryNeeded={shipment.tailLiftTruckDelivery ?? false}
                  shipmentDetailsRows={shipment.shipmentDetailsRows}
                />
              )}
            </Box>
            {shipment && isLgUp && statusBlock}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default TrackingApp;
