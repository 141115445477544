import React, { ReactElement, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Truncate, { TruncateProps } from 'react-truncate';
import makeStyles from '@material-ui/styles/makeStyles';
import { theme } from 'theme';
import Dialog from 'components/CustomMaterial/Dialog/Dialog';
import Typography from 'components/CustomMaterial/Typography/Typography';

type ReadMoreProps = {
  expandInDialog?: boolean;
  dialogTitle?: string;
} & TruncateProps;

const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  dialogText: {
    whiteSpace: 'pre-line',
  },
});

const ReadMore: FC<ReadMoreProps> = ({
  expandInDialog = true,
  dialogTitle = '',
  width,
  lines,
  children,
  ...restProps
}): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [truncated, setTruncated] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [readMoreDialogOpen, setReadMoreDialogOpen] = useState(false);

  const toggleReadMoreDialog = () => setReadMoreDialogOpen(!readMoreDialogOpen);

  const handleTruncate = (isTruncated: boolean) => {
    if (truncated !== isTruncated) {
      setTruncated(isTruncated);
    }
  };

  const toggleExpanded = (event?: React.MouseEvent | React.KeyboardEvent) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    toggleReadMoreDialog();
    setExpanded(!expanded);
  };

  return (
    <>
      <Truncate
        {...restProps}
        width={!expanded || expandInDialog ? width : 0}
        lines={(!expanded || expandInDialog) && lines}
        onTruncate={handleTruncate}
        ellipsis={
          <span>
            ...{' '}
            <span
              className={classes.link}
              role="presentation"
              onClick={toggleExpanded}
              onKeyDown={toggleExpanded}
            >
              {t('READ_MORE')}
            </span>
          </span>
        }
      >
        {children}
      </Truncate>
      {!truncated && expanded && !expandInDialog && (
        <>
          {' '}
          <span
            className={classes.link}
            role="presentation"
            onClick={toggleExpanded}
            onKeyDown={toggleExpanded}
          >
            {t('LESS')}
          </span>
        </>
      )}
      {expanded && expandInDialog && (
        <Dialog
          title={dialogTitle}
          maxWidth="sm"
          open={readMoreDialogOpen}
          handleClose={toggleExpanded}
          actionCancelLabel={t('CLOSE')}
          onActionCancel={toggleExpanded}
        >
          <Typography variant="body2" className={classes.dialogText}>
            {children}
          </Typography>
        </Dialog>
      )}
    </>
  );
};

export default ReadMore;
