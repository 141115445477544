import React, { ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';

import Box, { BoxProps } from '@material-ui/core/Box';

import { BaseProps } from 'shared/types';
import { ShipmentDetailsRow } from 'shared/types/shipments/shipments';

import { ShipmentItem, Typography } from 'components';
import Chip from '@material-ui/core/Chip';
import { generateKey } from 'shared/functions/generateKey';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ShipmentItemTotals from './ShipmentItemTotals';

const useChipNativeStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.custom.lightGrayishCyan,
    padding: theme.spacing(1, 4),
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(-1),
    '&:hover': {
      backgroundColor: theme.palette.custom.lightGrayishCyan,
    },
  },
  label: {
    padding: theme.spacing(1),
    color: theme.palette.custom.veryDarkGray,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    fontWeight: 500,
  },
}));

type ShipmentItemsProps = {
  tailLiftTruckNeeded: boolean;
  tailLiftTruckDeliveryNeeded: boolean;
  shipmentDetailsRows: ShipmentDetailsRow[];
  noAccordion?: boolean;
  smallHeading?: boolean;
  smallPadding?: boolean;
  boxProps?: BoxProps;
  readOnly?: boolean;
  isPriceRequest?: boolean;
  hideTitle?: boolean;
} & BaseProps;

const ShipmentItems: FC<ShipmentItemsProps> = ({
  shipmentDetailsRows,
  tailLiftTruckNeeded,
  tailLiftTruckDeliveryNeeded,
  noAccordion,
  smallHeading,
  boxProps = {},
  readOnly,
  isPriceRequest = false,
  hideTitle,
  smallPadding,
}): ReactElement => {
  const { t } = useTranslation();
  const nativeClasses = useChipNativeStyles();

  let totalWeight = 0;
  let totalLdm = 0;
  let totalVolume = 0;
  shipmentDetailsRows.forEach((item) => {
    if (item.totalWeight) totalWeight = totalWeight + parseFloat(item.totalWeight.toString()) ?? 0;
    if (item.volume) totalVolume = totalVolume + parseFloat(item.volume.toString()) ?? 0;
    if (item.ldm) totalLdm = totalLdm + parseFloat(item.ldm.toString()) ?? 0;
  });

  return (
    <Box marginTop={10} {...boxProps}>
      {!hideTitle && (
        <Typography
          variant={smallHeading ? 'subtitle2' : 'h5'}
          fontWeight={smallHeading ? 'semibold' : undefined}
        >
          {!isPriceRequest ? t('ITEMS_IN_THE_SHIPMENT') : t('ITEMS_ADDED_PRICE_REQUEST')}{' '}
          {!readOnly && tailLiftTruckNeeded && (
            <Chip
              size="small"
              classes={nativeClasses}
              key={generateKey(6, `tailLiftTruckNeeded_`)}
              label={t('SHIPMENT.TRANSPORT_WITH_TAIL_LIFT_TRUCK')}
            />
          )}
          {!readOnly && tailLiftTruckDeliveryNeeded && (
            <Chip
              size="small"
              classes={nativeClasses}
              key={generateKey(6, `tailLiftTruckNeeded_`)}
              label={t('SHIPMENT.TRANSPORT_WITH_TAIL_LIFT_TRUCK_DELIVERY')}
            />
          )}
        </Typography>
      )}
      <Box marginBottom={4}>
        {shipmentDetailsRows.map((data, index) => (
          <Box marginTop={4} key={generateKey(index, 'shipment_item')}>
            <ShipmentItem data={data} noAccordion={noAccordion} smallPadding={smallPadding} />
          </Box>
        ))}
      </Box>
      {shipmentDetailsRows.length > 0 && (
        <ShipmentItemTotals
          totalLdm={totalLdm}
          totalVolume={totalVolume}
          totalWeight={totalWeight}
        />
      )}
    </Box>
  );
};

export default ShipmentItems;
