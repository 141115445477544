// import { environmentTypes } from 'shared/constants/environmentTypes';
// import { useAccess } from 'shared/hooks/useAccess';

type Features = {
  priceRequests: boolean;
  chat: boolean;
  companyProfile: boolean;
  importAddresses: boolean;
  priceEngine: boolean;
  templates: boolean;
};

export const useFeatures = (): Features => {
  // const isDevelopmentEnvironment = useAccess({ environment: environmentTypes.DEVELOPMENT });

  return {
    priceRequests: true,
    chat: true,
    companyProfile: true,
    importAddresses: true,
    priceEngine: true,
    templates: true,
  };
};
