import { useHistory } from 'react-router-dom';
import { notFound } from 'routes';
import * as Sentry from '@sentry/react';

export const useNotFound = (): (() => void) => {
  const history = useHistory();

  const navigateToNotFound = () => {
    Sentry.captureMessage(
      `Route not found: ${history.location.pathname}${history.location.search ?? ''}`,
    );
    history.replace(notFound);
  };

  return navigateToNotFound;
};
