import React, { ReactElement, FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';

import { Typography } from 'components';

type LabelCellProps = {
  label: string;
} & BoxProps;

const LabelCell: FC<LabelCellProps> = ({ label, ...boxProps }): ReactElement => (
  <Box {...boxProps}>
    <Typography variant="body2" fontWeight="bold">
      {label}
    </Typography>
  </Box>
);

export default LabelCell;
