import React, { FC, ReactElement } from 'react';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';

type StyleProps = {
  noBackground?: boolean;
};

const useStyle = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
  },
  center: {
    textAlign: 'center',
  },
  cover: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: ({ noBackground }: StyleProps) => (noBackground ? 'none' : 'rgba(255,255,255,.5)'),
    zIndex: 50,
  },
  circle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

type LoaderProps = {
  center?: boolean;
  cover?: boolean;
  noBackground?: boolean;
  color?: 'inherit' | 'primary' | 'secondary' | undefined;
  className?: string;
};

const Loader: FC<LoaderProps> = ({
  center = false,
  cover = false,
  noBackground = false,
  color,
  className,
}): ReactElement => {
  const classes = useStyle({ noBackground });
  return (
    <div className={clsx(center && classes.center, cover && classes.cover, className)}>
      <CircularProgress color={color} />
    </div>
  );
};

const FullPageLoader: FC = (): ReactElement => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <div className={classes.circle}>
        <Loader />
      </div>
    </div>
  );
};

export { Loader, FullPageLoader };
