import React, { ReactElement, FC } from 'react';
import { theme } from 'theme';
import clsx from 'clsx';

import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';

import { BaseProps } from 'shared/types';

import { ChevronIcon } from 'components';
import { generateKey } from 'shared/functions/generateKey';
import { pagingRange } from 'shared/functions/pagingRange';

type PaginationProps = {
  total: number;
  sizePerPage: number;
  page: number;
  maxPages: number;
  onPageChange: (page: number) => void;
  hideSizePerPage?: boolean;
} & BaseProps;

const useStyles = makeStyles({
  wrapper: {
    paddingTop: '8px',
    '& :not(:last-child)': {
      marginRight: 1,
    },
  },
  pageWrapper: {
    '& :not(:last-child)': {
      marginRight: 1,
    },
  },
  pageButton: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:hover': {
      border: `1px solid ${theme.palette.custom.grayishViolet}`,
    },
  },
  pageButtonSelected: {
    cursor: 'default',
    backgroundColor: theme.palette.custom.lightGrayishVioletAlt,
    '&:hover': {
      border: 'none',
    },
  },
  alignCenter: {
    margin: '0 auto',
  },
  strong: {
    fontWeight: 700,
  },
});

const Pagination: FC<PaginationProps> = ({
  total,
  sizePerPage,
  page,
  onPageChange,
  maxPages = 5,
  hideSizePerPage = false,
}): ReactElement => {
  const classes = useStyles();

  const pageCount = Math.ceil(total / sizePerPage);

  const prevDisabled = page <= 1;
  const nextDisabled = page >= pageCount;

  const getPageDirectionalChangeButton = (
    direction: 'left' | 'right',
    variant: 'single' | 'double',
  ) => {
    const disabled = direction === 'left' ? prevDisabled : nextDisabled;
    let pageChange = page;
    if (variant === 'double') {
      pageChange = direction === 'left' ? 1 : pageCount;
    } else {
      pageChange += direction === 'left' ? -1 : 1;
    }

    return (
      <Box
        width={32}
        height={32}
        borderRadius={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={!disabled ? () => onPageChange(pageChange) : undefined}
        className={!disabled ? classes.pageButton : undefined}
      >
        <ChevronIcon direction={direction} variant={variant} disabled={disabled} />
      </Box>
    );
  };

  const pages = pagingRange(page, pageCount, maxPages);
  return (
    <>
      {pageCount > 1 && (
        <Box
          display="flex"
          alignItems="center"
          className={clsx(classes.wrapper, hideSizePerPage && classes.alignCenter)}
        >
          {pages?.length > 0 && getPageDirectionalChangeButton('left', 'double')}
          {pages?.length > 0 && getPageDirectionalChangeButton('left', 'single')}
          <Box display="flex" className={classes.pageWrapper}>
            {pages.map((pageIndex) => {
              const pageSelected = pageIndex === parseInt(page.toString(), 10);
              return (
                <Box
                  key={generateKey(pageIndex, 'page_button')}
                  className={clsx(classes.pageButton, pageSelected && classes.pageButtonSelected)}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={32}
                  height={32}
                  borderRadius={4}
                  onClick={() => (pageSelected ? null : onPageChange(pageIndex))}
                >
                  <span className={pageSelected ? classes.strong : undefined}>{pageIndex}</span>
                </Box>
              );
            })}
          </Box>
          {pages?.length > 0 && getPageDirectionalChangeButton('right', 'single')}
          {pages?.length > 0 && getPageDirectionalChangeButton('right', 'double')}
        </Box>
      )}
    </>
  );
};

export default Pagination;
