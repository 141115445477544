import React, { Suspense, FC, ReactElement, useState, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import { generateKey } from 'shared/functions/generateKey';
import { theme } from 'theme';
import { RouteProps, routes, routeShipments } from 'routes';

import { NotFound } from 'scenes';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import {
  Loader,
  LanguageSwitcher,
  UserSettings,
  Sidebar,
  AddNewMenu,
  CommentsNotifications,
} from 'components';
import { useTranslation } from 'react-i18next';
import { getLanguageByCode } from 'shared/functions/getLanguageByCode';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumbs';
import { languages } from 'shared/constants/languages';
import { countPrefix } from 'shared/constants/regExp';
import { BaseProps } from 'shared/types';
import { GlobalData } from 'shared/types/globalData';
import { useClassifiers } from 'shared/services/classifier';
import { useUser } from 'shared/hooks/useUser';
import { useShipments } from 'shared/services/shipments';
import { useSystemAlert } from 'shared/hooks/useSystemAlert';
import ShipmentFromTemplate from 'components/Shipment/NewShipment/ShipmentFromTemplate/ShipmentFromTemplate';
import { useUserAccountApi } from 'shared/services/user';
import ShipmentFromExcel from '../components/Shipment/NewShipment/ShipmentsFromExcel/ShipmentFromExcel';
import { hasPermission } from '../shared/functions/hasPermission';

const RouteLoadingFallback = (
  <Box height="100vh" width="100vw">
    <Loader cover noBackground color="secondary" />
  </Box>
);

const useStyles = makeStyles(() => ({
  topContainer: {
    padding: 0,
    width: '100%',
    maxWidth: 1294,
    display: 'flex',
    justifyContent: 'flex-end',
    height: 48,
    marginBottom: theme.spacing(6),
    position: 'relative',
    [theme.breakpoints.down(1440)]: {
      width: 1180,
      maxWidth: 1180,
    },
    [theme.breakpoints.down(1280)]: {
      width: 900,
      maxWidth: 900,
    },
  },
  container: {
    maxWidth: 1294,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 6),
    },
    [theme.breakpoints.down(1440)]: {
      width: 1180,
      maxWidth: 1180,
    },
  },
  noAbsolute: {
    position: 'relative',
  },
  poweredByGoogle: {
    height: 0, // todo hide now, find the right place for this
    width: 144,
  },
}));

const defaultGlobalData: GlobalData = {
  countries: [],
  characterization: [],
};

export const GlobalDataContext = React.createContext<GlobalData>(defaultGlobalData);

const AuthenticatedApp: FC<BaseProps> = (): ReactElement => {
  const { setNewComments, setUserProfile, getValidToken, userUnreadComments, profile } = useUser();
  const history = useHistory();
  const { hideSystemMessage } = useSystemAlert();
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { fetchCountries, fetchCharacterization } = useClassifiers();
  const { fetchShipmentCommentList } = useShipments();
  const { fetchUserAccountInfo } = useUserAccountApi();

  const [globalData, setGlobalData] = useState<GlobalData>(defaultGlobalData);
  const [showShipmentFromTemplateModal, setShowShipmentFromTemplateModal] = useState<boolean>(
    false,
  );
  const [showShipmentFromExcelModal, setShowShipmentFromExcelModal] = useState<boolean>(false);
  const toggleShowShipmentFromTemplateModal = () => {
    setShowShipmentFromTemplateModal(!showShipmentFromTemplateModal);
  };
  const toggleShowShipmentFromExcelModal = () => {
    setShowShipmentFromExcelModal(!showShipmentFromExcelModal);
  };
  const getCountries = async () => {
    try {
      const countries = await fetchCountries({ language: i18n.language });
      setGlobalData((current) => ({ ...current, countries }));
    } catch (error) {
      // TODO: Add error handling when messages notification messages are specified
    }
  };

  const getCharacterization = async () => {
    try {
      const characterization = await fetchCharacterization({ language: i18n.language });
      setGlobalData((current) => ({ ...current, characterization }));
    } catch (error) {
      // TODO: Add error handling when messages notification messages are specified
    }
  };

  // adds '({count})' prefix to document.title if there are any comment changes, not a hook because we never want to start it more than once
  const setTitlePrefix = (prefix: RegExpMatchArray | null, count?: number) => {
    if (count === 0) {
      document.title = document.title.replace(countPrefix, '');
    } else if (prefix) {
      document.title = document.title.replace(countPrefix, `(${count ?? 0})`);
    } else {
      document.title = `(${count ?? 0}) ${document.title}`;
    }
  };

  const pullComments = async () => {
    const delay = 60000;

    try {
      const newComments = await fetchShipmentCommentList();

      setNewComments(newComments.payload);

      // pull comments every x seconds, not setInterval as we want to run it instantly on render
      setTimeout(pullComments, delay);
    } catch (error) {
      // TODO: Add error handling when messages notification messages are specified
    }
  };

  const onRouteChange = async () => {
    hideSystemMessage();
    const validToken = await getValidToken();
    const { payload: userProfile } = await fetchUserAccountInfo({ token: validToken });
    setUserProfile(userProfile ?? {});
  };

  // update document.title prefix every time new comments get pulled or read
  useEffect(() => {
    setTitlePrefix(document.title.match(countPrefix), userUnreadComments?.unreadOperatorComments);
  }, [userUnreadComments?.unreadOperatorComments]);

  useEffect(() => {
    getCountries();
    getCharacterization();
  }, [i18n?.language]);

  useEffect(() => {
    pullComments();

    history.listen(onRouteChange);
  }, []);

  const user = useUser();

  const mapRouter = (router: RouteProps) => {
    if (
      router.featureName &&
      profile &&
      (!profile.features || !profile.features.includes(router.featureName))
    )
      return false;
    if (
      router.action &&
      router.actionType &&
      !hasPermission(user, router.action, router.actionType)
    )
      return false;
    return true;
  };

  const routeMapper = (
    { path, component, exact, environment, ...rest }: RouteProps,
    index: number,
  ): ReactElement =>
    component && (
      <Route
        key={generateKey(index, (path.length ? path[0] : path).replace('/', '-'))}
        path={path}
        component={component}
        exact={exact}
        environment={environment}
        {...rest}
      />
    );
  const renderRoutes = (
    <Suspense fallback={RouteLoadingFallback}>
      <Switch>
        <Redirect from="/" to={routeShipments} exact />
        {routes.filter((item) => mapRouter(item)).map(routeMapper)}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );

  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <ShipmentFromTemplate
        showDialog={showShipmentFromTemplateModal}
        toggleShowDialog={toggleShowShipmentFromTemplateModal}
      />
      <ShipmentFromExcel
        showDialog={showShipmentFromExcelModal}
        toggleShowDialog={toggleShowShipmentFromExcelModal}
      />
      <Box display="flex" height={1}>
        <Sidebar />
        <Box
          bgcolor={theme.palette.custom.veryLightGray}
          width={1}
          height={1}
          pb={10}
          position="relative"
        >
          <Container maxWidth="xl" className={classes.topContainer}>
            <Breadcrumb />
            <Box position="relative" mr={5}>
              <LanguageSwitcher
                onLanguageChange={onLanguageChange}
                selectedLanguage={getLanguageByCode(i18n.language)}
                languages={languages}
                className={classes.noAbsolute}
              />
            </Box>
            {userUnreadComments && (
              <Box position="relative" mr={5}>
                <CommentsNotifications
                  comments={userUnreadComments}
                  badge={userUnreadComments?.unreadOperatorComments}
                  className={classes.noAbsolute}
                />
              </Box>
            )}
            <Box position="relative" mr={4}>
              <UserSettings
                userName={profile?.name || ''}
                companyName={profile?.company || ''}
                className={classes.noAbsolute}
              />
            </Box>
            <AddNewMenu
              toggleShowDialog={toggleShowShipmentFromTemplateModal}
              toggleShowShipmentFromExcelModal={toggleShowShipmentFromExcelModal}
            />
          </Container>
          <Container maxWidth="lg" className={classes.container}>
            <GlobalDataContext.Provider value={globalData}>
              {renderRoutes}
            </GlobalDataContext.Provider>
          </Container>
          {/* todo move to the right place as designed */}
          {/* https://developers.google.com/places/web-service/policies */}
          <CardMedia
            id="Logtech HoldingPoweredByGoogleDiv"
            className={classes.poweredByGoogle}
            image="/images/powered_by_google_on_white.png"
          />
        </Box>
      </Box>
    </>
  );
};

export default AuthenticatedApp;
