import React, { FC, ReactElement } from 'react';

import { makeStyles } from '@material-ui/styles';
import Radio, { RadioProps as MaterialRadioProps } from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

import { QuestionMarkIcon, Typography } from 'components';
import { useCommonStyles } from 'shared/styles/common';
import RadioOptionIcon from 'components/Icons/RadioOptionIcon/RadioOptionIcon';

type StyleProps = {
  padding?: number;
};

type RadioOptionProps = {
  label?: string;
  tooltip?: string;
  padding?: number;
} & Omit<MaterialRadioProps, 'size'>;

const useNativeStyles = makeStyles({
  root: ({ padding }: StyleProps) => ({
    padding,
  }),
});

const RadioOption: FC<RadioOptionProps> = ({
  classes: nativeCustomClasses,
  label,
  tooltip,
  padding = 9,
  ...props
}): ReactElement => {
  const commonClasses = useCommonStyles();
  const nativeClasses = useNativeStyles({ padding });

  const radioComponent = (
    <Radio
      color="secondary"
      {...props}
      checkedIcon={<RadioOptionIcon variant="dark" size="small" checked />}
      icon={<RadioOptionIcon variant="lightAlt" size="small" />}
      classes={nativeClasses}
    />
  );

  return !label ? (
    radioComponent
  ) : (
    <Box display="flex" alignItems="center">
      {radioComponent}
      <Typography variant="body2" marginLeft={2}>
        {label}
      </Typography>
      {tooltip ? (
        <Tooltip title={tooltip} arrow>
          <div>
            <QuestionMarkIcon className={commonClasses.ml1} />
          </div>
        </Tooltip>
      ) : null}
    </Box>
  );
};

export default RadioOption;
