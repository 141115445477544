import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';

import { theme } from 'theme';
import { TextFieldFormik, Button, Loader, LanguageSwitcher, Labeled, Typography } from 'components';
import { formStates } from 'shared/constants/formStates';
import { languages } from 'shared/constants/languages';
import { getLanguageByCode } from 'shared/functions/getLanguageByCode';

import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useSystemAlert } from 'shared/hooks/useSystemAlert';

import { useCommonStyles } from 'shared/styles/common';

import { ApiValidationError } from 'shared/types/api';
import { generateKey } from 'shared/functions/generateKey';
import { useSessionApi } from 'shared/services/session';
import { NotFound } from '../scenes';
import SystemAlert from '../components/CustomMaterial/Alert/Alert';
import ArrowRight from '../components/Icons/Arrows/ArrowRight';
import { routeLogin } from '../routes';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: theme.palette.custom.veryDarkViolet,
  },
  topContainer: {
    paddingTop: theme.spacing(10),
    position: 'absolute',
    height: '100%',
    left: 0,
    right: 0,
  },
  container: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 6),
    },
    position: 'relative',
    height: '100%',
  },
  accent1: {
    position: 'absolute',
    top: 53,
    left: -252,
  },
  accent2: {
    position: 'absolute',
    top: 445,
    left: 691,
  },
  accent3: {
    position: 'absolute',
    top: -44,
    left: 36,
  },
  accent4: {
    position: 'absolute',
    bottom: -41,
    right: 26,
  },
  heroTextWrapper: {
    paddingTop: theme.spacing(14),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(0),
    },
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    zIndex: 1,
  },
  loginImage: {
    position: 'absolute',
    top: 0,
    maxWidth: '100%',
    objectFit: 'cover',
  },
  logo: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  paper: {
    width: '100%',
    padding: theme.spacing(16, 8, 19),
    marginLeft: theme.spacing(2.5),
    position: 'relative',
  },
  title: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  description: {
    marginBottom: theme.spacing(6),
    textAlign: 'center',
  },
  label: {
    marginTop: theme.spacing(4),
  },
  passwordInput: {
    marginBottom: theme.spacing(6),
  },
  languageSwitcher: {
    right: 40,
  },
  hint: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontSize: '10px',
    fontStyle: 'italic',
  },
}));

type FormValues = {
  username: string;
};

const initialValues: FormValues = {
  username: '',
};

const ForgotPasswordApp: FC = (): ReactElement => {
  const commonClasses = useCommonStyles();
  const { showSystemSuccessMessage } = useSystemAlert();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { forgotPassword } = useSessionApi();
  const [formState, setFormState] = useState(formStates.PRISTINE);
  const [isPending, setIsPending] = useState(false);
  const [notFound] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [apiErrors, setApiErrors] = useState<ApiValidationError[]>([]);

  const validationSchema = Yup.object().shape({
    username: Yup.string().min(2, t('VALIDATION.INVALID_VALUE')).required(),
  });

  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const handleSubmit = async (
    payload: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    setApiErrors([]);
    setFormState(formStates.PRISTINE);
    setIsPending(true);
    try {
      const reset = await forgotPassword({ email: payload.username, token: '' });
      if (reset) {
        setFormValues({ username: '' });
        showSystemSuccessMessage(t('FORGOT_PASSWORD_BOX_SUCCESS'));
      }
    } catch (e) {
      setFormState(formStates.ERROR);
      setSubmitting(false);
      setIsPending(false);
    }
    setIsPending(false);
  };

  const goToForgotPasswordPage = () => {
    window.location.href = routeLogin;
  };
  return (
    <Box bgcolor="#ebedef" className={classes.wrapper} width={1} height={1} overflow="hidden">
      <Container maxWidth="xl" className={classes.topContainer}>
        <LanguageSwitcher
          onLanguageChange={onLanguageChange}
          selectedLanguage={getLanguageByCode(i18n.language)}
          languages={languages}
          className={classes.languageSwitcher}
        />
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        <Box display="flex" height={1}>
          <Box width="14%" />
          <Box width="70%" display="flex" alignItems="center">
            <Paper className={classes.paper}>
              <div className={classes.logo}>
                <img height={180} alt="LaasOne" src="/images/laasone_logo.png" />
              </div>
              {notFound && <NotFound hideHomeButton />}
              {!notFound && (
                <>
                  <Typography variant="h3" className={classes.title}>
                    {t('FORGOT_PASSWORD_BOX_TITLE')}
                  </Typography>
                  <Typography variant="body2" className={classes.description}>
                    {t('VALIDATION.IS_REQUIRED_TEXT')}{' '}
                  </Typography>
                  <Box position="relative">
                    {formState === formStates.ERROR && (
                      <SystemAlert
                        color="error"
                        fullWidth={false}
                        lessPadding
                        onClose={() => setFormState(formStates.PRISTINE)}
                      >
                        {apiErrors.map((item, key) => {
                          return (
                            <Typography variant="inherit" key={generateKey(key, 'error')}>
                              {t(item.errorMessage)}
                            </Typography>
                          );
                        })}
                      </SystemAlert>
                    )}
                    {isPending && <Loader cover />}
                    {!isPending && (
                      <Formik
                        validateOnBlur
                        validateOnChange
                        enableReinitialize
                        initialValues={formValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                      >
                        {({ errors, touched, isSubmitting }) => (
                          <Form>
                            <Labeled
                              id="username-label"
                              className={classes.label}
                              error={!!(errors.username && touched.username)}
                              text={t('USERNAME')}
                              required
                            >
                              <TextFieldFormik
                                name="username"
                                variant="variant2"
                                type="text"
                                error={!!(errors.username && touched.username)}
                                fullWidth
                              />
                            </Labeled>
                            <Box textAlign="center" marginTop={3}>
                              <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isSubmitting || isPending}
                              >
                                {t('SEND_EMAIL')}
                                <span className={commonClasses.ml3}>
                                  <ArrowRight />
                                </span>
                              </Button>
                            </Box>
                            <Box textAlign="center" marginTop={3}>
                              <Typography
                                link
                                variant="caption"
                                marginTop={3}
                                onClick={() => goToForgotPasswordPage()}
                              >
                                {t('SIGN_IN')}
                              </Typography>
                            </Box>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </Box>
                </>
              )}
            </Paper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ForgotPasswordApp;
