import React, { ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import { BaseProps } from 'shared/types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { generateKey } from 'shared/functions/generateKey';
import { NavLinkProps, useNavLinks } from 'shared/hooks/useNavLinks';
import { useCommonStyles } from 'shared/styles/common';
import { useHistory, useLocation } from 'react-router-dom';
import { routeShipments } from 'routes';
import { hasPermission } from 'shared/functions/hasPermission';
import { UserActionType } from 'shared/constants/user/userRights';
import { useUser } from 'shared/hooks/useUser';

type SidebarProps = BaseProps;

const useStyles = makeStyles(() => ({
  logoWrapper: {
    display: 'block',
    position: 'relative',
    marginTop: '-2px',
    textAlign: 'center',
  },
  logo: {
    marginTop: '30px',
    display: 'inline-flex',
  },
  navLinks: {
    margin: '0',
    padding: '0',
    marginTop: '30px',
    textAlign: 'center',
    listStyle: 'none',
    '& a': {
      lineHeight: '17px',
      color: theme.palette.custom.lightGray,
      textDecoration: 'none',
      fontSize: '1rem',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(5),
    },
    '& .active': {
      background: theme.palette.custom.vividYellow,
      color: theme.palette.custom.veryDarkViolet,
      '& a': {
        display: 'flex',
        color: theme.palette.custom.veryDarkViolet,
        fontWeight: 700,
      },
      '& a:hover': {
        color: theme.palette.custom.veryDarkViolet,
      },
      '& :hover svg': {
        fill: theme.palette.custom.veryDarkViolet,
      },
      '& span': {
        fontSize: '0.75rem',
      },
      '& svg': {
        fill: theme.palette.custom.veryDarkViolet,
      },
    },
  },
  navLink: {
    lineHeight: '17px',
    padding: '20px 0',
    borderTop: '1px solid #7A797C',
    '& a:hover': {
      color: theme.palette.custom.vividYellow,
    },
    '& :hover svg': {
      fill: theme.palette.custom.vividYellow,
    },
  },
  icon: {
    fill: theme.palette.custom.white,
    display: 'flex',
    marginBottom: '3px',
    '& :hover': {
      fill: theme.palette.custom.vividYellow,
    },
  },
}));
const Sidebar: FC<SidebarProps> = (): ReactElement => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const navLinks = useNavLinks();
  const activeNavLinks = navLinks.filter((link) => !link.inactive);
  const user = useUser();
  return (
    <Box bgcolor={theme.palette.primary.main} width={230} height={1} zIndex={1}>
      <Link className={classes.logoWrapper} href={routeShipments}>
        <span className={classes.logo}>
          <img src="/images/sidebar-logo.png" alt="LaasOne" />
        </span>
      </Link>
      <Box position="relative">
        <ul className={classes.navLinks}>
          {activeNavLinks.map((item: NavLinkProps, index: number) => {
            const isActive =
              location.pathname === item.path ||
              (item.parentPath &&
                location.pathname !== '/' &&
                location.pathname.startsWith(item.parentPath));
            if (item.action && !hasPermission(user, item.action, UserActionType.ViewOnly))
              return <></>;
            return (
              <li
                key={generateKey(index, 'sidebar')}
                className={clsx(classes.navLink, isActive && 'active')}
              >
                <Typography
                  variant="body2"
                  component="a"
                  onClick={() => history.push(item.path)}
                  className={commonClasses.cursorPointer}
                >
                  <Typography
                    variant="body1"
                    className={clsx(classes.icon, commonClasses.mr2)}
                    component={item.icon}
                  />
                  {t(item.title)}
                </Typography>
              </li>
            );
          })}
        </ul>
      </Box>
    </Box>
  );
};

export default Sidebar;
