import React, { ReactElement, FC } from 'react';
import { BaseProps } from 'shared/types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

type FlagProps = { flag: string } & BaseProps;

const useStyles = makeStyles({
  img: {
    width: 22,
    height: 22,
  },
  imgFilter: {
    filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.5))',
  },
});

const Flag: FC<FlagProps> = ({ flag }): ReactElement => {
  const classes = useStyles();
  if (!flag) return <img src="/images/flags/no_flag.svg" alt={flag} className={classes.img} />;

  return (
    <img
      src={`/images/flag/${flag.toLowerCase()}.svg`}
      alt={flag}
      className={clsx(classes.img, classes.imgFilter)}
    />
  );
};

export default Flag;
