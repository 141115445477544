import React, { FC, ReactElement, useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { FormControlLabel, RadioGroup } from '@material-ui/core';

import { theme } from 'theme';
import { useHistory } from 'react-router-dom';
import { BaseProps } from 'shared/types';
import {
  PriceOfferRejectReason,
  priceOfferRejectReasonLabels,
  PriceRequestDetailsPayload,
} from 'shared/types/priceRequests/priceRequests';
import { useTranslation } from 'react-i18next';
import { usePriceRequests } from 'shared/services/priceRequests';
import { useSystemAlert } from 'shared/hooks/useSystemAlert';
import { generateDate } from 'shared/functions/date';

import { Dialog, Typography, Button, TextField } from 'components';
import RadioOption from 'components/CustomMaterial/Radio/Radio';
import { routeShipments } from 'routes';

type PriceOfferSidebarProps = {
  priceRequest: PriceRequestDetailsPayload;
  priceRequestId: number | undefined;
  selectedPriceOffer?: number | null;
  onPriceOfferRejected?: () => void;
  onPriceOfferApproved?: () => void;
  toggleShowNoPricesSelectedAlert: (show: boolean) => void;
} & BaseProps;

const useStyles = makeStyles({
  asterisk: {
    color: theme.palette.custom.darkCyan,
    marginLeft: theme.spacing(1),
  },
});

const PriceOfferSidebar: FC<PriceOfferSidebarProps> = ({
  priceRequestId,
  priceRequest,
  selectedPriceOffer,
  onPriceOfferApproved,
  onPriceOfferRejected,
  toggleShowNoPricesSelectedAlert,
}): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { rejectPriceOffer, approvePriceOffer } = usePriceRequests();

  const {
    showSystemItsOurBugMessage,
    showSystemExceptionMessage,
    showSystemSuccessMessage,
  } = useSystemAlert();
  const history = useHistory();
  const [showOfferRejectionDialog, setShowOfferRejectionDialog] = useState(false);
  const [approveAndOrderNow, setApproveAndOrderNow] = useState(false);
  const [showOfferApprovalDialog, setShowOfferApprovalDialog] = useState(false);

  const [rejectionReason, setRejectionReason] = useState<PriceOfferRejectReason>(
    PriceOfferRejectReason.NotSelected,
  );
  const [rejectionComments, setRejectionComments] = useState('');
  const [showRejectionReasonError, setShowRejectionReasonError] = useState(false);

  const toggleOfferRejectionDialog = () => {
    toggleShowNoPricesSelectedAlert(false);
    setShowOfferRejectionDialog(!showOfferRejectionDialog);
  };

  const onClickAddShipment = () => {
    history.push(`${routeShipments}/new-shipment?priceRequestId=${priceRequestId}`);
  };

  const toggleOfferApprovalDialog = () => {
    setShowOfferApprovalDialog(!showOfferApprovalDialog);
  };

  const validate = (): boolean => {
    if (rejectionReason === PriceOfferRejectReason.NotSelected) {
      setShowRejectionReasonError(true);
      return false;
    }
    return true;
  };

  const rejectOffer = async () => {
    if (!validate()) {
      return;
    }

    try {
      if (priceRequestId) {
        const response = await rejectPriceOffer({
          requestId: priceRequestId,
          rejectReason: rejectionReason,
          notes: rejectionComments,
          selectedOffer: 0,
        });

        if (response.isSuccessful) {
          showSystemSuccessMessage(t('PRICE_REQUEST.REJECT_SUCCESS'));
          if (onPriceOfferRejected) {
            onPriceOfferRejected();
          }
        } else {
          showSystemItsOurBugMessage();
        }
      }
    } catch (err) {
      showSystemExceptionMessage(err);
    } finally {
      toggleOfferRejectionDialog();
    }
  };

  const approveOffer = async () => {
    try {
      if (priceRequestId && selectedPriceOffer) {
        const response = await approvePriceOffer({
          requestId: priceRequestId,
          selectedOffer: selectedPriceOffer,
        });

        if (response.isSuccessful) {
          showSystemSuccessMessage(t('PRICE_REQUEST.APPROVE_SUCCESS'));
          if (onPriceOfferApproved) {
            onPriceOfferApproved();
          }
          if (approveAndOrderNow) {
            setTimeout(() => {
              onClickAddShipment();
            }, 2000);
          }
        } else {
          showSystemItsOurBugMessage();
        }
      }
    } catch (err) {
      showSystemExceptionMessage(err);
    } finally {
      toggleOfferApprovalDialog();
    }
  };

  useEffect(() => {
    if (!showOfferRejectionDialog) {
      setRejectionReason(PriceOfferRejectReason.NotSelected);
      setRejectionComments('');
      setShowRejectionReasonError(false);
    }
  }, [showOfferRejectionDialog]);

  const onRejectionReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRejectionReason(+event.target.value);
    if (showRejectionReasonError) {
      setShowRejectionReasonError(false);
    }
  };

  const selectedPriceOfferData = priceRequest.offeredPrices.find(
    (item) => item.id === selectedPriceOffer,
  );

  return (
    <>
      <Dialog
        title={t('PRICE_REQUEST.OFFER_APPROVAL_DIALOG_TITLE').replace(
          '{0}',
          String(selectedPriceOfferData?.price),
        )}
        maxWidth="sm"
        open={showOfferApprovalDialog}
        handleClose={toggleOfferApprovalDialog}
        actionCancelLabel={t('CANCEL')}
        actionAcceptLabel={t('APPROVE')}
        onActionCancel={toggleOfferApprovalDialog}
        onActionAccept={approveOffer}
      >
        <Typography variant="body2">{t('PRICE_REQUEST.OFFER_APPROVAL_DIALOG_TEXT')}</Typography>
      </Dialog>

      <Dialog
        title={t('PRICE_REQUEST.OFFER_REJECTION_DIALOG_TITLE')}
        maxWidth="sm"
        open={showOfferRejectionDialog}
        handleClose={toggleOfferRejectionDialog}
        actionCancelLabel={t('CANCEL')}
        actionAcceptLabel={t('PRICE_REQUEST.SEND_YOUR_ANSWER')}
        onActionCancel={toggleOfferRejectionDialog}
        onActionAccept={rejectOffer}
      >
        <Typography variant="caption" fontWeight="medium" component="p" marginBottom={3}>
          {t('PRICE_REQUEST.YOUR_REASON_FOR_REJECTION')}
          <span className={classes.asterisk}>*</span>
        </Typography>
        <Box
          padding={showRejectionReasonError ? 1 : 0}
          border="solid 1px"
          borderRadius={16}
          borderColor={showRejectionReasonError ? theme.palette.custom.alertError : 'transparent'}
        >
          <RadioGroup value={rejectionReason} onChange={onRejectionReasonChange}>
            {Object.keys(priceOfferRejectReasonLabels).map((reason, index) => {
              const label =
                priceOfferRejectReasonLabels[(reason as unknown) as PriceOfferRejectReason];
              const checked = rejectionReason === +reason;
              const isLast = index >= Object.keys(priceOfferRejectReasonLabels).length - 1;

              return label ? (
                <Box key={label} marginBottom={isLast ? 0 : 3} paddingLeft={3}>
                  <FormControlLabel
                    value={reason}
                    control={<RadioOption checked={checked} padding={0} />}
                    label={
                      <Typography
                        variant="body2"
                        component="p"
                        fontWeight={checked ? 'bold' : 'medium'}
                        marginLeft={2}
                      >
                        {t(label)}
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </Box>
              ) : null;
            })}
          </RadioGroup>
        </Box>
        {showRejectionReasonError && (
          <Typography
            customColor={theme.palette.custom.alertError}
            variant="caption"
            component="p"
            marginTop={1}
          >
            {t('PRICE_REQUEST.REASON_FOR_REJECTION_HAS_TO_BE_SPECIFIED')}
          </Typography>
        )}

        <Typography variant="caption" component="p" fontWeight="medium" marginTop={4}>
          {t('PRICE_REQUEST.PLEASE_COMMENT_YOUR_CHOICE')}
        </Typography>
        <TextField
          variant="variant6"
          size="small"
          fullWidth
          multiline
          inputProps={{ maxLength: 2000 }}
          rows={4}
          onChange={(event) => setRejectionComments(event.target.value)}
        />
      </Dialog>

      <Box height={185}>
        <Typography variant="caption" fontWeight="medium">
          {t('PRICE_REQUEST.PRICE_REQUEST_VALID_UNTIL')}
        </Typography>
        <Typography variant="h6" component="p" marginTop={1}>
          {priceRequest.validUntilDate ? generateDate(priceRequest.validUntilDate) : '–'}
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          marginTop={4}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={
              selectedPriceOffer
                ? () => {
                    toggleOfferApprovalDialog();
                    setApproveAndOrderNow(true);
                  }
                : () => toggleShowNoPricesSelectedAlert(true)
            }
            fullWidth
          >
            {t('PRICE_REQUEST.APPROVE_OFFER_ORDER_NOW')}
          </Button>
          <Box marginTop={theme.spacing(1)}>
            <Button
              variant="contained"
              color="inherit"
              onClick={
                selectedPriceOffer
                  ? () => {
                      toggleOfferApprovalDialog();
                      setApproveAndOrderNow(false);
                    }
                  : () => toggleShowNoPricesSelectedAlert(true)
              }
              fullWidth
            >
              {t('PRICE_REQUEST.APPROVE_OFFER')}
            </Button>
          </Box>
          <Typography
            variant="body2"
            fontWeight="medium"
            component="p"
            marginTop={6}
            link
            onClick={toggleOfferRejectionDialog}
          >
            {t('PRICE_REQUEST.REJECT_THIS_OFFER')}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PriceOfferSidebar;
