/* eslint react/require-default-props: 0 */
/* eslint react-hooks/rules-of-hooks: 0 */
import { ErrorMessage } from 'components';
import { Field, FastField, FieldProps, ErrorMessage as FormikErrorMessage } from 'formik';
import React, { FC, ReactElement, useCallback, useEffect } from 'react';
import { validateDelay } from 'shared/constants/inputDelay';
import { BaseProps } from 'shared/types';
import { useDebouncedCallback } from 'use-debounce/lib';

type InputProps = JSX.IntrinsicAttributes & {
  name?: string;
  showErrorLabel?: boolean;
};

type FieldChildrenProps<T> = {
  Component: FC<T & InputProps>;
} & FieldProps &
  BaseProps;

const FieldContent: <T extends InputProps>(props: FieldChildrenProps<T>) => ReactElement = ({
  Component,
  field,
  meta: { error },
  form: { values, validateForm },
  ...restProps
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const doValidate = useCallback((vals: any) => {
    validateForm(vals);
  }, []);
  const doValidateDebounced = useDebouncedCallback(doValidate, validateDelay);

  useEffect(() => {
    if (error) {
      doValidateDebounced.callback(values);
    }
  }, [field.value]);

  return (
    <Component
      {...field}
      value={field.value ?? ''}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(restProps as any)}
    />
  );
};

export const withFormikField = <T extends InputProps>(Component: FC<T & InputProps>) => {
  return ({ name = '', showErrorLabel = false, ...restProps }: T & InputProps): ReactElement => {
    return (
      <>
        <Field name={name}>
          {(fieldProps: FieldProps) => (
            <FieldContent<T> Component={Component} {...fieldProps} {...restProps} />
          )}
        </Field>
        {showErrorLabel && name && <FormikErrorMessage name={name} component={ErrorMessage} />}
      </>
    );
  };
};

export const withFormikFastField = <T extends InputProps>(Component: FC<T & InputProps>) => {
  return ({ name = '', showErrorLabel = false, ...restProps }: T & InputProps): ReactElement => {
    return (
      <>
        <FastField name={name}>
          {(fieldProps: FieldProps) => (
            <FieldContent<T> Component={Component} {...fieldProps} {...restProps} />
          )}
        </FastField>
        {showErrorLabel && name && <FormikErrorMessage name={name} component={ErrorMessage} />}
      </>
    );
  };
};
