import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { useUser } from 'shared/hooks/useUser';
import { SystemAlertContext, useSystemAlert } from 'shared/hooks/useSystemAlert';
import { IS_SANDBOX } from 'shared/constants/appConstants';
import { EnvironmentPanel, SystemAlert } from 'components';
import AuthenticatedApp from './AuthenticatedApp';
import TrackingApp from './TrackingApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import InvitationApp from './InvitationApp';
import ForgotPasswordApp from './ForgotPasswordApp';
import ResetPasswordApp from './ResetPasswordApp';

const AppContents: FC = (): ReactElement => {
  const user = useUser();
  const { t } = useTranslation();
  const alertContext = useContext(SystemAlertContext);
  const { showSystemSuccessMessage, hideSystemMessage } = useSystemAlert();
  const [wasPreviouslyLoggedIn, setWasPreviouslyLoggedIn] = useState(false);

  useEffect(() => {
    // notify user after logout
    if (wasPreviouslyLoggedIn && !user.isLoggedIn) {
      showSystemSuccessMessage(t('YOU_ARE_LOGGED_OUT'));
    }
    setWasPreviouslyLoggedIn(user.isLoggedIn);
  }, [user.isLoggedIn]);

  const hideLogoutMessage = () => {
    hideSystemMessage();
  };
  const isTrackingView = window.location.pathname.includes('tracking');
  const isInvitationView = window.location.pathname.includes('invitation');
  const isForgotPasswordView = window.location.pathname.includes('forgot-password');
  const isResetPasswordView = window.location.pathname.includes('reset-password');
  return (
    <>
      {IS_SANDBOX && <EnvironmentPanel />}
      {alertContext.isSystemAlertVisible && (
        <SystemAlert onClose={alertContext.hideSystemAlert} color={alertContext.systemAlertColor}>
          {alertContext.message}
        </SystemAlert>
      )}
      {isResetPasswordView && (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ResetPasswordApp />
        </BrowserRouter>
      )}
      {isForgotPasswordView && (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ForgotPasswordApp />
        </BrowserRouter>
      )}
      {isInvitationView && (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <InvitationApp />
        </BrowserRouter>
      )}
      {isTrackingView && (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <TrackingApp />
        </BrowserRouter>
      )}
      {!isInvitationView &&
        !isResetPasswordView &&
        !isTrackingView &&
        !isForgotPasswordView &&
        user.isLoggedIn && (
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <AuthenticatedApp />
          </BrowserRouter>
        )}
      {!isInvitationView &&
        !isResetPasswordView &&
        !isForgotPasswordView &&
        !user.isLoggedIn &&
        !isTrackingView && <UnauthenticatedApp hideLogoutMessage={hideLogoutMessage} />}
    </>
  );
};

export default AppContents;
