import React, { ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { theme } from 'theme';

import {
  ExpandableSettings,
  InfoIcon,
  LogOutIcon,
  UsersIcon,
  // ProfileIcon,
  // WriteIcon,
} from 'components';

import { useFeatures } from 'shared/hooks/useFeatures';
import { BaseProps } from 'shared/types';
import { truncate } from 'shared/functions/truncate';
import { ItemType as ExpandableSettingsItemType } from 'components/ExpandableSettings/ExpandableSettings';
import { useHistory } from 'react-router-dom';
import { useUser } from 'shared/hooks/useUser';
import { routeAddressBook, routeCompanyProfile, routeTemplates, routeUsers } from '../../routes';

type UserSettingsProps = {
  userName: string;
  companyName: string;
  logOut?: () => void;
} & BaseProps;

const useStyles = makeStyles(() => ({
  item: {
    '&:hover': {
      backgroundColor: theme.palette.custom.lightGrayishVioletAlt,
    },
  },
}));

const UserSettings: FC<UserSettingsProps> = ({
  userName,
  companyName,
  className,
}): ReactElement => {
  const user = useUser();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { companyProfile: companyFeature } = useFeatures();

  // FIXME: dont we have consts for user roles stored somewhere? couldn't find them
  const isAdmin = user.profile?.roles?.includes(40);

  const userDataPreview = (
    <>
      {truncate(companyName.toUpperCase(), 10)} / <strong>{userName}</strong>
    </>
  );

  const items: ExpandableSettingsItemType[] = [
    // TODO: Uncomment when implementation is in place
    // { code: 'myProfile', label: t('MY_PROFILE'), icon: <ProfileIcon /> },
    // { code: 'invoices', label: t('INVOICES'), icon: <WriteIcon /> },
    /* { code: 'addressBook', label: t('ADDRESS_BOOK'), icon: <AddressBookIcon /> },
    {
      code: 'templates',
      label: t('TEMPLATE.TEMPLATES'),
      icon: <NoteIcon fill={theme.palette.custom.veryDarkViolet} />,
      inactive: !templatesFeature,
    }, */
    {
      code: 'companyProfile',
      label: t('COMPANY_PROFILE'),
      icon: <InfoIcon />,
      inactive: !isAdmin || !companyFeature,
    },
    {
      code: 'companyUsers',
      label: t('SIDEBAR_USERS'),
      icon: <UsersIcon />,
      inactive: !isAdmin,
    },
    { code: 'logOut', label: t('LOG_OUT'), icon: <LogOutIcon /> },
  ];

  const activeItems = items.filter((item) => !item.inactive);

  const onItemSelected = (code: string): void => {
    if (code === 'logOut') {
      user.logOut();
    } else if (code === 'addressBook') {
      history.push(routeAddressBook);
    } else if (code === 'companyProfile') {
      history.push(routeCompanyProfile);
    } else if (code === 'templates') {
      history.push(routeTemplates);
    } else if (code === 'companyUsers') {
      history.push(routeUsers);
    }
  };

  return (
    <ExpandableSettings
      bgcolor={theme.palette.custom.white}
      className={className}
      selectedItem={{ code: 'userDataPreview', label: userDataPreview }}
      itemClassName={classes.item}
      items={activeItems}
      onItemSelected={onItemSelected}
    />
  );
};

export default UserSettings;
