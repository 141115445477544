import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const BellIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 15,
  height = 16,
  fill = theme.palette.custom.white,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M11.5 9.32812V6C11.4688 4.86458 11.0781 3.92188 10.3281 3.17188C9.57812 2.42188 8.63542 2.03125 7.5 2C6.36458 2.03125 5.42188 2.42188 4.67188 3.17188C3.92188 3.92188 3.53125 4.86458 3.5 6V9.32812C3.48958 9.82812 3.36979 10.276 3.14062 10.6719H11.8594C11.6198 10.2552 11.5 9.80729 11.5 9.32812ZM14.1719 12H0.828125C0.390625 11.9479 0.171875 11.7292 0.171875 11.3438C0.171875 10.9479 0.390625 10.724 0.828125 10.6719C1.19271 10.6615 1.50521 10.526 1.76562 10.2656C2.02604 10.0052 2.16146 9.69271 2.17188 9.32812V6C2.20312 4.48958 2.71875 3.23438 3.71875 2.23438C4.72917 1.22396 5.98958 0.703125 7.5 0.671875C9.01042 0.703125 10.2656 1.22396 11.2656 2.23438C12.276 3.23438 12.7969 4.48958 12.8281 6V9.32812C12.8385 9.69271 12.974 10.0052 13.2344 10.2656C13.4948 10.526 13.8073 10.6615 14.1719 10.6719C14.6094 10.724 14.8281 10.9479 14.8281 11.3438C14.8281 11.7292 14.6094 11.9479 14.1719 12ZM8.07812 13.6719C8.01562 13.776 7.93229 13.8594 7.82812 13.9219C7.73438 13.974 7.625 14 7.5 14C7.375 14 7.26042 13.974 7.15625 13.9219C7.0625 13.8594 6.98438 13.776 6.92188 13.6719C6.82812 13.5052 6.69271 13.401 6.51562 13.3594C6.34896 13.3073 6.18229 13.3333 6.01562 13.4375C5.84896 13.5312 5.74479 13.6667 5.70312 13.8438C5.66146 14.0104 5.68229 14.1719 5.76562 14.3281C5.95312 14.6406 6.19792 14.8854 6.5 15.0625C6.80208 15.2396 7.13542 15.3281 7.5 15.3281C7.86458 15.3281 8.19792 15.2396 8.5 15.0625C8.80208 14.8854 9.04688 14.6406 9.23438 14.3281C9.31771 14.1719 9.33854 14.0104 9.29688 13.8438C9.25521 13.6667 9.15104 13.5312 8.98438 13.4375C8.81771 13.3333 8.64583 13.3073 8.46875 13.3594C8.30208 13.401 8.17188 13.5052 8.07812 13.6719Z"
        fill={fill}
      />
    </svg>
  );
};

export default BellIcon;
