import React, { FC, ReactElement } from 'react';

import MaterialCheckbox, {
  CheckboxProps as MaterialCheckboxProps,
} from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

import { CheckboxIcon, QuestionMarkIcon, Typography } from 'components';
import makeStyles from '@material-ui/styles/makeStyles';
import { useCommonStyles } from 'shared/styles/common';

type CheckboxProps = {
  variant?: 'lightAlt' | 'light' | 'dark';
  size?: 'tiny' | 'small' | 'big';
  label?: string;
  tooltip?: string;
  boldWhenChecked?: boolean;
} & Omit<MaterialCheckboxProps, 'size'>;

const useNativeStyles = makeStyles({
  root: {
    padding: 0,
  },
});

const Checkbox: FC<CheckboxProps> = ({
  variant = 'light',
  size = 'small',
  classes: nativeCustomClasses,
  label,
  tooltip,
  boldWhenChecked = true,
  checked,
  disabled,
  ...props
}): ReactElement => {
  const nativeClasses = useNativeStyles();
  const commonClasses = useCommonStyles();

  const checkboxComponent = (
    <MaterialCheckbox
      classes={{ ...nativeClasses, ...nativeCustomClasses }}
      checkedIcon={<CheckboxIcon variant={variant} size={size} disabled={disabled} checked />}
      icon={<CheckboxIcon variant={variant} size={size} disabled={disabled} />}
      checked={checked}
      disabled={disabled}
      {...props}
    />
  );

  return !label ? (
    checkboxComponent
  ) : (
    <Box display="flex" alignItems="center">
      {checkboxComponent}
      <Typography
        variant="body2"
        marginLeft={2}
        fontWeight={boldWhenChecked && checked ? 'bold' : undefined}
      >
        {label}
      </Typography>
      {tooltip && (
        <Tooltip title={tooltip || ''} arrow>
          <div>
            <QuestionMarkIcon className={commonClasses.ml1} />
          </div>
        </Tooltip>
      )}
    </Box>
  );
};

export default Checkbox;
