import { ShipmentItemType, ShipmentItemSubType } from 'shared/types/shipments/shipmentItem';

export const shipmentItemTypeLabels: Record<ShipmentItemType, string> = {
  [ShipmentItemType.Pallet]: 'SHIPMENT.ITEM.TYPE.PALLET',
  [ShipmentItemType.Box]: 'SHIPMENT.ITEM.TYPE.PACKAGE',
  [ShipmentItemType.Truck]: 'SHIPMENT.ITEM.TYPE.TRUCK',
  [ShipmentItemType.Other]: 'SHIPMENT.ITEM.TYPE.OTHER',
  [ShipmentItemType.Container]: 'SHIPMENT.ITEM.TYPE.CONTAINER',
  [ShipmentItemType.Machine]: 'SHIPMENT.ITEM.TYPE.MACHINE',
};

export const shipmentItemSubTypeLabels: Record<ShipmentItemSubType, string> = {
  [ShipmentItemSubType.EurPallet]: 'SHIPMENT.ITEM.SUBTYPE.EUR_PALLET',
  [ShipmentItemSubType.FinPallet]: 'SHIPMENT.ITEM.SUBTYPE.FIN_PALLET',
  [ShipmentItemSubType.Pallet]: 'SHIPMENT.ITEM.SUBTYPE.PALLET',
  [ShipmentItemSubType.Package]: 'SHIPMENT.ITEM.SUBTYPE.PACKAGE',
  [ShipmentItemSubType.FullTruckLoad]: 'SHIPMENT.ITEM.SUBTYPE.FULL_TRUCK_LOAD',
  [ShipmentItemSubType.LongPallet]: 'SHIPMENT.ITEM.SUBTYPE.LONG_PALLET',
  [ShipmentItemSubType.HalfPallet]: 'SHIPMENT.ITEM.SUBTYPE.HALF_PALLET',
  [ShipmentItemSubType.Container20ftDC]: 'SHIPMENT.ITEM.SUBTYPE.CONTAINER_20_DC',
  [ShipmentItemSubType.Container40ftDC]: 'SHIPMENT.ITEM.SUBTYPE.CONTAINER_40_DC',
  [ShipmentItemSubType.Container40ftHC]: 'SHIPMENT.ITEM.SUBTYPE.CONTAINER_40_HC',
  [ShipmentItemSubType.ContainerOther]: 'SHIPMENT.ITEM.SUBTYPE.CONTAINER_OTHER',
  [ShipmentItemSubType.LDM]: 'SHIPMENT.ITEM.SUBTYPE.LDM',
  [ShipmentItemSubType.HeavyCargo]: 'SHIPMENT.ITEM.SUBTYPE.MACHINE',
};
