import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const MoreIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 19,
  height = 4,
  fill = theme.palette.custom.darkCyan,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.5 3.99219C8.9375 3.97656 8.46875 3.78125 8.09375 3.40625C7.71875 3.03125 7.53125 2.5625 7.53125 2C7.53125 1.4375 7.71875 0.96875 8.09375 0.59375C8.46875 0.21875 8.9375 0.03125 9.5 0.03125C10.0625 0.03125 10.5312 0.21875 10.9062 0.59375C11.2812 0.96875 11.4688 1.4375 11.4688 2C11.4687 2.5625 11.2812 3.03125 10.9062 3.40625C10.5312 3.78125 10.0625 3.97656 9.5 3.99219ZM16.5078 3.99219C15.9297 3.97656 15.4531 3.78125 15.0781 3.40625C14.7031 3.03125 14.5156 2.5625 14.5156 2C14.5156 1.4375 14.7031 0.96875 15.0781 0.59375C15.4531 0.21875 15.9219 0.03125 16.4844 0.03125C17.0625 0.03125 17.5391 0.21875 17.9141 0.59375C18.2891 0.96875 18.4766 1.4375 18.4766 2C18.4766 2.5625 18.2891 3.03125 17.9141 3.40625C17.5391 3.78125 17.0703 3.97656 16.5078 3.99219ZM2.49219 3.99219C1.92969 3.97656 1.46094 3.78125 1.08594 3.40625C0.710937 3.03125 0.523437 2.5625 0.523437 2C0.523437 1.4375 0.710937 0.96875 1.08594 0.59375C1.46094 0.21875 1.92969 0.03125 2.49219 0.03125C3.07031 0.03125 3.54687 0.21875 3.92187 0.59375C4.29687 0.96875 4.48437 1.4375 4.48437 2C4.48437 2.5625 4.29687 3.03125 3.92187 3.40625C3.54687 3.78125 3.07031 3.97656 2.49219 3.99219Z"
        fill={fill}
      />
    </svg>
  );
};

export default MoreIcon;
