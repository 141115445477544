import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const DashboardIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 20,
  height = 19,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 19"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path d="M0.992188 6.33594L8.49219 0.515625C8.64844 0.398438 8.81771 0.339844 9 0.339844C9.18229 0.339844 9.35156 0.398438 9.50781 0.515625L17.0078 6.33594C17.2161 6.50521 17.3268 6.72656 17.3398 7V16.1602C17.3138 16.8763 17.0664 17.4688 16.5977 17.9375C16.1289 18.4062 15.543 18.6471 14.8398 18.6602H3.16016C2.45703 18.6471 1.87109 18.4062 1.40234 17.9375C0.933594 17.4688 0.686198 16.8763 0.660156 16.1602V7C0.673177 6.72656 0.783854 6.50521 0.992188 6.33594ZM12.3398 17H14.8398C15.0742 17 15.2695 16.9219 15.4258 16.7656C15.582 16.5964 15.6602 16.3945 15.6602 16.1602V7.41016L9 2.21484L2.33984 7.41016V16.1602C2.33984 16.3945 2.41797 16.5964 2.57422 16.7656C2.73047 16.9219 2.92578 17 3.16016 17H5.66016V9.5C5.67318 9.26562 5.75781 9.07031 5.91406 8.91406C6.07031 8.75781 6.26562 8.67318 6.5 8.66016H11.5C11.7344 8.67318 11.9297 8.75781 12.0859 8.91406C12.2422 9.07031 12.3268 9.26562 12.3398 9.5V17ZM7.33984 17H10.6602V10.3398H7.33984V17Z" />
    </svg>
  );
};

export default DashboardIcon;
