import React, { ReactElement, FC } from 'react';
import { Typography } from 'components';
import { TypographyProps } from 'components/CustomMaterial/Typography/Typography';
import { BaseProps } from 'shared/types';
import { theme } from 'theme';

type ErrorMessageProps = TypographyProps & BaseProps;

const ErrorMessage: FC<ErrorMessageProps> = ({ marginTop = 1, ...restProps }): ReactElement => {
  return (
    <Typography
      customColor={theme.palette.custom.alertError}
      variant="caption"
      component="p"
      marginTop={marginTop}
      {...restProps}
    />
  );
};

export default ErrorMessage;
