import React, { ReactElement, FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import { theme } from 'theme';
import { BaseProps } from 'shared/types';
import Box from '@material-ui/core/Box';
import { Typography } from 'components/index';
import { NewShipmentPayload } from 'shared/types/shipments/shipments';
import { useFormikContext } from 'formik';
import { AutomaticPricesState, NewShipmentContext } from 'scenes/NewShipment/NewShipment';
import ShipmentDatesEditable from '../../ShipmentDatesEditable';

type ShipmentDateStepProps = BaseProps;

const useStyles = makeStyles({
  asterisk: {
    color: theme.palette.custom.darkCyan,
  },
});

const ShipmentDateStep: FC<ShipmentDateStepProps> = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const formik = useFormikContext<NewShipmentPayload>();
  const { setAutomaticPricesState } = useContext(NewShipmentContext);

  const {
    earliestArrivalDate,
    earliestArrivalTime,
    earliestPickupDate,
    earliestPickupTime,
    isFixedArrivalDate,
    isFixedPickupDate,
    latestArrivalDate,
    latestArrivalTime,
    latestPickupDate,
    latestPickupTime,
  } = formik.values;

  useEffect(() => {
    setAutomaticPricesState(AutomaticPricesState.InProgress);
  }, [
    setAutomaticPricesState,
    earliestArrivalDate,
    earliestArrivalTime,
    earliestPickupDate,
    earliestPickupTime,
    isFixedArrivalDate,
    isFixedPickupDate,
    latestArrivalDate,
    latestArrivalTime,
    latestPickupDate,
    latestPickupTime,
  ]);

  return (
    <Box>
      <Typography variant="h5" marginBottom={1.5}>
        {t('SHIPMENT.DATES.ADD_PICKUP_DELIVERY_DATES')}
      </Typography>
      <Typography variant="body2" fontWeight="medium">
        {t('SHIPMENT.ADDRESSES.REQUIRED_FIELDS_ARE_MARKED_WITH')}{' '}
        <span className={classes.asterisk}>*</span>
      </Typography>
      <Box width="100%" display="flex" marginTop={4.5} position="relative">
        <ShipmentDatesEditable type="Pickup" marginRight={6} />
        <ShipmentDatesEditable type="Arrival" />
      </Box>
    </Box>
  );
};

export default ShipmentDateStep;
