import { toFormData } from 'shared/functions/toFormData';
import { callFetchApi, useFetchApi } from 'shared/functions/fetchApi';
import {
  InvitationProps,
  InvitationResponseProps,
  LogInProps,
  LogInResponseType,
  RefreshTokenProps,
  ResetPasswordResponseProps,
} from 'shared/types/session';
import { ApiResponse } from '../types/api';

type UseSessionApiReturnType = {
  logIn: (request: LogInProps) => Promise<LogInResponseType>;
  refreshUserToken: (request: RefreshTokenProps) => Promise<LogInResponseType>;
  findInvitation: (request: InvitationProps) => Promise<InvitationResponseProps>;
  forgotPassword: (request: InvitationProps) => Promise<boolean>;
  createUser: (request: InvitationResponseProps) => Promise<ApiResponse<number>>;
  resetPassword: (request: ResetPasswordResponseProps) => Promise<ApiResponse<boolean>>;
};

export const refreshUserToken = async ({
  refreshToken,
}: RefreshTokenProps): Promise<LogInResponseType> => {
  const body = toFormData({
    Grant_type: 'refresh_token',
    Refresh_token: refreshToken,
    Username: '',
    Password: '',
  });

  const params = {
    method: 'POST',
    body,
  };

  return callFetchApi(`/connect/token/bookingportal`, null, params).then((response) =>
    response.json(),
  );
};

export const useSessionApi = (): UseSessionApiReturnType => {
  const { fetchApi } = useFetchApi();

  const logIn = ({ username, password }: LogInProps): Promise<LogInResponseType> => {
    const body = toFormData({
      Grant_type: 'password',
      Username: username,
      Password: password,
    });

    const params = {
      method: 'POST',
      body,
    };

    return fetchApi(`/connect/token/bookingportal`, params).then((response) => response.json());
  };

  const forgotPassword = async ({ email }: InvitationProps): Promise<boolean> => {
    const payload = {
      email: email.replace(' ', '+'),
    };
    const body = JSON.stringify(payload);
    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body,
    };

    const response = await fetchApi(`/api/authorize/forgot-password`, params);
    return response.json();
  };

  const resetPassword = async ({
    email,
    confirmPassword,
    password,
    code,
  }: ResetPasswordResponseProps): Promise<ApiResponse<boolean>> => {
    const payload = {
      email: email.replace(' ', '+'),
      confirmPassword,
      password,
      code,
    };
    const body = JSON.stringify(payload);
    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body,
    };

    const response = await fetchApi(`/api/authorize/reset-password`, params);
    return response.json();
  };

  const findInvitation = async ({
    email,
    token,
  }: InvitationProps): Promise<InvitationResponseProps> => {
    const payload = {
      username: email,
      token,
    };
    const body = JSON.stringify(payload);
    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body,
    };

    const response = await fetchApi(`/api/authorize/get-invitation`, params);
    return response.json();
  };

  const createUser = async (payload: InvitationResponseProps): Promise<ApiResponse<number>> => {
    const body = JSON.stringify(payload);
    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body,
    };

    const response = await fetchApi(`/api/authorize/create-user`, params);
    return response.json();
  };
  return {
    logIn,
    refreshUserToken,
    findInvitation,
    createUser,
    forgotPassword,
    resetPassword,
  };
};
