import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const ContainerIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 16,
  height = 14,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3273 8.42146L14.6389 1.13798C14.1702 0.894953 13.6755 0.773438 13.1547 0.773438C12.6339 0.773438 12.1392 0.894953 11.6704 1.13798L2.79115 5.56461C2.21829 5.85972 1.76695 6.27635 1.43712 6.81448C1.1073 7.33526 0.942383 7.9168 0.942383 8.5591V19.1309C0.942383 19.7732 1.09862 20.3634 1.41108 20.9016C1.74091 21.4224 2.19225 21.8303 2.76511 22.1254L15.4796 29.4349C15.9483 29.6606 16.443 29.7734 16.9638 29.7734C17.4846 29.7734 17.9793 29.6606 18.448 29.4349L27.3273 24.9823C27.9002 24.6872 28.3515 24.2792 28.6814 23.7584C29.0112 23.2376 29.1761 22.6561 29.1761 22.0138V11.4159C29.1761 10.7737 29.0112 10.1921 28.6814 9.67133C28.3515 9.13319 27.9002 8.71657 27.3273 8.42146ZM25.5827 10.0359L16.9638 14.3584L4.53576 7.17903L12.6599 3.11695C12.9898 2.96071 13.3196 2.96071 13.6494 3.11695L25.5827 10.0359ZM23.852 13.3925L26.9628 11.8326V22.0138C26.9628 22.2221 26.902 22.4131 26.7805 22.5867C26.6763 22.7603 26.5288 22.8991 26.3378 23.0033L23.852 24.2501V19.8444L24.8724 19.4363C25.3607 19.2409 25.5982 18.6867 25.4029 18.1984C25.2075 17.7101 24.6533 17.4726 24.165 17.6679L23.852 17.7931V13.3925ZM21.9474 18.555V14.3476L18.0835 16.2852V27.1435L21.9474 25.2054V20.6062L21.8793 20.6335C21.391 20.8288 20.8368 20.5913 20.6415 20.103C20.4461 19.6147 20.6836 19.0605 21.172 18.8651L21.9474 18.555ZM15.8441 16.2852V27.1435L14.3292 26.2667L14.3292 15.4125L15.8441 16.2852ZM10.52 24.0621L12.4246 25.1644L12.4246 14.3212H12.4347L10.52 13.2181L10.52 24.0621ZM8.61546 22.9598L6.71089 21.8575L6.71089 11.0238L8.61546 12.121L8.61546 22.9598ZM3.1557 8.97572L4.80633 9.92661L4.80633 20.7552L3.75459 20.1464C3.56364 20.0423 3.41609 19.9034 3.31193 19.7298C3.20778 19.5562 3.1557 19.3566 3.1557 19.1309V8.97572Z"
        fill="#858585"
      />
    </svg>
  );
};

export default ContainerIcon;
