import React, { ReactElement, FC } from 'react';

import { BaseProps } from 'shared/types';

import Box from '@material-ui/core/Box';

import { theme } from 'theme';

type IndicatorProps = {
  active: boolean;
  icon: ReactElement;
  activeColor?: string;
  inactiveColor?: string;
  size?: number;
} & BaseProps;

const Indicator: FC<IndicatorProps> = ({
  active,
  activeColor,
  inactiveColor,
  icon,
  size = 32,
  children,
  ...restProps
}): ReactElement => {
  const colors = {
    active: activeColor || theme.palette.custom.strongViolet,
    inactive: inactiveColor || theme.palette.custom.grayAlt,
  };

  const main = (margin: boolean) => (
    <Box
      borderRadius="100%"
      width={size}
      height={size}
      minWidth={size}
      minHeight={size}
      bgcolor={active ? colors.active : colors.inactive}
      marginRight={margin ? 2 : 0}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {icon}
    </Box>
  );

  return children ? (
    <Box display="flex" alignItems="center" {...restProps}>
      {main(true)}
      {children}
    </Box>
  ) : (
    main(false)
  );
};

export default Indicator;
