import React, { ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { DialogProps } from 'components/CustomMaterial/Dialog/Dialog';
import {
  Typography,
  Dialog,
  ShipmentItems,
  ShipmentItemsComments,
  Checkbox,
  Separator,
  CalendarIcon,
} from 'components';
import { combineDateWithTime, generateDate } from 'shared/functions/date';
import { BaseProps } from 'shared/types';
import {
  PriceRequestDetailsPayload,
  RegularShipmentPeriod,
  regularShipmentPeriodLabels,
} from 'shared/types/priceRequests/priceRequests';
import { useCommonStyles } from 'shared/styles/common';
import { ShipmentDocument } from 'shared/types/shipments/shipments';
import { LoadingContact } from 'shared/types/loadingContact';
import { FaPhoneAlt } from 'react-icons/fa';
import { buildAddress } from 'shared/functions/buildAddress';
import Chip from '@material-ui/core/Chip';
import { generateKey } from 'shared/functions/generateKey';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { theme as theme2 } from '../../theme';
import PickupIcon from '../Icons/PickupIcon/PickupIcon';
import DeliveryIcon from '../Icons/DeliveryIcon/DeliveryIcon';
import FlagComponent from '../Flag/FlagComponent';

const useStyles = makeStyles({
  dateLabel: {
    color: theme2.palette.custom.veryDarkGrayAlt2,
    display: 'flex',
    alignItems: 'center',
  },
  dateWithWarning: {
    color: theme2.palette.custom.strongViolet,
  },
  warningIcon: {
    position: 'relative',
    top: 1,
    left: 5,
  },
  smallText: {
    fontSize: '0.75rem',
  },
  dateIcon: {
    '& svg': {
      minWidth: 42,
    },
  },
});

const useChipNativeStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.custom.lightGrayishCyan,
    padding: theme.spacing(1, 4),
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(-1),
    '&:hover': {
      backgroundColor: theme.palette.custom.lightGrayishCyan,
    },
  },
  label: {
    padding: theme.spacing(1),
    color: theme.palette.custom.veryDarkGray,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    fontWeight: 500,
  },
}));

type PriceRequestConfirmationDialogProps = {
  priceRequest: PriceRequestDetailsPayload;
  documents: ShipmentDocument[];
} & Omit<DialogProps, 'maxWidth' | 'title'> &
  BaseProps;

const PriceRequestConfirmationDialog: FC<PriceRequestConfirmationDialogProps> = ({
  priceRequest,
  documents,
  ...restProps
}): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const nativeClasses = useChipNativeStyles();
  const commonClasses = useCommonStyles();

  const earliestDate = priceRequest.earliestPickupDate
    ? combineDateWithTime(priceRequest.earliestPickupDate, priceRequest.earliestPickupTime ?? '')
    : null;
  const latestDate = priceRequest.latestPickupDate
    ? combineDateWithTime(priceRequest.latestPickupDate, priceRequest.latestPickupTime ?? '')
    : null;
  const earliestDeliveryDate = priceRequest.earliestArrivalDate
    ? combineDateWithTime(priceRequest.earliestArrivalDate, priceRequest.earliestArrivalTime ?? '')
    : null;
  const latestDeliveryDate = priceRequest.latestArrivalDate
    ? combineDateWithTime(priceRequest.latestArrivalDate, priceRequest.latestArrivalTime ?? '')
    : null;

  const earliestDateFormatted = earliestDate ? generateDate(earliestDate, 'dd.MM.yyyy HH:mm') : '–';
  const latestDateFormatted = latestDate ? generateDate(latestDate, 'dd.MM.yyyy HH:mm') : '–';
  const earliestDeliveryDateFormatted = earliestDeliveryDate
    ? generateDate(earliestDeliveryDate, 'dd.MM.yyyy HH:mm')
    : '–';
  const latestDeliveryDateFormatted = latestDeliveryDate
    ? generateDate(latestDeliveryDate, 'dd.MM.yyyy HH:mm')
    : '–';

  const formatAddress = (address: LoadingContact | null | undefined): JSX.Element => {
    if (!address) {
      return <>–</>;
    }
    return (
      <Box display="flex" alignItems="center">
        <FlagComponent flag={address.country ?? ''} />
        <Typography
          variant="body2"
          fontWeight="semibold"
          marginLeft={2}
          className={classes.dateLabel}
        >
          {address.companyName ? `${address.companyName} ` : ''}
          {address.addressLine1}, {buildAddress(address)}
        </Typography>
      </Box>
    );
  };
  const formatAddressContact = (address: LoadingContact | null | undefined): JSX.Element => {
    if (!address) {
      return <>–</>;
    }
    return (
      <Box display="flex" alignItems="center">
        {address.contactPersonName && (
          <Typography variant="body2" fontWeight="semibold" marginRight={2} dashWhenEmpty>
            {address.contactPersonName}
          </Typography>
        )}
        {address.email && (
          <Typography variant="body2" marginRight={2} dashWhenEmpty>
            {address.email}
          </Typography>
        )}
        {address.phone && (
          <Typography variant="body2" marginRight={2} dashWhenEmpty>
            {address.phone}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Dialog
      {...restProps}
      title={t('SHIPMENT.PLEASE_CHECK_YOUR_SHIPMENT_INFORMATION_BEFORE_CONFIRMING')}
      maxWidth="lg"
      actionAcceptLabel={t('PRICE_REQUEST.CONFIRM_PRICE_REQUEST')}
      showGoBack
    >
      <Typography variant="h6">{t('PICKUP_FROM')}</Typography>
      <Box>
        <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
          <PickupIcon height={24} width={24} />
          <Typography variant="body2" marginLeft={2}>
            {t('PICKUP_ADDRESS')}:
          </Typography>
          <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
            {formatAddress(
              priceRequest.useReceiverAlternative
                ? priceRequest.senderAlternative
                : priceRequest.sender,
            )}
          </Typography>
        </Typography>
        <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
          <FaPhoneAlt />
          <Typography variant="body2" marginLeft={2}>
            {t('PICKUP_CONTACT')}:
          </Typography>
          <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
            {formatAddressContact(
              priceRequest.useReceiverAlternative
                ? priceRequest.senderAlternative
                : priceRequest.sender,
            )}
          </Typography>
        </Typography>
        <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
          <CalendarIcon height={18} width={18} />
          <Typography variant="body2" marginLeft={2}>
            {!priceRequest.isFixedPickupDate && t('EARLIEST_PICKUP')}
            {priceRequest.isFixedPickupDate && t('FIXED_PICKUP')}:
          </Typography>
          <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
            {earliestDateFormatted}
          </Typography>
        </Typography>
        {!priceRequest.isFixedPickupDate && (
          <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
            <CalendarIcon height={18} width={18} />
            <Typography variant="body2" marginLeft={2}>
              {!priceRequest.isFixedPickupDate && t('LATEST_PICKUP')}:
            </Typography>
            <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
              {latestDateFormatted}
            </Typography>
          </Typography>
        )}
      </Box>
      <Separator my={4} />
      <Typography variant="h6">{t('DELIVERY_TO')}</Typography>
      <Box>
        <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
          <DeliveryIcon height={24} width={24} />
          <Typography variant="body2" marginLeft={2}>
            {t('DELIVERY_ADDRESS')}:
          </Typography>
          <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
            {formatAddress(
              priceRequest.useReceiverAlternative
                ? priceRequest.receiverAlternative
                : priceRequest.receiver,
            )}
          </Typography>
        </Typography>
        <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
          <FaPhoneAlt />
          <Typography variant="body2" marginLeft={2}>
            {t('DELIVERY_CONTACT')}:
          </Typography>
          <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
            {formatAddressContact(
              priceRequest.useReceiverAlternative
                ? priceRequest.receiverAlternative
                : priceRequest.receiver,
            )}
          </Typography>
        </Typography>
        <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
          <CalendarIcon height={18} width={18} />
          <Typography variant="body2" marginLeft={2}>
            {!priceRequest.isFixedArrivalDate && t('EARLIEST_DELIVERY')}
            {priceRequest.isFixedArrivalDate && t('FIXED_DELIVERY')}:
          </Typography>
          <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
            {earliestDeliveryDateFormatted}
          </Typography>
        </Typography>
        {!priceRequest.isFixedArrivalDate && (
          <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
            <CalendarIcon height={18} width={18} />
            <Typography variant="body2" marginLeft={2}>
              {!priceRequest.isFixedArrivalDate && t('LATEST_DELIVERY')}:
            </Typography>
            <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
              {latestDeliveryDateFormatted}
            </Typography>
          </Typography>
        )}
      </Box>
      <Separator my={4} />
      <Typography variant="h6">
        {t('ITEMS_IN_THE_SHIPMENT')}
        {priceRequest.tailLiftTruck && (
          <Chip
            size="small"
            classes={nativeClasses}
            key={generateKey(6, `tailLiftTruckNeeded_`)}
            label={t('SHIPMENT.TRANSPORT_WITH_TAIL_LIFT_TRUCK')}
          />
        )}
        {priceRequest.tailLiftTruckDelivery && (
          <Chip
            size="small"
            classes={nativeClasses}
            key={generateKey(6, `tailLiftTruckNeeded_`)}
            label={t('SHIPMENT.TRANSPORT_WITH_TAIL_LIFT_TRUCK_DELIVERY')}
          />
        )}
      </Typography>
      <ShipmentItems
        hideTitle
        tailLiftTruckNeeded={priceRequest.tailLiftTruck ?? false}
        tailLiftTruckDeliveryNeeded={priceRequest.tailLiftTruckDelivery ?? false}
        shipmentDetailsRows={priceRequest.shipmentDetailsRows}
        boxProps={{ marginTop: 3 }}
        readOnly
        smallPadding
      />
      {priceRequest.otherInstructions && (
        <ShipmentItemsComments
          comments={priceRequest.otherInstructions}
          className={commonClasses.mt6}
          bigHeading
        />
      )}
      {!!priceRequest.noticeEmails && (
        <>
          <Typography variant="subtitle2" marginTop={4} marginBottom={4} fontWeight="semibold">
            {t('PRICE_REQUEST.EMAIL_WHERE_WE_WILL_SEND_THE_OFFER')}
          </Typography>
          <Typography variant="body2" marginTop={4}>
            {priceRequest.noticeEmails}
          </Typography>
        </>
      )}
      {!!priceRequest.regularShipments && (
        <Box marginTop={4}>
          <Checkbox
            variant="lightAlt"
            checked={!!priceRequest.regularShipments}
            label={t('PRICE_REQUEST.IM_LOOKING_FOR_PRICES_FOR_REGULAR_SHIPMENTS')}
            disabled
          />
          <Box marginTop={1.5} marginLeft={8}>
            <Typography variant="caption" component="p" marginTop={1.5}>
              {t('PRICE_REQUEST.TIMES_AND_PERIOD')}
            </Typography>
            <Typography variant="caption" component="p" fontWeight="semibold" marginTop={1}>
              {`${priceRequest.regularShipmentsNumber} ${t('PRICE_REQUEST.TIMES')} ${t(
                'PRICE_REQUEST.PER',
              )} ${t(
                regularShipmentPeriodLabels[
                  priceRequest.regularShipmentsPeriod ?? RegularShipmentPeriod.NotSelected
                ],
              )}`}
            </Typography>
          </Box>
        </Box>
      )}
    </Dialog>
  );
};

export default PriceRequestConfirmationDialog;
