import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const InfoIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 14,
  height = 14,
  fill = theme.palette.custom.veryDarkViolet,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M7 13.1621C5.1862 13.1165 3.67318 12.4922 2.46094 11.2891C1.25781 10.0768 0.633464 8.5638 0.587891 6.75C0.633464 4.9362 1.25781 3.42773 2.46094 2.22461C3.67318 1.01237 5.1862 0.383464 7 0.337891C8.8138 0.383464 10.3223 1.01237 11.5254 2.22461C12.7376 3.42773 13.3665 4.9362 13.4121 6.75C13.3665 8.5638 12.7376 10.0768 11.5254 11.2891C10.3223 12.4922 8.8138 13.1165 7 13.1621ZM7 12C8.48568 11.9635 9.7207 11.4531 10.7051 10.4688C11.6986 9.47526 12.2135 8.23568 12.25 6.75C12.2135 5.26432 11.6986 4.0293 10.7051 3.04492C9.7207 2.05143 8.48568 1.53646 7 1.5C5.51432 1.53646 4.27474 2.05143 3.28125 3.04492C2.29687 4.0293 1.78646 5.26432 1.75 6.75C1.78646 8.23568 2.29687 9.47526 3.28125 10.4688C4.27474 11.4531 5.51432 11.9635 7 12ZM7.58789 9.08789L7.58789 6.75C7.57878 6.58594 7.51953 6.44922 7.41016 6.33984C7.30078 6.23047 7.16406 6.17578 7 6.17578C6.83594 6.17578 6.69922 6.23047 6.58984 6.33984C6.48047 6.44922 6.42122 6.58594 6.41211 6.75L6.41211 9.08789C6.42122 9.25195 6.48047 9.38867 6.58984 9.49805C6.69922 9.60742 6.83594 9.66211 7 9.66211C7.16406 9.66211 7.30078 9.60742 7.41016 9.49805C7.51953 9.38867 7.57878 9.25195 7.58789 9.08789ZM6.41211 4.41211C6.41211 4.52148 6.43945 4.62174 6.49414 4.71289C6.54883 4.79492 6.62174 4.86328 6.71289 4.91797C6.80404 4.97266 6.89974 5 7 5C7.10026 5 7.19596 4.97266 7.28711 4.91797C7.37826 4.86328 7.45117 4.79492 7.50586 4.71289C7.56055 4.62174 7.58789 4.52604 7.58789 4.42578C7.58789 4.31641 7.56055 4.21615 7.50586 4.125C7.45117 4.03385 7.37826 3.96549 7.28711 3.91992C7.19596 3.86523 7.10026 3.83789 7 3.83789C6.89974 3.83789 6.80404 3.86523 6.71289 3.91992C6.62174 3.96549 6.54883 4.03385 6.49414 4.125C6.43945 4.21615 6.41211 4.31185 6.41211 4.41211Z"
        fill={fill}
      />
    </svg>
  );
};

export default InfoIcon;
