import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const EnvelopeIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 20,
  height = 32,
  fill = theme.palette.custom.darkCyan,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 488 488"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <g>
        <g>
          <path
            d="M487.9,87.85c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2,0-0.4s-0.1-0.4-0.1-0.6c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.1-0.5
			c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.2-0.4-0.3-0.5
			c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.3-0.5c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.2-0.2-0.3-0.3s-0.3-0.2-0.4-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.2
			c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.3-0.1-0.5-0.1s-0.3-0.1-0.5-0.1
			s-0.3,0-0.5-0.1c-0.2,0-0.4,0-0.6,0c-0.1,0-0.2,0-0.3,0H9.9c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.5,0c-0.2,0-0.3,0-0.5,0.1
			c-0.2,0-0.3,0-0.5,0.1c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.1-0.4,0.1-0.5,0.2c-0.1,0-0.3,0.1-0.4,0.1
			c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.4,0.2
			c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.4
			c-0.1,0.1-0.2,0.3-0.4,0.5c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0,0.1-0.1,0.1c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.2-0.2,0.3
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.3-0.1,0.4
			c0,0.2-0.1,0.3-0.1,0.5c0,0.1,0,0.3-0.1,0.4c0,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.2,0,0.4s0,0.4,0,0.6v0.1v312c0,5.5,4.5,10,10,10h468
			c5.5,0,10-4.5,10-10v-312C487.9,87.95,487.9,87.85,487.9,87.85z M446.7,97.95l-202.8,144.6L41.1,97.95H446.7z M467.9,389.95h-448
			v-282.6l218.2,155.6c1.7,1.2,3.8,1.9,5.8,1.9s4.1-0.6,5.8-1.9l218.2-155.6V389.95z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};

export default EnvelopeIcon;
