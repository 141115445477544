import React, { ReactElement, FC, useEffect, useState, useContext } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { theme } from 'theme';

import { Labeled } from 'components';
import { emptyAddressData } from 'shared/constants/shipments/shipmentAddress';
import { useFormikError } from 'shared/hooks/useFormikError';
import { emptyPlacePrediction, PlacePrediction, usePlaces } from 'shared/services/places';
import { LoadingContact } from 'shared/types/loadingContact';
import { NewShipmentPayload } from 'shared/types/shipments/shipments';
import { TextFieldSlowFormik } from 'components/CustomMaterial/TextField/TextField';
import { AddressAutocompleteContext } from './ShipmentAddressEditable';

type AddressLine1OrLookupFieldsProps = {
  name: string;
  typeTranslation: string;
  isAlternative?: boolean;
  isTemplate?: boolean;
};

const useStyles = makeStyles(() => ({
  autocompleteListbox: {
    maxHeight: 'unset',
    '& li:last-of-type': {
      '& .MuiAutocomplete-groupUl': {
        position: 'relative',
        marginBottom: theme.spacing(4),
        '&:after': {
          position: 'absolute',
          content: "url('/images/powered-by-google-small.png')",
          bottom: theme.spacing(-5),
          left: theme.spacing(3),
        },
      },
    },
    '& .MuiListSubheader-sticky': {
      position: 'static',
    },
  },
}));

const AddressLine1OrLookup: FC<AddressLine1OrLookupFieldsProps> = ({
  name,
  typeTranslation,
  isAlternative = false,
  isTemplate = false,
}): ReactElement | null => {
  const { t } = useTranslation();
  const formik = useFormikContext<NewShipmentPayload>();
  const classes = useStyles();

  const onAddressAutocomplete = useContext(AddressAutocompleteContext);

  const isError = useFormikError();

  const addressData = formik.values[name as keyof NewShipmentPayload] as LoadingContact;

  const placePredictionFromAddressString = (addressStr?: string): PlacePrediction | null => {
    return addressStr
      ? {
          ...emptyPlacePrediction,
          description: addressStr,
        }
      : null;
  };

  const [value, setValue] = React.useState<PlacePrediction | null>(
    placePredictionFromAddressString(addressData.addressLine1),
  );
  const [inputValue, setInputValue] = React.useState('');

  const [debouncedInput] = useDebounce(inputValue, 500);

  const { fetchLoadingContactByPlaceId, fetchLoadingAddressSuggestions } = usePlaces();
  const [autoCompleteChoices, setAutoCompleteChoices] = useState<PlacePrediction[]>([]);

  const fieldName = `${name}.addressLine1`;

  useEffect(() => {
    if (!inputValue) {
      setAutoCompleteChoices([]);
    } else {
      fetchLoadingAddressSuggestions(inputValue)
        .then((result) => setAutoCompleteChoices(result.sort((a, b) => a.source - b.source)))
        .catch(() => setAutoCompleteChoices([]));
    }
  }, [debouncedInput]);

  const onAutoCompleteSelected = (choice: LoadingContact) => {
    if (choice) {
      formik.setFieldValue(name, {
        ...emptyAddressData.address,
        ...choice,
      });

      const moreInformationFieldsFilled =
        choice.companyName ||
        choice.contactPersonName ||
        choice.phone ||
        choice.email ||
        choice.reference ||
        choice.notes;

      if (onAddressAutocomplete && moreInformationFieldsFilled) {
        onAddressAutocomplete();
      }
    }
  };

  useEffect(() => {
    setValue(placePredictionFromAddressString(addressData?.addressLine1));
  }, [addressData]);

  useEffect(() => {
    if (value && value.placeId) {
      fetchLoadingContactByPlaceId(value).then((place) => {
        if (place) {
          onAutoCompleteSelected(place);
        }
      });
    }
  }, [value]);

  return (
    <Labeled
      error={isError(fieldName)}
      variant="variant2"
      text={t(
        !isAlternative
          ? `SHIPMENT.ADDRESSES.${typeTranslation}.ADDRESS`
          : 'SHIPMENT.ADDRESSES.ADDRESS',
      )}
      required={!isTemplate}
    >
      <Autocomplete
        freeSolo
        getOptionLabel={(option: PlacePrediction | string) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={autoCompleteChoices}
        groupBy={(option) => option.sourceTranslation}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(_, newValue: PlacePrediction | string | null) => {
          if (typeof newValue === 'string') {
            // User hasn't chosen any suggested options
            formik.setFieldValue(fieldName, newValue);
          } else {
            // User has chosen one of the suggested options
            setValue(newValue);
          }
        }}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextFieldSlowFormik
            {...params}
            inputProps={{ ...params.inputProps, autoComplete: 'custom-autocomplete' }}
            InputProps={{ ...params.InputProps, endAdornment: undefined, className: undefined }}
            fullWidth
            name={fieldName}
            error={isError(fieldName)}
            size="small"
            variant="variant6"
            placeholder={t(
              'SHIPMENT.ADDRESSES.TYPE_HERE_THE_ADDRESS_AND_SELECT_IT_FROM_GOOGLE_RESULTS_OR_FROM_ADDRESS_BOOK',
            )}
            showErrorLabel
          />
        )}
        renderOption={(option) => option.description}
        classes={{
          listbox: classes.autocompleteListbox,
        }}
      />
    </Labeled>
  );
};

export default AddressLine1OrLookup;
