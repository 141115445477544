import { UserData } from 'shared/hooks/useUser';
import { UserAction, UserActionType } from '../constants/user/userRights';

export const hasPermission = (
  user: UserData,
  action: UserAction,
  actionType: UserActionType,
): boolean => {
  const isAdmin = user.profile?.roles?.includes(40);
  if (isAdmin) return true;

  if (user?.profile?.rights && user.profile.rights.length > 0) {
    const actionTypeIndex = user.profile.rights.find((e) => e.label.toLowerCase() === action);
    try {
      if (actionTypeIndex && actionTypeIndex.value) {
        const actionInt = parseInt(actionTypeIndex.value, 10);
        if (actionInt >= actionType) return true;
      }
    } catch {
      return false;
    }
  }
  return false;
};
