import React, { ReactElement, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { DropdownIcon, Typography, Accordion, AccordionSummary } from 'components';
import { theme } from 'theme';

import { BaseProps } from 'shared/types';
import { LoadingContact } from 'shared/types/loadingContact';
import { buildAddress } from 'shared/functions/buildAddress';
import { ShipmentAddressType } from 'shared/types/shipments/shipments';
import { useCommonStyles } from 'shared/styles/common';

type ShipmentAddressAccordionProps = {
  type: ShipmentAddressType;
  address: LoadingContact;
  onDelete?: () => void;
  onEdit?: () => void;
} & BaseProps;

const ShipmentAddressAccordion: FC<ShipmentAddressAccordionProps> = ({
  address,
  type,
  onDelete,
  onEdit,
  ...restProps
}): ReactElement => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();

  const translationType = type === 'receiver' ? 'RECEIVER' : 'SENDER';

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  const transitionProps = { timeout: 200 };
  const iconButtonProps = { onClick: toggleExpanded };

  return (
    <Accordion
      expanded={expanded}
      TransitionProps={transitionProps}
      borderRadius={8}
      borderWidth={0}
      backgroundColor={theme.palette.custom.lightGrayishViolet}
      backgroundColorExpanded={theme.palette.custom.lightGrayishVioletAlt}
      {...restProps}
    >
      <AccordionSummary
        expandIcon={<DropdownIcon variant="gray" width={15} height={14} />}
        IconButtonProps={iconButtonProps}
        onDelete={onDelete}
        onEdit={onEdit}
        variant="variant2"
      >
        <Typography variant="body2" fontWeight="bold">
          {t(`SHIPMENT.ADDRESSES.${translationType}.ALTERNATIVE_ADDRESS`)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box px={6} pb={12}>
          <Typography variant="body2" fontWeight="bold" marginTop={4} marginBottom={2}>
            {t(`SHIPMENT.ADDRESSES.${translationType}.ALTERNATIVE_ADDRESS`)}
          </Typography>
          <Typography variant="body2" component="p" marginBottom={2} dashWhenEmpty>
            {address.companyName}
          </Typography>
          <Typography variant="body2" component="p" marginBottom={2} dashWhenEmpty>
            {address.addressLine1}
          </Typography>
          <Typography variant="body2" component="p" marginBottom={8} dashWhenEmpty>
            {buildAddress(address)}
          </Typography>

          <Typography variant="body2" fontWeight="bold" marginBottom={2}>
            {t(`SHIPMENT.ADDRESSES.${translationType}.ALTERNATIVE_CONTACT_PERSON`)}
          </Typography>
          <Typography variant="body2" component="p" marginBottom={2} dashWhenEmpty>
            {address.contactPersonName}
          </Typography>
          <Typography variant="body2" component="p" marginBottom={2} dashWhenEmpty>
            {address.email}
          </Typography>
          <Typography variant="body2" component="p" marginBottom={8} dashWhenEmpty>
            {address.phone}
          </Typography>

          <Typography variant="body2" fontWeight="bold" marginBottom={2}>
            {t(`SHIPMENT.ADDRESSES.${translationType}.ALTERNATIVE_ADDRESS_DETAILS`)}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={commonClasses.wordBreak}
            dashWhenEmpty
          >
            {address.notes}
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ShipmentAddressAccordion;
