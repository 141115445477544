import React, { ReactElement, FC, useContext, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { TextFieldFormik, Labeled, SwitchFormik, AutocompleteSelectFormik } from 'components';
import { theme } from 'theme';

import { simplePhoneRegExp, nameRegExp, emailRegExp, cityRegExp } from 'shared/constants/regExp';
import { useFormikError } from 'shared/hooks/useFormikError';
import { NewShipmentPayload, ShipmentAddressType } from 'shared/types/shipments/shipments';
import { LoadingContact } from 'shared/types/loadingContact';
import { GlobalDataContext } from 'app/AuthenticatedApp';
import AddressLine1OrLookup from './AddressLine1OrLookup';

type ShipmentAddressFieldsProps = {
  name: 'sender' | 'senderAlternative' | 'receiver' | 'receiverAlternative';
  type: ShipmentAddressType;
  typeTranslation: string;
  isAlternative?: boolean;
  showMoreInformation?: boolean;
  isPriceRequest?: boolean;
  isTemplate?: boolean;
};

const ShipmentAddressFields: FC<ShipmentAddressFieldsProps> = ({
  name,
  type,
  typeTranslation,
  isAlternative = false,
  showMoreInformation = true,
  isPriceRequest = false,
  isTemplate = false,
}): ReactElement | null => {
  const { t } = useTranslation();
  const { countries } = useContext(GlobalDataContext);
  const formik = useFormikContext<NewShipmentPayload>();

  const [saveAddressChecked, setSaveAddressChecked] = useState(false);

  const address: LoadingContact | null | undefined = formik.values[name];

  const isError = useFormikError();

  const onSaveAddressChanged = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (saveAddressChecked !== checked) {
      setSaveAddressChecked(checked);
    }
    formik.setFieldValue(`${name}.updateContact`, checked);
  };

  if (!address) {
    return null;
  }

  return (
    <>
      <AddressLine1OrLookup
        name={name}
        typeTranslation={typeTranslation}
        isAlternative={isAlternative}
        isTemplate={isTemplate}
      />

      <Box display="flex" marginTop={3.75}>
        <Labeled
          variant="variant2"
          text={t('SHIPMENT.ADDRESSES.COUNTRY')}
          error={isError(`${name}.country`)}
          required={!isTemplate}
          marginRight={6}
          width="100%"
        >
          <AutocompleteSelectFormik
            name={`${name}.country`}
            error={isError(`${name}.country`)}
            borderColor={theme.palette.custom.lightGray}
            background="opaque"
            fullWidth
            displayEmpty
            defaultValue=""
            defaultLabel={t('SELECT_COUNTRY')}
            data={countries}
            value={address.country || ''}
            showErrorLabel
          />
        </Labeled>

        <Labeled
          variant="variant2"
          text={t('SHIPMENT.ADDRESSES.CITY')}
          error={isError(`${name}.city`)}
          required={!isTemplate}
          width="100%"
        >
          <TextFieldFormik
            name={`${name}.city`}
            error={isError(`${name}.city`)}
            size="small"
            variant="variant6"
            fullWidth
            showErrorLabel
            debounced
            allowedChars={cityRegExp}
          />
        </Labeled>
      </Box>

      <Box display="flex" marginTop={3.75}>
        <Box width="50%" marginRight={6}>
          <Labeled
            variant="variant2"
            text={t('SHIPMENT.ADDRESSES.POSTCODE')}
            error={isError(`${name}.postCode`)}
            required={!isTemplate}
            width="100%"
          >
            <TextFieldFormik
              name={`${name}.postCode`}
              error={isError(`${name}.postCode`)}
              size="small"
              variant="variant6"
              fullWidth
              showErrorLabel
              debounced
            />
          </Labeled>
        </Box>

        <Box width="50%">
          {showMoreInformation && (
            <Labeled
              variant="variant2"
              text={t('SHIPMENT.ADDRESSES.COMPANY_NAME')}
              error={isError(`${name}.companyName`)}
              required={!isPriceRequest && !isTemplate}
              width="100%"
            >
              <TextFieldFormik
                name={`${name}.companyName`}
                error={isError(`${name}.companyName`)}
                size="small"
                variant="variant6"
                fullWidth
                showErrorLabel
                debounced
              />
            </Labeled>
          )}
        </Box>
      </Box>

      {showMoreInformation && (
        <>
          <Box display="flex" marginTop={3.75}>
            <Labeled
              variant="variant2"
              text={t('SHIPMENT.ADDRESSES.CONTACT_PERSON')}
              error={isError(`${name}.contactPersonName`)}
              required={!isPriceRequest && !isTemplate}
              marginRight={6}
              width="100%"
            >
              <TextFieldFormik
                name={`${name}.contactPersonName`}
                error={isError(`${name}.contactPersonName`)}
                size="small"
                variant="variant6"
                fullWidth
                showErrorLabel
                debounced
                allowedChars={nameRegExp}
              />
            </Labeled>

            <Labeled
              variant="variant2"
              text={t('SHIPMENT.ADDRESSES.CONTACT_NUMBER')}
              error={isError(`${name}.phone`)}
              required={!isPriceRequest && !isTemplate}
              width="100%"
            >
              <TextFieldFormik
                name={`${name}.phone`}
                error={isError(`${name}.phone`)}
                size="small"
                variant="variant6"
                fullWidth
                placeholder="+372 55555555"
                showErrorLabel
                debounced
                allowedChars={simplePhoneRegExp}
              />
            </Labeled>
          </Box>

          <Box display="flex" marginY={3.75}>
            <Labeled
              variant="variant2"
              text={t('SHIPMENT.ADDRESSES.EMAIL')}
              error={isError(`${name}.email`)}
              width="100%"
              required={!isPriceRequest && !isTemplate}
            >
              <TextFieldFormik
                name={`${name}.email`}
                error={isError(`${name}.email`)}
                size="small"
                variant="variant6"
                fullWidth
                showErrorLabel
                debounced
                allowedChars={emailRegExp}
              />
            </Labeled>

            {!isAlternative && (
              <Labeled
                variant="variant2"
                text={t(
                  type === 'sender'
                    ? 'SHIPMENT.ADDRESSES.LOADING_REFERENCE'
                    : 'SHIPMENT.ADDRESSES.UNLOADING_REFERENCE',
                )}
                error={isError(`${name}.reference`)}
                tooltip={t(
                  type === 'sender'
                    ? 'SHIPMENT.ADDRESSES.LOADING_REFERENCE_TOOLTIP'
                    : 'SHIPMENT.ADDRESSES.UNLOADING_REFERENCE_TOOLTIP',
                )}
                marginLeft={6}
                width="100%"
              >
                <TextFieldFormik
                  name={`${name}.reference`}
                  error={isError(`${name}.reference`)}
                  size="small"
                  variant="variant6"
                  fullWidth
                  showErrorLabel
                  debounced
                />
              </Labeled>
            )}
          </Box>

          <Labeled
            variant="variant2"
            error={isError(`${name}.notes`)}
            text={t(
              !isAlternative
                ? `SHIPMENT.ADDRESSES.${typeTranslation}.ADDRESS_DETAILS`
                : 'SHIPMENT.ADDRESSES.ADDRESS_DETAILS',
            )}
            tooltip={t(`SHIPMENT.ADDRESSES.${typeTranslation}.ADDRESS_DETAILS`)}
          >
            <TextFieldFormik
              name={`${name}.notes`}
              error={isError(`${name}.notes`)}
              variant="variant6"
              size="small"
              multiline
              fullWidth
              rows={5}
              showErrorLabel
              debounced
            />
          </Labeled>

          {!isPriceRequest && !isTemplate && (
            <Box display="flex" marginTop={5.5} marginBottom={2.75} height={48} alignItems="center">
              <Box width={280} marginRight={6}>
                <SwitchFormik
                  name={`${name}.saveAddress`}
                  label={t('SHIPMENT.ADDRESSES.SAVE_THIS_ADDRESS')}
                  checked={saveAddressChecked || false}
                  onChange={onSaveAddressChanged}
                />
              </Box>
              {saveAddressChecked && (
                <Labeled
                  variant="variant2"
                  text={t(`SHIPMENT.ADDRESSES.NAME_THIS_ADDRESS`)}
                  error={isError(`${name}.addressName`)}
                  required={!isTemplate}
                  width="100%"
                >
                  <TextFieldFormik
                    name={`${name}.addressName`}
                    error={isError(`${name}.addressName`)}
                    size="small"
                    variant="variant6"
                    fullWidth
                    showErrorLabel
                    debounced
                  />
                </Labeled>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ShipmentAddressFields;
