import React, { ReactElement, FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Labeled, TextFieldFormik, Typography } from 'components';

import { BaseProps } from 'shared/types';
import { useFormikContext } from 'formik';
import { NewShipmentPayload } from 'shared/types/shipments/shipments';

type ShipmentReferenceNumberStepProps = BaseProps;

export type ShipmentReferenceData = {
  notifyAboutShipment?: boolean;
  saveShipmentAsTemplate?: boolean;
};

const ShipmentReferenceNumberStep: FC<ShipmentReferenceNumberStepProps> = (): ReactElement => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [referenceData, setReferenceData] = useState<ShipmentReferenceData>({});

  const { saveShipmentAsTemplate } = referenceData;

  const formik = useFormikContext<NewShipmentPayload>();

  // TODO: Uncomment when saving as template is redesigned, current implementation confuses customers
  // const formValueChanged = (value: unknown, name: string) =>
  //   setReferenceData((currentReferenceData) => ({
  //     ...currentReferenceData,
  //     [name]: value,
  //   }));

  useEffect(() => {
    formik.setFieldValue('templateName', saveShipmentAsTemplate ? '' : null);
  }, [saveShipmentAsTemplate]);

  return (
    <Box>
      <Typography variant="h5" marginBottom={4.25}>
        {t('SHIPMENT.ADD_REFERENCE_NUMBER')}
      </Typography>

      <Labeled
        variant="variant2"
        error={!!formik.errors.reference}
        text={t('SHIPMENT.REFERENCE_NUMBER')}
        tooltip={t('SHIPMENT.REFERENCE_NUMBER_TOOLTIP')}
      >
        <TextFieldFormik
          name="reference"
          error={!!formik.errors.reference}
          size="small"
          variant="variant6"
          width={342}
          debounced
        />
      </Labeled>

      <Box width="100%" marginTop={10} position="relative">
        {/* TODO: Uncomment when the feature is available.
        <Box marginBottom={3}>
          <Checkbox
            variant="lightAlt"
            onChange={(event) => formValueChanged(event.target.checked, 'notifyAboutShipment')}
            checked={notifyAboutShipment}
            label={t('SHIPMENT.NOTIFY_ME_ABOUT_THIS_SHIPMENT')}
            tooltip={t('SHIPMENT.NOTIFY_ME_ABOUT_THIS_SHIPMENT')}
          />
        </Box> */}
        {/* TODO: Uncomment when saving as template is redesigned, current implementation confuses customers */}
        {/* <Checkbox
          variant="lightAlt"
          onChange={(event) => formValueChanged(event.target.checked, 'saveShipmentAsTemplate')}
          checked={saveShipmentAsTemplate ?? false}
          label={t('SHIPMENT.SAVE_THIS_SHIPMENT_AS_TEMPLATE')}
          tooltip={t('SHIPMENT.SAVE_THIS_SHIPMENT_AS_TEMPLATE_TOOLTIP')}
        />
        {saveShipmentAsTemplate && (
          <Box marginTop={2} marginLeft={8}>
            <Labeled
              variant="variant2"
              error={!!formik.errors.templateName}
              text={t('SHIPMENT.NAME_THIS_SHIPMENT_TEMPLATE')}
              required
            >
              <TextFieldFormik
                name="templateName"
                error={!!formik.errors.templateName}
                size="small"
                variant="variant6"
                width={310}
                debounced
              />
            </Labeled>
          </Box>
        )} */}
      </Box>
    </Box>
  );
};

export default ShipmentReferenceNumberStep;
