import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const AddressBookIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 20,
  height = 19,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 13"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path d="M1.75 2.08789L1.75 9.66211H5.25C5.67839 9.67122 6.06576 9.77604 6.41211 9.97656L6.41211 3.83789C6.40299 3.33659 6.23437 2.92188 5.90625 2.59375C5.57812 2.26563 5.16341 2.09701 4.66211 2.08789L1.75 2.08789ZM7 12.5879C6.84505 12.5879 6.70833 12.5378 6.58984 12.4375C6.48047 12.3372 6.42122 12.1914 6.41211 12C6.40299 11.6719 6.28906 11.3984 6.07031 11.1797C5.85156 10.9609 5.57812 10.847 5.25 10.8379H1.16211C0.998047 10.8288 0.861328 10.7695 0.751953 10.6602C0.642578 10.5508 0.587891 10.4141 0.587891 10.25L0.587891 1.5C0.587891 1.33594 0.642578 1.19922 0.751953 1.08984C0.861328 0.980469 0.998047 0.921224 1.16211 0.912109L4.66211 0.912109C5.14518 0.921224 5.58724 1.0306 5.98828 1.24023C6.38932 1.44076 6.72656 1.72331 7 2.08789C7.28255 1.71419 7.62435 1.42708 8.02539 1.22656C8.43555 1.02604 8.87305 0.921224 9.33789 0.912109L12.8379 0.912109C13.002 0.921224 13.1387 0.980469 13.248 1.08984C13.3574 1.19922 13.4121 1.33594 13.4121 1.5L13.4121 10.25C13.4121 10.4141 13.3574 10.5508 13.248 10.6602C13.1387 10.7695 13.002 10.8288 12.8379 10.8379H8.75C8.43099 10.8379 8.15755 10.9518 7.92969 11.1797C7.70182 11.4076 7.58789 11.681 7.58789 12C7.57878 12.1914 7.51497 12.3372 7.39648 12.4375C7.28711 12.5378 7.15495 12.5879 7 12.5879C6.84505 12.5879 6.70833 12.5378 6.58984 12.4375C6.48047 12.3372 6.42122 12.1914 6.41211 12C6.42122 11.5625 6.5306 11.1706 6.74023 10.8242C6.94987 10.4688 7.23242 10.1862 7.58789 9.97656V12C7.57878 12.1914 7.51497 12.3372 7.39648 12.4375C7.28711 12.5378 7.15495 12.5879 7 12.5879ZM12.25 2.08789L9.33789 2.08789C8.83659 2.09701 8.42188 2.26563 8.09375 2.59375C7.76562 2.92188 7.59701 3.33659 7.58789 3.83789L7.58789 9.97656C7.94336 9.76693 8.33073 9.66211 8.75 9.66211H12.25L12.25 2.08789Z" />
    </svg>
  );
};

export default AddressBookIcon;
