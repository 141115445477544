import qs from 'qs';
import { useFetchApi } from 'shared/functions/fetchApi';
import {
  ShipmentsApiRequest,
  ShipmentApiRequest,
  ShipmentsPayload,
  ShipmentPayload,
  ShipmentLabelsRequest,
  ShipmentEvent,
  ShipmentPrice,
  SaveShipmentApiRequest,
  ShipmentCommentsPayload,
  ShipmentCommentsApiRequest,
  ShipmentUpdateCommentApiRequest,
  ShipmentsExcelImportPayload,
  ShipmentTrackingApiRequest,
  UnSavedShipmentApiRequest,
} from 'shared/types/shipments/shipments';
import { UserUnreadCommentsState } from 'shared/types/comments';
import { shipmentParser } from 'shared/parsers/shipmentParser';
import { ApiBasePropsWithId, ApiResponse } from 'shared/types/api';
import { downloadFile } from './fileService';
import { FilesUploadRequest } from '../types/files';

type UseShipmentsReturnType = {
  fetchShipments: (request: ShipmentsApiRequest) => Promise<ApiResponse<ShipmentsPayload>>;
  fetchShipment: (request: ShipmentApiRequest) => Promise<ApiResponse<ShipmentPayload>>;
  fetchShipmentByTrackingCode: (
    request: ShipmentTrackingApiRequest,
  ) => Promise<ApiResponse<ShipmentPayload>>;
  fetchShipmentLabels: (request: ShipmentLabelsRequest) => Promise<string | undefined>;
  fetchShipmentCmr: (request: ShipmentLabelsRequest) => Promise<string | undefined>;
  downloadExcel: (request: ShipmentsApiRequest) => Promise<string | undefined>;
  fetchShipmentEvents: (request: ShipmentApiRequest) => Promise<ApiResponse<ShipmentEvent[]>>;
  fetchShipmentPrice: (request: ShipmentApiRequest) => Promise<ApiResponse<ShipmentPrice>>;
  saveShipment: (request: SaveShipmentApiRequest) => Promise<ApiResponse<number>>;
  deleteDraft: (request: ShipmentApiRequest) => Promise<ApiResponse<boolean>>;
  toggleAsFavorite: (request: ShipmentApiRequest) => Promise<ApiResponse<boolean>>;
  fetchShipmentCommentList: () => Promise<ApiResponse<UserUnreadCommentsState>>;
  fetchShipmentComments: (
    request: ShipmentCommentsApiRequest,
  ) => Promise<ApiResponse<ShipmentCommentsPayload>>;
  updateShipmentComment: (request: ShipmentUpdateCommentApiRequest) => Promise<ApiResponse<number>>;
  saveShipmentComment: (request: ShipmentCommentsApiRequest) => Promise<ApiResponse<number>>;
  importShipments: (
    request: FilesUploadRequest,
  ) => Promise<ApiResponse<ShipmentsExcelImportPayload>>;
  importFromWithHeldExcelFile: (
    request: ApiBasePropsWithId,
  ) => Promise<ApiResponse<ShipmentsExcelImportPayload>>;
  saveUnSavedShipment: (request: UnSavedShipmentApiRequest) => Promise<ApiResponse<string>>;
};

export const useShipments = (): UseShipmentsReturnType => {
  const { fetchApi } = useFetchApi();

  const fetchShipments = async ({
    page = 1,
    sizePerPage = 25,
    createdByMe: CreatedByMe = true,
    sortName,
    sortOrder,
    filters = {},
  }: ShipmentsApiRequest): Promise<ApiResponse<ShipmentsPayload>> => {
    const params = {
      page,
      sizePerPage,
      CreatedByMe,
      sortName,
      sortOrder,
      ...filters,
    };

    const response = await fetchApi(
      `/api/shipment?${qs.stringify(params, { arrayFormat: 'comma' })}`,
    );
    return response.json();
  };

  const downloadExcel = async ({
    page = 1,
    sizePerPage = 25,
    createdByMe: CreatedByMe = true,
    sortName,
    sortOrder,
    filters = {},
  }: ShipmentsApiRequest): Promise<string | undefined> => {
    const params = {
      page,
      sizePerPage,
      CreatedByMe,
      sortName,
      sortOrder,
      ...filters,
    };
    const response = await fetchApi(
      `/api/shipment/excel-export?${qs.stringify(params, { arrayFormat: 'comma' })}`,
    );
    return downloadFile(response, 'shipments.xlsx');
  };

  const fetchShipment = async ({
    id,
  }: ShipmentApiRequest): Promise<ApiResponse<ShipmentPayload>> => {
    const responsePromise: Promise<ApiResponse<ShipmentPayload>> = (
      await fetchApi(`/api/shipment/${id}`, undefined, undefined, true)
    ).json();

    return responsePromise.then((response) => ({
      ...response,
      payload: response.payload ? shipmentParser(response.payload) : undefined,
    }));
  };
  const fetchShipmentByTrackingCode = async ({
    trackingCode,
  }: ShipmentTrackingApiRequest): Promise<ApiResponse<ShipmentPayload>> => {
    const responsePromise: Promise<ApiResponse<ShipmentPayload>> = (
      await fetchApi(`/api/public/shipment/${trackingCode}`, undefined, undefined, true)
    ).json();

    return responsePromise.then((response) => ({
      ...response,
      payload: response.payload ? shipmentParser(response.payload) : undefined,
    }));
  };
  const fetchShipmentLabels = async ({
    shipmentId,
  }: ShipmentLabelsRequest): Promise<string | undefined> => {
    const response = await fetchApi(`/api/shipment/${shipmentId}/labels/0`);
    return downloadFile(response, 'labels.pdf');
  };

  const fetchShipmentCmr = async ({
    shipmentId,
  }: ShipmentLabelsRequest): Promise<string | undefined> => {
    const response = await fetchApi(`/api/shipment/${shipmentId}/cmr/0`);
    return downloadFile(response, `LaasOne_shipment_cmr_${shipmentId}.pdf`);
  };

  const fetchShipmentEvents = async ({
    id,
  }: ShipmentApiRequest): Promise<ApiResponse<ShipmentEvent[]>> => {
    const response = await fetchApi(`/api/shipment/shipment-events/${id}`);
    return response.json();
  };

  const fetchShipmentPrice = async ({
    id,
  }: ShipmentApiRequest): Promise<ApiResponse<ShipmentPrice>> => {
    const response = await fetchApi(`/api/shipment/shipment-total-prices/${id}`);
    return response.json();
  };

  const saveShipment = async ({
    payload,
    draft = false,
  }: SaveShipmentApiRequest): Promise<ApiResponse<number>> => {
    const body = JSON.stringify(payload);

    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: payload.id ? 'PUT' : 'POST',
      body,
    };

    const idParam = payload.id ? `${payload.id}/` : '';

    const response = await fetchApi(`/api/shipment/${idParam}${draft ? '' : 'confirm'}`, params);
    return response.json();
  };

  const saveUnSavedShipment = async ({
    payload,
    id,
  }: UnSavedShipmentApiRequest): Promise<ApiResponse<string>> => {
    const body = JSON.stringify(payload);

    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body,
    };
    const response = await fetchApi(`/api/unfinished-objects/shipment/${id}`, params);
    return response.json();
  };
  const deleteDraft = async ({ id }: ShipmentApiRequest): Promise<ApiResponse<boolean>> => {
    const response = await fetchApi(`/api/shipment/${id}`, { method: 'DELETE' });
    return response.json();
  };

  const fetchShipmentCommentList = async (): Promise<ApiResponse<UserUnreadCommentsState>> => {
    const response = await fetchApi('/api/object_comment/unread/count', { method: 'GET' });

    return response.json();
  };

  const fetchShipmentComments = async ({
    id,
  }: ShipmentCommentsApiRequest): Promise<ApiResponse<ShipmentCommentsPayload>> => {
    const response = await fetchApi(`/api/object_comment/${id}/get-comments/Shipment`, {
      method: 'GET',
    });
    return response.json();
  };

  const updateShipmentComment = async ({
    id,
    payload,
    object,
  }: ShipmentUpdateCommentApiRequest): Promise<ApiResponse<number>> => {
    const body = JSON.stringify(payload);

    const params = {
      method: 'PUT',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await fetchApi(`/api/object_comment/${id}/update-comment/${object}`, params);
    return response.json();
  };

  const saveShipmentComment = async ({
    id,
    payload,
    object,
  }: ShipmentCommentsApiRequest): Promise<ApiResponse<number>> => {
    const body = JSON.stringify(payload);

    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body,
    };

    const response = await fetchApi(`/api/object_comment/${id}/add-comment/${object}`, params);
    return response.json();
  };

  const toggleAsFavorite = async ({ id }: ShipmentApiRequest): Promise<ApiResponse<boolean>> => {
    const body = JSON.stringify({});

    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body,
    };

    const response = await fetchApi(`/api/shipment/toggle-is-favorite/${id}/`, params);
    return response.json();
  };

  const importShipments = async ({
    files,
  }: FilesUploadRequest): Promise<ApiResponse<ShipmentsExcelImportPayload>> => {
    const formData = new FormData();

    for (let i = 0; i < files.length; i += 1) {
      formData.append(`files[${i}]`, files[i]);
    }

    const response = await fetchApi(
      `/api/shipment/import-excel-template/`,
      {
        method: 'POST',
        body: formData,
      },
      ['Content-Type'],
    );

    return response.json();
  };

  const importFromWithHeldExcelFile = async ({
    id,
  }: ApiBasePropsWithId): Promise<ApiResponse<ShipmentsExcelImportPayload>> => {
    const response = await fetchApi(`/api/shipment/import-from-excel/${id}`);
    return response.json();
  };

  return {
    importFromWithHeldExcelFile,
    importShipments,
    fetchShipments,
    fetchShipment,
    fetchShipmentLabels,
    fetchShipmentCmr,
    fetchShipmentEvents,
    fetchShipmentPrice,
    saveShipment,
    deleteDraft,
    fetchShipmentCommentList,
    fetchShipmentComments,
    updateShipmentComment,
    saveShipmentComment,
    downloadExcel,
    fetchShipmentByTrackingCode,
    saveUnSavedShipment,
    toggleAsFavorite,
  };
};
