import React, { ReactElement, FC, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import Box, { BoxProps } from '@material-ui/core/Box';

import clsx from 'clsx';

import { Typography } from 'components';
import { theme } from 'theme';
import { BaseProps } from 'shared/types';
import { generateKey } from 'shared/functions/generateKey';
import { Link } from '@material-ui/core';

export type ItemType = {
  label: string | ReactElement;
  code: string;
  href?: string;
  inactive?: boolean;
};

type DropdownMenuProps = {
  toggler: string | ReactElement;
  selectedItem?: ItemType;
  items: ItemType[];
  onItemSelected: (itemCode: string) => void;
  itemClassName?: string;
  shadowCollapsed?: number;
  shadowExpanded?: number;
} & BoxProps &
  BaseProps;

const useStyles = makeStyles({
  toggler: {
    display: 'inline-flex',
    padding: theme.spacing(3.75, 6),
    border: `1px solid ${theme.palette.custom.veryDarkViolet}`,
    borderRadius: 8,
    cursor: 'pointer',
    zIndex: 10,
  },
  menu: {
    padding: theme.spacing(4, 5, 0, 4),
    border: `1px solid ${theme.palette.custom.veryDarkViolet}`,
    borderRadius: theme.spacing(2, 0, 2, 2),
    zIndex: 1,
  },
  item: {
    display: 'inline-block',
    marginBottom: theme.spacing(4),
    color: theme.palette.custom.veryDarkViolet,
    textDecoration: 'underline',
    fontSize: '0.875rem',
    cursor: 'pointer',
    userSelect: 'none',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  expanded: {
    borderBottom: `1px solid ${theme.palette.custom.white}`,
    borderRadius: theme.spacing(2, 2, 0, 0),

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: theme.spacing(-4),
      right: theme.spacing(0),
      backgroundColor: theme.palette.custom.white,
      zIndex: 50,
      width: '100%',
      height: theme.spacing(4),
    },
  },
  inactive: {
    opacity: '0.6',
  },
});

const DropdownMenu: FC<DropdownMenuProps> = ({
  toggler,
  items,
  onItemSelected,
  itemClassName,
  className,
  shadowCollapsed,
  shadowExpanded = 1,
  ...restProps
}): ReactElement | null => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  useEffect(() => {
    const clickListener = () => {
      if (expanded) {
        toggleExpanded();
      }
    };

    if (expanded) {
      document.addEventListener('click', clickListener);
    } else {
      document.removeEventListener('click', clickListener);
    }
    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, [expanded]);

  return (
    <Box {...restProps}>
      <Box
        className={clsx(classes.toggler, expanded && classes.expanded, className)}
        boxShadow={expanded ? shadowExpanded : shadowCollapsed}
        bgcolor={theme.palette.custom.white}
        position="absolute"
        top={0}
        right={0}
        borderRadius={8}
        onClick={toggleExpanded}
      >
        {toggler}
      </Box>

      {expanded && (
        <Box
          className={clsx(classes.menu)}
          boxShadow={shadowExpanded}
          bgcolor={theme.palette.custom.white}
          position="absolute"
          top={47}
        >
          {items.map(({ label, code, href, inactive }, index) =>
            href ? (
              <Link
                className={clsx(classes.item, itemClassName, inactive === true && classes.inactive)}
                href={href}
              >
                {label}
              </Link>
            ) : (
              <Typography
                className={clsx(classes.item, itemClassName, inactive === true && classes.inactive)}
                key={generateKey(index, 'label')}
                onClick={() => onItemSelected(code)}
              >
                {label}
              </Typography>
            ),
          )}
        </Box>
      )}
    </Box>
  );
};

export default DropdownMenu;
