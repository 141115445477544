import React, { ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { DialogProps } from 'components/CustomMaterial/Dialog/Dialog';
import {
  Typography,
  Dialog,
  ShipmentItems,
  ShipmentItemsComments,
  Checkbox,
  Separator,
  CalendarIcon,
} from 'components';
import { combineDateWithTime, generateDate } from 'shared/functions/date';
import { BaseProps } from 'shared/types';
import { ShipmentDocument, ShipmentPayload } from 'shared/types/shipments/shipments';
import { useCommonStyles } from 'shared/styles/common';

import { LoadingContact } from 'shared/types/loadingContact';
import { FaPhoneAlt } from 'react-icons/fa';
import { buildAddress } from 'shared/functions/buildAddress';
import Chip from '@material-ui/core/Chip';
import { generateKey } from 'shared/functions/generateKey';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { theme as theme2 } from '../../theme';
import PickupIcon from '../Icons/PickupIcon/PickupIcon';
import DeliveryIcon from '../Icons/DeliveryIcon/DeliveryIcon';
import FlagComponent from '../Flag/FlagComponent';

const useStyles = makeStyles({
  dateLabel: {
    color: theme2.palette.custom.veryDarkGrayAlt2,
    display: 'flex',
    alignItems: 'center',
  },
  dateWithWarning: {
    color: theme2.palette.custom.strongViolet,
  },
  warningIcon: {
    position: 'relative',
    top: 1,
    left: 5,
  },
  smallText: {
    fontSize: '0.75rem',
  },
  dateIcon: {
    '& svg': {
      minWidth: 42,
    },
  },
});

const useChipNativeStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.custom.lightGrayishCyan,
    padding: theme.spacing(1, 4),
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(-1),
    '&:hover': {
      backgroundColor: theme.palette.custom.lightGrayishCyan,
    },
  },
  label: {
    padding: theme.spacing(1),
    color: theme.palette.custom.veryDarkGray,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    fontWeight: 500,
  },
}));

type ShipmentConfirmationDialogProps = {
  shipment: ShipmentPayload;
  documents: ShipmentDocument[];
} & Omit<DialogProps, 'maxWidth' | 'title'> &
  BaseProps;

const ShipmentConfirmationDialog: FC<ShipmentConfirmationDialogProps> = ({
  shipment,
  documents,
  ...restProps
}): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const nativeClasses = useChipNativeStyles();
  const commonClasses = useCommonStyles();
  let offeredPrice;
  if (shipment.selectedPriceOffer) {
    offeredPrice = shipment?.offeredPrices?.find(
      (price) => price.id.toString() === shipment.selectedPriceOffer?.toString(),
    );
  } else if (shipment?.offeredPrices?.length === 1 && shipment?.selectedPriceOffer) {
    [offeredPrice] = shipment.offeredPrices;
  }
  const earliestDate = shipment.earliestPickupDate
    ? combineDateWithTime(shipment.earliestPickupDate, shipment.earliestPickupTime ?? '')
    : null;
  const latestDate = shipment.latestPickupDate
    ? combineDateWithTime(shipment.latestPickupDate, shipment.latestPickupTime ?? '')
    : null;
  const earliestDeliveryDate = shipment.earliestArrivalDate
    ? combineDateWithTime(shipment.earliestArrivalDate, shipment.earliestArrivalTime ?? '')
    : null;
  const latestDeliveryDate = shipment.latestArrivalDate
    ? combineDateWithTime(shipment.latestArrivalDate, shipment.latestArrivalTime ?? '')
    : null;

  const earliestDateFormatted = earliestDate ? generateDate(earliestDate, 'dd.MM.yyyy HH:mm') : '–';
  const latestDateFormatted = latestDate ? generateDate(latestDate, 'dd.MM.yyyy HH:mm') : '–';
  const earliestDeliveryDateFormatted = earliestDeliveryDate
    ? generateDate(earliestDeliveryDate, 'dd.MM.yyyy HH:mm')
    : '–';
  const latestDeliveryDateFormatted = latestDeliveryDate
    ? generateDate(latestDeliveryDate, 'dd.MM.yyyy HH:mm')
    : '–';

  const formatAddress = (address: LoadingContact | null | undefined): JSX.Element => {
    if (!address) {
      return <>–</>;
    }
    return (
      <Box display="flex" alignItems="center">
        <FlagComponent flag={address.country ?? ''} />
        <Typography
          variant="body2"
          fontWeight="semibold"
          marginLeft={2}
          className={classes.dateLabel}
        >
          {address.companyName}, {address.addressLine1}, {buildAddress(address)}
        </Typography>
      </Box>
    );
  };
  const formatAddressContact = (address: LoadingContact | null | undefined): JSX.Element => {
    if (!address) {
      return <>–</>;
    }
    return (
      <Box display="flex" alignItems="center">
        <Typography variant="body2" fontWeight="semibold" marginRight={2} dashWhenEmpty>
          {address.contactPersonName}
        </Typography>
        <Typography variant="body2" marginRight={2} dashWhenEmpty>
          {address.email}
        </Typography>
        <Typography variant="body2" marginRight={2} dashWhenEmpty>
          {address.phone}
        </Typography>
      </Box>
    );
  };
  return (
    <Dialog
      {...restProps}
      title={t('SHIPMENT.PLEASE_CHECK_YOUR_SHIPMENT_INFORMATION_BEFORE_CONFIRMING')}
      maxWidth="lg"
      actionAcceptLabel={t('SAVE_CHANGES')}
      showGoBack
    >
      <Typography variant="h6">{t('PICKUP_FROM')}</Typography>
      <Box>
        <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
          <PickupIcon height={24} width={24} />
          <Typography variant="body2" marginLeft={2}>
            {t('PICKUP_ADDRESS')}:
          </Typography>
          <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
            {formatAddress(
              shipment.useReceiverAlternative ? shipment.senderAlternative : shipment.sender,
            )}
          </Typography>
        </Typography>
        <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
          <FaPhoneAlt />
          <Typography variant="body2" marginLeft={2}>
            {t('PICKUP_CONTACT')}:
          </Typography>
          <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
            {formatAddressContact(
              shipment.useReceiverAlternative ? shipment.senderAlternative : shipment.sender,
            )}
          </Typography>
        </Typography>
        <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
          <CalendarIcon height={18} width={18} />
          <Typography variant="body2" marginLeft={2}>
            {!shipment.isFixedPickupDate && t('EARLIEST_PICKUP')}
            {shipment.isFixedPickupDate && t('FIXED_PICKUP')}:
          </Typography>
          <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
            {earliestDateFormatted}
          </Typography>
        </Typography>
        {!shipment.isFixedPickupDate && (
          <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
            <CalendarIcon height={18} width={18} />
            <Typography variant="body2" marginLeft={2}>
              {!shipment.isFixedPickupDate && t('LATEST_PICKUP')}:
            </Typography>
            <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
              {latestDateFormatted}
            </Typography>
          </Typography>
        )}
      </Box>
      <Separator my={4} />
      <Typography variant="h6">{t('DELIVERY_TO')}</Typography>
      <Box>
        <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
          <DeliveryIcon height={24} width={24} />
          <Typography variant="body2" marginLeft={2}>
            {t('DELIVERY_ADDRESS')}:
          </Typography>
          <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
            {formatAddress(
              shipment.useReceiverAlternative ? shipment.receiverAlternative : shipment.receiver,
            )}
          </Typography>
        </Typography>
        <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
          <FaPhoneAlt />
          <Typography variant="body2" marginLeft={2}>
            {t('DELIVERY_CONTACT')}:
          </Typography>
          <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
            {formatAddressContact(
              shipment.useReceiverAlternative ? shipment.receiverAlternative : shipment.receiver,
            )}
          </Typography>
        </Typography>
        <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
          <CalendarIcon height={18} width={18} />
          <Typography variant="body2" marginLeft={2}>
            {!shipment.isFixedArrivalDate && t('EARLIEST_DELIVERY')}
            {shipment.isFixedArrivalDate && t('FIXED_DELIVERY')}:
          </Typography>
          <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
            {earliestDeliveryDateFormatted}
          </Typography>
        </Typography>
        {!shipment.isFixedArrivalDate && (
          <Typography variant="body2" marginTop={2} className={classes.dateLabel}>
            <CalendarIcon height={18} width={18} />
            <Typography variant="body2" marginLeft={2}>
              {!shipment.isFixedArrivalDate && t('LATEST_DELIVERY')}:
            </Typography>
            <Typography variant="body2" fontWeight="semibold" marginLeft={2}>
              {latestDeliveryDateFormatted}
            </Typography>
          </Typography>
        )}
      </Box>
      <Separator my={4} />
      <Typography variant="h6">
        {t('ITEMS_IN_THE_SHIPMENT')}
        {shipment.tailLiftTruck && (
          <Chip
            size="small"
            classes={nativeClasses}
            key={generateKey(6, `tailLiftTruckNeeded_`)}
            label={t('SHIPMENT.TRANSPORT_WITH_TAIL_LIFT_TRUCK')}
          />
        )}
        {shipment.tailLiftTruckDelivery && (
          <Chip
            size="small"
            classes={nativeClasses}
            key={generateKey(6, `tailLiftTruckNeeded_`)}
            label={t('SHIPMENT.TRANSPORT_WITH_TAIL_LIFT_TRUCK_DELIVERY')}
          />
        )}
      </Typography>
      <ShipmentItems
        hideTitle
        tailLiftTruckNeeded={shipment.tailLiftTruck ?? false}
        tailLiftTruckDeliveryNeeded={shipment.tailLiftTruckDelivery ?? false}
        shipmentDetailsRows={shipment.shipmentDetailsRows}
        boxProps={{ marginTop: 3 }}
        readOnly
        smallPadding
      />
      {shipment.otherInstructions && (
        <ShipmentItemsComments
          comments={shipment.otherInstructions}
          className={commonClasses.mt6}
          bigHeading={false}
        />
      )}
      {!!shipment.reference && (
        <>
          <Typography variant="subtitle2" marginTop={3} fontWeight="semibold">
            {t('SHIPMENT.REFERENCE_NUMBER')}
          </Typography>
          <Typography variant="body2" marginBottom={8}>
            {shipment.reference}
          </Typography>
        </>
      )}
      {offeredPrice && (
        <>
          <Typography variant="h6" marginTop={3} fontWeight="semibold">
            {t('SHIPMENT.YOUR_SHIPMENTS_PRICE')}
          </Typography>
          <Box marginTop={1}>
            <Typography
              variant="body2"
              className={classes.smallText}
              fontStyle="italic"
              marginBottom={0.8}
            >
              {t('PRICE_REQUEST.ESTIMATED_DELIVERY')}
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6" fontWeight="black">
                {offeredPrice.transitTime && offeredPrice.transitTime !== '0'
                  ? `${offeredPrice.transitTime} ${
                      offeredPrice.transitTime === '1'
                        ? t('PRICE_REQUEST.WORKING_DAY_DELIVERY')
                        : t('PRICE_REQUEST.WORKING_DAYS_DELIVERY')
                    }`
                  : ''}
                {offeredPrice.transitTime && offeredPrice.transitTime === '0'
                  ? `${t('PRICE_REQUEST.SAME_DAY_DELIVERY')}`
                  : ''}
              </Typography>
              <Box display="flex">
                <Typography variant="h6" fontWeight="black">
                  {offeredPrice.price ? offeredPrice.price.toFixed(2) : ''}
                </Typography>
                <Typography marginLeft={1} variant="body1">
                  €
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {shipment.templateName && (
        <>
          <Checkbox
            variant="lightAlt"
            checked={!!shipment.templateName}
            label={t('SHIPMENT.SAVE_THIS_SHIPMENT_AS_TEMPLATE')}
            disabled
          />
          <Box marginTop={1.5} marginLeft={8}>
            <Typography variant="caption" component="p" marginTop={1.5}>
              {t('SHIPMENT.TEMPLATE_NAME')}
            </Typography>
            <Typography variant="caption" component="p" fontWeight="semibold" marginTop={1}>
              {shipment.templateName}
            </Typography>
          </Box>
        </>
      )}
    </Dialog>
  );
};

export default ShipmentConfirmationDialog;
