import React, { FC, ReactElement } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { Separator, Typography, ShipmentAddressAccordion } from 'components';
import { useTranslation } from 'react-i18next';
import { LoadingContact } from 'shared/types/loadingContact';
import { ShipmentAddressType } from 'shared/types/shipments/shipments';
import { buildAddress } from 'shared/functions/buildAddress';
import { theme } from 'theme';
import { makeStyles } from '@material-ui/styles';
import { isObjectEmpty } from 'shared/functions/isObjectEmpty';
import { useCommonStyles } from 'shared/styles/common';
import FlagComponent from '../Flag/FlagComponent';

const useStyles = makeStyles({
  flagStyle: {
    position: 'absolute',
    top: 0,
    right: 23,
  },
});

type ShipmentAddressProps = {
  type: ShipmentAddressType;
  address: LoadingContact;
  alternativeAddress?: LoadingContact | null;
  showEmptyBlocks?: boolean;
} & BoxProps;

const ShipmentAddress: FC<ShipmentAddressProps> = ({
  type,
  address,
  alternativeAddress,
  showEmptyBlocks,
  ...restProps
}): ReactElement => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();

  const translationType = type === 'receiver' ? 'RECEIVER' : 'SENDER';
  const classes = useStyles();
  const addressReference = (addressData: LoadingContact): string => addressData?.reference || '–';
  return (
    <Box
      borderRadius={16}
      padding={10}
      paddingRight={6}
      border={`1px solid ${theme.palette.custom.lightGray}`}
      {...restProps}
    >
      <Box paddingRight={4} marginBottom={8} position="relative">
        <Box className={classes.flagStyle}>
          <FlagComponent flag={address.country ?? ''} />
        </Box>
        <Typography variant="h6" marginBottom={6}>
          {t(`SHIPMENT.ADDRESSES.${translationType}.INFORMATION`)}
        </Typography>
        <Typography variant="body2" marginBottom={2} fontWeight="bold">
          {t(`SHIPMENT.ADDRESSES.${translationType}.ADDRESS`)}
        </Typography>
        <Typography variant="body2" component="p" marginBottom={2} dashWhenEmpty>
          {address.companyName}
        </Typography>
        <Typography variant="body2" component="p" marginBottom={2} dashWhenEmpty>
          {address.addressLine1}
        </Typography>
        <Typography variant="body2" component="p" marginBottom={2} dashWhenEmpty>
          {buildAddress(address)}
        </Typography>

        {(showEmptyBlocks || address.contactPersonName || address.email || address.phone) && (
          <>
            <Separator my={4} />
            <Typography variant="body2" marginBottom={2} fontWeight="bold">
              {t(`SHIPMENT.ADDRESSES.${translationType}.CONTACT_PERSON`)}
            </Typography>
            <Typography variant="body2" component="p" marginBottom={2} dashWhenEmpty>
              {address.contactPersonName}
            </Typography>
            <Typography variant="body2" component="p" marginBottom={2} dashWhenEmpty>
              {address.email}
            </Typography>
            <Typography variant="body2" component="p" marginBottom={2} dashWhenEmpty>
              {address.phone}
            </Typography>
          </>
        )}

        {(showEmptyBlocks || addressReference(address) !== '–') && (
          <>
            <Separator my={4} />
            <Typography
              variant="body2"
              component="p"
              marginBottom={2}
              dashWhenEmpty
              fontWeight="bold"
            >
              {type === 'sender'
                ? t(`SHIPMENT.ADDRESSES.LOADING_REFERENCE`)
                : t(`SHIPMENT.ADDRESSES.UNLOADING_REFERENCE`)}
            </Typography>
            <Typography variant="body2" component="p" fontWeight="medium" dashWhenEmpty>
              {addressReference(address)}
            </Typography>
          </>
        )}
        {(showEmptyBlocks || address.notes) && (
          <>
            <Separator my={4} />
            <Typography variant="body2" marginBottom={2} fontWeight="bold">
              {t(`SHIPMENT.ADDRESSES.${translationType}.ADDRESS_DETAILS`)}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={commonClasses.wordBreak}
              dashWhenEmpty
            >
              {address.notes}
            </Typography>
          </>
        )}
      </Box>

      {!isObjectEmpty(alternativeAddress) && alternativeAddress && (
        <ShipmentAddressAccordion address={alternativeAddress} type={type} />
      )}
    </Box>
  );
};

export default ShipmentAddress;
