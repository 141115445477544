import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import { ExpandableSettings, PlusIcon } from 'components';
import { theme } from 'theme';

import { BaseProps } from 'shared/types';
import { ItemType as ExpandableSettingsItemType } from 'components/ExpandableSettings/ExpandableSettings';
import { useHistory } from 'react-router-dom';
import { routePriceRequests, routeShipments } from 'routes';
import { useUser } from 'shared/hooks/useUser';
import { hasPermission } from 'shared/functions/hasPermission';
import Box from '@material-ui/core/Box';
import { UserAction, UserActionType } from 'shared/constants/user/userRights';
import NewShipmentIcon from '../Icons/AddNewMenu/Shipment/NewShipmentIcon';
import FromTemplateIcon from '../Icons/AddNewMenu/FromTemplate/FromTemplateIcon';
import ImportExcelIcon from '../Icons/AddNewMenu/ImportExcel/ImportExcelIcon';
import NewPriceRequestIcon from '../Icons/AddNewMenu/PriceRequest/PriceRequestIcon';

type AddNewMenuProps = BaseProps & {
  toggleShowDialog: () => void;
  toggleShowShipmentFromExcelModal: () => void;
};

const useStyles = makeStyles({
  item: {
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.custom.menuBgYellow,
    },
    alignItems: 'center',
  },
  selectedItem: {
    fontWeight: ('700 !important' as unknown) as number, // FIXME: Find a way to better solve the CSS specificity problem
  },
});

const AddNewMenu: FC<AddNewMenuProps> = ({
  toggleShowDialog,
  toggleShowShipmentFromExcelModal,
}): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const user = useUser();
  const items: ExpandableSettingsItemType[] = [
    {
      code: 'shipment',
      label: t('ADD_NEW_SHIPMENT'),
      icon: <NewShipmentIcon />,
      action: UserAction.shipments,
    },
    {
      code: 'shipmentFromTemplate',
      label: t('ADD_NEW_SHIPMENT_FROM_TEMPLATE'),
      icon: <FromTemplateIcon />,
      action: UserAction.shipments,
    },
    {
      code: 'priceRequest',
      label: t('ADD_NEW_PRICE_REQUEST'),
      icon: <NewPriceRequestIcon />,
      action: UserAction.priceRequests,
    },
    {
      code: 'shipmentFromExcel',
      label: t('ADD_NEW_SHIPMENT_FROM_EXCEL'),
      icon: <ImportExcelIcon />,
      action: UserAction.shipments,
    },
  ];

  const activeItems = items.filter(
    (item) =>
      !item.inactive &&
      hasPermission(user, item.action ?? UserAction.noAction, UserActionType.Edit),
  );

  const separatorProps = { bgcolor: theme.palette.custom.brightYellow };

  const onItemSelected = (code: string): void => {
    if (code === 'shipment') {
      history.push(`${routeShipments}/new-shipment`);
    } else if (code === 'shipmentFromTemplate') {
      toggleShowDialog();
    } else if (code === 'priceRequest') {
      history.push(`${routePriceRequests}/new-price-request`);
    } else if (code === 'shipmentFromExcel') {
      toggleShowShipmentFromExcelModal();
    }
  };
  if (activeItems.length < 1) {
    return <></>;
  }
  return (
    <Box width={172} position="relative">
      <ExpandableSettings
        selectedItem={{
          code: 'addNewPreview',
          label: `${t('ADD_NEW')}...`,
          icon: <PlusIcon fill="#333" />,
        }}
        itemClassName={classes.item}
        selectedItemClassName={classes.selectedItem}
        items={activeItems}
        onItemSelected={onItemSelected}
        separatorProps={separatorProps}
        position="relative"
        bgcolor={theme.palette.secondary.main}
        shadowCollapsed={3}
      />
    </Box>
  );
};

export default AddNewMenu;
