/* eslint-disable no-param-reassign */
import React, { ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import isEmpty from 'lodash/isEmpty';

import { theme } from 'theme';
import { ShipmentCommentCountPayload } from 'shared/types/shipments/shipments';
import { BaseProps } from 'shared/types';
import { routePriceRequests, routeShipments } from 'routes';
import { ExpandableSettings } from 'components';
import { ItemType } from 'components/ExpandableSettings/ExpandableSettings';

type CommentsNotificationsProps = {
  comments: ShipmentCommentCountPayload;
  badge: number;
} & BaseProps;

const useStyles = makeStyles(() => ({
  selectedItem: {
    fontWeight: ('700 !important' as unknown) as number, // FIXME: Find a way to better solve the CSS specificity problem
  },
  item: {
    '&:hover': {
      backgroundColor: theme.palette.custom.lightGrayishVioletAlt,
    },
    minWidth: theme.spacing(31.25),
    maxWidth: theme.spacing(36),
  },
}));

const CommentsNotifications: FC<CommentsNotificationsProps> = ({
  comments,
  badge,
  className,
}): ReactElement | null => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const onItemSelected = (code: string, object?: string): void => {
    if (code && object === 'Shipment') {
      history.push(`${routeShipments}/${code}#messages`);
    } else if (code && object === 'PriceRequest') {
      history.push(`${routePriceRequests}/${code}#messages`);
    }
  };

  const commentsParser = (payload: ShipmentCommentCountPayload): ItemType[] => {
    let parsedComments: ItemType[] = [
      { label: `${t('SHIPMENT.NO_NEW_COMMENTS')}`, code: '', isLink: false },
    ];

    if (!isEmpty(payload?.unreadOperatorCommentsPerEntity)) {
      parsedComments = payload.unreadOperatorCommentsPerEntity.map((comment) => ({
        label: `${comment.objectCode?.replace('-', ': ')} (${comment.commentCount})`,
        code: comment.objectId.toString(),
        object: comment.objectType,
      }));
    }

    return parsedComments;
  };

  return (
    <ExpandableSettings
      selectedItem={{
        code: 'comments',
        label: `${t('SHIPMENT.COMMENTS')}`,
        badge,
      }}
      bgcolor={theme.palette.custom.white}
      className={className}
      selectedItemClassName={classes.selectedItem}
      itemClassName={classes.item}
      items={commentsParser(comments) || []}
      onItemSelected={onItemSelected}
    />
  );
};
export default CommentsNotifications;
