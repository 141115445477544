import React, { FC, ReactElement } from 'react';
import { theme } from 'theme';

type CheckboxIconProps = {
  checked?: boolean;
  disabled?: boolean;
  variant?: 'light' | 'lightAlt' | 'dark';
  size?: 'tiny' | 'small' | 'big';
};

const sizeMap = {
  tiny: 16,
  small: 24,
  big: 32,
};

const uncheckedColorsMap = {
  light: theme.palette.custom.lightGrayishVioletAlt,
  lightAlt: theme.palette.custom.grayAlt,
  dark: theme.palette.custom.mostlyDesaturatedDarkViolet,
};

const checkedColorsMap = {
  light: theme.palette.custom.lightGrayishVioletAlt,
  lightAlt: theme.palette.custom.lightGrayishVioletAlt,
  dark: theme.palette.custom.mostlyDesaturatedDarkViolet,
};

const tickColorsMap = {
  light: theme.palette.custom.veryDarkGray,
  lightAlt: theme.palette.custom.veryDarkGray,
  dark: theme.palette.custom.white,
};

const CheckboxIcon: FC<CheckboxIconProps> = ({
  checked = false,
  disabled = false,
  variant = 'light',
  size = 'small',
  ...restProps
}): ReactElement => {
  const sizeValue = sizeMap[size];

  let icon = null;
  if (!checked) {
    icon = (
      <svg
        width={sizeValue}
        height={sizeValue}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
      >
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="3.5"
          stroke={disabled ? theme.palette.custom.grayAlt : uncheckedColorsMap[variant]}
          fill={disabled ? theme.palette.custom.grayAlt : undefined}
        />
      </svg>
    );
  } else {
    icon = (
      <svg
        width={sizeValue}
        height={sizeValue}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
      >
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="3.5"
          fill={disabled ? theme.palette.custom.grayAlt : checkedColorsMap[variant]}
          stroke={disabled ? theme.palette.custom.grayAlt : checkedColorsMap[variant]}
        />
        <path
          d="M7.14062 12.2031C7.05729 12.1094 6.95833 12.0469 6.84375 12.0156C6.72917 11.9844 6.60938 11.9844 6.48438 12.0156C6.36979 12.0469 6.27083 12.1042 6.1875 12.1875C6.10417 12.2708 6.04688 12.375 6.01562 12.5C5.98438 12.6146 5.98438 12.7292 6.01562 12.8438C6.04688 12.9583 6.10938 13.0573 6.20312 13.1406L9.53125 16.4688C9.66667 16.6042 9.82292 16.6719 10 16.6719C10.1771 16.6719 10.3333 16.6042 10.4688 16.4688L17.7969 9.14062C17.8906 9.05729 17.9531 8.95833 17.9844 8.84375C18.0156 8.72917 18.0156 8.61458 17.9844 8.5C17.9531 8.375 17.8958 8.27083 17.8125 8.1875C17.7292 8.10417 17.625 8.04688 17.5 8.01562C17.3854 7.98438 17.2708 7.98438 17.1562 8.01562C17.0417 8.04688 16.9427 8.10938 16.8594 8.20312L10 15.0625L7.14062 12.2031Z"
          fill={disabled ? theme.palette.custom.veryDarkGray : tickColorsMap[variant]}
        />
      </svg>
    );
  }

  return icon;
};

export default CheckboxIcon;
