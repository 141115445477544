import React, { FC, ReactElement } from 'react';
import { BoxIcon, ContainerIcon, MachineIcon, OtherIcon, PalletIcon, TruckIcon } from 'components';
import { theme } from 'theme';

import { BaseProps } from 'shared/types';
import { ShipmentItemType } from 'shared/types/shipments/shipmentItem';

type ShipmentItemIconProps = {
  itemType?: ShipmentItemType;
  width?: number;
  height?: number;
  active?: boolean;
} & BaseProps;

const ShipmentItemIcon: FC<ShipmentItemIconProps> = ({
  width = 27.5,
  height = 30,
  active,
  itemType = ShipmentItemType.Pallet,
  ...restProps
}): ReactElement | null => {
  const iconProps = {
    width,
    height,
    fill: active ? theme.palette.custom.darkCyan : theme.palette.custom.gray,
    ...restProps,
  };

  let itemIcon = null;
  switch (itemType) {
    case ShipmentItemType.Pallet:
      itemIcon = <PalletIcon {...iconProps} />;
      break;
    case ShipmentItemType.Box:
      itemIcon = <BoxIcon {...iconProps} />;
      break;
    case ShipmentItemType.Truck:
      itemIcon = <TruckIcon {...iconProps} />;
      break;
    case ShipmentItemType.Container:
      itemIcon = <ContainerIcon {...iconProps} />;
      break;
    case ShipmentItemType.Other:
      itemIcon = <OtherIcon {...iconProps} />;
      break;
    case ShipmentItemType.Machine:
      itemIcon = (
        <MachineIcon
          width={width}
          fill={active ? theme.palette.custom.darkCyan : theme.palette.custom.gray}
        />
      );
      break;
    default:
      itemIcon = null;
  }

  return itemIcon;
};

export default ShipmentItemIcon;
