import React, { ReactElement, FC } from 'react';
import Box from '@material-ui/core/Box';
import { FaPhoneAlt } from 'react-icons/fa';
import { Typography } from 'components';
import { BaseProps } from 'shared/types';
import { UserProfilePrimaryContact } from 'shared/types/userProfile';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { theme } from '../../theme';

const useStyles = makeStyles({
  borderedBox: {
    border: '2px solid',
    borderColor: theme.palette.custom.veryDarkViolet,
    borderRadius: 20,
    background: theme.palette.custom.veryDarkViolet,
    color: '#FFFFFF',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.4)',
  },
  img: {
    border: '1px solid #FFFFFF',
    borderRadius: 50,
    padding: 12,
    fontSize: 24,
    marginRight: 16,
    color: '#f9dc43',
  },
});

type UserCardProps = {
  profile: UserProfilePrimaryContact;
  contactOnVacation?: string;
} & BaseProps;

const UserCard: FC<UserCardProps> = ({ profile, contactOnVacation }): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.borderedBox} padding={!contactOnVacation ? 8 : 5}>
      <Box display="inline-flex">
        <Box display="flex" alignItems="center" justifyContent="center" className={classes.img}>
          <FaPhoneAlt height={20} width={20} />
        </Box>
        <Box>
          <Typography variant="body2">{t('PRIMARY_CONTACT')}</Typography>
          <Typography variant="h5">
            {profile.name} {profile.phone && profile.phone}
          </Typography>
        </Box>
      </Box>
      {contactOnVacation && (
        <Box display="block" textAlign="center">
          {' '}
          <Typography variant="body2" fontStyle="italic" marginTop={3}>
            {t('OUT_OF_OFFICE').replace('{0}', contactOnVacation)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default UserCard;
