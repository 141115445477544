export enum UserAction {
  noAction = 'noAction',
  shipments = 'shipments',
  priceRequests = 'pricerequests',
  invoices = 'invoices',
  claims = 'claims',
}

export enum UserActionType {
  NoAccess,
  ViewOnly,
  Edit,
}
