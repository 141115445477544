import React, { ReactElement, FC } from 'react';
import Box from '@material-ui/core/Box';
import { useUser } from 'shared/hooks/useUser';
import { theme } from 'theme';
import { Typography } from 'components';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { useFeatures } from 'shared/hooks/useFeatures';

const useStyles = makeStyles(() => ({
  bubbleWrap: {
    pointerEvents: 'none',
  },
  image: {
    position: 'relative',
    right: '47px',
    bottom: '29px',
  },
  textWrap: {
    position: 'relative',
    right: '48px',
    bottom: '28px',
    textAlign: 'center',
  },
  textBold: {
    margin: '0.5rem 0 1rem',
  },
  arrow: {
    position: 'relative',
    zIndex: 1,
  },
  caption: {
    display: 'block',
    lineHeight: '1.0675rem',
    color: theme.palette.custom.veryDarkGrayAlt2,
  },
  bubble: {
    padding: '10px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.custom.veryDarkGray,
    background: 'url(/images/shipments/bubble.svg)',
    width: 250,
    height: 100,
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    '&.shortText': {
      padding: '10px 50px',
    },
  },
}));

const ShipmentsOnboarding: FC = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useUser();
  const features = useFeatures();

  return (
    <Box
      pt={15.5}
      pb={4}
      px={4}
      mb={4}
      borderColor={theme.palette.custom.lightGray}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      position="relative"
      bottom={62}
      bgcolor={theme.palette.custom.white}
    >
      <img className={classes.image} alt="Not found" src="/images/shipments/placeholder.svg" />
      <Box className={classes.textWrap}>
        <Typography variant="h3" customColor={theme.palette.custom.darkCyan}>
          {t('SHIPMENTS_SCENARIO.FILTER.HELLO_USERNAME').replace('{0}', user.profile?.name || '')}
        </Typography>
        <Typography className={classes.textBold} variant="h6">
          {t('SHIPMENTS_SCENARIO.FILTER.NO_SHIPMENTS_HERE')}
        </Typography>
        <Typography>{t('SHIPMENTS_SCENARIO.FILTER.MAKE_A_FEW_SELECTIONS')}</Typography>
        <Typography>
          {t('SHIPMENTS_SCENARIO.FILTER.NEED_HELP')}
          <a href="mailto: info@laasone.com">info@laasone.com</a>
        </Typography>
      </Box>

      <Box position="absolute" top={30} left={4} className={classes.bubbleWrap}>
        <img className={classes.arrow} alt="" src="/images/shipments/arrow-variant-1.svg" />
        <Box position="absolute" top={30} left={74} width={280}>
          <Typography className={classes.caption} variant="caption">
            {t('SHIPMENTS_SCENARIO.FILTER.PSST_DONT_WANT_TO_ADD')}
          </Typography>
        </Box>
      </Box>
      <Box position="absolute" top={-135} right={-10} className={classes.bubbleWrap}>
        <img className={classes.arrow} alt="" src="/images/shipments/arrow-variant-2.svg" />
        <Box position="absolute" top={268} right={286} width={154} textAlign="center">
          <Typography className={`${classes.caption} ${classes.bubble}`} variant="caption">
            {t('SHIPMENTS_SCENARIO.FILTER.START_ADDING_A_NEW_SHIPMENT')}
          </Typography>
        </Box>
      </Box>
      {features.priceRequests && (
        <Box position="fixed" zIndex={1} top={153} left={35} className={classes.bubbleWrap}>
          <img className={classes.arrow} alt="" src="/images/shipments/arrow-variant-3.svg" />
          <Box position="absolute" top={129} left={177} width={177} textAlign="center">
            <Typography
              className={`${classes.caption} ${classes.bubble} shortText`}
              variant="caption"
            >
              {t('SHIPMENTS_SCENARIO.FILTER.HERE_YOU_WILL_SEE')}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ShipmentsOnboarding;
