import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';

import './i18n';

Sentry.init({
  dsn: 'https://8d3c7dc2a05943a4a2d7c99a6f370bc3@o554023.ingest.sentry.io/5682052',
  integrations: [new Integrations.BrowserTracing()],
  denyUrls: ['127.0.0.1', 'localhost'],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  maxValueLength: 5000,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
