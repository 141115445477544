import React, { ReactElement, FC, SVGProps } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';

import { BaseProps } from 'shared/types';

import { theme } from 'theme';

type ChevronIconProps = {
  direction?: 'left' | 'right';
  variant?: 'single' | 'double';
  disabled?: boolean;
} & BaseProps &
  Omit<SVGProps<SVGSVGElement>, 'variant'>;

const useStyles = makeStyles({
  rotated: {
    transform: 'rotate(180deg)',
  },
});

const ChevronIcon: FC<ChevronIconProps> = ({
  direction = 'right',
  variant = 'single',
  disabled = false,
  className: customClassName = '',
  ...props
}): ReactElement => {
  const classes = useStyles();

  const fill = disabled ? theme.palette.custom.gray : theme.palette.primary.main;

  const className = direction === 'left' ? classes.rotated : undefined;

  return variant === 'single' ? (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(className, customClassName)}
      {...props}
    >
      <path
        d="M0.839844 7.83984C0.730469 7.95833 0.675781 8.09505 0.675781 8.25C0.675781 8.40495 0.730469 8.54167 0.839844 8.66016C0.958333 8.76953 1.09505 8.82422 1.25 8.82422C1.40495 8.82422 1.54167 8.76953 1.66016 8.66016L5.16016 5.16016C5.27865 5.04167 5.33789 4.90495 5.33789 4.75C5.33789 4.59505 5.27865 4.45833 5.16016 4.33984L1.66016 0.839844C1.54167 0.730469 1.40495 0.675781 1.25 0.675781C1.09505 0.675781 0.958333 0.735026 0.839844 0.853516C0.730469 0.962891 0.675781 1.09505 0.675781 1.25C0.675781 1.40495 0.730469 1.54167 0.839844 1.66016L3.92969 4.75L0.839844 7.83984Z"
        fill={fill}
      />
    </svg>
  ) : (
    <svg
      width="10"
      height="9"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(className, customClassName)}
      {...props}
    >
      <path
        d="M5.17773 6.25195C5.05924 6.37044 5 6.50716 5 6.66211C5.00911 6.81706 5.06836 6.95378 5.17773 7.07227C5.29622 7.18164 5.43294 7.24089 5.58789 7.25C5.74284 7.25 5.87956 7.19076 5.99805 7.07227L8.91016 4.16016C9.02865 4.04167 9.08789 3.90495 9.08789 3.75C9.08789 3.59505 9.02865 3.45833 8.91016 3.33984L5.99805 0.427734C5.87956 0.309245 5.74284 0.254557 5.58789 0.263672C5.43294 0.263672 5.29622 0.322917 5.17773 0.441406C5.06836 0.550781 5.00911 0.682943 5 0.837891C5 0.992839 5.05924 1.12956 5.17773 1.24805L7.67969 3.75L5.17773 6.25195ZM1.08984 6.25195C0.980469 6.37044 0.925781 6.50716 0.925781 6.66211C0.925781 6.81706 0.980469 6.95378 1.08984 7.07227C1.20833 7.18164 1.34505 7.24089 1.5 7.25C1.65495 7.25 1.79167 7.19076 1.91016 7.07227L4.82227 4.16016C4.94076 4.04167 5 3.90495 5 3.75C5 3.59505 4.94076 3.45833 4.82227 3.33984L1.91016 0.427734C1.79167 0.309245 1.65495 0.254557 1.5 0.263672C1.34505 0.263672 1.20833 0.322917 1.08984 0.441406C0.980469 0.550781 0.925781 0.682943 0.925781 0.837891C0.925781 0.992839 0.980469 1.12956 1.08984 1.24805L3.5918 3.75L1.08984 6.25195Z"
        fill={fill}
      />
    </svg>
  );
};

export default ChevronIcon;
