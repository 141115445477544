import * as React from 'react';
import { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const Co2LeafIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 12,
  height = 12,
}): ReactElement => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M10.5 1.5L9.5 1.5C4.6865 1.5 2.5 4.1865 2.5 7.5L2.5 8.451C4.006 8.345 4.9175 7.876 6.0005 6.652C6.533 6.05 6.8405 6.101 6.664 6.3975C5.449 8.4395 3.807 9.4535 1.185 9.4985L1 9.5L1 10.5C1.6815 10.5 2.3 10.442 2.866 10.327C3.513 10.442 4.391 10.5 5.5 10.5C8.2615 10.5 10.5 8.2615 10.5 5.5C10.5 4.5 10 3.5 10.5 1.5Z"
        fill="#28A745"
      />
    </svg>
  );
};

export default Co2LeafIcon;
