import { useEffect, useState } from 'react';

type UseHoverReturnType = {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

type UseHoverProps = {
  hoverStateChanged: (hover: boolean) => void;
};

export const useHover = ({ hoverStateChanged }: UseHoverProps): UseHoverReturnType => {
  const [hover, setHover] = useState(false);

  const onMouseEnter = () => setHover(true);
  const onMouseLeave = () => setHover(false);

  useEffect(() => {
    hoverStateChanged(hover);
  }, [hover, hoverStateChanged]);

  return { onMouseEnter, onMouseLeave };
};
