import React, { ReactElement, FC, useContext, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { TextField, Button, Typography, MultiSelect, Labeled, DatePicker } from 'components';
import { SelectItem } from 'components/CustomMaterial/MultiSelect/MultiSelect';
import { BaseProps } from 'shared/types';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, FormikHelpers, FieldProps } from 'formik';
import { theme } from 'theme';
import { IShipmentsFilters } from 'shared/types/shipments/shipments';
import pickBy from 'lodash/pickBy';
import {
  shipmentStatusCodes,
  shipmentStatusLabels,
  simplifiedStatuses,
} from 'shared/constants/shipments/shipmentStatuses';
import { GlobalDataContext } from 'app/AuthenticatedApp';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useShipments } from 'shared/services/shipments';
import { useQuery } from 'shared/hooks/useQuery';
import { DataTableQueryParams } from 'components/Datatable/Datatable';
import { FormikState } from 'formik/dist/types';

const useStyles = makeStyles(() => ({
  container: {
    alignSelf: 'stretch',
  },
  child: {
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
  borderedBox: {
    borderRight: '2px solid',
    borderRightColor: theme.palette.custom.lightGrayishVioletAlt,
    marginRight: theme.spacing(5),
    paddingRight: theme.spacing(5),
    flexShrink: 2,
  },
}));

type ShipmentsFilterProps = {
  applyFilters: (filtered: IShipmentsFilters) => void;
  closeFilters: () => void;
  createdByMeString: string;
} & BaseProps;

const ShipmentsFilter: FC<ShipmentsFilterProps> = ({
  applyFilters,
  closeFilters,
  createdByMeString,
}): ReactElement => {
  const { t } = useTranslation();
  const { downloadExcel } = useShipments();
  const classes = useStyles();

  const { queryParams, clearQueryParams } = useQuery<DataTableQueryParams>();
  const { filters } = queryParams;

  const { countries } = useContext(GlobalDataContext);
  const [exportFilter, setExportFilter] = useState<boolean>(false);

  const emptyFilters: IShipmentsFilters = {
    number: '',
    reference: '',
    pickupCountries: [],
    pickUpDateFrom: '',
    pickUpDateTo: '',
    deliveryCountries: [],
    deliveryDateFrom: '',
    deliveryDateTo: '',
    statuses: [],
    consignee: '',
    consignor: '',
  };

  const [initialValues, setInitialValues] = useState<IShipmentsFilters>({
    ...emptyFilters,
    ...filters,
  });

  useEffect(() => {
    setInitialValues({ ...emptyFilters, ...filters });
  }, [filters]);

  const shipmentStatusItems: SelectItem[] = simplifiedStatuses.map((status) => ({
    label: t(shipmentStatusLabels[status]),
    value: shipmentStatusCodes[status],
  }));

  const handleFormSubmit = async (
    values: IShipmentsFilters,
    actions: FormikHelpers<IShipmentsFilters>,
  ) => {
    actions.setSubmitting(true);
    const filtered = pickBy(values, (value) => (Array.isArray(value) ? value.length > 0 : value));
    if (!exportFilter) {
      applyFilters(filtered);
    } else {
      await downloadExcel({ filters: filtered, createdByMe: createdByMeString === 'true' });
      setExportFilter(false);
    }
  };

  const clearAllFields = (
    resetForm: (nextState?: Partial<FormikState<IShipmentsFilters>>) => void,
  ) => {
    clearQueryParams();
    resetForm({});
  };

  return (
    <Box
      pt={8}
      px={9.75}
      pb={10}
      mb={8}
      borderColor={theme.palette.custom.lightGray}
      borderRadius={16}
      bgcolor={theme.palette.custom.lightGrayishViolet}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, actions) => handleFormSubmit(values, actions)}
      >
        {({ errors, touched, setFieldValue, handleSubmit, resetForm }) => (
          <Form>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2" fontWeight="extraBold">
                {t('SHIPMENTS_SCENARIO.FILTER.FILTER')}
                <Typography
                  onClick={() => clearAllFields(resetForm)}
                  variant="caption"
                  link
                  marginLeft={4}
                >
                  {t('SHIPMENTS_SCENARIO.FILTER.CLEAR_ALL_FIELDS')}
                </Typography>
              </Typography>
              <Typography onClick={() => closeFilters()} variant="body2" link>
                {t('SHIPMENTS_SCENARIO.FILTER.CLOSE_FILTER')}
              </Typography>
            </Box>
            <Box display="flex" className={classes.container} pb={6}>
              <Box display="flex" pt={3.5} className={clsx(classes.child, classes.borderedBox)}>
                <Field name="number">
                  {({ field }: FieldProps) => {
                    return (
                      <Box marginRight={3}>
                        <Labeled
                          error={!!(errors.number && touched.number)}
                          variant="variant2"
                          text={t('SHIPMENTS_SCENARIO.FILTER.SHIPMENT_NR')}
                        >
                          <TextField
                            {...field}
                            variant="variant4"
                            type="text"
                            size="small"
                            error={!!(errors.number && touched.number)}
                            width={160}
                          />
                        </Labeled>
                      </Box>
                    );
                  }}
                </Field>
                <Field name="reference">
                  {({ field }: FieldProps) => {
                    return (
                      <Box>
                        <Labeled
                          error={!!(errors.reference && touched.reference)}
                          variant="variant2"
                          text={t('SHIPMENTS_SCENARIO.FILTER.REFERENCE_NR')}
                        >
                          <TextField
                            {...field}
                            variant="variant4"
                            type="text"
                            size="small"
                            error={!!(errors.reference && touched.reference)}
                            width={160}
                          />
                        </Labeled>
                      </Box>
                    );
                  }}
                </Field>
                <Field name="statuses">
                  {({ field }: FieldProps) => {
                    return (
                      <Box mt={3}>
                        <Labeled
                          error={!!(errors.statuses && touched.statuses)}
                          variant="variant2"
                          text={t('SHIPMENTS_SCENARIO.FILTER.STATUS')}
                        >
                          <MultiSelect
                            {...field}
                            resultsFilter
                            placeholder={t('SHIPMENTS_SCENARIO.FILTER.SELECT_STATUS')}
                            data={shipmentStatusItems}
                            background="opaque"
                            noBorder
                            width={334}
                            onChange={(values) => setFieldValue('statuses', values)}
                          />
                        </Labeled>
                      </Box>
                    );
                  }}
                </Field>
              </Box>
              <Box display="flex" pt={3.5} className={classes.child}>
                <Field name="pickupCountries">
                  {({ field }: FieldProps) => {
                    return (
                      <Box marginRight={4}>
                        <Labeled
                          error={!!(errors.pickupCountries && touched.pickupCountries)}
                          variant="variant2"
                          text={t('SHIPMENTS_SCENARIO.FILTER.PICK_UP_FROM')}
                        >
                          <MultiSelect
                            {...field}
                            resultsFilter
                            placeholder={t('SHIPMENTS_SCENARIO.FILTER.SELECT_COUNTRY')}
                            data={countries}
                            background="opaque"
                            noBorder
                            width={240}
                            onChange={(values) => setFieldValue('pickupCountries', values)}
                          />
                        </Labeled>
                      </Box>
                    );
                  }}
                </Field>
                <Field name="consignor">
                  {({ field }: FieldProps) => {
                    return (
                      <Box marginRight={4}>
                        <Labeled
                          error={!!(errors.consignor && touched.consignor)}
                          variant="variant2"
                          text={t('SHIPMENTS_SCENARIO.FILTER.PICKUP_COMPANY')}
                        >
                          <TextField
                            {...field}
                            variant="variant4"
                            type="text"
                            size="small"
                            error={!!(errors.consignor && touched.consignor)}
                            width={240}
                          />
                        </Labeled>
                      </Box>
                    );
                  }}
                </Field>
                <Box>
                  <Labeled
                    error={false}
                    variant="variant2"
                    text={t('SHIPMENTS_SCENARIO.FILTER.PICK_UP_DATE')}
                  >
                    <DatePicker
                      size="small"
                      variant="variant4"
                      width={224}
                      isRangePicker
                      startDateName="pickUpDateFrom"
                      endDateName="pickUpDateTo"
                      initialStartDateISO={initialValues.pickUpDateFrom}
                      initialEndDateISO={initialValues.pickUpDateTo}
                    />
                  </Labeled>
                </Box>
                <Field name="deliveryCountries">
                  {({ field }: FieldProps) => {
                    return (
                      <Box marginRight={4} mt={3}>
                        <Labeled
                          error={!!(errors.deliveryCountries && touched.deliveryCountries)}
                          variant="variant2"
                          text={t('SHIPMENTS_SCENARIO.FILTER.DELIVERY_TO')}
                        >
                          <MultiSelect
                            {...field}
                            resultsFilter
                            placeholder={t('SHIPMENTS_SCENARIO.FILTER.SELECT_COUNTRY')}
                            data={countries}
                            background="opaque"
                            noBorder
                            width={240}
                            onChange={(values) => setFieldValue('deliveryCountries', values)}
                          />
                        </Labeled>
                      </Box>
                    );
                  }}
                </Field>
                <Field name="consignee">
                  {({ field }: FieldProps) => {
                    return (
                      <Box marginRight={4} mt={3}>
                        <Labeled
                          error={!!(errors.consignee && touched.consignee)}
                          variant="variant2"
                          text={t('SHIPMENTS_SCENARIO.FILTER.DELIVERY_COMPANY')}
                        >
                          <TextField
                            {...field}
                            variant="variant4"
                            type="text"
                            size="small"
                            error={!!(errors.consignee && touched.consignee)}
                            width={240}
                          />
                        </Labeled>
                      </Box>
                    );
                  }}
                </Field>
                <Box mt={3}>
                  <Labeled
                    error={false}
                    variant="variant2"
                    text={t('SHIPMENTS_SCENARIO.FILTER.DELIVERY_DATE')}
                  >
                    <DatePicker
                      size="small"
                      variant="variant4"
                      isRangePicker
                      width={224}
                      startDateName="deliveryDateFrom"
                      endDateName="deliveryDateTo"
                      initialStartDateISO={initialValues.deliveryDateFrom}
                      initialEndDateISO={initialValues.deliveryDateTo}
                    />
                  </Labeled>
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Typography
                onClick={() => {
                  setExportFilter(true);
                  handleSubmit();
                }}
                variant="caption"
                link
                marginRight={6}
              >
                {t('SHIPMENTS_SCENARIO.FILTER.EXPORT_XLS')}
              </Typography>
              <Button variant="contained" color="primary" size="small" type="submit">
                {t('SHIPMENTS_SCENARIO.FILTER.FILTER')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ShipmentsFilter;
