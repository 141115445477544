import React, { ReactElement, FC } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import MaterialDialog, { DialogProps as MaterialDialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { theme } from 'theme';
import { TimesIcon, IconWrapper, Typography, Button } from 'components';
import { useCommonStyles } from 'shared/styles/common';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useDialogContentNativeStyles = makeStyles({
  root: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
});

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      boxShadow: theme.shadows[1],
    },
    '& .MuiDialogTitle-root': {
      marginTop: theme.spacing(12),
      padding: '0 40px',
      paddingBottom: theme.spacing(6),
    },
    '& .MuiDialogContent-root': {
      padding: '0 40px',
      overflow: 'hidden',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 500,
    },
    '& .MuiDialog-paperWidthMd': {
      maxWidth: 700,
    },
    '& .MuiDialog-paperWidthLg': {
      maxWidth: 992,
    },
    '& .MuiDialog-scrollBody': {
      [theme.breakpoints.down(992)]: {
        overflowX: 'scroll',
      },
    },
  },
  modalLg: {
    width: 992,
  },
  modalMd: {
    width: 700,
  },
  modalSm: {
    width: 500,
  },
  closeButton: {
    position: 'absolute',
    top: 18,
    right: 12,
    width: 24,
    height: 24,
    minWidth: 24,
    minHeight: 24,
  },
  asterisk: {
    color: theme.palette.custom.darkCyan,
    marginLeft: theme.spacing(1),
  },
});

const useDialogActionsNativeStyles = makeStyles({
  root: {
    padding: theme.spacing(8, 10, 14, 10),
    justifyContent: 'space-between',
  },
  spacing: {
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(6),
    },
  },
});

export type DialogProps = {
  open: boolean;
  fullWidth?: boolean;
  maxWidth: 'sm' | 'md' | 'lg';
  title: string;
  titleExtraText?: string;
  titleExtraTextAsterisk?: boolean;
  handleClose: () => void;
  onActionAccept?: () => void;
  onActionCancel?: () => void;
  actionAcceptLabel?: string;
  actionCancelLabel?: string;
  showGoBack?: boolean;
  actionOnLeft?: () => void;
  actionOnLeftBtnText?: string;
} & MaterialDialogProps;

const Dialog: FC<DialogProps> = ({
  handleClose,
  open,
  title,
  titleExtraText,
  titleExtraTextAsterisk,
  maxWidth,
  fullWidth,
  actionCancelLabel,
  actionAcceptLabel,
  onActionAccept,
  onActionCancel,
  showGoBack,
  actionOnLeft,
  actionOnLeftBtnText,
  children,
}): ReactElement => {
  const isFullWidth: boolean = fullWidth ?? false;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const dialogContentNativeClasses = useDialogContentNativeStyles();
  const dialogActionsNativeClasses = useDialogActionsNativeStyles();
  const { t } = useTranslation();

  const onEnter = () => {
    /* As requested in SG-385, the modal needs to be scrollable within the body.
     * Due to the fact that body is scrollable too, in order to prevent the double scrollbars
     * we hide the document's scrollbars while the modal is opened.
     * SG-534 - couldn't make it work within a few hours for Alerts case. Everything seems to
     * work though when we completely stop manipulating overflow-x at html and body level.
     *  */
    // document.documentElement.style.overflow = 'hidden';
  };

  const onExited = () => {
    // document.documentElement.style.overflow = 'initial';
  };

  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <MaterialDialog
      fullWidth={isFullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      className={classes.root}
      scroll="body"
      onEnter={onEnter}
      onExited={onExited}
      disableBackdropClick={!isDownMd}
    >
      <DialogTitle
        disableTypography
        className={clsx(
          maxWidth === 'lg' && classes.modalLg,
          maxWidth === 'md' && classes.modalMd,
          maxWidth === 'sm' && classes.modalSm,
        )}
        id="max-width-dialog-title"
      >
        <IconWrapper
          className={classes.closeButton}
          bgcolor="transparent"
          bgcolorHover={theme.palette.custom.lightGray}
          onClick={handleClose}
        >
          <TimesIcon width={11} height={13} />
        </IconWrapper>
        <Typography variant="h5">{title}</Typography>
        {titleExtraText && (
          <Typography variant="body2" className={commonClasses.pt1} fontWeight="medium">
            {titleExtraText}
            {titleExtraTextAsterisk ? <span className={classes.asterisk}>*</span> : null}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent classes={dialogContentNativeClasses}>{children}</DialogContent>
      {!!(actionCancelLabel || onActionAccept) && (
        <DialogActions classes={dialogActionsNativeClasses}>
          <>
            {actionOnLeft && actionOnLeftBtnText && (
              <Typography variant="body2" link onClick={actionOnLeft}>
                {actionOnLeftBtnText}
              </Typography>
            )}
            {showGoBack ? (
              <Typography variant="body2" link onClick={handleClose}>
                {t('GO_BACK')}
              </Typography>
            ) : (
              <div />
            )}
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Typography variant="body2" link onClick={onActionCancel}>
                {actionCancelLabel}
              </Typography>
              {onActionAccept && actionAcceptLabel && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onActionAccept}
                  className={commonClasses.ml6}
                >
                  {actionAcceptLabel}
                </Button>
              )}
            </Box>
          </>
        </DialogActions>
      )}
    </MaterialDialog>
  );
};

export default Dialog;
