import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const EditIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 16,
  height = 16,
  fill = theme.palette.custom.darkCyan,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M12.6719 9.76562V13.3281C12.6615 13.5156 12.5938 13.6771 12.4688 13.8125C12.3438 13.9375 12.1875 14 12 14H2.67188C2.48438 14 2.32292 13.9375 2.1875 13.8125C2.0625 13.6771 2 13.5156 2 13.3281V4C2 3.8125 2.0625 3.65625 2.1875 3.53125C2.32292 3.40625 2.48438 3.33854 2.67188 3.32812H6.23438C6.34896 3.32812 6.45833 3.30208 6.5625 3.25C6.66667 3.1875 6.74479 3.10417 6.79688 3C6.85938 2.89583 6.89062 2.78646 6.89062 2.67188C6.89062 2.54688 6.85938 2.43229 6.79688 2.32812C6.74479 2.22396 6.66667 2.14583 6.5625 2.09375C6.45833 2.03125 6.34896 2 6.23438 2H2.67188C2.09896 2.01042 1.625 2.20833 1.25 2.59375C0.875 2.96875 0.682292 3.4375 0.671875 4V13.3281C0.682292 13.901 0.875 14.375 1.25 14.75C1.625 15.125 2.09896 15.3177 2.67188 15.3281H12C12.5625 15.3177 13.0312 15.125 13.4062 14.75C13.7917 14.375 13.9896 13.901 14 13.3281V9.76562C14 9.57812 13.9323 9.42188 13.7969 9.29688C13.6719 9.17188 13.5156 9.10938 13.3281 9.10938C13.1406 9.10938 12.9844 9.17188 12.8594 9.29688C12.7344 9.42188 12.6719 9.57812 12.6719 9.76562ZM6 8.28125V10H7.71875L13.7188 4L12 2.28125L6 8.28125ZM12.4688 0.859375L15.1406 3.53125C15.2656 3.66667 15.3281 3.82292 15.3281 4C15.3281 4.17708 15.2656 4.33333 15.1406 4.46875L8.46875 11.1406C8.34375 11.2656 8.1875 11.3281 8 11.3281H5.32812C5.14062 11.3281 4.98438 11.2656 4.85938 11.1406C4.73438 11.0156 4.67188 10.8594 4.67188 10.6719V8C4.67188 7.8125 4.73438 7.65625 4.85938 7.53125L11.5312 0.859375C11.6667 0.734375 11.8229 0.671875 12 0.671875C12.1771 0.671875 12.3333 0.734375 12.4688 0.859375Z"
        fill={fill}
      />
    </svg>
  );
};

export default EditIcon;
