import React, { ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import MaterialSwitch, { SwitchProps as MaterialSwitchProps } from '@material-ui/core/Switch';
import { theme } from 'theme';

import { BaseProps } from 'shared/types';
import { TFunction } from 'i18next';
import { Typography } from 'components';
import { withFormikField } from 'shared/hocs/formik';

type SwitchProps = {
  label?: string;
} & BaseProps &
  MaterialSwitchProps;

type StyleProps = {
  t: TFunction;
};

const useNativeStyles = makeStyles({
  root: {
    width: 56,
    height: 24,
    padding: 0,
    boxSizing: 'content-box',
  },
  switchBase: {
    padding: theme.spacing(1),
    color: '#ff6a00',
  },
  thumb: {
    width: 16,
    height: 16,
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
  track: {
    background: theme.palette.custom.grayAlt,
    opacity: '1 !important',
    borderRadius: 100,
    position: 'relative',
    '&:before, &:after': {
      display: 'inline-block',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#fff',
      textAlign: 'center',
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    '&:before': {
      color: theme.palette.custom.white,
      content: ({ t }: StyleProps) => `"${t('SWITCH.YES')}"`,
      left: 8,
      opacity: 0,
    },
    '&:after': {
      color: theme.palette.custom.veryDarkGrayAlt2,
      content: ({ t }: StyleProps) => `"${t('SWITCH.NO')}"`,
      right: 8,
    },
  },
  checked: {
    '&$switchBase': {
      color: '#185a9d',
      transform: 'translateX(32px)',
      '&:hover': {
        backgroundColor: 'rgba(24,90,257,0.08)',
      },
    },
    '& $thumb': {
      backgroundColor: '#fff',
    },
    '& + $track': {
      background: theme.palette.custom.darkCyan,
      '&:before': {
        opacity: 1,
      },
      '&:after': {
        opacity: 0,
      },
    },
  },
});

const Switch: FC<SwitchProps> = ({ label, ...restProps }): ReactElement => {
  const { t } = useTranslation();
  const nativeClasses = useNativeStyles({ t });

  const main = <MaterialSwitch color="default" classes={nativeClasses} {...restProps} />;

  return label ? (
    <Box display="flex" alignItems="center">
      <Typography variant="caption" marginRight={2}>
        {label}
      </Typography>
      {main}
    </Box>
  ) : (
    main
  );
};

const SwitchFormik = withFormikField<SwitchProps>(Switch);

export default Switch;
export { SwitchFormik };
