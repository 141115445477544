import React, { ReactElement, FC } from 'react';
import Box from '@material-ui/core/Box';
import { theme } from 'theme';
import { Typography } from 'components';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  bubbleWrap: {
    pointerEvents: 'none',
  },
  image: {
    position: 'relative',
    right: '47px',
    bottom: '29px',
  },
  textWrap: {
    position: 'relative',
    right: '48px',
    bottom: '28px',
    textAlign: 'center',
  },
  textBold: {
    margin: '0.5rem 0 1rem',
  },
  arrow: {
    position: 'relative',
    zIndex: 1,
    rotate: '292deg',
  },
  caption: {
    display: 'block',
    lineHeight: '1.0675rem',
    color: theme.palette.custom.veryDarkGrayAlt2,
  },
  bubble: {
    padding: '10px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.custom.veryDarkGray,
    background: 'url(/images/shipments/bubble-green.svg)',
    width: 222,
    height: 100,
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    '&.shortText': {
      padding: '10px 50px',
    },
  },
}));

const UseFullScreen: FC = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box position="absolute" top={-64} left={-118} className={classes.bubbleWrap}>
      <img className={classes.arrow} alt="" src="/images/shipments/arrow-variant-4.svg" />
      <Box position="absolute" top={88} right={106} width={167} textAlign="center">
        <Typography className={`${classes.caption} ${classes.bubble}`} variant="caption">
          {t('TRY_FULL_SCREEN')}
        </Typography>
      </Box>
    </Box>
  );
};

export default UseFullScreen;
