import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const PalletIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 27,
  height = 27,
  fill = theme.palette.custom.gray,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M15.3253 1.15625L24.6495 5.80469C25.251 6.11458 25.725 6.55208 26.0714 7.11719C26.4177 7.66406 26.5909 8.27474 26.5909 8.94922V20.0781C26.5909 20.7526 26.4177 21.3633 26.0714 21.9102C25.725 22.457 25.251 22.8854 24.6495 23.1953L15.3253 27.8711C14.8331 28.1081 14.3135 28.2266 13.7667 28.2266C13.2198 28.2266 12.7003 28.1081 12.2081 27.8711L2.85651 23.1953C2.25495 22.8854 1.78099 22.457 1.43464 21.9102C1.10651 21.3451 0.942448 20.7253 0.942448 20.0508V8.94922C0.942448 8.27474 1.11562 7.66406 1.46198 7.11719C1.80833 6.55208 2.28229 6.11458 2.88385 5.80469L12.2081 1.15625C12.7003 0.901042 13.2198 0.773438 13.7667 0.773438C14.3135 0.773438 14.8331 0.901042 15.3253 1.15625ZM22.8174 7.5L14.2862 3.23438C13.9398 3.07031 13.5935 3.07031 13.2471 3.23438L10.5401 4.57422L19.5909 9.11328L22.8174 7.5ZM24.2667 9.38672L20.1378 11.4648L14.9424 14.0625V25.4648L23.6104 21.1172C23.8109 21.0078 23.9659 20.862 24.0753 20.6797C24.2029 20.4974 24.2667 20.2969 24.2667 20.0781V9.38672ZM12.5909 25.4648V14.0625L3.26667 9.38672V20.0508C3.26667 20.2878 3.32135 20.4974 3.43073 20.6797C3.5401 20.862 3.69505 21.0078 3.89557 21.1172L12.5909 25.4648ZM7.94245 5.88672L4.71589 7.5L13.7667 12.0391L16.9932 10.4258L7.94245 5.88672Z"
        fill={fill}
      />
    </svg>
  );
};

export default PalletIcon;
