import React, { FC, ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, FileDropZone } from 'components';
import { ApiResponse } from 'shared/types/api';
import { useFiles } from 'shared/services/fileService';
import { ShipmentsExcelImportPayload } from 'shared/types/shipments/shipments';
import { useCommonStyles } from 'shared/styles/common';
import ShipmentImportResultTable from './ShipmentImportResultTable';

/* const useStyles = makeStyles({
  button: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      boxShadow: theme.shadows[3],
      '& .MuiTypography-caption': {
        textDecoration: 'none',
      },
    },
  },
  link: {
    fontSize: '1rem',
    lineHeight: '22px',
    color: theme.palette.custom.veryDarkViolet,
  },
  caption: {
    display: 'block',
    marginTop: theme.spacing(0.5),
    color: theme.palette.custom.gray,
  },
  selected: {
    backgroundColor: theme.palette.custom.lightGrayishViolet,
    borderColor: theme.palette.custom.mostlyDesaturatedDarkViolet,
    boxShadow: theme.shadows[1],
    '& .MuiTypography-caption:first-of-type': {
      textDecoration: 'none',
      fontWeight: 700,
    },
    '& .MuiTypography-caption': {
      color: theme.palette.custom.veryDarkViolet,
    },
  },
  templateList: {
    maxHeight: '50vh',
    overflow: 'auto',
  },
  templateListWithScrollbar: {
    paddingRight: theme.spacing(5),
  },
  separator: {
    position: 'absolute',
    left: 0,
    right: 0,
    boxShadow: '0 -5px 10px -2px rgb(0 0 0 / 15%)',
    height: theme.spacing(5),
  },
}); */

type ShipmentFromExcelProps = {
  showDialog: boolean;
  toggleShowDialog: () => void;
};

const ShipmentFromExcel: FC<ShipmentFromExcelProps> = ({
  showDialog,
  toggleShowDialog,
}): ReactElement => {
  const { t } = useTranslation();
  const { downloadFiles } = useFiles();
  const commonStyles = useCommonStyles();

  const [importPayload, setImportPayload] = useState<ShipmentsExcelImportPayload | undefined>(
    undefined,
  );
  const hideModal = () => {
    setImportPayload(undefined);
    toggleShowDialog();
  };
  const importModalTitle = () => {
    if (importPayload) {
      if (!importPayload?.malformedRowsCount && importPayload.correctRowsCount > 0) {
        return t(`SHIPMENT.IMPORT_MODAL_TITLE_CORRECT_ROWS`).replace(
          '{0}',
          importPayload.correctRowsCount.toString(),
        );
      }
      return t(
        `SHIPMENT.${
          importPayload?.malformedRowsCount || importPayload?.duplicateRowsCount
            ? 'IMPORT_MODAL_TITLE_INCORRECT_ROWS'
            : 'IMPORT_MODAL_TITLE_CORRECT_ROWS'
        }`,
      ).replace('{0}', importPayload.correctRowsCount.toString());
    }
    return t('SHIPMENT.IMPORT_MODAL_TITLE');
  };

  const fileDropZoneRef = useRef<{
    uploadAcceptedFiles: (
      objectId: number,
    ) => Promise<ApiResponse<ShipmentsExcelImportPayload> | undefined>;
  }>(null);

  const uploadFiles = async () => {
    const result = await fileDropZoneRef?.current?.uploadAcceptedFiles(0);
    setImportPayload(result?.payload);
    if (!result?.payload) toggleShowDialog();
  };

  const downloadErrorFile = async () => {
    const id = parseInt(importPayload?.issuesFileReference ?? '', 10);
    await downloadFiles({
      id: 0,
      objectId: id,
      object: 'ShipmentImportErrorReport',
      fileName: 'shipments_import_errors.xlsx',
    });
  };

  const uploadCorrectRows = async () => {
    setImportPayload(undefined);
  };

  const inCorrectBtnText =
    importPayload && !importPayload.validationErrors?.length
      ? t('SHIPMENT.BTN_IMPORT_FINISH')
      : t('SHIPMENT.BTN_IMPORT_CORRECT_ROWS');

  const downloadExcelTemplate = () => {
    window.location.href = '/files/template_shipments_import.xlsx';
  };

  const onActionAcceptMethod =
    importPayload && !importPayload.validationErrors?.length ? hideModal : uploadCorrectRows;
  const onDialogLeftAction =
    importPayload && importPayload.issuesFileReference?.length
      ? downloadErrorFile
      : downloadExcelTemplate;
  const onDialogLeftActionText =
    importPayload && importPayload.issuesFileReference?.length
      ? t('SHIPMENT.BTN_IMPORT_DOWNLOAD_ERROR_XLSX')
      : undefined;

  return (
    <Dialog
      title={importModalTitle()}
      titleExtraText={!importPayload ? t('SHIPMENT.IMPORT_MODAL_TITLE_SMALL') : undefined}
      maxWidth="lg"
      open={showDialog}
      handleClose={hideModal}
      actionCancelLabel={t('CANCEL')}
      actionAcceptLabel={!importPayload ? t('SHIPMENT.BTN_IMPORT') : inCorrectBtnText}
      onActionCancel={hideModal}
      onActionAccept={!importPayload ? uploadFiles : onActionAcceptMethod}
      actionOnLeft={onDialogLeftAction}
      actionOnLeftBtnText={
        (importPayload?.malformedRowsCount ?? 0) > 0
          ? onDialogLeftActionText
          : t('SHIPMENT.BTN_DOWNLOAD_XLSX_TEMPLATE')
      }
    >
      {!importPayload && showDialog && (
        <FileDropZone
          onlyOneFileAllowed
          object="ImportShipments"
          ref={fileDropZoneRef}
          hideTitle
          acceptTypes={['.xlsx']}
          supportedFilesText={t(`DROPZONE.SUPPORTED_FILES_XLS`)}
        />
      )}

      {importPayload && importPayload.validationErrors?.length > 0 && (
        <ShipmentImportResultTable
          errorMessage={t('SHIPMENT.ERROR_MALFORMED_ROWS_TEXT')}
          cell2Header={t('SHIPMENT.EXCEL_ROW_MISSING_WRONG_INFO')}
          shipments={importPayload.validationErrors}
        />
      )}

      {importPayload?.exists && importPayload.exists?.length > 0 && (
        <div className={commonStyles.mt12}>
          <ShipmentImportResultTable
            errorMessage={t('SHIPMENT.ERROR_DUPLICATED_ROWS_TEXT')}
            cell2Header={t('SHIPMENT.EXCEL_ROW_EXISTS')}
            shipments={importPayload.exists.map((item) => {
              return {
                reference: item.reference,
                doNotTranslate: true,
                errors: [
                  {
                    field: '',
                    row: item.row,
                    message: `${item.reference}, ${t('CREATED_ON')} ${item.createdOn}`,
                  },
                ],
              };
            })}
          />
        </div>
      )}
    </Dialog>
  );
};

export default ShipmentFromExcel;
