import React, { ReactElement, FC } from 'react';
import { theme } from 'theme';
import { BaseProps } from 'shared/types';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

type ShipmentItemTotalsProps = {
  totalWeight: number;
  totalLdm: number;
  totalVolume: number;
} & BaseProps;

const useStyles = makeStyles({
  totalNumbers: {
    color: 'white',
    borderRadius: 5,
    display: 'flex',
  },
  totalItem: {
    marginLeft: 12,
  },
});

const ShipmentItemTotals: FC<ShipmentItemTotalsProps> = ({
  totalWeight = 0,
  totalLdm = 0,
  totalVolume = 0,
}): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Box
        bgcolor={theme.palette.custom.darkCyan}
        paddingX={theme.spacing(2)}
        paddingY={theme.spacing(0.5)}
        className={classes.totalNumbers}
      >
        {t('SHIPMENT.ITEM.TOTAL')}:
        <Box className={classes.totalItem}>{totalWeight.toFixed(1)} KG</Box>
        <Box className={classes.totalItem}>{totalLdm.toFixed(2)} LDM</Box>
        <Box className={classes.totalItem}>
          {totalVolume.toFixed(3)} m<sup>3</sup>
        </Box>
      </Box>
    </Box>
  );
};

export default ShipmentItemTotals;
