import React, { ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import Box, { BoxProps } from '@material-ui/core/Box';
import { ShipmentAddress, Typography } from 'components';

import { BaseProps } from 'shared/types';
import { LoadingContact } from 'shared/types/loadingContact';

type ShipmentAddressesProps = {
  senderAddress?: LoadingContact;
  receiverAddress?: LoadingContact;
  alternativeSenderAddress?: LoadingContact | null;
  alternativeReceiverAddress?: LoadingContact | null;
  smallHeading?: boolean;
  showEmptyBlocks?: boolean;
  boxProps?: BoxProps;
} & BaseProps;

const ShipmentAddresses: FC<ShipmentAddressesProps> = ({
  senderAddress,
  receiverAddress,
  alternativeReceiverAddress,
  alternativeSenderAddress,
  smallHeading,
  boxProps = {},
  showEmptyBlocks,
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <Box marginTop={10} {...boxProps}>
      <Typography
        variant={smallHeading ? 'subtitle2' : 'h5'}
        fontWeight={smallHeading ? 'semibold' : undefined}
        marginBottom={4}
      >
        {t('SHIPMENT.ADDRESSES.SENDER_AND_DELIVERY_ADDRESSES')}
      </Typography>
      <Box display="flex">
        {senderAddress && (
          <ShipmentAddress
            address={senderAddress}
            alternativeAddress={alternativeSenderAddress}
            type="sender"
            width={468}
            marginRight={6}
            showEmptyBlocks={showEmptyBlocks}
          />
        )}
        {receiverAddress && (
          <ShipmentAddress
            address={receiverAddress}
            alternativeAddress={alternativeReceiverAddress}
            type="receiver"
            width={468}
            showEmptyBlocks={showEmptyBlocks}
          />
        )}
      </Box>
    </Box>
  );
};

export default ShipmentAddresses;
