import React, { ReactElement, FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import {
  Typography,
  ShipmentItemButton,
  Placeholder,
  Checkbox,
  TextField,
  ShipmentItemEditable,
  ErrorMessage,
} from 'components';

import { BaseProps } from 'shared/types';
import { ShipmentItemSubType, ShipmentItemType } from 'shared/types/shipments/shipmentItem';
import { generateKey } from 'shared/functions/generateKey';
import { NewShipmentPayload, ShipmentDetailsRow } from 'shared/types/shipments/shipments';
import { shipmentItemTypesToSubTypes } from 'shared/functions/shipments/shipmentItemTypesMapping';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { theme } from 'theme';
import clsx from 'clsx';
import { AutomaticPricesState, NewShipmentContext } from 'scenes/NewShipment/NewShipment';
import ShipmentItemTotals from '../../ShipmentItemTotals';

type ShipmentItemsStepProps = {
  isPriceRequest?: boolean;
  isTemplateView?: boolean;
} & BaseProps;

const supportedItems: ShipmentItemType[] = [
  ShipmentItemType.Pallet,
  ShipmentItemType.Box,
  ShipmentItemType.Truck,
  ShipmentItemType.Container,
  ShipmentItemType.Other,
  ShipmentItemType.Machine,
];

const ShipmentItemsStep: FC<ShipmentItemsStepProps> = ({
  isPriceRequest = false,
  isTemplateView = false,
}): ReactElement => {
  const { t } = useTranslation();
  const { setAutomaticPricesState } = useContext(NewShipmentContext);
  const formik = useFormikContext<NewShipmentPayload>();

  const shipmentItems = formik.values.shipmentDetailsRows ?? [];
  const { tailLiftTruck } = formik.values;

  useEffect(() => {
    setAutomaticPricesState(AutomaticPricesState.InProgress);
  }, [setAutomaticPricesState, tailLiftTruck]);

  const noItemsError =
    (!Array.isArray(formik.errors.shipmentDetailsRows) && formik.errors.shipmentDetailsRows) ??
    null;

  const shipmentItemButtonClicked = (itemType: ShipmentItemType) => {
    if (shipmentItems.length < 1) {
      formik.setFieldError('shipmentDetailsRows', '');
    }

    if (itemType in shipmentItemTypesToSubTypes) {
      const newItem: ShipmentDetailsRow = {
        typeId: shipmentItemTypesToSubTypes[itemType][0],
      };

      if (newItem.typeId?.toString() === ShipmentItemSubType.FullTruckLoad.toString()) {
        newItem.ldm = 13.6;
      }
      formik.setFieldValue('shipmentDetailsRows', [...shipmentItems, newItem]);
    }
  };

  // eslint-disable-next-line no-nested-ternary
  const title = isPriceRequest
    ? 'PRICE_REQUEST.ADD_ITEMS_TO_YOUR_PRICE_REQUEST'
    : isTemplateView
    ? 'TEMPLATE.ADD_ITEMS_TO_YOUR_TEMPLATE'
    : 'SHIPMENT.ADD_ITEMS_TO_YOUR_SHIPMENT';

  let totalWeight = 0;
  let totalLdm = 0;
  let totalVolume = 0;
  shipmentItems.forEach((item) => {
    if (item.totalWeight) totalWeight = totalWeight + parseFloat(item.totalWeight.toString()) ?? 0;
    if (item.volume) totalVolume = totalVolume + parseFloat(item.volume.toString()) ?? 0;
    if (item.ldm) totalLdm = totalLdm + parseFloat(item.ldm.toString()) ?? 0;
  });
  const content = (arrayHelpers: FieldArrayRenderProps) => (
    <Box width="100%">
      <Typography variant="h5" marginBottom={4}>
        {t(title)}
      </Typography>
      <Typography variant="body2" fontWeight="semibold">
        {t('SHIPMENT.CLICK_ON_THE_ITEM_YOU_WANT_TO_ADD')}:
      </Typography>
      <Box display="flex" marginTop={3} className={clsx(noItemsError && 'Mui-error')}>
        {supportedItems.map((itemType, index) => (
          <ShipmentItemButton
            key={generateKey(index, 'shipment_item_button')}
            itemType={itemType}
            marginRight={4}
            onClick={() => {
              arrayHelpers.insert(shipmentItems.length, {});
              shipmentItemButtonClicked(itemType);
            }}
            borderColor={noItemsError ? theme.palette.custom.alertError : undefined}
          />
        ))}
      </Box>
      {noItemsError && <ErrorMessage marginTop={2}>{noItemsError}</ErrorMessage>}
      <Typography variant="body2" fontWeight="semibold" marginTop={6} marginBottom={3}>
        {t('SHIPMENT.ITEMS_ADDED')}:
      </Typography>
      {!shipmentItems.length ? (
        <Placeholder
          text={t(
            isPriceRequest
              ? 'PRICE_REQUEST.CLICK_ONE_OR_MORE_ITEMS_ABOVE_TO_START_ADDING_THEM_TO_YOUR_PRICE_REQUEST'
              : 'SHIPMENT.CLICK_ONE_OR_MORE_ITEMS_ABOVE_TO_START_ADDING_THEM_TO_YOUR_SHIPMENT',
          )}
          marginBottom={6}
        />
      ) : (
        <Box display="flex" flexDirection="column-reverse">
          {shipmentItems.map((_, index) => (
            <Box marginBottom={6} key={generateKey(index, 'shipment_item_editable')}>
              <ShipmentItemEditable
                name={`shipmentDetailsRows[${index}]`}
                index={index}
                isTemplateView={isTemplateView}
                onDelete={() => arrayHelpers.remove(index)}
              />
            </Box>
          ))}
        </Box>
      )}
      {shipmentItems.length > 0 && (
        <ShipmentItemTotals
          totalLdm={totalLdm}
          totalVolume={totalVolume}
          totalWeight={totalWeight}
        />
      )}
      <Box display="flex" alignItems="center" marginBottom={isPriceRequest ? 1 : 6}>
        <Checkbox
          name="tailLiftTruck"
          variant="dark"
          onChange={formik.handleChange}
          checked={formik.values.tailLiftTruck}
          label={t('SHIPMENT.I_NEED_PICKUP_WITH_A_TAIL_LIFT_TRUCK')}
          tooltip={t('SHIPMENT.I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK_TOOLTIP')}
        />
      </Box>
      <Box display="flex" alignItems="center" marginBottom={isPriceRequest ? 1 : 6}>
        <Checkbox
          name="tailLiftTruckDelivery"
          variant="dark"
          onChange={formik.handleChange}
          checked={formik.values.tailLiftTruckDelivery}
          label={t('SHIPMENT.I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK')}
          tooltip={t('SHIPMENT.I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK_TOOLTIP')}
        />
      </Box>
      {!isPriceRequest && (
        <>
          <Typography variant="body2" marginBottom={2}>
            {t('SHIPMENT.ADD_A_COMMENT_REGARDING_THE_ITEMS_ADDED')}
          </Typography>
          <TextField
            name="otherInstructions"
            variant="variant6"
            size="small"
            fullWidth
            multiline
            inputProps={{ maxLength: 2000 }}
            rows={4}
            onChange={formik.handleChange}
            value={formik.values.otherInstructions}
          />
        </>
      )}
    </Box>
  );

  return <FieldArray name="shipmentDetailsRows" render={content} />;
};

export default ShipmentItemsStep;
