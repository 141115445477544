import { isObjectEmpty } from 'shared/functions/isObjectEmpty';
import { ShipmentPayload } from 'shared/types/shipments/shipments';

export const shipmentParser = (shipment: ShipmentPayload): ShipmentPayload => ({
  ...shipment,
  receiverAlternative: !isObjectEmpty(shipment.receiverAlternative)
    ? shipment.receiverAlternative
    : null,
  senderAlternative: !isObjectEmpty(shipment.senderAlternative) ? shipment.senderAlternative : null,
});
