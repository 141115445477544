import React, { FC, ReactElement, useEffect, useState } from 'react';
import { SelectProps as MaterialSelectProps } from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormikContext } from 'formik';
import { withFormikField } from 'shared/hocs/formik';
import { NewShipmentPayload } from 'shared/types/shipments/shipments';
import { TextFieldSlowFormik } from '../TextField/TextField';
import { SelectItem } from '../MultiSelect/MultiSelect';

type Value = string | undefined;

export type AutocompleteSelectProps = {
  variant?: 'light' | 'dark';
  background?: 'opaque' | 'transparent';
  size?: 'small' | 'default';
  defaultValue?: Value;
  defaultLabel?: string;
  noBorder?: boolean;
  borderColor?: string;
  data: SelectItem[];
} & Omit<MaterialSelectProps, 'variant'>;

const AutocompleteSelect: FC<AutocompleteSelectProps> = ({ data, value, name }): ReactElement => {
  const [selectValue, setSelectValue] = React.useState<SelectItem | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const formik = useFormikContext<NewShipmentPayload>();

  useEffect(() => {
    if (data) {
      const selected = data.filter((item) => item.value === value);
      if (selected.length > 0) setSelectValue(selected[0]);
    }
  }, [data, value]);

  return (
    <Autocomplete
      getOptionLabel={(option: SelectItem | string) =>
        typeof option === 'string' ? option : option.label
      }
      options={data}
      value={selectValue}
      onChange={(_, newValue: SelectItem | string | null) => {
        if (typeof newValue === 'string') {
          formik.setFieldValue(name ?? '', newValue);
        } else {
          // User has chosen one of the suggested options
          formik.setFieldValue(name ?? '', newValue?.value);
          setSelectValue(newValue);
        }
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <>
          <TextFieldSlowFormik
            {...params}
            inputProps={{ ...params.inputProps, autoComplete: 'custom-autocomplete' }}
            InputProps={{ ...params.InputProps, className: undefined }}
            fullWidth
            name={name}
            size="small"
            variant="variant2"
            value={inputValue}
            showErrorLabel
          />
        </>
      )}
    />
  );
};

const AutocompleteSelectFormik = withFormikField<AutocompleteSelectProps>(AutocompleteSelect);

export default AutocompleteSelect;
export { AutocompleteSelectFormik };
