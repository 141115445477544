import React, { ReactElement, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import Box, { BoxProps } from '@material-ui/core/Box';
import { Chip, ReadMore, Typography } from 'components';
import { theme } from 'theme';

import { BaseProps } from 'shared/types';
import { PriceRequestOfferedPrice } from 'shared/types/priceRequests/priceRequests';
import { useUser } from 'shared/hooks/useUser';

type PriceOfferProps = {
  offeredPrice: PriceRequestOfferedPrice;
  selected?: boolean;
  onSelected?: (id: number) => void;
  showError?: boolean;
  showFullComment?: boolean;
  hideCharacterization: boolean;
  isPriceRequest?: boolean;
  onClickOrderNow?: (offeredPrice: PriceRequestOfferedPrice) => void;
} & BoxProps &
  BaseProps;

const useStyles = makeStyles({
  selectable: {
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
    cursor: 'pointer',
  },
  chip: {
    backgroundColor: `${theme.palette.custom.veryLightGrayAlt} !important`,
  },
  chipSelected: {
    backgroundColor: `${theme.palette.custom.verySoftViolet} !important`,
    fontWeight: 700,
  },
  chipPartnerName: {
    backgroundColor: `${theme.palette.custom.grayAlt} !important`,
  },
  chipPartnerNameSelected: {
    backgroundColor: `${theme.palette.custom.grayAlt} !important`,
    fontWeight: 700,
  },
  smallText: {
    fontSize: '0.75rem',
  },
});

const PriceOffer: FC<PriceOfferProps> = ({
  offeredPrice,
  selected,
  onSelected,
  showError,
  showFullComment = false,
  hideCharacterization,
  isPriceRequest,
  onClickOrderNow,
  ...boxProps
}): ReactElement => {
  const { id, price, comment, transitTime, onTimeDelivery, partnerName, comments } = offeredPrice;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { profile } = useUser();
  const onPriceOfferSelected = useCallback(() => {
    if (onSelected) {
      onSelected(id);
    }
  }, [id, onSelected]);

  let boxBorderColor = selected
    ? theme.palette.custom.strongViolet
    : theme.palette.custom.lightGray;
  if (showError) boxBorderColor = theme.palette.custom.alertError;

  const commentBgColor = onSelected
    ? theme.palette.custom.lightGrayishVioletAlt3
    : theme.palette.custom.veryLightGrayAlt2;

  let languageComment = comment;
  if (comments && comments.length > 0) {
    let languageName = '';
    switch (i18n.language) {
      case 'et':
        languageName = 'Estonian';
        break;
      case 'fi':
        languageName = 'Finnish';
        break;
      case 'se':
        languageName = 'Swedish';
        break;
      default:
        languageName = 'English';
        break;
    }
    if (languageName) {
      const newComment = comments.filter((item) => item.language === languageName);
      if (newComment && newComment.length > 0) {
        languageComment = newComment[0].comment;
      }
    }
  }
  return (
    <Box
      border={1}
      borderColor={boxBorderColor}
      bgcolor={selected ? theme.palette.custom.lightGrayishVioletAlt4 : undefined}
      borderRadius={16}
      boxShadow={selected ? 3 : undefined}
      paddingTop={8}
      paddingBottom={!languageComment ? 9 : 0}
      width={288}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      onClick={onPriceOfferSelected}
      className={onSelected ? classes.selectable : undefined}
      {...boxProps}
    >
      {profile && profile.showPartnerNameOnPrice && partnerName && (
        <Box marginBottom={theme.spacing(0.5)}>
          <Chip
            size="small"
            className={selected ? classes.chipPartnerNameSelected : classes.chipPartnerName}
            label={partnerName}
          />
        </Box>
      )}
      <Box marginTop={4} marginBottom={2} textAlign="center">
        <Typography
          variant="body2"
          className={classes.smallText}
          fontStyle="italic"
          marginBottom={0.8}
        >
          {t('PRICE_REQUEST.ESTIMATED_DELIVERY')}
        </Typography>
        <Typography variant="h5" fontWeight={selected ? 'black' : 'regular'} marginBottom={0.5}>
          {transitTime && transitTime !== '0'
            ? `${transitTime} ${
                transitTime === '1'
                  ? t('PRICE_REQUEST.WORKING_DAY_DELIVERY')
                  : t('PRICE_REQUEST.WORKING_DAYS_DELIVERY')
              }`
            : ''}
          {transitTime && transitTime === '0' ? `${t('PRICE_REQUEST.SAME_DAY_DELIVERY')}` : ''}
        </Typography>
        <Typography variant="caption" fontWeight="bold" component="p">
          {onTimeDelivery ? `${t('PRICE_REQUEST.ON_TIME_DELIVERY')} >${onTimeDelivery}%` : ''}
        </Typography>
      </Box>
      <Box display="flex" alignItems="baseline" marginBottom={4}>
        <Typography variant="h5" fontWeight="bold" customColor={theme.palette.common.black}>
          {price ? price.toFixed(2) : ''}
        </Typography>
        <Typography marginLeft={1} variant="body1">
          €
        </Typography>
      </Box>
      {onSelected && (
        <Typography variant="caption" component="p" link marginBottom={comment ? 0 : 10}>
          {t(selected ? 'PRICE_REQUEST.THIS_OPTION_UNSELECT' : 'PRICE_REQUEST.CHOOSE_THIS_OPTION')}
        </Typography>
      )}
      {languageComment && (
        <Box
          bgcolor={languageComment ? commentBgColor : undefined}
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingTop={5.5}
          paddingX={4}
          paddingBottom={5}
          marginTop={4}
          width="100%"
        >
          <Typography variant="caption" textAlign="center">
            {showFullComment ? (
              languageComment
            ) : (
              <ReadMore dialogTitle={t('PRICE_REQUEST.COMMENT_RELATED_TO_PRICE')} lines={2}>
                {languageComment}
              </ReadMore>
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PriceOffer;
