import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, TextField, Typography } from 'components';
import Box from '@material-ui/core/Box';
import { theme } from 'theme';
import { makeStyles } from '@material-ui/styles';
import { generateDate } from 'shared/functions/date';
import { generateKey } from 'shared/functions/generateKey';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { routeShipments } from 'routes';
import { useTemplates } from 'shared/services/templates';
import { useTemplatesParser } from 'shared/parsers/useTemplatesParser';
import { DatatableTemplate } from 'shared/types/templates/templates';
import { Form, Formik } from 'formik';
import { ISimpleFilters } from 'shared/types';
import { useCommonStyles } from 'shared/styles/common';

const useStyles = makeStyles({
  button: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      boxShadow: theme.shadows[3],
      '& .MuiTypography-caption': {
        textDecoration: 'none',
      },
    },
  },
  link: {
    fontSize: '1rem',
    lineHeight: '22px',
    color: theme.palette.custom.veryDarkViolet,
  },
  caption: {
    display: 'block',
    marginTop: theme.spacing(0.5),
    color: theme.palette.custom.gray,
  },
  selected: {
    backgroundColor: theme.palette.custom.lightGrayishViolet,
    borderColor: theme.palette.custom.mostlyDesaturatedDarkViolet,
    boxShadow: theme.shadows[1],
    '& .MuiTypography-caption:first-of-type': {
      textDecoration: 'none',
      fontWeight: 700,
    },
    '& .MuiTypography-caption': {
      color: theme.palette.custom.veryDarkViolet,
    },
  },
  templateList: {
    maxHeight: '50vh',
    overflow: 'auto',
  },
  templateListWithScrollbar: {
    paddingRight: theme.spacing(5),
  },
  separator: {
    position: 'absolute',
    left: 0,
    right: 0,
    boxShadow: '0 -5px 10px -2px rgb(0 0 0 / 15%)',
    height: theme.spacing(5),
  },
});

type ShipmentFromTemplateProps = {
  showDialog: boolean;
  toggleShowDialog: () => void;
};

const initialValues: ISimpleFilters = {
  keyword: '',
};

const ShipmentFromTemplate: FC<ShipmentFromTemplateProps> = ({
  showDialog,
  toggleShowDialog,
}): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { fetchTemplates } = useTemplates();
  const [shipmentsData, setShipmentsData] = useState<DatatableTemplate[]>([]);
  const [totalCountTemplates, setTotalCountTemplates] = useState<number>(0);

  const [stateKeyword, setStateKeyword] = useState<string>('');
  const [selectedTemplate, setSelectedTemplate] = useState<number | undefined>(undefined);
  const [hasScrollbar, setHasScrollbar] = useState<boolean>(false);
  const { templatesParser } = useTemplatesParser();
  const templateListRef = useRef<HTMLDivElement>(null);
  const commonClasses = useCommonStyles();
  useEffect(() => {
    setHasScrollbar(
      templateListRef.current
        ? templateListRef.current.scrollHeight > templateListRef.current.clientHeight
        : false,
    );
  }, [templateListRef.current]);

  const getTemplates = async (keyword: string) => {
    try {
      const response = await fetchTemplates({
        keyword,
        sizePerPage: 30,
        page: 1,
        createdByMe: false,
        filters: {
          isTemplate: 'true',
          keyword,
        },
      });
      if (response.isSuccessful && response.payload) {
        const data = response.payload?.contextObjects;
        if (data) {
          const parsed = templatesParser(data);
          setShipmentsData(parsed);
        }
        setTotalCountTemplates(response.payload?.totalCount ?? 0);
      }
    } catch (_error) {
      // TODO: Add error handling once the system notifications implementation is ready
    }
  };
  const addNewShipment = () => {
    if (selectedTemplate) {
      toggleShowDialog();
      history.push(`${routeShipments}/new-shipment?templateId=${selectedTemplate}`);
    }
  };
  useEffect(() => {
    setSelectedTemplate(undefined);
    if (showDialog) {
      getTemplates('');
    }
  }, [showDialog]);

  useEffect(() => {
    if (stateKeyword) {
      getTemplates(stateKeyword);
    }
  }, [stateKeyword]);

  return (
    <Dialog
      title={t('SHIPMENT.TITLE_ADD_NEW_SHIPMENT_FROM_TITLE')}
      maxWidth="md"
      open={showDialog}
      handleClose={toggleShowDialog}
      actionCancelLabel={t('CANCEL')}
      actionAcceptLabel={t('SHIPMENT.BTN_ADD_SHIPMENT')}
      onActionCancel={toggleShowDialog}
      onActionAccept={addNewShipment}
    >
      <Typography marginBottom={6} variant="body1">
        {t('SHIPMENT.FROM_TEMPLATE_DESCRIPTION')}
      </Typography>
      <Box>
        <Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
          {({ setFieldValue }) => (
            <Form>
              <Typography marginBottom={2} variant="h6">
                {t('TEMPLATE.SEARCH_FOR_A_TEMPLATE_TITLE')}
              </Typography>
              <Box display="flex" height={32}>
                <TextField
                  size="small"
                  variant="variant6"
                  width={620}
                  placeholder={t('TEMPLATE.SEARCH_BY_ALL_TEMPLATES_TEXT_FIELDS')}
                  className={commonClasses.placeHolder}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('keyword', event.target?.value ?? '');
                    setStateKeyword(event.target?.value ?? '');
                  }}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <Typography marginTop={6} marginBottom={2} variant="h6">
        {t('TEMPLATE.TEMPLATES')} ({totalCountTemplates}):
      </Typography>
      <div
        ref={templateListRef}
        className={clsx(classes.templateList, hasScrollbar && classes.templateListWithScrollbar)}
      >
        {shipmentsData.map((item, index) => {
          return (
            <Box
              className={clsx(classes.button, selectedTemplate === item.id && classes.selected)}
              key={generateKey(item.id, 'template_item')}
              border={1}
              borderRadius={8}
              borderColor={theme.palette.custom.grayAlt}
              paddingX={4}
              paddingY={4}
              marginBottom={index !== shipmentsData.length - 1 ? 4 : 8}
              onClick={() => setSelectedTemplate(item.id)}
            >
              <Typography link variant="caption" className={classes.link}>
                {item.templateName}
              </Typography>
              <Typography className={classes.caption} variant="caption">
                {t('TEMPLATE.TEMPLATE_CREATED').replace('{0}', generateDate(item.createdOn))}
              </Typography>
            </Box>
          );
        })}
      </div>
      <Box className={classes.separator} />
    </Dialog>
  );
};

export default ShipmentFromTemplate;
