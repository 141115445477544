import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const ChatIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 12,
  height = 20,
  fill = theme.palette.custom.white,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M3.19531 8.57031C3.32812 8.53125 3.45312 8.54297 3.57031 8.60547C4.10156 8.87109 4.66016 9.00391 5.24609 9.00391C5.97266 8.99609 6.62891 8.80859 7.21484 8.44141C7.80859 8.07422 8.26953 7.57031 8.59766 6.92969C8.86328 6.39844 8.99609 5.83984 8.99609 5.25391V5.03125C8.92578 4.05469 8.56641 3.24219 7.91797 2.59375C7.26953 1.9375 6.46484 1.57422 5.50391 1.50391H5.24609C4.66016 1.50391 4.10156 1.63672 3.57031 1.90234C2.92969 2.23047 2.42578 2.69141 2.05859 3.28516C1.69141 3.87109 1.50391 4.52734 1.49609 5.25391C1.49609 5.83984 1.62891 6.39844 1.89453 6.92969C1.95703 7.04688 1.96875 7.17188 1.92969 7.30469L1.28516 9.21484L3.19531 8.57031ZM9.5 7.375C9.07812 8.1875 8.49219 8.82812 7.74219 9.29688C6.99219 9.75781 6.16406 9.99219 5.25781 10C4.57812 10 3.92969 9.86328 3.3125 9.58984L0.664062 10.4805C0.570312 10.5039 0.476562 10.5039 0.382812 10.4805C0.296875 10.4648 0.21875 10.4219 0.148438 10.3516C0.078125 10.2812 0.03125 10.1992 0.0078125 10.1055C-0.0078125 10.0195 0 9.93359 0.03125 9.84766L0.910156 7.1875C0.410156 5.98438 0.367188 4.80469 0.78125 3.64844C1.19531 2.49219 1.97656 1.60938 3.125 1C3.79688 0.664062 4.50391 0.496094 5.24609 0.496094H5.52734C6.76172 0.589844 7.79297 1.05469 8.62109 1.89062C9.44922 2.71875 9.91016 3.75391 10.0039 4.99609V5.25391C10.0039 5.99609 9.83594 6.70312 9.5 7.375Z"
        fill={fill}
      />
    </svg>
  );
};

export default ChatIcon;
