import React, { ReactElement, FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';

import { BaseProps } from 'shared/types';
import { theme } from 'theme';
import { Indicator, Typography } from 'components';

type ShipmentProps = {
  hideBorder?: boolean;
  step?: number;
  allSteps?: number;
} & BoxProps &
  BaseProps;

const useStyles = makeStyles({
  indicatorWrapper: {
    [theme.breakpoints.down(1440)]: {
      marginRight: theme.spacing(4),
    },
  },
});

const AddShipmentStep: FC<ShipmentProps> = ({
  step,
  allSteps,
  hideBorder,
  children,
  ...restProps
}): ReactElement => {
  const classes = useStyles();
  return (
    <Box display="flex" width="100%">
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        marginRight={12.5}
        className={classes.indicatorWrapper}
      >
        <Indicator
          active
          size={40}
          icon={
            <Typography variant="h6" color="textSecondary">
              {step}/{allSteps}
            </Typography>
          }
        />
        {!hideBorder && (
          <Box
            bgcolor={theme.palette.custom.strongViolet}
            height="100%"
            width={3}
            color={theme.palette.custom.strongViolet}
          />
        )}
      </Box>
      <Box width="100%" paddingTop={2} paddingBottom={11.25} {...restProps}>
        {children}
      </Box>
    </Box>
  );
};

export default AddShipmentStep;
