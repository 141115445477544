import { useFetchApi } from 'shared/functions/fetchApi';
import { ApiResponse } from 'shared/types/api';
import { PricesPayload } from 'shared/types/prices';
import { NewShipmentPayload } from 'shared/types/shipments/shipments';
import { PriceRequestDetailsPayload } from '../types/priceRequests/priceRequests';

type UsePricesReturnType = {
  fetchPrices: (
    request: NewShipmentPayload | PriceRequestDetailsPayload,
  ) => Promise<ApiResponse<PricesPayload>>;
};

export const usePrices = (): UsePricesReturnType => {
  const { fetchApi } = useFetchApi();

  const fetchPrices = async (
    payload: NewShipmentPayload | PriceRequestDetailsPayload,
  ): Promise<ApiResponse<PricesPayload>> => {
    const body = JSON.stringify(payload);

    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body,
    };

    const response = await fetchApi(`/api/prices`, params, undefined, true);

    return response.json();
  };

  return {
    fetchPrices,
  };
};
