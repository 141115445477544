import { RouteComponentProps } from 'react-router-dom';
import ShipmentsIcon from 'components/Icons/Sidebar/ShipmentsIcon';
import PriceRequestsIcon from 'components/Icons/Sidebar/PriceRequestsIcon';
import {
  featureWarehouseView,
  routeAddressBook,
  routeClaims,
  routeDashboard,
  routeInvoices,
  routePriceRequests,
  routeShipments,
  routeTemplates,
  routeWarehouse,
} from 'routes';
import React from 'react';
import { useFeatures } from './useFeatures';
import {
  AddressBookIcon,
  ClaimIconSidebar,
  InvoicesIcon,
  NoteIcon,
  WarehouseIcon,
} from '../../components';
import DashboardIcon from '../../components/Icons/Sidebar/DashboardIcon';
import { useUser } from './useUser';
import { UserAction } from '../constants/user/userRights';

export type NavLinkProps = {
  title: string;
  path: string;
  icon: React.ComponentType<RouteComponentProps> | React.ComponentType;
  parentPath?: string;
  inactive?: boolean;
  action?: UserAction;
};

const useNavLinks = (): NavLinkProps[] => {
  const features = useFeatures();
  const { profile } = useUser();
  const navLinks: NavLinkProps[] = [
    {
      title: 'SIDEBAR_DASHBOARD',
      path: routeDashboard,
      icon: DashboardIcon,
      parentPath: routeDashboard,
    },
    {
      title: 'SIDEBAR_SHIPMENTS',
      path: routeShipments,
      icon: ShipmentsIcon,
      parentPath: routeShipments,
      action: UserAction.shipments,
    },
    {
      title: 'SIDEBAR_PRICE_REQUESTS',
      path: routePriceRequests,
      icon: PriceRequestsIcon,
      parentPath: routePriceRequests,
      inactive: !features.priceRequests,
      action: UserAction.priceRequests,
    },
    {
      title: 'ADDRESS_BOOK',
      path: routeAddressBook,
      icon: AddressBookIcon,
      parentPath: routeAddressBook,
      action: UserAction.shipments,
    },
    {
      title: 'TEMPLATE.TEMPLATES',
      path: routeTemplates,
      icon: NoteIcon,
      parentPath: routeTemplates,
      action: UserAction.shipments,
    },
    {
      title: 'INVOICES',
      path: routeInvoices,
      icon: InvoicesIcon,
      parentPath: routeInvoices,
      action: UserAction.invoices,
    },
    {
      title: 'WAREHOUSE',
      path: routeWarehouse,
      icon: WarehouseIcon,
      parentPath: routeWarehouse,
      inactive: !profile?.features?.includes(featureWarehouseView),
      action: UserAction.shipments,
    },
    {
      title: 'CLAIMS',
      path: routeClaims,
      icon: ClaimIconSidebar,
      parentPath: routeClaims,
      action: UserAction.claims,
    },
  ];

  return navLinks;
};

export { useNavLinks };
