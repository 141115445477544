import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const NewShipmentIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 12,
  height = 14,
  fill = theme.palette.custom.veryDarkViolet,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M6.58789 1.5H2.5C2.33594 1.5 2.19922 1.55924 2.08984 1.67773C1.98047 1.78711 1.92122 1.92383 1.91211 2.08789V11.4121C1.92122 11.5762 1.98047 11.7174 2.08984 11.8359C2.19922 11.9453 2.33594 12 2.5 12H9.5C9.66406 12 9.80078 11.9453 9.91016 11.8359C10.0195 11.7174 10.0788 11.5762 10.0879 11.4121V5H7.16211C6.99805 5 6.86133 4.94531 6.75195 4.83594C6.64258 4.71745 6.58789 4.57617 6.58789 4.41211V1.5ZM7.75 2.32031V3.83789H9.25391L7.75 2.32031ZM7.16211 0.337891C7.32617 0.337891 7.46289 0.392578 7.57227 0.501953L11.0723 4.00195C11.1908 4.12044 11.25 4.25716 11.25 4.41211V11.4121C11.2409 11.9134 11.0677 12.3281 10.7305 12.6562C10.4023 12.9844 9.99219 13.153 9.5 13.1621H2.5C2.00781 13.153 1.5931 12.9844 1.25586 12.6562C0.927734 12.3281 0.759115 11.9134 0.75 11.4121V2.08789C0.759115 1.58659 0.927734 1.17188 1.25586 0.84375C1.5931 0.515625 2.00781 0.347005 2.5 0.337891H7.16211ZM5.41211 9.08789H4.25C4.08594 9.07878 3.94922 9.01953 3.83984 8.91016C3.73047 8.80078 3.67578 8.66406 3.67578 8.5C3.67578 8.33594 3.73047 8.19922 3.83984 8.08984C3.94922 7.98047 4.08594 7.92122 4.25 7.91211H5.41211V6.75C5.42122 6.58594 5.48047 6.44922 5.58984 6.33984C5.69922 6.23047 5.83594 6.17578 6 6.17578C6.16406 6.17578 6.30078 6.23047 6.41016 6.33984C6.51953 6.44922 6.57878 6.58594 6.58789 6.75V7.91211H7.75C7.91406 7.92122 8.05078 7.98047 8.16016 8.08984C8.26953 8.19922 8.32422 8.33594 8.32422 8.5C8.32422 8.66406 8.26953 8.80078 8.16016 8.91016C8.05078 9.01953 7.91406 9.07878 7.75 9.08789H6.58789V10.25C6.57878 10.4141 6.51953 10.5508 6.41016 10.6602C6.30078 10.7695 6.16406 10.8242 6 10.8242C5.83594 10.8242 5.69922 10.7695 5.58984 10.6602C5.48047 10.5508 5.42122 10.4141 5.41211 10.25V9.08789Z"
        fill={fill}
      />
    </svg>
  );
};

export default NewShipmentIcon;
