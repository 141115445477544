import React, { ReactElement, FC, useContext, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { TextField, Button, Typography, MultiSelect, Labeled, DatePicker } from 'components';
import { SelectItem } from 'components/CustomMaterial/MultiSelect/MultiSelect';
import { BaseProps } from 'shared/types';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, FormikHelpers, FieldProps } from 'formik';
import { theme } from 'theme';
import { IShipmentsFilters } from 'shared/types/shipments/shipments';
import pickBy from 'lodash/pickBy';
import {
  priceRequestStatusCodes,
  priceRequestStatusLabels,
  simplifiedStatuses,
} from 'shared/constants/priceRequests/priceRequestStatuses';
import { GlobalDataContext } from 'app/AuthenticatedApp';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { PriceRequestsFilters } from 'shared/types/priceRequests/priceRequests';
import { DataTableQueryParams } from 'components/Datatable/Datatable';
import { useQuery } from 'shared/hooks/useQuery';

const useStyles = makeStyles(() => ({
  container: {
    alignSelf: 'stretch',
  },
  child: {
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
  borderedBox: {
    borderRight: '2px solid',
    borderRightColor: theme.palette.custom.lightGrayishVioletAlt,
    marginRight: theme.spacing(5),
    paddingRight: theme.spacing(5),
    flexShrink: 2,
  },
  pickUpDate: {
    [theme.breakpoints.down(1440)]: {
      margin: theme.spacing(3, 4, 0, 0),
    },
  },
}));

type PriceRequestsFilterProps = {
  applyFilters: (filtered: IShipmentsFilters) => void;
  closeFilters: () => void;
} & BaseProps;

const PriceRequestsFilter: FC<PriceRequestsFilterProps> = ({
  applyFilters,
  closeFilters,
}): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { queryParams, clearQueryParams } = useQuery<DataTableQueryParams>();
  const { filters } = queryParams;

  const { countries } = useContext(GlobalDataContext);

  const emptyFilters: PriceRequestsFilters = {
    number: '',
    reference: '',
    pickupCountries: [],
    pickUpDateFrom: '',
    pickUpDateTo: '',
    deliveryCountries: [],
    arrivalDateFrom: '',
    arrivalDateTo: '',
    statuses: [],
    consignee: '',
    consignor: '',
    ...filters,
  };

  const [initialValues, setInitialValues] = useState<IShipmentsFilters>({
    ...emptyFilters,
    ...filters,
  });

  useEffect(() => {
    setInitialValues({ ...emptyFilters, ...filters });
  }, [filters]);

  const priceRequestStatusItems: SelectItem[] = simplifiedStatuses.map((status) => ({
    label: t(priceRequestStatusLabels[status]),
    value: priceRequestStatusCodes[status],
  }));

  const handleSubmit = async (
    values: IShipmentsFilters,
    actions: FormikHelpers<IShipmentsFilters>,
  ) => {
    actions.setSubmitting(true);
    const filtered = pickBy(values, (value) => (Array.isArray(value) ? value.length > 0 : value));
    applyFilters(filtered);
  };

  const clearAllFields = () => {
    clearQueryParams();
  };

  return (
    <Box
      pt={8}
      px={9.75}
      pb={10}
      mb={8}
      borderColor={theme.palette.custom.lightGray}
      borderRadius={16}
      bgcolor={theme.palette.custom.lightGrayishViolet}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2" fontWeight="extraBold">
                {t('SHIPMENTS_SCENARIO.FILTER.FILTER')}
                <Typography onClick={clearAllFields} variant="caption" link marginLeft={4}>
                  {t('SHIPMENTS_SCENARIO.FILTER.CLEAR_ALL_FIELDS')}
                </Typography>
              </Typography>
              <Typography onClick={() => closeFilters()} variant="body2" link>
                {t('SHIPMENTS_SCENARIO.FILTER.CLOSE_FILTER')}
              </Typography>
            </Box>
            <Box display="flex" className={classes.container} pb={6}>
              <Box display="flex" pt={3.5} className={clsx(classes.child, classes.borderedBox)}>
                <Field name="number">
                  {({ field }: FieldProps) => {
                    return (
                      <Box>
                        <Labeled
                          error={!!(errors.number && touched.number)}
                          variant="variant2"
                          text={t('PRICE_REQUEST.FILTER.NUMBER')}
                        >
                          <TextField
                            {...field}
                            variant="variant4"
                            type="text"
                            size="small"
                            error={!!(errors.number && touched.number)}
                            width={336}
                          />
                        </Labeled>
                      </Box>
                    );
                  }}
                </Field>
                <Field name="statuses">
                  {({ field }: FieldProps) => {
                    return (
                      <Box mt={3}>
                        <Labeled
                          error={!!(errors.statuses && touched.statuses)}
                          variant="variant2"
                          text={t('SHIPMENTS_SCENARIO.FILTER.STATUS')}
                        >
                          <MultiSelect
                            {...field}
                            resultsFilter
                            placeholder={t('SHIPMENTS_SCENARIO.FILTER.SELECT_STATUS')}
                            data={priceRequestStatusItems}
                            background="opaque"
                            noBorder
                            width={336}
                            onChange={(values) => setFieldValue('statuses', values)}
                          />
                        </Labeled>
                      </Box>
                    );
                  }}
                </Field>
              </Box>
              <Box display="flex" pt={3.5} className={classes.child}>
                <Field name="pickupCountries">
                  {({ field }: FieldProps) => {
                    return (
                      <Box marginRight={4}>
                        <Labeled
                          error={!!(errors.pickupCountries && touched.pickupCountries)}
                          variant="variant2"
                          text={t('SHIPMENTS_SCENARIO.FILTER.PICK_UP_FROM')}
                        >
                          <MultiSelect
                            {...field}
                            resultsFilter
                            placeholder={t('SHIPMENTS_SCENARIO.FILTER.SELECT_COUNTRY')}
                            data={countries}
                            background="opaque"
                            noBorder
                            width={240}
                            onChange={(values) => setFieldValue('pickupCountries', values)}
                          />
                        </Labeled>
                      </Box>
                    );
                  }}
                </Field>
                <Field name="consignor">
                  {({ field }: FieldProps) => {
                    return (
                      <Box marginRight={4}>
                        <Labeled
                          error={!!(errors.consignor && touched.consignor)}
                          variant="variant2"
                          text={t('SHIPMENTS_SCENARIO.FILTER.PICKUP_COMPANY')}
                        >
                          <TextField
                            {...field}
                            variant="variant4"
                            type="text"
                            size="small"
                            error={!!(errors.consignor && touched.consignor)}
                            width={238}
                          />
                        </Labeled>
                      </Box>
                    );
                  }}
                </Field>
                <Box className={classes.pickUpDate}>
                  <Labeled
                    error={false}
                    variant="variant2"
                    text={t('SHIPMENTS_SCENARIO.FILTER.PICK_UP_DATE')}
                  >
                    <DatePicker
                      size="small"
                      variant="variant4"
                      width={224}
                      isRangePicker
                      startDateName="pickUpDateFrom"
                      endDateName="pickUpDateTo"
                      initialStartDateISO={initialValues.pickUpDateFrom}
                      initialEndDateISO={initialValues.pickUpDateTo}
                    />
                  </Labeled>
                </Box>
                <Field name="deliveryCountries">
                  {({ field }: FieldProps) => {
                    return (
                      <Box marginRight={4} mt={3}>
                        <Labeled
                          error={!!(errors.deliveryCountries && touched.deliveryCountries)}
                          variant="variant2"
                          text={t('SHIPMENTS_SCENARIO.FILTER.DELIVERY_TO')}
                        >
                          <MultiSelect
                            {...field}
                            resultsFilter
                            placeholder={t('SHIPMENTS_SCENARIO.FILTER.SELECT_COUNTRY')}
                            data={countries}
                            background="opaque"
                            noBorder
                            width={240}
                            onChange={(values) => setFieldValue('deliveryCountries', values)}
                          />
                        </Labeled>
                      </Box>
                    );
                  }}
                </Field>
                <Field name="consignee">
                  {({ field }: FieldProps) => {
                    return (
                      <Box marginRight={4} mt={3}>
                        <Labeled
                          error={!!(errors.consignee && touched.consignee)}
                          variant="variant2"
                          text={t('SHIPMENTS_SCENARIO.FILTER.DELIVERY_COMPANY')}
                        >
                          <TextField
                            {...field}
                            variant="variant4"
                            type="text"
                            size="small"
                            error={!!(errors.consignee && touched.consignee)}
                            width={238}
                          />
                        </Labeled>
                      </Box>
                    );
                  }}
                </Field>
                <Box mt={3}>
                  <Labeled
                    error={false}
                    variant="variant2"
                    text={t('SHIPMENTS_SCENARIO.FILTER.DELIVERY_DATE')}
                  >
                    <DatePicker
                      size="small"
                      variant="variant4"
                      isRangePicker
                      width={224}
                      startDateName="arrivalDateFrom"
                      endDateName="arrivalDateTo"
                      initialStartDateISO={initialValues.arrivalDateFrom}
                      initialEndDateISO={initialValues.arrivalDateTo}
                    />
                  </Labeled>
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Button variant="contained" color="primary" size="small" type="submit">
                {t('SHIPMENTS_SCENARIO.FILTER.FILTER')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PriceRequestsFilter;
