import React, { ReactElement, FC } from 'react';
import Typography from '@material-ui/core/Typography';
import Box, { BoxProps } from '@material-ui/core/Box';

import { BaseProps } from 'shared/types';

import { theme } from 'theme';
import makeStyles from '@material-ui/styles/makeStyles';

type PageViewProps = {
  title: string | ReactElement;
  extraTitle?: string | ReactElement;
  boxProps?: BoxProps;
  addNewButton?: ReactElement;
} & BaseProps;

const useStyles = makeStyles({
  title: {
    fontWeight: 800,
  },
  extraTitle: {
    fontWeight: 500,
    color: theme.palette.custom.gray,
  },
});

const PageView: FC<PageViewProps> = ({
  title,
  extraTitle,
  children,
  boxProps = {},
  addNewButton,
}): ReactElement => {
  const classes = useStyles();

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          {extraTitle ? (
            <Typography variant="h4" className={classes.extraTitle}>
              {extraTitle}
            </Typography>
          ) : null}
        </Box>
        {addNewButton && addNewButton}
      </Box>
      <Box
        bgcolor={theme.palette.custom.white}
        borderRadius={20}
        pt={10}
        pb={15}
        px={10}
        mt={3}
        {...boxProps}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PageView;
