import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const CancelIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 14,
  height = 16,
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 13.1621C5.1862 13.1165 3.67318 12.4922 2.46094 11.2891C1.25781 10.0768 0.633464 8.5638 0.587891 6.75C0.633464 4.9362 1.25781 3.42773 2.46094 2.22461C3.67318 1.01237 5.1862 0.383464 7 0.337891C8.8138 0.383464 10.3223 1.01237 11.5254 2.22461C12.7376 3.42773 13.3665 4.9362 13.4121 6.75C13.3665 8.5638 12.7376 10.0768 11.5254 11.2891C10.3223 12.4922 8.8138 13.1165 7 13.1621ZM7 12C8.48568 11.9635 9.7207 11.4531 10.7051 10.4688C11.6986 9.47526 12.2135 8.23568 12.25 6.75C12.2135 5.26432 11.6986 4.0293 10.7051 3.04492C9.7207 2.05143 8.48568 1.53646 7 1.5C5.51432 1.53646 4.27474 2.05143 3.28125 3.04492C2.29688 4.0293 1.78646 5.26432 1.75 6.75C1.78646 8.23568 2.29688 9.47526 3.28125 10.4688C4.27474 11.4531 5.51432 11.9635 7 12ZM8.33984 4.58984L4.83984 8.08984C4.73047 8.20833 4.67578 8.34505 4.67578 8.5C4.67578 8.65495 4.73047 8.79167 4.83984 8.91016C4.95833 9.01953 5.09505 9.07422 5.25 9.07422C5.40495 9.07422 5.54167 9.01953 5.66016 8.91016L9.16016 5.41016C9.24219 5.33724 9.29688 5.25065 9.32422 5.15039C9.35156 5.05013 9.35156 4.94987 9.32422 4.84961C9.29688 4.74023 9.24674 4.64909 9.17383 4.57617C9.10091 4.50326 9.00977 4.45312 8.90039 4.42578C8.80013 4.39844 8.69987 4.39844 8.59961 4.42578C8.49935 4.45312 8.41276 4.50781 8.33984 4.58984ZM4.83984 5.41016L8.33984 8.91016C8.45833 9.01953 8.59505 9.07422 8.75 9.07422C8.90495 9.07422 9.03711 9.01953 9.14648 8.91016C9.26497 8.79167 9.32422 8.65495 9.32422 8.5C9.32422 8.34505 9.26953 8.20833 9.16016 8.08984L5.66016 4.58984C5.54167 4.48047 5.40495 4.42578 5.25 4.42578C5.09505 4.42578 4.95833 4.48503 4.83984 4.60352C4.73047 4.71289 4.67578 4.84505 4.67578 5C4.67578 5.15495 4.73047 5.29167 4.83984 5.41016Z"
        fill="#26084D"
      />
    </svg>
  );
};

export default CancelIcon;
