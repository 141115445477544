import React, { FC, ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertTypes } from 'components/CustomMaterial/Alert/Alert';

type UseSystemAlertReturnType = {
  showSystemErrorMessage: (message: string) => void;
  showSystemItsOurBugMessage: () => void;
  showSystemExceptionMessage: (error: Error) => void;
  showSystemSuccessMessage: (message: string) => void;
  hideSystemMessage: () => void;
};

export type SystemAlertContextData = {
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  isSystemAlertVisible: boolean;
  setIsSystemAlertVisible: React.Dispatch<React.SetStateAction<boolean>>;
  systemAlertColor: AlertTypes;
  setSystemAlertColor: React.Dispatch<React.SetStateAction<AlertTypes>>;
  hideSystemAlert: () => void;
};

export const dummySystemAlertContextData: SystemAlertContextData = {
  message: '',
  setMessage: () => {},
  isSystemAlertVisible: false,
  setIsSystemAlertVisible: () => {},
  systemAlertColor: 'info',
  setSystemAlertColor: () => {},
  hideSystemAlert: () => {},
};

export const SystemAlertContext = React.createContext<SystemAlertContextData>(
  dummySystemAlertContextData,
);

export const SystemAlertProvider: FC = ({ children }): ReactElement => {
  const [message, setMessage] = useState('');
  const [isSystemAlertVisible, setIsSystemAlertVisible] = useState(false);
  const [systemAlertColor, setSystemAlertColor] = useState<AlertTypes>('default');

  return (
    <SystemAlertContext.Provider
      value={{
        message,
        setMessage,
        isSystemAlertVisible,
        setIsSystemAlertVisible,
        systemAlertColor,
        setSystemAlertColor,
        hideSystemAlert: () => setIsSystemAlertVisible(false),
      }}
    >
      {children}
    </SystemAlertContext.Provider>
  );
};

export const useSystemAlert = (): UseSystemAlertReturnType => {
  const { t } = useTranslation();
  const alertContext = useContext(SystemAlertContext);

  const showMessage = (message: string, alertType: AlertTypes) => {
    alertContext.setMessage(message);
    alertContext.setSystemAlertColor(alertType);
    alertContext.setIsSystemAlertVisible(true);
  };

  const showSystemErrorMessage = (message: string) => {
    showMessage(message, 'error');
  };

  const showSystemItsOurBugMessage = () => {
    showSystemErrorMessage(t('ERROR_OUR_BUG'));
  };

  const showSystemExceptionMessage = (error: Error) => {
    showSystemErrorMessage(error?.message ?? t('ERROR_OUR_BUG'));
  };

  const showSystemSuccessMessage = (message: string) => {
    showMessage(message, 'success');
  };

  const hideSystemMessage = () => {
    alertContext.setIsSystemAlertVisible(false);
  };

  return {
    showSystemErrorMessage,
    showSystemItsOurBugMessage,
    showSystemExceptionMessage,
    showSystemSuccessMessage,
    hideSystemMessage,
  };
};

export default useSystemAlert;
