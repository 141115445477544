import { ShipmentStatus } from 'shared/types/shipments/shipments';

export const shipmentStatusLabels: Record<ShipmentStatus, string> = {
  [ShipmentStatus.NotSelected]: 'NOT_SELECTED',
  [ShipmentStatus.Draft]: 'DRAFT',
  [ShipmentStatus.Canceled]: 'CANCELED',
  [ShipmentStatus.MissingData]: 'MISSING_DATA',
  [ShipmentStatus.Received]: 'SHIPMENT_CREATED',
  [ShipmentStatus.Confirmed]: 'CONFIRMED',
  [ShipmentStatus.Processing]: 'PROCESSING',
  [ShipmentStatus.PickedUp]: 'IN_TRANSIT',
  [ShipmentStatus.InTransit]: 'IN_TRANSIT',
  [ShipmentStatus.Delivering]: 'DELIVERING',
  [ShipmentStatus.Delivered]: 'DELIVERED',
  [ShipmentStatus.LastDelivered]: 'LAST_DELIVERED',
  [ShipmentStatus.WaitingReadinessRequest]: 'WAITING_READINESS_REQUEST',
  [ShipmentStatus.WaitingForConfirmation]: 'WAITING_FOR_CONFIRMATION',
};

export const shipmentStatusCodes: Record<ShipmentStatus, string> = {
  [ShipmentStatus.NotSelected]: 'NotSelected',
  [ShipmentStatus.Draft]: 'Draft',
  [ShipmentStatus.Canceled]: 'Canceled',
  [ShipmentStatus.MissingData]: 'MissingData',
  [ShipmentStatus.Received]: 'Received',
  [ShipmentStatus.Confirmed]: 'Confirmed',
  [ShipmentStatus.Processing]: 'Processing',
  [ShipmentStatus.PickedUp]: 'InTransit',
  [ShipmentStatus.InTransit]: 'InTransit',
  [ShipmentStatus.Delivering]: 'Delivering',
  [ShipmentStatus.Delivered]: 'Delivered',
  [ShipmentStatus.LastDelivered]: 'LastDelivered',
  [ShipmentStatus.WaitingReadinessRequest]: 'WaitingReadinessRequest',
  [ShipmentStatus.WaitingForConfirmation]: 'WaitingForConfirmation',
};

export const simplifiedStatuses: ShipmentStatus[] = [
  ShipmentStatus.Draft,
  ShipmentStatus.Received,
  ShipmentStatus.Processing,
  ShipmentStatus.InTransit,
  ShipmentStatus.Delivered,
];
