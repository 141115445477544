import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';

const ReceiverIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 11,
  height = 11,
  fill = '#858585',
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00391 10.9961H2.49609C2.07422 10.9883 1.72266 10.8438 1.44141 10.5625C1.16016 10.2812 1.01172 9.92578 0.996094 9.49609V1.50391C1.01172 1.07422 1.16016 0.71875 1.44141 0.4375C1.72266 0.15625 2.07422 0.0117188 2.49609 0.00390625H5.00391C5.14453 0.00390625 5.26172 0.0507812 5.35547 0.144531C5.44922 0.238281 5.49609 0.355469 5.49609 0.496094C5.49609 0.636719 5.44922 0.757812 5.35547 0.859375C5.26172 0.953125 5.14453 1 5.00391 1H2.49609C2.35547 1 2.23828 1.05078 2.14453 1.15234C2.05078 1.24609 2.00391 1.36328 2.00391 1.50391V9.49609C2.00391 9.63672 2.05078 9.75781 2.14453 9.85938C2.23828 9.95312 2.35547 10 2.49609 10H5.00391C5.14453 10 5.26172 10.0469 5.35547 10.1406C5.44922 10.2422 5.49609 10.3633 5.49609 10.5039C5.49609 10.6445 5.44922 10.7617 5.35547 10.8555C5.26172 10.9492 5.14453 10.9961 5.00391 10.9961ZM6.85547 7.15234C6.94922 7.25391 6.99609 7.37109 6.99609 7.50391C6.99609 7.62891 6.94531 7.74219 6.84375 7.84375C6.74219 7.94531 6.62891 7.99609 6.50391 7.99609C6.37109 7.99609 6.25391 7.94922 6.15234 7.85547L4.14844 5.85156C4.04688 5.75 3.99609 5.63281 3.99609 5.5C3.99609 5.36719 4.04688 5.25 4.14844 5.14844L6.15234 3.14453C6.25391 3.05078 6.37109 3.00391 6.50391 3.00391C6.62891 3.00391 6.74219 3.05469 6.84375 3.15625C6.94531 3.25781 6.99609 3.375 6.99609 3.50781C6.99609 3.63281 6.94922 3.74609 6.85547 3.84766L5.20312 5.5L6.85547 7.15234ZM4.5 4.99609H10.5C10.6406 5.00391 10.7578 5.05469 10.8516 5.14844C10.9453 5.24219 10.9922 5.35938 10.9922 5.5C10.9922 5.64062 10.9453 5.75781 10.8516 5.85156C10.7578 5.94531 10.6406 5.99609 10.5 6.00391H4.5C4.35938 5.99609 4.24219 5.94531 4.14844 5.85156C4.05469 5.75781 4.00781 5.64062 4.00781 5.5C4.00781 5.35938 4.05469 5.24219 4.14844 5.14844C4.24219 5.05469 4.35938 5.00391 4.5 4.99609Z"
        fill={fill}
      />
    </svg>
  );
};

export default ReceiverIcon;
