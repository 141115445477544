import { PriceRequestStatus } from '../../types/priceRequests/priceRequests';

export const priceRequestStatusLabels: Record<PriceRequestStatus, string> = {
  [PriceRequestStatus.NotSelected]: 'NOT_SELECTED',
  [PriceRequestStatus.Draft]: 'DRAFT',
  [PriceRequestStatus.Canceled]: 'CANCELED',
  [PriceRequestStatus.Received]: 'PENDING_PRICES',
  [PriceRequestStatus.WaitingConfirmed]: 'WAITING_CONFIRMED',
  [PriceRequestStatus.Accepted]: 'ACCEPTED',
  [PriceRequestStatus.Rejected]: 'REJECTED',
  [PriceRequestStatus.Expired]: 'EXPIRED',
};

export const priceRequestStatusCodes: Record<PriceRequestStatus, string> = {
  [PriceRequestStatus.NotSelected]: 'NotSelected',
  [PriceRequestStatus.Draft]: 'Draft',
  [PriceRequestStatus.Canceled]: 'Canceled',
  [PriceRequestStatus.Received]: 'Received',
  [PriceRequestStatus.WaitingConfirmed]: 'WaitingConfirmed',
  [PriceRequestStatus.Accepted]: 'Accepted',
  [PriceRequestStatus.Rejected]: 'Rejected',
  [PriceRequestStatus.Expired]: 'Expired',
};

export const simplifiedStatuses: PriceRequestStatus[] = [
  PriceRequestStatus.Draft,
  PriceRequestStatus.Canceled,
  PriceRequestStatus.Received,
  PriceRequestStatus.WaitingConfirmed,
  PriceRequestStatus.Accepted,
  PriceRequestStatus.Rejected,
  PriceRequestStatus.Expired,
];
