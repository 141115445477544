import React, { ReactElement, FC, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import useInView from 'react-cool-inview';

import {
  ShipmentCommentPayload,
  ShipmentUpdateCommentPayload,
} from 'shared/types/shipments/shipments';
import { useShipments } from 'shared/services/shipments';
import { useUser } from 'shared/hooks/useUser';
import { Typography } from 'components';
import { theme } from 'theme';
import { useTranslation } from 'react-i18next';
import { UserAction, UserActionType } from 'shared/constants/user/userRights';
import { hasPermission } from 'shared/functions/hasPermission';

const useStyles = makeStyles({
  comment: {
    background: theme.palette.custom.lightCyan,
    marginBottom: theme.spacing(6),
    padding: theme.spacing(4, 4, 4, 5.5),
    fontWeight: 100,
    borderLeft: `solid ${theme.spacing(0.75)}px ${theme.palette.custom.darkCyan}`,
    borderRadius: theme.spacing(0, 2, 2, 0),
    whiteSpace: 'break-spaces',

    '& p': {
      fontWeight: 700,
    },
  },
  commentBody: {
    marginTop: theme.spacing(1),
  },
  author: {
    fontWeight: 700,
  },
  date: {
    marginLeft: theme.spacing(4),
    color: theme.palette.custom.veryDarkGrayAlt2,
  },
  seenDate: {
    marginBottom: theme.spacing(2),
    color: theme.palette.custom.veryDarkGrayAlt2,
    fontSize: '0.75rem',
  },
  read: {
    background: 'none',
    fontWeight: 100,

    '& p': {
      fontWeight: 100,
    },
  },
});

type ShipmentCommentProps = {
  comment: ShipmentCommentPayload;
  object: 'Shipment' | 'PriceRequest';
};

const ShipmentComment: FC<ShipmentCommentProps> = ({ comment, object }): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { updateShipmentComment } = useShipments();
  const user = useUser();
  const { fetchShipmentCommentList } = useShipments();

  const [isBeingRead, setBeingRead] = useState(false);

  const componentUserActon: UserAction =
    object === 'Shipment' ? UserAction.shipments : UserAction.priceRequests;

  const getUnreadComments = async () => {
    try {
      const newComments = await fetchShipmentCommentList();
      user.setNewComments(newComments.payload);
    } catch (error) {
      // TODO: Add error handling when messages notification messages are specified
    }
  };

  const handleInView = async (id: number, payload: ShipmentUpdateCommentPayload) => {
    try {
      if (!hasPermission(user, componentUserActon, UserActionType.Edit)) {
        return;
      }
      const result = await updateShipmentComment({ id, payload, object });

      if (result.isSuccessful) {
        setBeingRead(true);
      }
    } catch (error) {
      // TODO: error handling if needed
    } finally {
      getUnreadComments();
    }
  };

  const commentViewData = {
    operatorRead: comment.operatorRead,
    customerRead: new Date(),
  };

  const { observe } = useInView<HTMLDivElement | null>({
    unobserveOnEnter: true,
    threshold: 1,
    delay: 500,

    onEnter: ({ unobserve }) => {
      unobserve();
      handleInView(comment.id, commentViewData);
    },
  });

  return (
    <div ref={!comment.customerRead ? observe : null}>
      <Box
        key={comment.id}
        className={clsx(classes.comment, (comment.customerRead || isBeingRead) && classes.read)}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="body2" component="span" className={classes.author}>
            {comment.createdBy}
          </Typography>
          <Typography variant="body2" component="span" className={classes.date}>
            {comment.createdOn ? comment.createdOn : '–'}
          </Typography>
        </Box>
        {comment.createdOn &&
          comment.operatorReadUTCString &&
          comment.createdOn !== comment.operatorReadUTCString && (
            <Typography
              variant="body2"
              fontStyle="italic"
              component="p"
              className={classes.seenDate}
            >
              {t('OPERATOR_SEEN')}: {comment.operatorReadUTCString}
            </Typography>
          )}
        <Typography variant="body2" component="p" className={classes.commentBody}>
          {comment.comment}
        </Typography>
      </Box>
    </div>
  );
};

export default ShipmentComment;
