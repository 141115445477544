import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const PodIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 15,
  height = 15,
  fill = theme.palette.custom.veryDarkGray,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M3.60938 13.3281L1.79688 15.1406C1.71354 15.224 1.61458 15.2812 1.5 15.3125C1.38542 15.3438 1.27083 15.3438 1.15625 15.3125C1.04167 15.2812 0.942708 15.224 0.859375 15.1406C0.776042 15.0573 0.71875 14.9583 0.6875 14.8438C0.65625 14.7292 0.65625 14.6146 0.6875 14.5C0.71875 14.3854 0.776042 14.2865 0.859375 14.2031L2.67188 12.3906V7C2.67188 6.8125 2.73438 6.65625 2.85938 6.53125L7.35938 2.03125C8.31771 1.11458 9.41667 0.65625 10.6562 0.65625C11.9062 0.65625 13.0104 1.11458 13.9688 2.03125C14.875 2.98958 15.3281 4.08854 15.3281 5.32812C15.3281 6.56771 14.875 7.66667 13.9688 8.625L9.46875 13.1406C9.34375 13.2656 9.1875 13.3281 9 13.3281H3.60938ZM7.60938 9.32812H11.375L13.0156 7.6875C13.4531 7.26042 13.75 6.76042 13.9062 6.1875C14.0729 5.61458 14.0781 5.03646 13.9219 4.45312C13.7656 3.85938 13.4792 3.35417 13.0625 2.9375C12.6458 2.51042 12.1406 2.21875 11.5469 2.0625C10.9531 1.90625 10.3698 1.91146 9.79688 2.07812C9.23438 2.23438 8.73438 2.53125 8.29688 2.96875L4 7.28125V11.0625L10.2031 4.85938C10.3385 4.73438 10.4896 4.67188 10.6562 4.67188C10.8333 4.67188 10.9896 4.73958 11.125 4.875C11.2604 5.01042 11.3281 5.16667 11.3281 5.34375C11.3281 5.51042 11.2656 5.66146 11.1406 5.79688L7.60938 9.32812ZM10.0469 10.6719H6.28125L4.9375 12H8.71875L10.0469 10.6719Z"
        fill={fill}
      />
    </svg>
  );
};

export default PodIcon;
