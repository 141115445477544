import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const ExclamationMarkIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 12,
  height = 11,
  fill = theme.palette.custom.white,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M6 10.9961C4.44531 10.957 3.14844 10.4219 2.10938 9.39062C1.07812 8.35156 0.542969 7.05469 0.503906 5.5C0.542969 3.94531 1.07812 2.65234 2.10938 1.62109C3.14844 0.582031 4.44531 0.0429688 6 0.00390625C7.55469 0.0429688 8.84766 0.582031 9.87891 1.62109C10.918 2.65234 11.457 3.94531 11.4961 5.5C11.457 7.05469 10.918 8.35156 9.87891 9.39062C8.84766 10.4219 7.55469 10.957 6 10.9961ZM6 10C7.27344 9.96875 8.33203 9.53125 9.17578 8.6875C10.0273 7.83594 10.4688 6.77344 10.5 5.5C10.4688 4.22656 10.0273 3.16797 9.17578 2.32422C8.33203 1.47266 7.27344 1.03125 6 1C4.72656 1.03125 3.66406 1.47266 2.8125 2.32422C1.96875 3.16797 1.53125 4.22656 1.5 5.5C1.53125 6.77344 1.96875 7.83594 2.8125 8.6875C3.66406 9.53125 4.72656 9.96875 6 10ZM5.49609 3.49609V5.5C5.50391 5.64062 5.55469 5.75781 5.64844 5.85156C5.74219 5.94531 5.85938 5.99219 6 5.99219C6.14062 5.99219 6.25781 5.94531 6.35156 5.85156C6.44531 5.75781 6.49609 5.64062 6.50391 5.5V3.49609C6.49609 3.35547 6.44531 3.23828 6.35156 3.14453C6.25781 3.05078 6.14062 3.00391 6 3.00391C5.85938 3.00391 5.74219 3.05078 5.64844 3.14453C5.55469 3.23828 5.50391 3.35547 5.49609 3.49609ZM5.49609 7.50391C5.49609 7.58984 5.51953 7.67188 5.56641 7.75C5.61328 7.82812 5.67578 7.89062 5.75391 7.9375C5.83203 7.97656 5.91406 7.99609 6 7.99609C6.08594 7.99609 6.16797 7.97656 6.24609 7.9375C6.32422 7.89062 6.38672 7.82812 6.43359 7.75C6.48047 7.67188 6.50391 7.58984 6.50391 7.50391C6.50391 7.41016 6.48047 7.32812 6.43359 7.25781C6.38672 7.17969 6.32422 7.11719 6.24609 7.07031C6.16797 7.02344 6.08594 7 6 7C5.91406 7 5.83203 7.02344 5.75391 7.07031C5.67578 7.11719 5.61328 7.17969 5.56641 7.25781C5.51953 7.32812 5.49609 7.41016 5.49609 7.50391Z"
        fill={fill}
      />
    </svg>
  );
};

export default ExclamationMarkIcon;
