import React, { ReactElement, FC, useState, useEffect } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { MinusIcon, PlusIcon, Typography } from 'components';
import { theme } from 'theme';

import { BaseProps } from 'shared/types';
import { useCommonStyles } from 'shared/styles/common';

type QuantityEditorProps = {
  step?: number;
  initialValue?: number;
  min?: number;
  max?: number;
  valueChanged?: (value: number) => void;
} & BaseProps &
  BoxProps;

const QuantityEditor: FC<QuantityEditorProps> = ({
  step = 1,
  initialValue = 0,
  min = 0,
  max = 999,
  valueChanged,
  ...restProps
}): ReactElement => {
  const commonClasses = useCommonStyles();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (valueChanged) {
      valueChanged(value);
    }
  }, [value]);

  const changeValue = (newValue: number) => {
    if (newValue >= min && newValue <= max) {
      setValue(newValue);
    }
  };

  return (
    <Box
      width={70}
      height={32}
      bgcolor={theme.palette.custom.darkCyan}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      paddingY={1.5}
      paddingX={2}
      borderRadius={100}
      {...restProps}
    >
      <MinusIcon
        className={commonClasses.cursorPointer}
        onClick={() => changeValue(value - step)}
      />
      <Typography variant="body2" fontWeight="bold" color="textSecondary">
        {value}
      </Typography>
      <PlusIcon className={commonClasses.cursorPointer} onClick={() => changeValue(value + step)} />
    </Box>
  );
};

export default QuantityEditor;
