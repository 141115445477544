export default {
  WELCOME_TEXT: "Hello, {0}!",
  OUT_OF_OFFICE: "{0} is out of office.",
  DASHBOARD_TEXT: "We are developing our portal every week and will let you know when there is something new and interesting!",
  PRIMARY_CONTACT: "Your dedicated contact:",
  READ_MORE: "Read more",
  LESS: 'Less',
  CLOSE: 'Close',
  OPEN: "Open",
  COPY: "Copy",
  CO2_EMISSIONS:"emission",
  CO2_EMISSIONS_TOOLTIP: "The CO2 footprint is calculated after the shipment has been delivered, taking into account the specifics of the route.",
  CREATED_ON: 'created on',
  REFRESH_DATA:'Refresh view',
  TRY_FULL_SCREEN:'Try fullscreen view, to get even better overview',
  DATEPICKER_PLACEHOLDER: "dd.mm.yyyy",
  NOT_FOUND_HEADING: "Oh no!",
  NOT_FOUND_DESC: "The page you are looking for does not exist or ",
  NOT_FOUND_DESC_2: "you don’t have permissions to see it.",
  NOT_FOUND_ERROR_CODE: "Error code: 404",
  FILTER_RESULT: "Filter result",
  FILTER_SEARCH_RESULT: "Search results ",
  ERROR_OUR_BUG: "It's not you, it's us! We are working on that problem and it will be fixed soon!",
  GO_TO_HOMEPAGE: "Go to shipments page",
  GO_BACK: 'Go back',
  FORGOT_PASSWORD: 'Forgot your password?',
  SEND_EMAIL: 'Send email',
  OPERATOR_SEEN: 'Operator has seen',
  RESET_PASSWORD_BOX_TITLE: "Reset password",
  RESET_PASSWORD_BTN: "Reset",
  FORGOT_PASSWORD_BOX_TITLE: "Forgot login details?",
  FORGOT_PASSWORD_BOX_SUCCESS: "Thanks! If we recognized you, you should receive a link to reset your password via email soon.",
  IMPORT_ADDRESSES: 'Import addresses from Excel file',
  LOGIN_BOX_TITLE: "Customer Portal",
  LOGIN_BOX_DESC: 'To see your shipments and their statuses sign in to our client portal.',
  INVITATION_BOX_TITLE : 'Create a new user',
  LOGIN_FAILED: "Automatic login failed",
  TERMS_OF_SERVICE: 'Terms of service',
  USERNAME: 'Username',
  PASSWORD: 'Password',
  PASSWORD_AGAIN: 'Password again',
  SIGN_IN: 'Sign in',
  PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
  USER_NOT_CREATED: 'User not created!',
  CREATE_USER_PASSWORD_ERROR: "Please check password requirements. (Require is at least one special characters, number, lowercase / uppercase letter.)",
  CREATE_USER: 'Create user',
  PASSWORD_HINT:'Password minimum length is 8 letters. Require is at least one special characters, number, lowercase / uppercase letter.',
  FAILED_TO_LOG_IN: 'Failed to log in',
  FAILED_TO_LOG_IN_BLOCKED: 'Your account has been blocked. Please contact customer@laasone.com to unlock your account',
  MY_PROFILE: 'My profile',
  THIS_IS_DEMO_ENVIRONMENT: 'This is a demo environment. Data inserted here will not be processed.',
  INVOICES: 'Invoices',
  WAREHOUSE: 'Warehouse view',
  ALL:'All',
  INCOMING:'Incoming',
  OUTGOING:'Outgoing',
  YESTERDAY:'Yesterday ({0})',
  TODAY:'Today ({0})',
  TOMORROW:'Tomorrow ({0})',
  ENTER_FULLSCREEN:'Enter fullscreen',
  LABEL_NOT_PRINTED:'Labels not printed',
  LABEL_PRINTED:'Labels printed',
  CMR_NOT_PRINTED:'CMR not printed',
  CMR_PRINTED:'CMR printed',
  INVOICES_EXTRA: 'invoices',
  ADDRESS_BOOK: 'Address book',
  RESULTS_FROM_ADDRESS_BOOK: 'Results from address book:',
  PRICE_REQUESTS: 'Price requests',
  RESULTS_FROM_GOOGLE_MAPS: 'Results from Google Maps:',
  COMPANY_PROFILE: 'Company profile',
  COMPANY_DETAILS: 'Company details',
  LOG_OUT: 'Log out',
  SHOW_ONLY_SHIPMENTS_CREATED_BY_ME: 'Show only shipments created by me',
  SHOW_ONLY_SHIPMENTS_CREATED_BY_ME_TOOLTIP: 'If this checkbox is selected, you will only see shipments created by you in this list. But if you uncheck this, all the shipments from your company appear here',
  SHOW_ONLY_PRICE_REQUESTS_CREATED_BY_ME: 'Show only price requests created by me',
  SHOW_ONLY_PRICE_REQUESTS_CREATED_BY_ME_TOOLTIP: 'If this checkbox is selected, you will only see price requests created by you in this list. But if you uncheck this, all the price requests from your company appear here',
  OPEN_FILTER: 'Open filter',
  SORT_BY: 'Sort by',
  SELECT: 'Select',
  SHIPMENT_NUMBER: 'Shipment no',
  REFERENCE_NO: 'Reference no',
  AMOUNT: 'Amount',
  CARRIER: 'Carrier',
  PICKUP: 'Pickup',
  PICKUP_DATE: 'Pickup date',
  PICKUP_ADDRESS: 'Pickup address',
  PICKUP_CONTACT: 'Pickup contact',
  DELIVERY_CONTACT: 'Delivery contact',
  DELIVERY: 'Delivery',
  DELIVERY_DATE: 'Delivery date',
  DELIVERY_ADDRESS: 'Delivery address',
  STATUS: 'Status',
  DATE: 'Date',
  SHOW: 'Show',
  UPLOADED: 'uploaded',
  CLOSE_ALERT: 'Close alert',
  LABELS_SENT_DETAILS: 'Labels sent',
  LOCK_FILE_TOOLTIP: 'Lock the file so others can not download or delete it',
  UNLOCK_FILE_TOOLTIP: 'Unlock the file so others can also download or delete it',
  LOCKING_FILE_CONFIRM_TEXT:'Are you sure you want to change file locking?',
  FILE_IS_LOCKED: 'Locked by ',
  SHIPMENT_SORT_SHIPMENT_NO_DSC: 'Shipment no: descending',
  SHIPMENT_SORT_SHIPMENT_NO_ASC: 'Shipment no: ascending',
  SHIPMENT_SORT_PICKUP_DATE_DSC: 'Pickup date: descending',
  SHIPMENT_SORT_PICKUP_DATE_ASC: 'Pickup date: ascending',
  SHIPMENT_SORT_DELIVERY_DATE_DSC: 'Delivery date: descending',
  SHIPMENT_SORT_DELIVERY_DATE_ASC: 'Delivery date: ascending',
  TEMPLATE_SORT_TEMPLATE_NAME_ASC:'Template name: ascending',
  TEMPLATE_SORT_TEMPLATE_NAME_DESC:'Template name: descending',
  TEMPLATE_SORT_TEMPLATE_PICKUP_ASC: 'Pickup company: ascending',
  TEMPLATE_SORT_TEMPLATE_PICKUP_DESC:'Pickup company: descending',
  TEMPLATE_SORT_TEMPLATE_DELIVERY_ASC:'Delivery company: ascending',
  TEMPLATE_SORT_TEMPLATE_DELIVERY_DESC:'Delivery company: descending',
  ADDRESSBOOK_SORT_NAME_ASC: 'Address name: ascending',
  ADDRESSBOOK_SORT_NAME_DESC: 'Address name: descending',
  ADDRESSBOOK_SORT_COMPANY_ASC: 'Company name: ascending',
  ADDRESSBOOK_SORT_COMPANY_DESC: 'Company name: descending',
  ADDRESSBOOK_SORT_COUNTRY_ASC: 'Country: ascending',
  ADDRESSBOOK_SORT_COUNTRY_DESC: 'Country: descending',
  PRICE_REQUEST_SORT_SHIPMENT_NO_DSC: 'Price request no: descending',
  PRICE_REQUEST_SORT_SHIPMENT_NO_ASC: 'Price request no: ascending',
  SHIPMENT_FILTER_SHIPMENT_NO: 'Shipment no',
  SHIPMENT_FILTER_REFERENCE_NO: 'Reference no',
  SHIPMENT_FILTER_PICKUP_FROM: 'Pickup from',
  SHIPMENT_FILTER_PICKUP_DATE: 'Pickup date',
  SHIPMENT_FILTER_DELIVERY_TO: 'Delivery to',
  SHIPMENT_FILTER_DELIVERY_DATE: 'Delivery date',
  SHIPMENT_FILTER_STATUS: 'Status',
  SEARCH: 'Search',
  SELECT_COUNTRY: 'Select country',
  DOWNLOAD_POD: 'Download POD {0}',
  COPY_TRACKING_LINK:'Copy tracking link',
  TRACKING_LINK_COPIED: 'Tracking link copied to clipboard',
  SEEN_BY_THE_OPERATOR: 'Seen by the operator',
  ON: 'ON',
  OFF: 'OFF',
  PICKUP_AND_DELIVERY_DATES: 'Pickup and delivery dates',
  EARLIEST_PICKUP: 'Earliest pickup',
  LATEST_PICKUP: 'Latest pickup',
  EARLIEST_DELIVERY: 'Earliest delivery',
  LATEST_DELIVERY: 'Latest delivery',
  FIXED_PICKUP: 'Fixed pickup',
  FIXED_DELIVERY: 'Fixed delivery',
  ITEMS_IN_THE_SHIPMENT: 'Items in the shipment',
  ITEMS_ADDED_PRICE_REQUEST: 'Items in the price request',
  COMMENTS_REGARDING_THE_ITEMS: 'Comments regarding the items',
  PICKUP_FROM: 'Pickup from',
  DELIVERY_TO: 'Delivery to',
  LENGTH: 'Length',
  HEIGHT_TOOLTIP: 'The length is fixed for selected item type',
  WIDTH: 'Width',
  WIDTH_TOOLTIP: 'The width is fixed for selected item type',
  HEIGHT: 'Height',
  VOLUME: 'Volume',
  WEIGHT_PC: 'Weight/pc',
  TOTAL_WEIGHT: 'Total weight',
  LDM: 'LDM',
  PRODUCT_DESCRIPTION: 'Product description',
  DESCRIPTION: 'Description',
  LOCATION: 'Location',
  UN_CODE: 'UN code',
  PG: 'PG',
  CLASS: 'Class',
  COLD_FROZEN: 'Cold/frozen',
  STACKABLE: 'Stackable',
  DELICATE: 'Delicate',
  PROPER_SHIPPING_NAME: 'Proper shipping name',
  YOU_ARE_LOGGED_OUT: 'You are logged out',
  SIDEBAR_DASHBOARD: 'Dashboard',
  SIDEBAR_SHIPMENTS: 'Shipments',
  SIDEBAR_PRICE_REQUESTS: 'Price requests',
  SIDEBAR_USERS: 'Users',
  ADD_DOCUMENTS_TITLE: 'Add documents',
  DASHBOARD:{
    LAST_MESSAGES:'Last messages',
    PRICE_REQUEST_SECTION: 'Price requests, that need your attention',
    SHIPMENTS_SECTION: 'Shipments, that need your attention',
    NOT_CONFIRMED_PR:'Not confirmed ({0})',
    NOT_ORDERED_PR:'Not ordered ({0})',
    CURRENT_MONTH_STATISTICS:'Deliverd shipment statistics for the current month',
    INCOMING: 'INBOUND',
    OUTGOING: 'OUTBOUND',
    TOTAL_WEIGHT: 'TOTAL WEIGHT',
    VOLUME: 'VOLUME',
    IN_COUNTRY_PERCENT:'{0}% of outbound shipments were on {1}-{1} route',
    SENDING_COST:'{0} € was your transport cost',
  },
  USERS:{
    ADD_USER:'Invite new user',
    EDIT_USER_TITLE:'Edit user',
    FIRST_NAME:'First name',
    LAST_NAME:'Last name',
    LIMITED_ACCESS:'Manage user access',
    USERNAME: 'Username / e-mail',
    PRIMARY_CONTACT: 'LaasOne primary contact',
    BTN_SAVE_BUTTON: "Save",
    RIGHTS:{
      NO_ACCESS: 'No access',
      VIEW: 'Only view',
      ADD_EDIT: 'Add or edit'
    },
    INVITED: 'Pending',
    DELETE_SUCCESS: 'User removed',
    DELETE_CONFIRMATION: 'Are you sure you want to remove user?',
    BTN_DELETE: "Delete",
    TEXT_USER_INVITED: "User invitation sent",
    TEXT_USER_SAVED: "User data saved",
    LIMITED_ACCESS_LABEL: "Limited access",
    SHOW_SHIPMENT_PRICE: "Show shipments price"
  },
  USERNAME_EXISTS: "This username already exists",
  REPLAY:'Reply',
  DROPZONE: {
    DRAG_AND_DROP: 'Drag and drop a file here or',
    CHOOSE_FROM_COMPUTER: 'choose from your computer',
    SUPPORTED_FILES: 'Supported file types: PDF, PNG, JPG, JPEG',
    SUPPORTED_FILES_XLS: 'Supported file types: XLSX',
    ERROR: 'File “{0}” has a file type that is not permitted',
  },
  INVOICE:{
    SEARCH_BY_ALL_INVOICES_TEXT_FIELDS : 'Search an invoice by number',
    DATE: 'Invoice date',
    NUMBER: 'Invoice number',
    PRICE: 'Price',
    DOWNLOAD: 'Download (PDF)'
  },
  INVOICE_SORT_DATE_ASC: 'Date: ascending',
  INVOICE_SORT_DATE_DESC: 'Date: descending',
  INVOICE_SORT_TOTAL_ASC: 'Total: ascending',
  INVOICE_SORT_TOTAL_DESC: 'Total: descending',
  CANCEL: 'Cancel',
  APPROVE: 'Approve',
  YES: 'Yes',
  NO: 'No',
  SAVE_CHANGES: 'Save changes',
  SAVE_CHANGES_ADDRESS_BOOK: 'Save changes',
  BREADCRUMB: {
    SHIPMENTS: 'Shipments',
    SHIPMENTS_ADD: 'Add new shipment',
    EDIT_SHIPMENT_DRAFT_NO: 'Edit shipment draft no {0}',
    PRICE_REQUESTS: 'Price requests',
    PRICE_REQUESTS_ADD: 'Add new price request',
    EDIT_PRICE_REQUEST_DRAFT_NO: 'Edit price request draft no {0}',
    PRICE_REQUEST: 'Price request',
    COMPANY_PROFILE: 'Company profile',
    TEMPLATES: 'Templates',
    TEMPLATES_ADD: 'Add new template',
    EDIT_TEMPLATE: 'Edit template',
    CLAIM: 'Claim',
    CLAIM_ADD: 'Add claim',
    EDIT_CLAIM_DRAFT_NO: 'Edit draft'
  },
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
  SHIPMENTS_SCENARIO: {
    FILTER: {
      FILTER: 'Filter',
      CLOSE_FILTER: 'Close filter',
      CLEAR_ALL_FIELDS: 'Clear all fields',
      SHIPMENT_NR: 'Shipment nr',
      REFERENCE_NR: 'Reference nr',
      PICK_UP_FROM: 'Pickup country',
      SELECT_COUNTRY: 'Select country',
      PICK_UP_DATE: 'Pick-up date',
      DELIVERY_TO: 'Delivery country',
      DELIVERY_DATE: 'Delivery date',
      STATUS: 'Status',
      SELECT_STATUS: 'Select status',
      OOOPS: 'Oooops, no results :(',
      WE_SEARCHED: 'We searched far and wide and couldn’t find any shipments matching your search.',
      TRY_DIFFERENT: 'Try different filter criteria or remove some of the filters.',
      INVALID_DATE: 'Invalid date',
      PICKUP_COMPANY: 'Pickup company',
      DELIVERY_COMPANY: 'Delivery company',
      HELLO_USERNAME: 'Hello, {0}!',
      NO_SHIPMENTS_HERE: 'No shipments here? Let\'s fix that!',
      MAKE_A_FEW_SELECTIONS: 'Make a few selections, fill in some boxes and your shipment is on its way!',
      NEED_HELP: 'Need help? Contact us: ',
      PSST_DONT_WANT_TO_ADD: 'Psst! Don’t want to add anything yet? Uncheck to see shipments by your company!',
      HERE_YOU_WILL_SEE: 'Here you will see the price requests that you have added.',
      START_ADDING_A_NEW_SHIPMENT: 'Start adding a new shipment or price request by clicking the yellow button up there!',
      EXPORT_XLS: 'Export filter results (.xlsx)'
    },
  },
  RELEASE_FROM_TOP: 'Release from top',
  DOWNLOAD_LABELS: 'Download labels',
  DOWNLOAD_CMR: 'Download CMR',
  COPY_SHIPMENT: 'Copy',
  CANCEL_SHIPMENT: 'Cancel shipment',
  COPY_PRICE_REQUEST: 'Copy price request',
  CANCEL_PRICE_REQUEST: 'Cancel price request',
  SHIPMENT_DETAILS: 'Shipment details',
  BASED_ON: 'Based on',
  COST: 'Cost',
  COST_TOOLTIP: 'Price is shown without VAT',
  WHERE_IS_MY_SHIPMENT: 'Where is my shipment?',
  DETAILED_SHIPMENT_EVENTS: 'Detailed shipment events',
  DOCUMENTS: 'Documents',
  ADD_NEW_DOCUMENT: 'Add new document',
  NOT_SELECTED: 'Not selected',
  DRAFT: 'Draft',
  CANCELED: 'Canceled',
  MISSING_DATA: 'Missing data',
  SHIPMENT_CREATED: 'Created',
  WAITING_CONFIRMED: 'To be approved',
  CONFIRMED: 'Processing',
  PROCESSING: 'Sent to carrier',
  IN_TRANSIT: 'In transit',
  DELIVERING: 'Delivering',
  DELIVERED: 'Delivered',
  LAST_DELIVERED: 'Last delivered',
  WAITING_READINESS_REQUEST: 'Waiting for readiness request',
  WAITING_FOR_CONFIRMATION: 'Waiting for confirmation',
  ACCEPTED: 'Approved',
  REJECTED: 'Rejected',
  EXPIRED: 'Expired',
  PENDING_PRICES: 'Pending prices',
  NOT_KNOWN: 'not known',
  ADD_NEW_SHIPMENT: 'Add new shipment',
  ADD_NEW_PRICE_REQUEST: 'Add new price request',
  ADD_NEW_CLAIM: 'Add new claim',
  ADD_NEW_SHIPMENT_FROM_TEMPLATE: 'Add new shipment from template',
  ADD_NEW_SHIPMENT_FROM_EXCEL: 'Add shipments from Excel template',
  EDIT_SHIPMENT_DRAFT_NO: 'Edit shipment draft no {0}',
  ADD_NEW_TEMPLATE: 'Add new template',
  EDIT_TEMPLATE_DRAFT_NAME: 'Edit template',
  ADD_NEW: 'Add new',
  SWAP: 'Swap',
  ADDRESSBOOK: {
    FILTER:{
      INPUT_TEXT: 'Search from all address fields',
      BUTTON: 'Search'
    },
    ADDRESSES: 'addresses',
    ONE_ADDRESS: 'address',
    IMPORT_MODAL_TITLE:'Import addresses from Excel file',
    IMPORT_MODAL_TITLE_CORRECT_ROWS:'{0} addresses were imported from the Excel file',
    IMPORT_MODAL_TITLE_INCORRECT_ROWS:'{0} addresses are going to be imported from Excel file',
    IMPORT_MODAL_TITLE_SMALL: 'Only one Excel file can be imported at once',
    TOOLTIPS: {
      ADDRESS_NOTE_TOOLTIP: 'tooltip',
      ADDRESS_REFERENCE_TOOLTIP: 'tooltip',
    },
    EDIT_ADDRESS: 'Edit address',
    ADDRESS_NAME: 'Address name',
    COMPANY_NAME: 'Company name',
    ADDRESS: 'Address',
    COUNTRY: 'Country',
    CITY: 'City',
    CONTACT_PERSON: 'Contact person',
    CONTACT_NUMBER: 'Contact no',
    CONTACT_EMAIL: 'E-mail',
    DELETE_BUTTON: 'Delete address',
    DELETE_CONFIRM_TEXT: 'Are you sure you want to delete address "{0}"?',
    BTN_IMPORT:'Import addresses',
    BTN_IMPORT_FINISH:'Ok, understood',
    BTN_IMPORT_CORRECT_ROWS:'Import rows with correct information',
    BTN_IMPORT_DOWNLOAD_ERROR_XLSX:'Download file with error rows (.xlsx)',
    ERROR_MALFORMED_ROWS_TEXT: 'Some rows shown below are missing information and therefore they cannot be imported',
    ERROR_DUPLICATED_ROWS_TEXT: 'Some addresses shown below are already saved and therefore they cannot be imported',
    MISSING: 'missing',
    IMPORT_EXCEL_FILE: 'Import Excel file',
    DOWNLOAD_EXCEL_TEMPLATE: 'Download Excel template',
    ADD_NEW_ADDRESS: 'Add new address',
    SAVE_ADDRESS: 'Save address',
    ADDRESS_ADDED_TO_ADDRESS_BOOK: 'Address added to addressbook',
  },
  PRICE_REQUEST: {
    TOOLTIP:{
      EXPIRES_SOON: 'Validity ends soon',
      CANCELED: 'Rejected or canceled',
      CAN_NOT_BE_CANCELED: 'This price request cannot be cancelled, because it’s already being processed.'
    },
    PRICE_ORDER_NOW: 'Order now',
    WE_CAN_OFFER_PRICES: 'We can offer prices',
    NO_PRICES: 'To see prices, fill in information about items and addresses.',
    NO_AUTOMATIC_PRICES: "Unfortunately we didn't find automatic prices for your request. Operator will take a look after you send it and let you know what we can offer.",
    CREATE_SHIPMENT_ALERT_HEADING:'Create new order',
    CREATE_SHIPMENT_ALERT:'For placing an order, system will open a new shipment form, which is pre-filled with the data from the approved price-request.\n {0}Please note that the price is valid only to the same information as in the price request.{1} When ordering from an approved price offer, the system does not automatically calculate a new price if the data has been changed, for this you must contact our logistics specialist.',
    CANCELED: 'Price request is cancelled',
    REJECTED: 'Price request is rejected',
    TITLE_ACCEPTED:'Nice, you approved the price {0}€!',
    TITLE_ACCEPTED_VALID_UNTIL:'Please note, that this price is valid until {0}.',
    TITLE_ACCEPTED_TEXT: 'You can now add a new shipment with the same data as in this price request. ',
    TITLE_EXPIRED: 'This price request is expired!',
    HEADER_REJECTED_TITLE_0: 'This price request is cancelled!',
    HEADER_REJECTED_TITLE_1: 'A bit sad, you rejected our offer :(',
    HEADER_REJECTED_TITLE_1_SMALL: 'But that’s okay, we understand. ',
    HEADER_REJECTED_TITLE_1_1_SMALL: 'If you want to ask another price, just start  \n a new price request. We are happy to offer you the best price possible!',
    PLEASE_START_A_NEW_PRICE_REQUEST: 'Please start a new price request or copy this price request to get new and updated \n prices for your delivery.',
    HEADER_EXPIRED_OFFERS: 'Price request included following prices, you did not choose a price for you:',
    HEADER_EXPIRED_OFFER: 'Price request included a price you chose:',
    OPERATOR_COMMENT: 'Operator comment: {0}',
    ADD_NEW_SHIPMENT:'Create an order',
    ALREADY_GENERATED_SHIPMENTS: 'You have already started shipments from this price request:',
    INSURANCE: 'Insurance',
    ITEMS_VALUE: 'Item(s) value',
    CONDITION: 'Condition',
    PRICE: 'Price',
    SAVE_AS_DRAFT_SUCCESS: 'Price request has been saved as draft',
    ADD_COMMENTS_REGARDING_THE_PRICE_REQUEST: 'Add comments regarding the price request',
    ADD_A_COMMENT: 'Add a comment',
    ADD_AN_E_MAIL_WHERE_WE_WILL_SEND_THE_OFFER: 'Add an e-mail where we will send the offer',
    IM_LOOKING_FOR_PRICES_FOR_30_DAYS: "I will freeze the selected price for 30 days",
    IM_LOOKING_FOR_PRICES_FOR_REGULAR_SHIPMENTS: "I'm looking for prices for regular shipments",
    IM_LOOKING_FOR_PRICES_FOR_REGULAR_SHIPMENTS_TOOLTIP: "If you will be sending this kind of shipment regularly, we can probably offer a better price. If this is the case, please fill below form so we can take this into account.",
    PLEASE_ADD_THE_NUMBER_OF_TIMES_AND_PERIOD: 'Please add the number of times and period',
    SEND_PRICE_REQUEST: 'Send price request',
    ADD_ITEMS_TO_YOUR_PRICE_REQUEST: 'Add items to your price request',
    OFFERED_PRICES: '{0} prices',
    OFFERED_PRICE: '{0} price',
    FILTER:{
      NUMBER: 'Price request number'
    },
    SIDEBAR:{
      DETAILS: 'Price request details',
      INSURANCE: 'Insurance',
      ITEMS_VALUE: 'Item(s) value',
      CONDITION: 'Condition',
      DESCRIPTION: 'Description',
      REGULAR_SHIPMENTS: 'Regular shipments',
      REGULARITY: 'Regularity',
      STATUSES: 'Price request status',
      ACTIONS: 'Copy and cancel price request',
      TIMES_PER: 'times per',
    },
    NUMBER: 'No',
    PICK_UP_DATE: 'Pickup date',
    DELIVERY_DATE: 'Delivery date',
    VALID_UNTIL_DATE: 'Valid until',
    HEADER_TITLE_1: 'We are working hard on your price request!',
    HEADER_TITLE_1_SMALL: 'We will let you know here and via e-mail if we have the prices for this request.',
    EMAIL_NOTIFICATIONS: 'E-mail for the offer notification',
    COMMENTS_TITLE: 'Comments regarding the price request',
    CLICK_ONE_OR_MORE_ITEMS_ABOVE_TO_START_ADDING_THEM_TO_YOUR_PRICE_REQUEST:
      'Click one or more items above to start adding them to your price request',
    TIMES: 'times',
    PER: 'per',
    SELECT_PERIOD: 'Select period',
    EMAIL_WHERE_WE_WILL_SEND_THE_OFFER: 'E-mail where we will send the offer',
    I_WANT_TO_INSURE_MY_ITEMS: 'I want to insure my items',
    TOTAL_VALUE_OF_ITEMS: 'Total value of items',
    PLEASE_ADD_THE_DESCRIPTION_OF_YOUR_ITEMS: 'Please add the description of your items',
    TIMES_AND_PERIOD: 'Times and period',
    CONFIRM_PRICE_REQUEST: 'Confirm price request',
    CANCEL_PRICE_REQUEST_BTN: 'Yes, cancel price request',
    CANCEL_PRICE_REQUEST: 'Cancel price request',
    CANCEL_CONFIRMATION:'Are you sure you want to cancel price request "PR-{0}"?',
    CANCEL_SUCCESS: 'Price request canceled successfully',
    DAYS_DELIVERY: 'days delivery',
    WORKING_DAY_DELIVERY: 'working day',
    WORKING_DAYS_DELIVERY: 'working days',
    ESTIMATED_DELIVERY: 'Estimated Delivery',
    SAME_DAY_DELIVERY: 'Same day delivery',
    ON_TIME_DELIVERY: 'On time delivery',
    THIS_OPTION_IS_CHOSEN: 'This option is chosen',
    THIS_OPTION_UNSELECT: 'Unselect this option',
    CHOOSE_THIS_OPTION: 'Choose this option',
    ADD_AN_EMAIL: 'Add here e-mail address or several separated by commas. We will send the offer to those e-mails once it is ready.',
    PRICE_REQUEST_VALID_UNTIL: 'Price request valid until',
    APPROVE_OFFER: 'Approve offer',
    APPROVE_OFFER_ORDER_NOW: 'Confirm and order now',
    APPROVE_SUCCESS: 'Offer was successfully approved',
    REJECT_THIS_OFFER: 'Reject this offer',
    REJECT_SUCCESS: 'Offer was successfully rejected',
    PRICE_TOO_HIGH: 'Price too high',
    SHIPMENT_POSTPONED: 'Shipment postponed',
    DELIVERY_NOT_NEEDED: 'Delivery not needed',
    ASKED_AS_REFERENCE_PRICE: 'Asked as a reference price',
    OTHER: 'Other',
    OFFER_REJECTION_DIALOG_TITLE: 'Please let us know why you rejected the price request?',
    YOUR_REASON_FOR_REJECTION: 'Your reason for rejection',
    SEND_YOUR_ANSWER: 'Send your answer',
    PLEASE_COMMENT_YOUR_CHOICE: 'Please comment your choice',
    REASON_FOR_REJECTION_HAS_TO_BE_SPECIFIED: 'Please fill in reason for rejection',
    OFFER_APPROVAL_DIALOG_TITLE: 'Are you sure you want to approve price {0}€?',
    OFFER_APPROVAL_DIALOG_TEXT: 'You can create a shipment based on this offer after confirming the price.',
    PICK_ONE_PRICE_ERROR: 'Please choose a price that you want to approve for this price request',
    COMMENT_RELATED_TO_PRICE: 'Comment related to price'
  },
  TEMPLATE: { 
    TEMPLATE_NAME: 'Template name',
    TEMPLATES: 'Templates',
    TEMPLATE: 'Malli',
    TEMPLATES_ADD: 'Add new template',
    SEARCH_BY_ALL_TEMPLATES_TEXT_FIELDS: 'Search from template name or address info',
    DELETE_BUTTON: 'Delete',
    DELETE_CONFIRM_TEXT: 'Are you sure you want to delete template "{0}"?',
    DELETE_SUCCESS: 'Template was deleted',
    DELETE_ERROR: 'There was a problem deleting template',
    ADD_ITEMS_TO_YOUR_TEMPLATE: 'Edit template items',
    SAVE_TEMPLATE: 'Save changes',
    ADD_TEMPLATE_NAME: 'Add template name',
    CHANGE_TEMPLATE_NAME: 'Edit template name',
    TEMPLATE_NAME_LABEL: 'Template name',
    TEMPLATE_NAME_TOOLTIP: 'Template name will be shown when you are creating a new shipment from template. Create a memorable name that helps you choose the correct template.',
    SAVE_SUCCESS: 'Template has been saved',
    ADDRESSES:{
      EDIT_ROUTE_ADDRESSES: 'Edit template addresses'
    },
    TEMPLATE_CREATED: 'Template created on {0}',
    SEARCH_FOR_A_TEMPLATE_TITLE:'Search for a template:',
  },
  SHIPMENT: {
    STAR_TOOLTIP:'Highlight shipment on list',
    PICK_ONE_PRICE_ERROR: 'Please pick one price for the approval',
    SEND: 'Send',
    SEND_MESSAGE: 'Send message',
    HAS_FILES:'Shipment has files',
    COMMENTS: 'Messages',
    HAS_COMMENT: 'Has messages',
    NEW_COMMENT: '1 new message',
    NEW_COMMENTS: '{0} new messages',
    NO_NEW_COMMENTS: 'No new messages',
    ADD_A_COMMENT: 'Add a message',
    CLEAR_THE_FIELD: 'Clear the field',
    SHIPMENT_TYPE: 'Send/Receive items',
    TYPE:{
      SENDER: 'I am sending items',
      RECEIVER: 'I am receiving items'
    },
    YOU_HAVE_UNSAVED_CHANGES: 'You have unsaved changes.',
    DISCARD_SHIPMENT: 'Discard shipment',
    TITLE_ADD_NEW_SHIPMENT_FROM_TITLE: 'Add a new shipment from template',
    BTN_ADD_SHIPMENT:'Add shipment',
    FROM_TEMPLATE_DESCRIPTION: 'To create and manage templates, open “Templates” under user menu in the header.',
    BASED_ON_SHIPMENT: 'Based on the shipment {0}. ',
    SHIPMENT_CREATED: 'Shipment created {0}',
    TRANSPORT_WITH_TAIL_LIFT_TRUCK: 'Pickup with tail lift truck',
    TRANSPORT_WITH_TAIL_LIFT_TRUCK_DELIVERY: 'Delivery with tail lift truck',
    I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK_TOOLTIP: 'Truck with a mechanical device permanently installed on the rear. It is designed to load goods from ground level or a loading dock to the level of the vehicle bed, or vice versa.',
    SAVE_SHIPMENT_AS_A_TEMPLATE: 'Save shipment {0} as a template',
    THIS_SHIPMENT_IS_NOT_SAVED_AS_TEMPLATE: 'This shipment is not saved as template',
    THIS_SHIPMENT_IS_SAVED_AS_TEMPLATE: 'This shipment is based on template',
    REMOVE_TEMPLATE_CONFIRM_TEXT: 'Are you sure you want to remove "{0}" from templates?',
    SAVE_THIS_SHIPMENT_AS_TEMPLATE_BUTTON: 'Save this shipment as a template',
    CANCEL_CONFIRMATION_DIALOG_TEXT: 'Are you sure you want to cancel? The changes to shipment draft will not be saved.',
    REMOVE_TEMPLATE_BUTTON: 'Remove template',
    REMOVE_FROM_TEMPLATES: 'Manage templates',
    NAME_YOUR_TEMPLATE: 'Name your template',
    MANAGE_TEMPLATES: 'Manage templates',
    SAVE_TEMPLATE: 'Save template',
    TEMPLATE_NAME: 'Template name',
    NOTIFICATIONS_FOR_THIS_SHIPMENT: 'Notifications for this shipment',
    SEND_SHIPMENT: 'Send shipment',
    SAVE_AS_DRAFT: 'Save as draft',
    SAVE_AS_DRAFT_SUCCESS: 'Shipment has been saved as draft',
    DELETE_DRAFT: 'Delete draft',
    DELETE_DRAFT_CONFIRMATION: 'Are you sure you want to delete shipment draft number {0}?',
    DELETE_DRAFT_SUCCESS: 'Shipment draft successfully deleted',
    SHIPMENTS: 'Shipments',
    SHIPMENT: 'Shipment',
    SHIPMENT_NR: 'Shipment nr',
    REF_NR: 'Ref nr',
    REF_MISSING:'Ref missing',
    CARRIER_MISSING: 'Carrier missing',
    PICKUP_MISSING: 'Pickup address missing',
    DELIVERY_MISSING: 'Delivery address missing',
    DATE_MISSING: 'Date missing',
    ADD_REFERENCE_NUMBER: 'Add reference number',
    YOUR_SHIPMENTS_PRICE: "Your shipment's price",
    YOUR_SHIPMENTS_PRICE_DESC: 'Prices shown are indicative and can change if actual items do not match the inserted values',
    NO_PRICES: 'To see prices for your shipment, fill in information about items and addresses.',
    NO_AUTOMATIC_PRICES: "Unfortunately we don't support automatic prices for your shipment. Operator will take a look after you send it and let you know what we can offer.",
    AUTOMATIC_PRICES_IN_PROGRESS: 'We are working hard to find the best prices for the items and routes you inserted!',
    REFERENCE_NUMBER: 'Reference number',
    REFERENCE_NUMBER_TOOLTIP: 'Number specific to the goods or the shipment in the customer systems.',
    NOTIFY_ME_ABOUT_THIS_SHIPMENT: 'Notify me about this shipment',
    SAVE_THIS_SHIPMENT_AS_TEMPLATE: 'Save this shipment as template',
    SAVE_THIS_SHIPMENT_AS_TEMPLATE_TOOLTIP: 'Possibility of saving the shipment as a template to use the same data in the future while making a new shipment.',
    EARLIEST_PICKUP_DATE: 'Earliest pickup date',
    LATEST_PICKUP_DATE: 'Latest pickup date',
    EARLIEST_DELIVERY_DATE: 'Earliest delivery date',
    LATEST_DELIVERY_DATE: 'Latest delivery date',
    FIXED_PICKUP_DATE: 'Fixed pickup date',
    FIXED_DELIVERY_DATE: 'Fixed delivery date',
    EARLIEST_PICKUP_TIME: 'Earliest pickup time',
    LATEST_PICKUP_TIME: 'Latest pickup time',
    EARLIEST_DELIVERY_TIME:'Earliest delivery time',
    LATEST_DELIVERY_TIME: 'Latest delivery time',
    FIXED_PICKUP_TIME:'Fixed pickup time',
    FIXED_DELIVERY_TIME:'Fixed delivery time',
    PLEASE_CHECK_YOUR_SHIPMENT_INFORMATION_BEFORE_CONFIRMING: 'Please check your shipment information before confirming',
    SIDEBAR:{
      ACTIONS: 'Copy shipment',
    },
    DATES:{
      ADD_PICKUP_DELIVERY_DATES: "Add dates for pickup and delivery",
      PICKUP_DATES: "Pickup dates",
      DELIVERY_DATES: "Delivery dates",
      PICK_PICKUP_DATES: "Add earliest and latest pickup dates",
      PICK_DELIVERY_DATES: "Add earliest and latest delivery dates",
      PICK_PICKUP_FIXED_DATE: "Add fixed pickup date",
      PICK_DELIVERY_FIXED_DATE: "Add fixed delivery date"
    },
    NAME_THIS_SHIPMENT_TEMPLATE: 'Name this shipment template',
    ADDRESSES: {
      ADD_ADDRESS: 'Add address',
      UPDATE_ADDRESS: 'Update address',
      ADD_ROUTE_ADDRESSES: 'Add route addresses',
      REQUIRED_FIELDS_ARE_MARKED_WITH: 'Required fields are marked with',
      WHERE_TO_PICK_UP_FROM: 'Where to pick up from?',
      WHERE_TO_DELIVER: 'Where to deliver?',
      RECENTLY_USED_ADDRESSES: 'Recently used addresses',
      CHOOSE_FROM_ADDRESS_BOOK: 'Choose from address book',
      ADD_MANUALLY: 'Add manually',
      CLEAR_ALL_FIELDS: 'Clear all fields',
      SAVE_THIS_ADDRESS: 'Save this address:',
      NAME_THIS_ADDRESS: 'Name this address',
      SENDER_AND_DELIVERY_ADDRESSES: 'Sender and delivery addresses',
      TYPE_HERE_THE_ADDRESS_AND_SELECT_IT_FROM_GOOGLE_RESULTS_OR_FROM_ADDRESS_BOOK:
        'Type here the address and select it from Google results or from address book',
      COUNTRY: 'Country',
      CITY: 'City',
      POSTCODE: 'Postcode',
      COMPANY_NAME: 'Company name',
      CONTACT_PERSON: 'Contact person',
      CONTACT_NUMBER: 'Contact number',
      EMAIL: 'E-mail',
      REFERENCE: 'Reference',
      LOADING_REFERENCE: 'Loading reference',
      LOADING_REFERENCE_TOOLTIP: 'Number specific to the pickup loading place/building etc.',
      UNLOADING_REFERENCE: 'Unloading reference',
      UNLOADING_REFERENCE_TOOLTIP: 'Number specific to the delivery loading place/building etc.',
      ADD_AN_ALTERNATIVE_ADDRESS: 'Add an alternative address',
      ADDRESS_DETAILS: 'Address details',
      ADDRESS: 'Address',
      ADD_MORE_INFORMATION: 'Add more information',
      CLOSE_MORE_INFORMATION: 'Close more information',
      SENDER: {
        INFORMATION: 'Sender information',
        ADDRESS: 'Sender address',
        CONTACT_PERSON: 'Sender contact person',
        ADDRESS_DETAILS: 'Sender address details',
        ADDRESS_DETAILS_TOOLTIP: 'Details related to the address to indicate a specific place for the pickup of goods (side of the building, door, terminal etc)',
        ALTERNATIVE_ADDRESS: 'Alternative sender address',
        ALTERNATIVE_CONTACT_PERSON: 'Alternative sender contact person',
        ALTERNATIVE_ADDRESS_DETAILS: 'Alternative sender address details',
        ADD_AN_ALTERNATIVE_ADDRESS: 'Add an alternative sender address',
      },
      RECEIVER: {
        INFORMATION: 'Receiver information',
        ADDRESS: 'Receiver address',
        CONTACT_PERSON: 'Receiver contact person',
        ADDRESS_DETAILS: 'Receiver address details',
        ADDRESS_DETAILS_TOOLTIP: 'Details related to the address to indicate a specific place for the delivery of goods (side of the building, door, terminal etc)',
        ALTERNATIVE_ADDRESS: 'Alternative receiver address',
        ALTERNATIVE_CONTACT_PERSON: 'Alternative receiver contact person',
        ALTERNATIVE_ADDRESS_DETAILS: 'Alternative receiver address details',
        ADD_AN_ALTERNATIVE_ADDRESS: 'Add an alternative receiver address',
      },
    },
    ADD_ITEMS_TO_YOUR_SHIPMENT: 'Add items to your shipment',
    CLICK_ON_THE_ITEM_YOU_WANT_TO_ADD: 'Click on the item you want to add',
    ITEMS_ADDED: 'Items added',
    CLICK_ONE_OR_MORE_ITEMS_ABOVE_TO_START_ADDING_THEM_TO_YOUR_SHIPMENT:
      'Click one or more items above to start adding them to your shipment',
    I_NEED_PICKUP_WITH_A_TAIL_LIFT_TRUCK: 'I need pickup with a tail lift truck',
    I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK: 'I need delivery with a tail lift truck',
    ADD_A_COMMENT_REGARDING_THE_ITEMS_ADDED: 'Add a comment regarding the items added',
    DOCUMENTS: {
      DELETE_TOOLTIP: 'This document cannot be deleted because it was created by the operator',
    },
    CMR_NOT_AVAILABLE: 'This document cannot yet be downloaded because the carrier is not selected',
    POD_NOT_AVAILABLE: 'This document cannot yet be downloaded because POD files are missing',
    ITEM: {
      SELECT_TYPE: 'Select type',
      TOTAL: 'Total',
      DETAILS: {
        PC: 'pc',
        TYPE: 'Type',
        LENGTH: 'Length',
        LENGTH_TOOLTIP: 'The length is fixed for selected item type',
        WIDTH: 'Width',
        WIDTH_TOOLTIP: 'The width is fixed for selected item type',
        HEIGHT: 'Height',
        VOLUME: 'Volume',
        WEIGHT_PC: 'Weight/pc',
        TOTAL_WEIGHT: 'Total weight',
        LDM: 'LDM',
        LDM_TOOLTIP: 'Loading metres (running metres of trailer length in full width and height). This is automatically calculated based on length, width and quantity, but you can write a different value if needed.',
        REFERENCE_NUMBER: 'Reference number',
        PRODUCT_DESCRIPTION: 'Product description',
        UN_NO: 'UN no',
        UN_NO_TOOLTIP: 'Number, that identifies dangerous goods, hazardous substances and articles',
        UN: 'UN',
        UN_CODE: 'UN code',
        PG: 'PG',
        PG_TOOLTIP: 'Dangerous goods are assigned into 3 packing groups in accordance with the degree of danger they present',
        CLASS: 'Class',
        CLASS_TOOLTIP: 'Classification numbers for the dangerous goods',
        DANGEROUS_GOODS: 'Dangerous goods',
        COLD_FROZEN: 'Cold/frozen',
        STACKABLE: 'Stackable',
        DELICATE: 'Delicate',
        MIN_TEMP: 'Min temp',
        MIN_TEMP_TOOLTIP: 'Minimum temperature of the goods that they require for transport',
        MAX_TEMP: 'Max temp',
        MAX_TEMP_TOOLTIP: 'Maximum temperature of the goods that they require for transport',
        PROPER_SHIPPING_NAME: 'Proper shipping name',
        PROPER_SHIPPING_NAME_TOOLTIP: 'Name to describe the hazard properties and the composition of dangerous goods',
        PROPER_SHIPPING_NAME_PLACEHOLDER: 'Black powder, compressed or Gunpowder',
        PACKAGE_INFO:'Mark the measurement of the longest side on the field "Length" when the package is stackable.'
      },
      TYPE: {
        PALLET: 'Pallet',
        PACKAGE: 'Package',
        TRUCK: 'Truck',
        OTHER: 'LDM',
        CONTAINER:'Container',
        MACHINE:'Machine'
      },
      SUBTYPE: {
        EUR_PALLET: 'EUR pallet',
        FIN_PALLET: 'FIN pallet',
        PALLET: 'Pallet',
        PACKAGE: 'Package',
        BOX: 'Box',
        FULL_TRUCK_LOAD: 'Full truck load',
        LONG_PALLET: 'Long pallet',
        HALF_PALLET: 'Half pallet',
        LDM: 'LDM',
        CONTAINER_20_DC: '20’DC',
        CONTAINER_40_DC: '40’DC',
        CONTAINER_40_HC: '40’HC',
        CONTAINER_OTHER: 'Other',
        MACHINE:'Machine'
      },
      CLASS: {
        EXPLOSIVES: 'Explosives',
        GASES: 'Gases',
        FLAMMABLE_LIQUIDS: 'Flammable Liquids',
        FLAMMABLE_SOLIDS: 'Flammable Solids',
        OXIDIZERS: 'Oxidizers',
        TOXIC_SUBSTANCES: 'Toxic Substances',
        RADIOACTIVE: 'Radioactive',
        CORROSIVES: 'Corrosives',
        MISCELLANOUS: 'Miscellanous',
      },
      PG: {
        PG1: 'Packing Group I: high danger',
        PG2: 'Packing Group II: medium danger',
        PG3: 'Packing Group III: low danger',
      },
    },
    DELETE_DOCUMENT: 'Delete document',
    DELETE_CONFIRM_TEXT: 'Are you sure you want to delete document "{0}"?',
    ADD_DOCUMENT_MODAL_TITLE: 'Add new document',
    ADD_DOCUMENT_BUTTON: 'Add document',
    CUSTOMER_CONFIRMED: 'Customer confirmed: {0}',
    PICKUP_ON: 'Pickup on: {0}',
    PICKUP_FROM: 'Pickup from: {0}',
    PICKUP_BETWEEN: 'Pickup between: {0} and {1}',
    DELIVERY_ON: 'Delivery on: {0}',
    DELIVERY_FROM: 'Delivery on: {0}',
    DELIVERY_BETWEEN: 'Delivery between: {0} and {1}',
    IMPORT_MODAL_TITLE:'Import shipments from Excel file',
    IMPORT_MODAL_TITLE_CORRECT_ROWS:'{0} shipments successfully imported',
    IMPORT_MODAL_TITLE_INCORRECT_ROWS:'No shipments were imported from Excel file',
    IMPORT_MODAL_TITLE_SMALL: 'Only one Excel file can be imported at once.',
    BTN_IMPORT:'Import shipments',
    BTN_IMPORT_FINISH:'Ok, understood.',
    BTN_IMPORT_CORRECT_ROWS:'Ok, understood. Will upload a new file',
    BTN_IMPORT_DOWNLOAD_ERROR_XLSX:'Download file with error rows (.xlsx)',
    ERROR_MALFORMED_ROWS_TEXT: 'Some rows shown below have validation errors and therefore they cannot be imported',
    ERROR_DUPLICATED_ROWS_TEXT: 'Some shipments shown below are already saved and therefore they cannot be imported',
    EXCEL_ROW_NUMBER: 'Excel row number',
    EXCEL_ROW_MISSING_WRONG_INFO: 'Missing or wrong information',
    EXCEL_ROW_EXISTS: 'Shipment that exists with the same information',
    EXCEL_IMPORT_ERRORS:{
      SENDER_REQUIRED:'Sender is missing',
      SENDER_ADDRESS_REQUIRED:'Sender address is missing',
      SENDER_CITY_REQUIRED:'Sender city is missing',
      SENDER_POST_CODE_REQUIRED:'Sender postcode is missing',
      SENDER_COUNTRY_REQUIRED:'Sender country is missing',
      SENDER_CONTACT_PERSON_REQUIRED:'Sender contact person name is missing',
      SENDER_CONTACT_PHONE_REQUIRED:'Sender contact person phone is missing',
      RECEIVER_REQUIRED:'Receiver is missing',
      RECEIVER_ADDRESS_REQUIRED:'Receiver address is missing',
      RECEIVER_CITY_REQUIRED:'Receiver city is missing',
      RECEIVER_POST_CODE_REQUIRED:'Receiver postcode is missing',
      RECEIVER_COUNTRY_REQUIRED:'Receiver country is missing',
      RECEIVER_CONTACT_PERSON_REQUIRED:'Receiver contact person name is missing',
      RECEIVER_CONTACT_PHONE_REQUIRED:'Receiver contact person phone is missing',
      PICKUP_DATE_ERROR:'Pickup date is missing',
      DELIVERY_DATE_ERROR:'Delivery date is missing',
      DELIVERY_DATE_IS_EARLIER_THAN_PICKUP_ERROR:'Tarne kuupäev on varasem kui korje kuupäev',
      QUANTITY_ERROR:'Quantity is missing',
      PACKAGE_ERROR:'Package type is missing',
      WEIGHT_ERROR:'Weight is missing',
      WIDTH_ERROR:'Width is missing',
      LENGTH_ERROR:'Length is missing',
      HEIGHT_ERROR:'Height is missing',
      PICKUP_DATE_IN_PAST: 'Pickup date is in the past',
      LDM_ERROR: 'LDM is missing',
    },
    BTN_DOWNLOAD_XLSX_TEMPLATE: 'Download template (.xlsx)',
  },
  SWITCH: {
    YES: 'YES',
    NO: 'NO',
  },
  VALIDATION: {
    IS_REQUIRED_TEXT: 'Required fields are marked with *',
    INVALID_VALUE: 'Invalid value',
    THIS_FIELD_IS_REQUIRED: 'This field is required',
    YOU_HAVE_TO_SELECT_AT_LEAST_ONE_ITEM: 'You have to select at least one item',
    AT_LEAST_ONE_ITEM_NEEDS_TO_BE_ADDED_IN_THE_SHIPMENT:
      'Please select at least 1 item to complete the shipment',
    SOME_REQUIRED_FIELDS_ARE_NOT_FILLED: 'Some required fields are not filled',
    NEW_SHIPMENT_VALIDATION_ERROR: 'The shipment cannot be saved, because there is information missing in the shipment.',
    INVALID_PHONE: 'Invalid phone number',
    INVALID_EMAIL: 'Invalid email address',
    SELECTED_DATE_IS_WRONG: 'Selected date is wrong',
    SELECTED_EARLIEST_DATE_IS_WRONG: 'Please check latest date',
    SELECTED_ARRIVAL_DATE_IS_WRONG: 'Please check earliest delivery date',
    TEMPLATE_NAME: 'Template name must be at least 3 characters'
  },
  UNITS:{
    PC:'pc'
  },
  COMPANY: {
    CLIENT_CODE: "Client code",
    CLIENT_CODE_TOOLTIP: 'E.g You can share it to your suppliers to make orders.',
    ORDER_URL: 'Order url',
    ORDER_URL_COPIED: 'Order url copied',
    COMPANY: 'Company',
    COMPANY_NAME: 'Company name',
    COMPANY_PROFILE: 'Company profile',
    COMPANY_DETAILS: 'Company details',
    EDIT_DETAILS: 'Edit details',
    WORKING_HOURS: 'Working hours',
    VAT_NUMBER: 'VAT number',
    REGISTRY_CODE: 'Registry code',
    CONTACT_PERSON: 'Contact person',
    EDIT_PERSON: 'Edit person',
    PHONE_NO: 'Phone no',
    EMAIL: 'E-mail',
    ADDRESSES: 'Addresses',
    OFFICIAL_ADDRESS: 'Company\'s official address',
    EDIT_OFFICIAL_ADDRESS: 'Edit company\'s official address',
    MAIN_ADDRESS: 'Company\'s main address',
    EDIT_MAIN_ADDRESS: 'Edit company’s main address',
    EDIT_INVOICE_ADDRESS: 'Edit invoice address',
    INVOICE_CONFIGURATION: 'Invoice configuration',
    INVOICE_COMPANY_DETAILS: 'Invoice company details',
    INVOICE_CONTACT_PERSON: 'Invoice contact person',
    INVOICE_RECEIVER_EMAIL: 'Invoice receiver e-mail',
    INVOICE_TYPE: 'Invoice type',
    DUE_DATE_DAYS: 'Due date days',
    INVOICE_ADDRESS: 'Invoice address',
    ADDRESS: 'Address',
    CITY: 'City',
    REGION: 'Region',
    POSTAL_CODE: 'Postal code',
    COUNTRY: 'Country',
    EDIT: 'Edit',
    DETAILED: 'Detailed',
    REGULAR: 'Regular',
    APPLY_THIS_INFORMATION_TO_MAIN_ADDRESS: 'Apply this information to company’s main address',
    APPLY_THIS_INFORMATION_TO_INVOICE_ADDRESS: 'Apply this information to invoice address',
    APPLY_THIS_INFORMATION_TO_INVOICE_DETAILS: 'Apply this information to invoice company details',
    APPLY_THIS_INFORMATION_TO_INVOICE_CONTACT: 'Apply this information to invoice contact person',
    DAYS: 'days',
    EDIT_COMPANY_DETAILS: 'Edit company details',
    EDIT_INVOICE_COMPANY_DETAILS: 'Edit invoice company details',
    EDIT_CONTACT_PERSON: 'Edit contact person',
    EDIT_INVOICE_CONTACT_PERSON: 'Edit invoice contact person',
    SAVE_CHANGES: 'Save changes',
  },
  CLAIMS:"Claims",
  CLAIM: {
    ADD_NEW_FROM_SHIPMENT:'Add a new claim for a shipment:',
    ADD_NEW_FROM_SHIPMENT_DESCRIPTION: 'To create a claim, you need to find the correct shipment.',
    SEARCH_FOR_A_SHIPMENT_TITLE:'Search for a shipment:',
    SEARCH_BY_KEYWORD: "Search by keyword",
    PLEASE_CONFIRM: "Submit",
    CONFIRM_TEXT: "Please be sure, that all the information is correct.",
    DOWNLOAD_PDF: "Download PDF",
    DAMAGE_COST: "Claim",
    NUMBER: "Number",
    SAVE_AS_DRAFT_SUCCESS: 'Claim saved as a draft',
    DELETE_DRAFT_SUCCESS: 'Claim draft deleted',
    DELETE_DRAFT_CONFIRMATION: 'Are you sre you want to delete the claim draft?',
    COMPANY_INFO_TITLE: 'Claimant details',
    GENERAL_INFO: 'General info',
    SHIPMENT_NR: 'Shipment number',
    CLAIM_DATE: 'Date of submission',
    DIRECTION: {
      TITLE: 'Direction',
      EXPORT: 'Outbound',
      IMPORT: 'Inbound'
    },
    COMPANY: {
      "NAME": "Company name",
      "ADDRESS": "Address",
      "CONTACT": "Contact person",
      "EMAIL": "E-mail",
      "PHONE": "Phone",
      "IBAN": "Bank account"
    },
    SIDEBAR: {
      DETAILS: "Claim details",
      STATUSES: "Claim status",
    },
    DATE: "Date of submission",
    SHIPMENT_NUMBER: "Shipment number",
    REASON: "Reason for the claim",
    REASON_ERROR: "At least one reason must be selected",
    REASONS: {
      "FULLY_LOST": "Total loss of shipment",
      "FULLY_DAMAGED": "Total damage to shipment",
      "PARTLY_LOST": "Partial loss of shipment",
      "PARTLY_DAMAGED": "Partial damage to shipment",
      "WAS_LATE": "Delay of shipment",
    },
    REASON_FULLY_TITLE: "Description of the entire shipment",
    REASON_PARTLY_TITLE: "Description of partially damaged or lost shipment",
    SEND_CLAIM: "Submit claim",
    DESCRIPTION_TITLE: "Time of discovery, description and amount of damages",
    DESCRIPTION: "Time of damage discovery and description",
    COST: "Amount of damages (€)",
    SHIPMENT_INFO: {
      DESCRIPTION: "Description of goods",
      QUANTITY: "Number of packing units",
      PACKAGE_TYPE: "Package type",
      WEIGHT: "Gross weight (kg)",
      COST: "Shipment value (€)"
    },
    FILES_ADDED: {
      attachedCmr: 'Signed waybill',
      attachedInvoices: 'Purchase or sales invoice(s) for the shipment',
      attachedExpenseDocuments: 'Invoice(s) for the cost of repair',
      attachedLabels: 'Packing list',
      attachedPictures: 'Photos of the damaged goods and packaging',
      attachedOtherDocuments: 'Other documents proving the amount of damages',
    },
    STATUS_Draft: 'Draft',
    STATUS_Processing: 'In process',
    STATUS_Accepted: 'Accepted',
    STATUS_Rejected: 'Rejected',
    STATUS_DECISION: 'Verdict',
    STATUS_DESCRIPTION: 'Decision description'
  }
};
