import React, { ReactElement, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { TextFieldFormik, Typography, Labeled, Checkbox, SelectFormik } from 'components';

import { BaseProps } from 'shared/types';
import { useFormikContext } from 'formik';
import {
  PriceRequestDetailsPayload,
  RegularShipmentPeriod,
  regularShipmentPeriodLabels,
} from 'shared/types/priceRequests/priceRequests';
import { useFormikError } from 'shared/hooks/useFormikError';
import { theme } from 'theme';
import { SelectItem } from 'components/CustomMaterial/MultiSelect/MultiSelect';
import { dimensionsRegExp } from 'shared/constants/regExp';

type PriceRequestEmailStepProps = BaseProps;

const PriceRequestEmailStep: FC<PriceRequestEmailStepProps> = (): ReactElement => {
  const { t } = useTranslation();

  const formik = useFormikContext<PriceRequestDetailsPayload>();

  const regularShipmentsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue('regularShipments', event.target.checked);

      if (!event.target.checked) {
        formik.setFieldValue('regularShipmentsNumber', '');
        formik.setFieldValue('regularShipmentsPeriod', RegularShipmentPeriod.NotSelected);
      }
    },
    [formik],
  );

  const isError = useFormikError();

  const regularShipmentsPeriodItems: SelectItem[] = Object.keys(regularShipmentPeriodLabels)
    .map((key) => ({
      label: t(regularShipmentPeriodLabels[(key as unknown) as RegularShipmentPeriod] ?? ''),
      value: key,
    }))
    .filter((item) => item.label);

  return (
    <Box>
      <Typography variant="h5" marginBottom={4.25}>
        {t('PRICE_REQUEST.ADD_AN_E_MAIL_WHERE_WE_WILL_SEND_THE_OFFER')}
      </Typography>

      <Labeled
        variant="variant2"
        error={!!formik.errors.noticeEmails}
        text={t('SHIPMENT.ADDRESSES.EMAIL')}
        tooltip={t('PRICE_REQUEST.ADD_AN_EMAIL')}
      >
        <TextFieldFormik
          name="noticeEmails"
          error={!!formik.errors.noticeEmails}
          size="small"
          variant="variant6"
          width={342}
          debounced
        />
      </Labeled>
      <Box marginTop={10}>
        <Checkbox
          variant="lightAlt"
          onChange={regularShipmentsChange}
          checked={formik.values.regularShipments ?? false}
          label={t('PRICE_REQUEST.IM_LOOKING_FOR_PRICES_FOR_REGULAR_SHIPMENTS')}
          tooltip={t('PRICE_REQUEST.IM_LOOKING_FOR_PRICES_FOR_REGULAR_SHIPMENTS_TOOLTIP')}
        />
        {formik.values.regularShipments && (
          <>
            <Typography variant="caption" marginTop={2} component="p">
              {t('PRICE_REQUEST.PLEASE_ADD_THE_NUMBER_OF_TIMES_AND_PERIOD')}
            </Typography>
            <Box display="flex" alignItems="center" marginTop={1}>
              <TextFieldFormik
                name="regularShipmentsNumber"
                error={isError('regularShipmentsNumber')}
                variant="variant6"
                type="text"
                size="small"
                width={72}
                numeric
                allowedChars={dimensionsRegExp}
                debounced
              />
              <Typography variant="caption" marginLeft={1} marginRight={3} component="p">
                {t('PRICE_REQUEST.TIMES')}
              </Typography>
              <Typography variant="caption" marginRight={1} component="p">
                {t('PRICE_REQUEST.PER')}
              </Typography>
              <Box width={120}>
                <SelectFormik
                  name="regularShipmentsPeriod"
                  error={isError('regularShipmentsPeriod')}
                  borderColor={theme.palette.custom.lightGray}
                  background="opaque"
                  displayEmpty
                  value={formik.values.regularShipmentsPeriod ?? ''}
                  defaultValue=""
                  defaultLabel={t('PRICE_REQUEST.SELECT_PERIOD')}
                  data={regularShipmentsPeriodItems}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default PriceRequestEmailStep;
