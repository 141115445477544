import qs from 'qs';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type UseQueryReturnType<T> = {
  queryParams: T;
  updateQueryParams: (newQueryParams: Partial<T>) => void;
  clearQueryParams: () => void;
  queryParamsLoading: boolean;
};

export function useQuery<T>(): UseQueryReturnType<T> {
  const { search } = useLocation();
  const history = useHistory();
  const [queryParams, setQueryParams] = useState<T>({} as T);
  const [queryParamsLoading, setQueryParamsLoading] = useState<boolean>(true);

  useEffect(() => {
    setQueryParams((qs.parse(search, { ignoreQueryPrefix: true }) as unknown) as T);
    setQueryParamsLoading(false);
  }, [search]);

  const updateQueryParams = (newQueryParams: Partial<T>) => {
    history.push({
      pathname: history.location.pathname,
      search: qs.stringify({ ...queryParams, ...newQueryParams }, { addQueryPrefix: true }),
    });
  };

  const clearQueryParams = () => {
    history.push({
      pathname: history.location.pathname,
      search: '',
    });
  };

  return {
    queryParams,
    updateQueryParams,
    clearQueryParams,
    queryParamsLoading,
  };
}
