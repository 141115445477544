import React, { FC, ReactElement, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';

import { BaseProps } from 'shared/types';
import { Dialog, Indicator, Typography } from 'components';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import { shipmentStatusLabels } from 'shared/constants/shipments/shipmentStatuses';
import {
  simplifiedStatusMapping,
  simplifiedStatusTrackingMapping,
} from 'shared/functions/shipments/simplifiedStatusMapping';
import {
  ShipmentEvent,
  ShipmentStatus,
  ShipmentStatus as ShipmentStatusType,
} from 'shared/types/shipments/shipments';
import clsx from 'clsx';
import { generateKey } from 'shared/functions/generateKey';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useCommonStyles } from '../../shared/styles/common';

type ShipmentStatusOverviewProps = {
  status: ShipmentStatusType;
  createdTime?: string;
  createdBy?: string;
  processingTime?: string;
  collectedTime?: string;
  deliveredTime?: string;
  shipmentEvents: ShipmentEvent[];
  publicTracking?: boolean;
} & BaseProps;

const useStyles = makeStyles({
  heading: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  indicator: {
    position: 'relative',
    marginBottom: theme.spacing(6),
  },
  current: {
    fontWeight: 700,
  },
  index: {
    marginLeft: -1,
    marginTop: 1,
  },
  future: {
    color: theme.palette.custom.gray,
  },
  time: {
    color: theme.palette.custom.veryDarkGrayAlt2,
  },
  table: {
    minWidth: 650,
    marginBottom: theme.spacing(4),
    '& .MuiTableCell-head': {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      borderBottomColor: theme.palette.custom.gray,
      paddingBottom: 5,
      paddingTop: 8,
    },
    '& .MuiTableCell-body': {
      fontSize: '0.75rem',
      paddingTop: 12,
      paddingBottom: 11,
    },
    '& tr:last-child .MuiTableCell-body': {
      borderBottom: 'none',
    },
    '& .MuiTableBody-root td:first-child, .MuiTableHead-root th:first-child': {
      paddingLeft: theme.spacing(6),
    },
  },
});

const ShipmentStatusOverview: FC<ShipmentStatusOverviewProps> = ({
  status,
  createdTime,
  processingTime,
  deliveredTime,
  collectedTime,
  shipmentEvents,
  publicTracking,
  createdBy,
  ...restProps
}): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const simplifiedStatusesMapped = !publicTracking
    ? simplifiedStatusMapping(status)
    : simplifiedStatusTrackingMapping(status);
  const [showEventsDialog, setShowEventsDialog] = useState(false);

  const toggleShowEventsDialog = async () => {
    setShowEventsDialog(!showEventsDialog);
  };

  const simplifiedStatuses = (Object.keys(simplifiedStatusesMapped).sort() as unknown) as Array<
    ShipmentStatusType
  >;

  const times: Partial<Record<ShipmentStatus, string | undefined>> = {
    [ShipmentStatus.Received]: createdTime,
    [ShipmentStatus.Processing]: processingTime,
    [ShipmentStatus.InTransit]: collectedTime,
    [ShipmentStatus.Delivered]: deliveredTime,
  };

  return (
    <Box {...restProps}>
      <Dialog
        title={t('DETAILED_SHIPMENT_EVENTS')}
        maxWidth="lg"
        open={showEventsDialog}
        handleClose={toggleShowEventsDialog}
      >
        <TableContainer>
          <Table className={classes.table} aria-label="shipment events">
            <TableHead>
              <TableRow>
                <TableCell align="left">{t('STATUS')}</TableCell>
                <TableCell align="left">{t('DATE')}</TableCell>
                <TableCell width={350} align="left">
                  {t('DESCRIPTION')}
                </TableCell>
                <TableCell width={250} align="left">
                  {t('LOCATION')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shipmentEvents?.length > 0 &&
                shipmentEvents.map((item: ShipmentEvent, index) => {
                  return (
                    <TableRow key={generateKey(index, `event`)}>
                      <TableCell align="left">{item.status}</TableCell>
                      <TableCell align="left">{item.dateString}</TableCell>
                      <TableCell align="left">
                        <Typography variant="inherit" component="span" dashWhenEmpty>
                          {item.description}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="inherit" component="span" dashWhenEmpty>
                          {item.location}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
      <Typography variant="subtitle2" component="p" className={classes.heading}>
        {t('WHERE_IS_MY_SHIPMENT')}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        link
        onClick={toggleShowEventsDialog}
        marginBottom={6}
      >
        {t('DETAILED_SHIPMENT_EVENTS')}
      </Typography>
      {simplifiedStatuses.map((value, index) => {
        if (!value) {
          return null;
        }

        const simplifiedStatus: ShipmentStatus = Number(value);

        const connectorColor =
          simplifiedStatus <= status
            ? theme.palette.custom.strongViolet
            : theme.palette.custom.grayAlt;

        const connectorStyle = `2px ${simplifiedStatus !== status ? 'solid' : 'dashed'}`;

        const time = times[simplifiedStatus];

        return (
          <Indicator
            key={generateKey(index, 'status_indicator')}
            className={classes.indicator}
            active={simplifiedStatus <= status}
            icon={
              <Typography variant="h6" color="textSecondary" className={classes.index}>
                {index + 1}
              </Typography>
            }
          >
            <Box
              height={32}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              marginLeft={1}
            >
              <Typography
                variant="body1"
                className={clsx(
                  simplifiedStatus === status && classes.current,
                  simplifiedStatus > status && classes.future,
                )}
                component="p"
              >
                {t(shipmentStatusLabels[simplifiedStatus])}
              </Typography>
              {time && (
                <Typography variant="caption" component="p" className={classes.time}>
                  {time}
                </Typography>
              )}
              {createdBy && simplifiedStatus === ShipmentStatus.Received && (
                <span
                  style={{ fontSize: '0.75rem' }}
                  className={clsx(commonClasses.italic, classes.time)}
                >
                  {createdBy}
                </span>
              )}
            </Box>
            {index < simplifiedStatuses.length - 1 && (
              <Box
                position="absolute"
                left={15}
                height={27}
                top={30}
                borderLeft={connectorStyle}
                borderColor={connectorColor}
              />
            )}
          </Indicator>
        );
      })}
    </Box>
  );
};

export default ShipmentStatusOverview;
