import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';

import { theme } from 'theme';
import { TextFieldFormik, Button, Loader, LanguageSwitcher, Labeled, Typography } from 'components';
import { formStates } from 'shared/constants/formStates';
import { languages } from 'shared/constants/languages';
import { useSessionApi } from 'shared/services/session';
import { useUserAccountApi } from 'shared/services/user';
import { getLanguageByCode } from 'shared/functions/getLanguageByCode';

import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { forgotPassword, routeDashboard } from 'routes';
import { useUser } from 'shared/hooks/useUser';
import SystemAlert from '../components/CustomMaterial/Alert/Alert';
import ArrowRight from '../components/Icons/Arrows/ArrowRight';
import { useCommonStyles } from '../shared/styles/common';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: theme.palette.custom.veryDarkViolet,
  },
  topContainer: {
    paddingTop: theme.spacing(10),
    position: 'absolute',
    height: '100%',
    left: 0,
    right: 0,
  },
  container: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 6),
    },
    position: 'relative',
    height: '100%',
  },
  accent1: {
    position: 'absolute',
    top: 53,
    left: -252,
  },
  accent2: {
    position: 'absolute',
    top: 445,
    left: 691,
  },
  accent3: {
    position: 'absolute',
    top: -44,
    left: 36,
  },
  accent4: {
    position: 'absolute',
    bottom: -41,
    right: 26,
  },
  heroTextWrapper: {
    paddingTop: theme.spacing(14),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(0),
    },
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    zIndex: 1,
  },
  loginImage: {
    position: 'absolute',
    top: 0,
    maxWidth: '100%',
    objectFit: 'cover',
  },
  logo: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  paper: {
    width: '100%',
    padding: theme.spacing(16, 8, 19),
    marginLeft: theme.spacing(2.5),
    position: 'relative',
  },
  title: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  description: {
    marginBottom: theme.spacing(6),
    textAlign: 'center',
  },
  label: {
    marginTop: theme.spacing(4),
  },
  passwordInput: {
    marginBottom: theme.spacing(6),
  },
  languageSwitcher: {
    right: 40,
  },
}));
type UnauthenticatedAppProps = {
  hideLogoutMessage: () => void;
};

const UnauthenticatedApp: FC<UnauthenticatedAppProps> = ({ hideLogoutMessage }): ReactElement => {
  const user = useUser();
  const commonClasses = useCommonStyles();
  const { logIn } = useSessionApi();
  const { fetchUserAccountInfo } = useUserAccountApi();
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [formState, setFormState] = useState(formStates.PRISTINE);
  const [isPending, setIsPending] = useState(false);
  const [accountIsBlocked, setAccountIsBlocked] = useState(false);

  type FormValues = {
    username: string;
    password: string;
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().min(2, t('INVALID_USERNAME')).required(),
    password: Yup.string().required(),
  });

  const initialValues: FormValues = {
    username: '',
    password: '',
  };

  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const handleSubmit = async (
    { username, password }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    hideLogoutMessage();
    setFormState(formStates.PRISTINE);
    setIsPending(true);
    setAccountIsBlocked(false);

    try {
      const {
        isBadRequest,
        access_token: accessToken,
        expires_in: expiresIn,
        refresh_token: refreshToken,
        errors,
      } = await logIn({
        username,
        password,
      });
      if (!isBadRequest && accessToken) {
        const { payload: accountInfo } = await fetchUserAccountInfo({
          token: accessToken,
        });
        user.logIn(
          {
            username,
            token: accessToken,
            expirationDate: Date.now() + expiresIn * 1000,
            refreshToken,
          },
          { ...accountInfo },
        );
        // FIXME: history.replace(routeShipments) or history.push(routeShipments)
        window.location.href = routeDashboard;
      } else if (errors && errors.length > 0) {
        throw new Error(errors[0]);
      } else {
        throw new Error('Failed to log in');
      }
    } catch (e) {
      if (e.message && e.message.includes('blocked')) setAccountIsBlocked(true);
      setFormState(formStates.ERROR);
      setSubmitting(false);
      setIsPending(false);
    }
  };

  const goToForgotPasswordPage = () => {
    window.location.href = forgotPassword;
  };

  return (
    <Box bgcolor="#ebedef" className={classes.wrapper} width={1} height={1} overflow="hidden">
      <Container maxWidth="xl" className={classes.topContainer}>
        <LanguageSwitcher
          onLanguageChange={onLanguageChange}
          selectedLanguage={getLanguageByCode(i18n.language)}
          languages={languages}
          className={classes.languageSwitcher}
        />
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        <Box display="flex" height={1}>
          <Box width="14%" />
          <Box width="70%" display="flex" alignItems="center">
            <Paper className={classes.paper}>
              <div className={classes.logo}>
                <img height={180} alt="LaasOne" src="/images/laasone_logo.png" />
              </div>
              <Typography variant="h3" className={classes.title}>
                {t('LOGIN_BOX_TITLE')}
              </Typography>
              <Typography variant="body2" className={classes.description}>
                {t('LOGIN_BOX_DESC')}{' '}
              </Typography>
              <Box position="relative">
                {formState === formStates.ERROR && (
                  <SystemAlert
                    color="error"
                    fullWidth={false}
                    lessPadding
                    onClose={() => setFormState(formStates.PRISTINE)}
                  >
                    <Typography variant="inherit">
                      {!accountIsBlocked ? t('FAILED_TO_LOG_IN') : t('FAILED_TO_LOG_IN_BLOCKED')}
                    </Typography>
                  </SystemAlert>
                )}
                {isPending && <Loader cover />}
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  {({ errors, touched, isSubmitting }) => (
                    <Form>
                      <Labeled
                        id="username-label"
                        className={classes.label}
                        error={!!(errors.username && touched.username)}
                        text={t('USERNAME')}
                        required
                      >
                        <TextFieldFormik
                          name="username"
                          variant="variant2"
                          type="text"
                          error={!!(errors.username && touched.username)}
                          fullWidth
                        />
                      </Labeled>
                      <Labeled
                        id="password-label"
                        className={classes.label}
                        error={!!(errors.password && touched.password)}
                        text={t('PASSWORD')}
                        required
                      >
                        <TextFieldFormik
                          name="password"
                          className={classes.passwordInput}
                          variant="variant2"
                          type="password"
                          error={!!(errors.password && touched.password)}
                          fullWidth
                        />
                      </Labeled>
                      <Box textAlign="center">
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting || isPending}
                        >
                          {t('SIGN_IN')}
                          <span className={commonClasses.ml3}>
                            <ArrowRight />
                          </span>
                        </Button>
                      </Box>
                      <Box textAlign="center" marginTop={3}>
                        <Typography
                          link
                          variant="caption"
                          marginTop={3}
                          onClick={() => goToForgotPasswordPage()}
                        >
                          {t('FORGOT_PASSWORD')}
                        </Typography>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default UnauthenticatedApp;
